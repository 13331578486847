import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { RouterConstants } from "../../constants/router.contants";
import { UserDetailsModel } from "../../models/UserDetailsModel";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { ApiCallService } from "../../services/api-call-svc/api-call.service";
import { ActivatedRoute } from "@angular/router";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent implements OnInit, AfterViewInit {
  config = [];
  resultImage: any;
  resultResult: any;
  routerConstants = RouterConstants;
  user: UserDetailsModel;
  environment = environment;
  followersCount: any;
  followingCount: any;
  postedJobsCount: any;
  appliedJobsCount: any;
  DesignationAndCompanyName: any;
  basicProfiles: any;
  educationalInfo: [];
  otherUser: any;
  editImage: boolean;
  aboutUser: any;
  userProfilePhoto: any;
  userBackgroundPhotoPath: any;
  openToHire = false;
  companyName: any;
  jobTitle: any;
  experienceYear: number;
  cropImgSrc: string;
  isProfilePicCropper: boolean = false;
  resumePathStatus: boolean = true;

  constructor(
    private authSvc: AuthenticationService,
    private api: ApiCallService,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) {
    this.editImage = false;
  }

  ngOnInit(): void {
    this.user = this.authSvc.getUserObj();
    this.getDesignationAndCompanyName();
    this.getBasicInfo();
    this.getEducationalInfo();
    this.getFollowersCount();
    this.getFollowingCount();
    this.getPostedJobsCount();
    this.getAppliedJobsCount();
    this.getResume();
    this.progressBar();
    this.getResumePathStatus();
  }

  getFollowersCount() {
    const url = "/api/user/follow/followers-count";
    this.api.getApiCall(url).subscribe(
      (res) => {
        this.followersCount = res;
      },
      (err) => { }
    );
  }

  getFollowingCount() {
    const url = "/api/user/follow/following-count";
    this.api.getApiCall(url).subscribe(
      (res) => {
        this.followingCount = res;
      },
      (err) => { }
    );
  }

  getPostedJobsCount() {
    const url = "/api/feed/jobs-posted-count";
    this.api.getApiCall(url).subscribe(
      (res) => {
        this.postedJobsCount = res;
      },
      (err) => { }
    );
  }

  getAppliedJobsCount() {
    const url = "/api/applied-job/count-by-user";
    this.api.getApiCall(url).subscribe(
      (res) => {
        this.appliedJobsCount = res;
      },
      (err) => { }
    );
  }

  getDesignationAndCompanyName() {
    const url = "/api/user/professional-info/" + this.user.id;
    this.api.getApiCall(url).subscribe(
      (res) => {
        if (res != null) {
          this.DesignationAndCompanyName = res;
          this.companyName =
            res[res.length - 1] != null
              ? res[res.length - 1].companyName
              : null;
          this.jobTitle =
            res[res.length - 1] != null ? res[res.length - 1].jobTitle : null;
        }

        // console.log(res);
        // var date = new Date(res[res.length-1].endDate);
        // var date1= new Date(res[res.length-1].startDate);
        // var Difference_In_Time = date.getTime() - date1.getTime();
        // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        // var Difference_In_years = Difference_In_Days/365;
        // this.experienceYear = +Difference_In_years.toFixed(2);
        // console.log(this.experienceYear)
      },
      (err) => { }
    );
  }

  getBasicInfo() {
    const url = "/api/user/user-details/" + this.user.username;
    this.api.getApiCall(url).subscribe(
      (res) => {
        this.basicProfiles = res;
        this.openToHire = res.isSearchingForJob;
        this.aboutUser = res.aboutUser;
        this.userProfilePhoto = res.userPhotoPath;
        this.userBackgroundPhotoPath = res.userBackgroundPhotoPath;
        // console.log(res);
      },
      (err) => { }
    );
  }

  getEducationalInfo() {
    const url = "/api/user/educational-info/" + this.user.id;
    this.api.getApiCall(url).subscribe(
      (res) => {
        this.educationalInfo = res;

        // console.log(res);
      },
      (err) => { }
    );
  }

  //profile Image
  coverFile: FileList;
  coverImage_path = "./assets/images/edit1.svg";
  coverMessage: string;
  coverImage: string;
  coverImageFile = [];
  uploadedFiles = [];
  files = [];
  content = "";

  getCoverImageFile(coverUpload: HTMLInputElement, event: any) {
    this.coverFile = coverUpload.files;
    if (this.coverFile.length === 0) return;

    const fileName = event.target.files[0].name;
    this.uploadedFiles = event.target.files;
    let mimeType = this.coverFile[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.coverImage_path = "./assets/images/profilecoverimage.jpeg";
      this.coverMessage = "Only image files are supported.";
      this.coverImage = "No File Chosen";
      return;
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(this.coverFile[0]);
      reader.onload = (subevent) => {
        this.content = reader.result as string;
        const json = {
          fileName: fileName,
          content: subevent.target.result,
          fileType: mimeType,
          fileData: this.uploadedFiles[0],
        };
        this.files.push(json);
        this.uploadCoverImage();
      };
      // reader.readAsDataURL(this.uploadedFiles[0]);
      this.coverMessage = null;
      this.coverImageFile = event.target.files[0];
    }
  }

  cropImageIndex = null;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  showImageCropContainer = false;

  cropSelectedImage(element, isProfilePicCropper) {
    $("#cropImage").modal("show");
    this.cropImageIndex = 0;
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.isProfilePicCropper = isProfilePicCropper;
    this.getProfilePicInBase64(element);
  }

  onCancelCropImg() {
    $("#cropImage").modal("hide");
  }

  imageCropped(event: ImageCroppedEvent) {
    // console.log('image cropped',event);
    this.croppedImage = event.base64;
  }

  onImageCrop() {
    const imageBlob = this.dataURIToBlob(this.croppedImage);
    const imgExt = this.getPicExtension(this.user.userDPUrl);
    const imageFile = new File([imageBlob], "crop." + imgExt, {
      type: this.getMimeType(imgExt),
    });
    this.uploadedFiles = [];
    this.uploadedFiles.push(imageFile);
    this.uploadCoverImage();
    $("#cropImage").modal("hide");
  }

  onBgImageCrop() {
    const imageBlob = this.dataURIToBlob(this.croppedImage);
    const imgExt = this.getPicExtension(this.user.userBgUrl);
    const imageFile = new File([imageBlob], "crop." + imgExt, {
      type: this.getMimeType(imgExt),
    });
    this.files1 = [];
    this.files1.push({ fileData1: imageFile });
    this.uploadCoverImage1();
    $("#cropImage").modal("hide");
  }

  getPicExtension(imgName) {
    let result = "";
    if (imgName) {
      result = imgName.substring(imgName.lastIndexOf(".") + 1, imgName.length);
      if (result.indexOf("?") > -1) {
        result = result.substring(0, result.lastIndexOf("?"));
      }
    }
    return result;
  }

  getMimeType(imgExt) {
    let result = "image/png";
    if (imgExt == "jpg" || imgExt == "jpeg") {
      result = "image/jpg";
    }
    return result;
  }

  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  uploadCoverImage() {
    const formData: FormData = new FormData();
    let imgExtension = "";
    let imgName = "";
    for (const fileData of this.uploadedFiles) {
      formData.append("file", fileData);
      imgExtension = this.getPicExtension(fileData.name);
      //console.log(fileData);
    }

    // formData.append( "coverImage", this.coverImageFile);
    // console.log(formData);
    const url = "/api/user/upload-user-profile/";
    this.api.postApiCall(url, formData).subscribe((res: any) => {
      let dpURL = this.getPicPath(imgExtension, "ProfilePhoto");
      this.user.userDPUrl = dpURL;
      this.authSvc.setUserObj(this.user);
      this.authSvc.profileChangeSubject.next(dpURL);
    });
  }

  getPicPath(ext: string, source: string): string {
    if (source == "ProfilePhoto") {
      return ext
        ? this.user.username +
        "/" +
        source +
        "/" +
        this.user.username +
        "." +
        ext +
        "?cache=" +
        Date.now()
        : this.user.userDPUrl;
    } else {
      return ext
        ? this.user.username +
        "/" +
        source +
        "/" +
        this.user.username +
        "." +
        ext +
        "?cache=" +
        Date.now()
        : this.user.userBgUrl;
    }
  }

  //Cover Image
  coverImage_path1 = "./assets/images/profile-square.jpg";
  coverFile1: FileList;
  coverMessage1: string;
  coverImage1: string;
  coverImageFile1 = [];
  uploadedFiles1 = [];
  files1 = [];
  content1 = "";

  getCoverImageFile1(coverUpload1: HTMLInputElement, event1: any) {
    this.coverFile1 = coverUpload1.files;
    if (this.coverFile1.length === 0) return;
    const fileName1 = event1.target.files[0].name;
    this.uploadedFiles1 = event1.target.files[0];
    let mimeType = this.coverFile1[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.coverImage_path1 = "./assets/images/profilecoverimage.jpeg";
      this.coverMessage1 = "Only image files are supported.";
      this.coverImage1 = "No File Chosen";
      return;
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(this.coverFile1[0]);
      reader.onload = (subevent1) => {
        this.content1 = reader.result as string;
        const json1 = {
          fileName: fileName1,
          content1: subevent1.target.result,
          fileType: mimeType,
          fileData1: this.uploadedFiles1,
        };
        this.files1.push(json1);
        this.uploadCoverImage1();
        // console.log(this.files1[0].content1);
      };
      // reader.readAsDataURL(this.uploadedFiles[0]);
      this.coverMessage1 = null;
      this.coverImageFile1 = event1.target.files[0];
    }
  }

  cropImageIndex1: any;
  imageChangedEvent1: any;
  croppedImage1: any;
  showImageCropContainer1 = false;

  cropSelectedImage1(index) {
    this.cropImageIndex1 = index;
    this.imageChangedEvent1 = "";
    this.croppedImage1 = "";
    this.imageChangedEvent1 = this.files1[index].content1;
    this.showImageCropContainer1 = true;
  }

  imageCropped1(event: ImageCroppedEvent) {
    this.croppedImage1 = event.base64;
  }

  onImageCrop1() {
    this.files1[this.cropImageIndex1].content1 = this.croppedImage1;
    this.showImageCropContainer1 = false;
    this.croppedImage1 = "";
    this.uploadCoverImage1();
  }

  uploadCoverImage1() {
    // console.log("test");
    const formData: FormData = new FormData();
    let imgExtension = "";
    if (this.coverImageFile1 != null) {
      formData.append("file", this.files1[0].fileData1);
      imgExtension = this.getPicExtension(this.files1[0].fileData1.name);
    }
    //console.log(formData)
    const url = "/api/user/upload-user-background-photo/";
    this.api.postApiCall(url, formData).subscribe((res: any) => {
      let dpURL = this.getPicPath(imgExtension, "BackgroundPhoto");
      this.user.userBgUrl = dpURL;
      this.authSvc.setUserObj(this.user);
      this.files1 = [];
    });
  }

  shareProfile() {
    // var copyText = document.getElementById("");
    // copyText.select();
    // copyText.setSelectionRange(0, 99999)
    // document.execCommand("copy");
    // alert("Copied the text: " + copyText.value);
  }

  openForHiring() {
    this.openToHire = !this.openToHire;
    const url = "/api/user/update-open-for-hiring/" + this.openToHire;
    const json = { isSearchingJob: this.openToHire };
    this.api.putApiCall(url, json).subscribe((res) => {
      // console.log(res);
    });
  }

  progressValue1: number;
  progressValue2: number;
  progressValue3: number;
  progressValue4: number;
  progressValue5: number;
  progressValue6: number;
  progressValue7: number;
  totalProgress: number;

  progressBar() {
    this.user = this.authSvc.getUserObj();
    if (this.user.email == null && this.user.fname == null && this.user.lname) {
      this.progressValue1 = 0;
    } else {
      this.progressValue1 = 15;
    }

    if (this.user.userDPUrl == null) {
      this.progressValue2 = 0;
    } else {
      this.progressValue2 = 20;
    }

    if (this.user.userBgUrl == null) {
      this.progressValue3 = 0;
    } else {
      this.progressValue3 = 20;
    }

    if (this.user.dateOfBirth == null) {
      this.progressValue4 = 0;
    } else {
      this.progressValue4 = 10;
    }

    if (this.resumeLink == 0) {
      this.progressValue5 = 0;
    } else {
      this.progressValue5 = 5;
    }

    if (this.educationalInfo.length == 0) {
      this.progressValue6 = 0;
    } else {
      this.progressValue6 = 15;
    }

    if (this.DesignationAndCompanyName == 0) {
      this.progressValue7 = 0;
    } else {
      this.progressValue7 = 15;
    }

    this.totalProgress =
      this.progressValue1 +
      this.progressValue2 +
      this.progressValue3 +
      this.progressValue4 +
      this.progressValue5 +
      this.progressValue6 +
      this.progressValue7;
  }

  onFileUpload(event, type) {
    this.files = [];
    this.uploadedFiles = [];
    this.uploadedFiles = event.target.files;
    const extension = this.uploadedFiles[0].name.split(".").pop();
    if (extension === "pdf" || extension === "docx" || extension === "doc") {
      if (this.uploadedFiles && this.uploadedFiles.length > 0) {
        const filesAmount = this.uploadedFiles.length;
        if (filesAmount < 2) {
          for (let i = 0; i < filesAmount; i++) {
            const fineName = this.uploadedFiles[i].name;
            const reader = new FileReader();
            reader.onload = (subevent: any) => {
              const json = {
                fileName: fineName,
                content: subevent.target.result,
                fileType: type === "image" ? "img" : "doc",
                fileData: this.uploadedFiles[i],
              };
              this.files.push(json);
            };
            reader.readAsDataURL(this.uploadedFiles[i]);
          }
        } else {
          alert("Sorry,  Max u can upload only 1 files");
        }
      }
      this.uploadResume();
    } else {
      alert('Invalid File Format. We accept only pdf, doc and docx file.');
    }
  }

  uploadResume() {
    const formData: FormData = new FormData();
    if (this.uploadedFiles != null) {
      for (const fileData of this.uploadedFiles) {
        formData.append("file", fileData);
      }
    }

    const url = "/api/user/upload-user-resume/";
    this.api.postApiCall(url, formData).subscribe(
      (res) => {
        this.toastr.success("Resume Uploaded successfully!");
        this.getResumePathStatus();
        this.getResume();
      },
      (err) => {
        alert(err);
      }
    );
  }

  resumeLink: any;
  getResume() {
    const url = "/api/user/get-resume-link";
    this.api.getApiCall(url).subscribe((res) => {
      this.resumeLink = res.url;
      //console.log(res)
    });
  }

  getProfilePicInBase64(elementId): void {
    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        //console.log(reader.result);
        self.imageChangedEvent = reader.result;
      };
      reader.readAsDataURL(xhr.response);
    };
    var img: any = document.getElementById(elementId);
    xhr.open("GET", img.src);
    xhr.responseType = "blob";
    xhr.send();
  }

  ngAfterViewInit() {
    $(".cropper-container").css("height", "368px");
  }

  getResumePathStatus() {
    this.api.getApiCall(`/api/user/get-resume-path-status`).subscribe((res) => {
      this.resumePathStatus = res;
    });
  }

  deleteResume(userId) {
    this.api
      .deleteApiCall(`/api/user/delete-resume-path/` + userId)
      .subscribe((res) => {
        this.getResumePathStatus();
        this.toastr.success("Resume Deleted successfully!");
      });
  }
}
