import { Component, OnInit, Input } from '@angular/core';
import { RouterConstants } from 'src/app/constants/router.contants';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  /*
  * default logo will be of blue color
  * if we need white color logo we need to pass logoColor as white
  */
 routerConstants = RouterConstants;


  @Input() logoColor: string;

  constructor() { }

  ngOnInit(): void { }

}
