import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpRequest, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserDetailsModel as UserModel } from '../../models/UserDetailsModel';
import { sessionStorageKeys } from '../../constants/ssKeys.constants';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private ssKeyConst = sessionStorageKeys;
    profileChangeSubject = new Subject();
    cachedRequests: Array<HttpRequest<any>> = [];

    constructor(
        private http: HttpClient,
        private router: Router) { }

    setUserObj(user: UserModel): any {
        sessionStorage.setItem(this.ssKeyConst.userObjKey, JSON.stringify(user));
    }
    getUserObj(): UserModel {
      return JSON.parse(sessionStorage.getItem(this.ssKeyConst.userObjKey));
    }

    getFullName(){
        return this.getUserObj().fname+' '+this.getUserObj().lname;
    }

    setUserById(user: string) {
      sessionStorage.setItem('user',user);
    }
  getUserById(id){
      return this.http.get('/api/user/'+ id);
    }

    setLoginId(name: string) {
        sessionStorage.setItem('loginId', name);
    }

    getLoginId() {
        return sessionStorage.getItem('loginId');
    }
    setUserEnvironment(env: string) {
        sessionStorage.setItem('env', env);
    }
    getUserEnvironment() {
        return sessionStorage.getItem('env');
    }
    setDisplayName(name: string) {
        sessionStorage.setItem('displayUserName', name);
    }
    getDisplayName() {
        return sessionStorage.getItem('displayUserName');
    }

    setUserId(userId: number) {
        sessionStorage.setItem('userId', '' + userId);
    }
    getUserId() {
        return sessionStorage.getItem('userId');
    }

    getUserName() {
        return sessionStorage.getItem('loginId');
    }

    setSecureKey(secKey) {
        // const key = '';
        sessionStorage.setItem(this.ssKeyConst.jwtTokenKey, secKey);
    }
    getSecureKey() {
        return sessionStorage.getItem(this.ssKeyConst.jwtTokenKey);
    }

    public logout(oldState?) {
        sessionStorage.removeItem(this.ssKeyConst.jwtTokenKey);
        sessionStorage.removeItem(this.ssKeyConst.userObjKey);
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['/'], oldState);
        // window.location.href = '/';
    }

    public clearSessionStorage(oldState?) {
        sessionStorage.removeItem(this.ssKeyConst.jwtTokenKey);
        sessionStorage.removeItem(this.ssKeyConst.userObjKey);
        sessionStorage.clear();
        localStorage.clear();
    }

    checkUserlogin() {
        const jwt = this.getSecureKey();
        if (jwt === null || jwt === undefined) {
            return false;
        } else {
            const helper = new JwtHelperService();
            return !helper.isTokenExpired(jwt);
        }

    }

    public collectFailedRequest(req): void {
        this.cachedRequests.push(req);
    }

    public retryFailedRequests(): void {
        // retry the requests. this method can
        // be called after the token is refreshed
    }
}
