<section class="applied-jobs-section">
    <div class="container">
        <h3 class="applied-jobs-title">Your Posts</h3>
        <div class="row">
            <div class="col-md-3 col-sm-12 col-lg-3">
                <app-user-profile-card></app-user-profile-card>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-6 scroll">

                <div class="card profileCard" style="border: 1px solid
        #E0E0E0;" *ngFor="let activity of activityPostList; let
        i=index;">
                    <div class="card-body" style="min-height: 0;">
                        <div class="row">
                            <div class="col-md-1">
                                <a (click)="viewOtherUser(activity.activityPostedByUserId)"><img class="network-userImage" [src]="api.getInitials(activity?.activityPostedByUser?.firstName
                  +
                  ' ' +
                  activity?.activityPostedByUser?.lastName)" alt="" /></a>
                            </div>
                            <div class="col-md-7">
                                <a (click)="viewOtherUser(activity.activityPostedByUserId)"> <span class="post-something">{{activity?.activityPostedByUser?.firstName}}
                  {{activity?.activityPostedByUser?.lastName}}</span>
                                    <span class="project-manager" *ngIf="activity?.activityPostedByUser?.userTitle
                  !=
                  null">{{activity?.activityPostedByUser?.userTitle}}
                </span></a>
                            </div>
                            <div class="col-md-3">
                                <span class="network-datetime">
                {{activity.createdOn | dateAgo}}</span>
                            </div>
                            <div class="col-md-1">
                                <img class="network-more-lines" src="./assets/images/lines.svg" alt="" />
                            </div>
                        </div>
                        <div class="middle-content">
                            <span class="network-postdescription">{{activity.postText}}
            </span>
                            <!-- 1 img -->
                            <div class="post-image1" *ngIf="activity.type == 1">
                                <div *ngIf="activity?.activityPostsPaths.length === 1">
                                    <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}" alt="" />
                                </div>
                            </div>

                            <!-- 2 img -->
                            <div class="row" *ngIf="activity.type == 1 &&
              activity?.activityPostsPaths.length === 2">
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}" alt="" />
                                    </div>
                                </div>
                            </div>

                            <!-- 3 img -->
                            <div class="row" *ngIf="activity.type == 1 &&
              activity?.activityPostsPaths.length === 3">
                                <div class="col-md-12 col-lg-12">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}" alt="" />
                                    </div>
                                </div>
                            </div>

                            <!-- 4 img -->
                            <div class="row" *ngIf="activity.type == 1 &&
              activity?.activityPostsPaths.length === 4">
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[3].path}}" alt="" />
                                    </div>
                                </div>
                            </div>

                            <!-- 5 img -->
                            <div class="row" *ngIf="activity.type == 1 &&
              activity?.activityPostsPaths.length === 5">
                                <div class="col-md-12 col-lg-12">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[3].path}}" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[4].path}}" alt="" />
                                    </div>
                                </div>
                            </div>

                            <div class="post-pdf" *ngIf="activity.type
              == 2">
                                <div class="clearfix">
                                    <img class="float-left
                  post-pdf-image" src="assets/images/pdf.png" alt="" />
                                    <div class="float-left
                  post-pdf-body">
                                        <strong class="post-pdf-title">10
                    Leadership values</strong>
                                        <p class="post-pdf-subtitle">PDF Document
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="post-like">
                                <div class="clearfix">
                                    <img *ngIf="activity.isActivityLike" src="assets/images/like-filled.svg" class="post-likeBtn
                  cursor_pointer" (click)="onUnLike(activity.activityId, i)" alt="" />
                                    <img *ngIf="!activity.isActivityLike" src="assets/images/like.svg" class="post-likeBtn
                  cursor_pointer" (click)="onLike(activity.activityId, i)" alt="" />
                                    <span *ngIf="activity.likedCounts> 0" class="post-likedBy">Liked
                  by <strong *ngIf="activity.isActivityLike">You</strong><strong
                    *ngIf="!activity.isActivityLike">{{activity?.lastLikedBy}}</strong>
                  <strong *ngIf="activity.likedCounts> 1"
                    (click)="allLikedUser(activity.activityId)">
                    and
                    {{activity.likedCounts - 1}}
                    others</strong></span>
                                    <div class="float-right">
                                        <img class="post-shareBtn
                    cursor_pointer" src="assets/images/share1.svg" alt="" />
                                        <img class="post-saveBtn
                    cursor_pointer" *ngIf="activity.isActivitySave" (click)="onRemoveFromSavedCollection(activity.activityId,
                    i)" src="assets/images/bookmark1-filled.svg" alt="" />
                                        <img class="post-saveBtn
                    cursor_pointer" *ngIf="!activity.isActivitySave" (click)="onSave(activity.activityId, i)" src="assets/images/bookmark1.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body" style="border-top: 1px solid
          #E0E0E0;
          min-height: 0;" *ngIf="activity.allowCommentForPost">
                        <div class="clearfix">
                            <div class="float-left">
                                <img *ngIf="!user.userDPUrl" class="post-profilepic" alt="" src="{{environment.userPhotoHost}}default-profile-200x150.png" />
                                <img *ngIf="user.userDPUrl" class="post-profilepic" alt="" src="{{environment.userPhotoHost}}{{user.userDPUrl}}" />
                            </div>
                            <div class="float-left" style="width: 92%;">
                                <div class="rounded rounded-pill
                shadow-sm
                post-comment-box">
                                    <form [formGroup]="commentOnPostFormGroup">
                                        <div class="input-group">
                                            <textarea placeholder="{{constants.add_comment}}" aria-describedby="button-addon2" class="form-control
                      border-0
                      postInput my-auto post-comment-text-box" formControlName="comment"></textarea>
                                            <div class="input-group-append
                      post-comment">
                                                <a (click)="onComment(activity.activityId, i)">POST</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="comments">
                            <div class="clearfix comment-section" *ngFor="let comment of
              activity?.activityComments.reverse()">
                                <div class="float-left" style="width:
                auto">
                                    <strong class="post-comment-author">{{comment.firstName}}
                  {{comment.lastName}}</strong>
                                </div>
                                <div class="float-left">
                                    <span class="post-comment-text">{{comment.commentsText}}
                  -
                  {{comment.createdOn | dateAgo}}</span>
                                </div>
                            </div>
                            <a *ngIf="activity?.totalComments>
              activity.initialCommentsList" class="view-all-comments" (click)="onLoadNextFewComments(activity.activityId, i)">View all
              {{activity?.totalComments- activity.initialCommentsList}}
              comments</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">

                <!-- <div class="manage-categories" *ngIf="userStats != null">
                    <div class="cursor_pointer" (click)="getAllActivityPosts();
                        activeTab=
                        'yourPosts'">
                        <img src="./assets/images/right.png" class="manage-categories-icon" alt="" /><span class="manage-categories-label">Your Posts</span> &nbsp;&nbsp; {{userStats.yourPosts}}
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section cursor_pointer" (click)="getAllActivityPosts(); activeTab=
                        'savedPosts'">
                        <img src="./assets/images/icons_save.png" class="manage-categories-icon-save" alt="" /><span class="manage-categories-label"> Saved Posts</span> &nbsp;&nbsp; {{userStats.savedPosts}}
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section cursor_pointer" (click)="activeTab= 'shortlistedJobs'">
                        <img src="./assets/images/love.png" class="manage-categories-icon" alt="" /><span class="manage-categories-label">Shortlisted Jobs</span> &nbsp;&nbsp; {{userStats.shortlistedJobs}}
                    </div>
                    <hr style="background-color: #C8C8C8;" *ngIf="userStats.postedJobs> 0">
                    <div class="manage-categories-section cursor_pointer" (click)="activeTab= 'jobsPosted'" *ngIf="userStats.postedJobs>
                        0">
                        <img src="./assets/images/right.png" class="manage-categories-icon" alt="" /><span class="manage-categories-label">Posted Jobs</span> &nbsp;&nbsp; {{userStats.postedJobs}}
                    </div>
                    <hr style="background-color: #C8C8C8;" *ngIf="userStats.appliedJobs> 0">
                    <div class="manage-categories-section cursor_pointer" (click)="activeTab= 'jobsApplied'" *ngIf="userStats.appliedJobs> 0">
                        <img src="./assets/images/right.png" class="manage-categories-icon" alt="" /><span class="manage-categories-label">Applied Jobs</span> &nbsp;&nbsp; {{userStats.appliedJobs}}
                    </div>
                </div> -->


                <div class="manage-categories applied-jobs-categories">
                    <div class="manage-categories-section clearfix">
                        <img src="./assets/images/mypost.svg" class="manage-categories-icon float-left" />
                        <span class="manage-categories-label float-left">Your Posts</span>
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section clearfix">
                        <img src="./assets/images/savepost.svg" class="manage-categories-icon float-left" />
                        <span class="manage-categories-label float-left"> Saved Posts</span>
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section clearfix">
                        <img src="./assets/images/manage-shortlisted.svg" class="manage-categories-icon float-left" /><span class="manage-categories-label float-left">Shortlisted Jobs</span>
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section clearfix">
                        <img src="./assets/images/manage-job.svg" class="manage-categories-icon float-left" />
                        <span class="manage-categories-label float-left">Applied Jobs</span>
                    </div>
                </div>
                <div class="card profileCard">
                    <app-add-box></app-add-box>
                </div>
                <hr class="network-linebreak">
                <div class="network-right-side-info">
                    <span>About US</span>
                    <span style="margin-left: 28px;margin-right: 28px;">Contact US</span>
                    <span>Privacy Policy</span>
                </div>
                <div class="network-socialMedia" align="center">
                    <img src="./assets/images/facebook_grey.png" class="network-facebook" alt="facebook" />
                    <img src="./assets/images/twitter_grey.png" class="network-twitter" alt="twitter" />
                    <img src="./assets/images/instagram_grey.png" class="network-instagram" alt="instagram" />
                </div>
            </div>
        </div>
    </div>
</section>
