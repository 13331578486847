<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-6">
      <div class="container">
        <div class="signupstep1">
          <img src="./assets/images/logo.png" />
          <div class="signupstep1-welcome">
            <h1 class="welcomeTitle">Welcome Back!</h1>
            <p class="welcomSubtitle">We missed you.</p>
          </div>
          <div class="signupstep1-welcomeimage">
            <img src="./assets/images/banner_image.png" alt="Welcome-Image" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 signup-step1background">
      <!-- Large outline input -->
      <div class="container">
        <div class="clearfix">
          <div class="signup-step1-siginTitle float-right">
            <p>Don't have an account yet? <a class="signin-" routerLink="/{{routerConstants.signUp}}"
                routerLinkActive="active">GET STARTED</a></p>
          </div>
        </div>
        <div class="signup-form">
          <h2 class="signuptitle">Forgot Password</h2>
          <p *ngIf="apiResponse" class="has-error">{{apiResponse}}</p>

          <form [formGroup]="formGroup" (ngSubmit)="forgotPasswordSubmit(formGroup.value)">
            <div class="md-form md-outline form-lg">
              <input id="form-lg" [ngClass]="{ 'is-invalid': isSubmitted && formControls.phoneNumber.errors }"
                class="form-control form-control-lg input signUpInput" type="text" formControlName="phoneNumber">
              <label for="form-lg" class="signUpLabel">Email or phone number</label>
              <div *ngIf="isSubmitted && formControls.phoneNumber.errors" class="invalid-feedback">
                <div *ngIf="formControls.phoneNumber.errors.required">Phone number or Email is required</div>
                <div *ngIf="formControls.phoneNumber.errors.pattern"> wrong format, It should be email or 10 digit
                  mobile number</div>
              </div>
            </div>

            <div align="right">
              <button type="submit"
                class="btn btn-primary btn-rounded primary-backgroundcolor signInFormBtn"><span>SEND</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>