import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { RouterConstants } from "src/app/constants/router.contants";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import {
  UserDetailsModel,
  ProfessionalDetailModel,
  EducationalDetailModel,
} from "src/app/models/UserDetailsModel";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { ApiCallService } from "../../services/api-call-svc/api-call.service";
declare var $: any;

@Component({
  selector: "app-setup-profile",
  templateUrl: "./setup-profile.component.html",
  styleUrls: ["./setup-profile.component.scss"],
})
export class SetupProfileComponent implements OnInit {
  experienceArray: Array<ProfessionalDetailModel> = [];
  newExpernce: any = {};

  educationArray: Array<EducationalDetailModel> = [];
  newEducation: any = {};

  routerConst = RouterConstants;
  basicPage = true;
  experincePage = false;
  educationPage = false;
  closeResult = "";
  user: UserDetailsModel;
  basicForm: FormGroup;
  experienceForm: FormGroup;
  educationForm: FormGroup;
  years = [];
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  jobLocationCountry: any = "India";
  jobLocationCity: any = null;
  loading = false;
  errorMsg = "";

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private authSvc: AuthenticationService,
    private api: ApiCallService
  ) { }

  ngOnInit(): void {
    this.newExpernce = {
      companyName: "",
      jobTitle: "",
      jobLocationState: "",
      employementType: "",
      isCurrentJob: "",
      startMonth: "",
      startYear: "",
      endDate: "",
      description: "",
    };
    this.experienceArray.push(this.newExpernce);

    this.newEducation = {
      instituteUniversityName: "",
      certificateDegreeName: "",
      major: "",
      startDate: "",
      completionDate: "",
      grade: "",
      description: "",
    };
    this.educationArray.push(this.newEducation);

    this.user = this.authSvc.getUserObj();
    this.editBasicProfile();
    this.editExperienceForm();
    this.editEducationForm();
    this.basicForm = this.formBuilder.group({
      id: [0],
      fname: [null, [Validators.required]],
      lname: [null, [Validators.required]],
      email: [null, [Validators.required]],
      dateOfBirth: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      jobLocationCountry: ["India", [Validators.required]],
      jobLocationCity: [null, [Validators.required]],
      aboutUser: [null, [Validators.required, Validators.maxLength(255)]],
      websiteLink: [null],
    });

    var year = new Date().getFullYear();
    var range = [];
    range.push(year);
    for (var i = 1; i < 60; i++) {
      range.push(year - i);
    }
    this.years = range;

    // console.log(this.years);
  }

  basic() {
    this.experincePage = false;
    this.educationPage = false;
    this.basicPage = true;
  }

  experience() {
    this.basicPage = false;
    this.educationPage = false;
    this.experincePage = true;
  }

  education() {
    this.basicPage = false;
    this.experincePage = false;
    this.educationPage = true;
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  editBasicProfile() {
    const url = "/api/user/user-details/" + this.user.username;
    this.api.getApiCall(url).subscribe((response: any) => {
      this.basicForm.patchValue(response);
      this.jobLocationCountry = response.location.countryName;
      this.jobLocationCity = response.location.cityName;
      // console.log(response)
    });
  }

  updateBasicProfile() {
    const url = "/api/user/update-user-details/";
    let form = this.basicForm.value;
    if (!this.validateBasicInfo(form)) {
      return;
    }
    form.id = this.user.id;
    form.isCurrentJob = false;
    form.jobLocationState = null;
    // console.log(this.basicForm.value)
    this.loading = true;
    this.api.postApiCall(url, this.basicForm.value).subscribe(
      (response: any) => {
        console.log(response);
        this.loading = false;
        this.experience();
        //console.log(response);
      },
      (error) => {
        this.loading = false;
        alert(error);
      }
    );
  }

  addExperienceRow(index) {
    this.newExpernce = {
      companyName: "",
      jobTitle: "",
      jobLocationState: "",
      employementType: "",
      isCurrentJob: "",
      startMonth: "",
      startYear: "",
      endDate: "",
      description: "",
    };
    this.experienceArray.push(this.newExpernce);
    // this.toastr.success('New row added successfully', 'New Row');
    // console.log(this.newExpernce);
    return true;
  }

  deleteExperienceRow(index) {
    if (this.experienceArray.length == 1) {
      // this.toastr.error("Can't delete the row when there is only one row", 'Warning');
      return false;
    } else {
      this.experienceArray.splice(index, 1);
      // this.toastr.warning('Row deleted successfully', 'Delete row');
      return true;
    }
  }

  editExperienceForm() {
    const url = "/api/user/professional-info/" + this.user.id;
    this.api.getApiCall(url).subscribe((response: any) => {
      response.forEach((list) => {
        this.experienceArray.push(list);
      });
      this.experienceArray.splice(0, 1);
    });
  }

  UpdateExperienceForm() {
    const url = "/api/user/professional-info/";

    this.experienceArray.forEach((list) => {
      if (!this.validateExperience(list)) {
        return;
      }
      this.loading = true;
      this.api.postApiCall(url, list).subscribe(
        (response: any) => {
          this.loading = false;
          this.education();
          // console.log(response);
        },
        (error) => {
          this.loading = false;
          alert(error);
        }
      );
    });
  }

  editEducationForm() {
    const url = "/api/user/educational-info/" + this.user.id;
    this.api.getApiCall(url).subscribe((response: any) => {
      response.forEach((list) => {
        this.educationArray.push(list);
      });
      this.educationArray.splice(0, 1);
    });
  }

  onUpdateEducationForm() {
    //console.log(this.educationArray);
    const url = "/api/user/educational-info/";
    if (this.educationArray && this.educationArray.length == 0) {
      alert("Thank You!");
    }

    this.educationArray.forEach((list) => {
      this.loading = true;
      this.api.postApiCall(url, list).subscribe(
        (response: any) => {
          this.loading = false;
          alert("Thank You!");
        },
        (error) => {
          this.loading = false;
          alert(error);
        }
      );
    });
  }

  addEducationRow(index) {
    this.newEducation = {
      instituteUniversityName: "",
      certificateDegreeName: "",
      major: "",
      startDate: "",
      completionDate: "",
      grade: "",
      description: "",
    };
    this.educationArray.push(this.newEducation);
    // this.toastr.success('New row added successfully', 'New Row');
    //console.log(this.newEducation);
    return true;
  }

  deleteEducationRow(index) {
    if (this.educationArray.length == 1) {
      // this.toastr.error("Can't delete the row when there is only one row", 'Warning');
      return false;
    } else {
      this.educationArray.splice(index, 1);
      // this.toastr.warning('Row deleted successfully', 'Delete row');
      return true;
    }
  }

  validateBasicInfo(form): boolean {
    let result = true;
    this.errorMsg = "";
    console.log(form);
    if (form) {
      if (!form.dateOfBirth) {
        this.errorMsg = "Date of Birth cannot left blank.";
        result = false;
      } else if (!form.phoneNumber) {
        this.errorMsg = "Phone Number cannot left blank.";
        result = false;
      } else if (!form.aboutUser) {
        this.errorMsg = "About you cannot left blank.";
        result = false;
      }
    }
    return result;
  }

  validateExperience(form): boolean {
    let result = true;
    this.errorMsg = "";
    console.log(form);
    if (form) {
      if (!form.companyName) {
        this.errorMsg = "Company cannot left blank.";
        result = false;
      } else if (!form.jobTitle) {
        this.errorMsg = "Designation cannot left blank.";
        result = false;
      } else if (!form.jobLocationCity) {
        this.errorMsg = "Location cannot left blank.";
        result = false;
      } else if (!form.employementType) {
        this.errorMsg = "Location cannot left blank.";
        result = false;
      } else if (!form.startMonth) {
        this.errorMsg = "Start month cannot left blank.";
        result = false;
      } else if (!form.startYear) {
        this.errorMsg = "Start year cannot left blank.";
        result = false;
      } else if (!form.endDate) {
        this.errorMsg = "End date cannot left blank.";
        result = false;
      } else if (!form.description) {
        this.errorMsg = "Description cannot left blank.";
        result = false;
      }
    }
    return result;
  }

  validateEducation(form): boolean {
    let result = true;
    console.log(form);
    return result;
  }

  validateNo(e): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }
}
