import { Component, OnInit } from '@angular/core';
import { RouterConstants } from 'src/app/constants/router.contants';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  routerConstants = RouterConstants;
  constructor() { }

  ngOnInit(): void {
  }

}
