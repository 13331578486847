import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {AuthenticationService} from '../authentication/authentication.service';
import * as socketConfig from './socket-config';
import {ApiCallService} from '../api-call-svc/api-call.service';
import { Subject } from 'rxjs';


@Injectable()
export class SocketService {
  stompClient: any;
  onSendHeartBeat = new Subject();
  subscribeChatboxSockets = new Subject();
  unsubscribeChatboxSockets = new Subject();
  reloadChatWindow = new Subject();
  socketConnected=false;

  constructor(private authService: AuthenticationService, private apiCallService: ApiCallService) {
    
  }

  initializeSocketForUser(): Promise<any>{
    const _this = this;
    return new Promise((resolve , reject) => {  

      if(!_this.authService || !_this.authService.getUserId()){
        reject();
      }

      if(!_this.stompClient){
        let ws = new SockJS(this.apiCallService.addHostToUrl(socketConfig.CHAT_SERVICE_TOPIC_NAME));
        _this.stompClient = Stomp.over(ws);
          
          _this.stompClient.connect({'Login':_this.authService.getUserId()}, function (frame) {
            _this.socketConnected = true;
            _this.stompClient.debug = null;
            _this.stompClient.uid = _this.authService.getUserId();
            resolve();                
        //_this.stompClient.reconnect_delay = 2000;
        }, (error)=>{
            console.error('Error occured!! Not able to establish socket connected with backend service', error);
            reject();
        });
      } else if(_this.stompClient.uid != _this.authService.getUserId()){
         _this.stompClient.disconnect();
         _this.initializeSocketForUser();
        console.log('socket is re established. creating new socket connection request.')
        reject();
      } else{
        console.log('socket is already established. skiping new socket connection request.')
        reject();
      }
  });
    
  }

  getUserList(){
    
    let interval = setInterval(()=>{
      if(this.stompClient){
        this.stompClient.send(socketConfig.GET_USER_LIST_EVENT, {}, JSON.stringify({'fromUserId': this.authService.getUserId()}));
        clearInterval(interval);
      }
    },100);
    
  }

  sendPrivateMessage(toUserId, message) {
    this.stompClient.send(socketConfig.SEND_PRIVATE_MESSAGE_EVENT, {}, 
      JSON.stringify({'fromUserId': this.authService.getUserId,'toUserId':toUserId,'message':message}));
  }

  broadCastMessage(message, toUserIdArry){	
    this.stompClient.send(socketConfig.BROADCAST_MESSAGE_EVENT, {}, JSON.stringify({'fromUserId': this.authService.getUserId,"message":message,"broadCastUID":Object.keys(toUserIdArry)}));
  }
}
