<section class="manage-background">
    <div class="container" style="margin-top:40px;">
        <div class="row">
            <div class="col-md-9 col-lg-9">
                <div class="row" *ngIf="userStats != null">
                    <div class="col-md-3 col-lg-3">
                        <div class="card manageCard">
                            <div class="card-body cursor_pointer" (click)="getFollowers();
                                activeTab='followers'">
                                <div class="manageCard-name">Followers</div>
                                <div class="manageCard-number">{{userStats.followers}}
                                    &nbsp;&nbsp; <i *ngIf="loader &&
                                        activeTab==='followers'" class="fa
                                        fa-spinner fa-spin" aria-hidden="true"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 manage-box">
                        <div class="card manageCard cursor_pointer" (click)="getFollowingUsers();
                            activeTab='followingUsers'">
                            <div class="card-body">
                                <div class="manageCard-name">Following</div>
                                <div class="manageCard-number">{{userStats.following}}&nbsp;&nbsp;
                                    <i *ngIf="loader &&
                                        activeTab==='followingUsers'" class="fa
                                        fa-spinner fa-spin" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 manage-box">
                        <div class="card manageCard cursor_pointer" (click)="activeTab= 'pendingInvitations';
                            getPendingInvitations(1); getSuggestions()">
                            <div class="card-body">
                                <div class="manageCard-name">Suggestions</div>
                                <div class="manageCard-number">{{userStats.suggestions}}
                                    &nbsp;&nbsp; <i *ngIf="loader &&
                                        activeTab==='pendingInvitations'" class="fa
                                        fa-spinner fa-spin" aria-hidden="true"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 manage-box">
                        <div class="card manageCard cursor_pointer" (click)="getProfileViewsData(); activeTab=
                            'profileView'">
                            <div class="card-body">
                                <div class="manageCard-name">Profile Views</div>
                                <div class="manageCard-number">{{userStats.profileViews}}
                                    &nbsp;&nbsp; <i *ngIf="loader &&
                                        activeTab==='profileView'" class="fa
                                        fa-spinner fa-spin" aria-hidden="true"></i></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card profileCard" style="margin-top:24px;" *ngIf="pendingInvitationsList.length === 0 && activeTab ===
                    'pendingInvitations'">
                    <div class="clearfix">
                        <div class="manage-aboutTitle1">No Pending Invitations
                        </div>
                    </div>
                </div>

                <!-- pending invitations -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="pendingInvitationsList.length> 0 && activeTab ===
                    'pendingInvitations'">
                    <div class="card-body manage-body">
                        <div class="clearfix">
                            <div class="manage-aboutTitle float-left">Requests
                            </div>
                            <span class="badge badge-light float-left
                                manage-request-number">{{pendingInvitationsList.length}}</span>
                        </div>
                        <div class="row" style="margin-bottom:50px;" *ngFor="let
                            list of pendingInvitationsList; let pi=index;">
                            <div class="col-md-1 col-lg-1">
                                <img style="width: 64px;" *ngIf="!list.userProfilePhotoPath"
                                    src="{{environment.userPhotoHost}}default-profile-200x150.png"
                                    class="manage-requests-image" alt="" />
                                <img style="width: 64px;" *ngIf="list.userProfilePhotoPath"
                                    src="{{environment.userPhotoHost}}{{list.userProfilePhotoPath}}"
                                    class="manage-requests-image" alt="" />
                            </div>
                            <div class="col-md-5 col-lg-5">
                                <div class="manage-requests-details">
                                    <div class="manage-requests-name
                                        cursor_pointer" (click)="viewOtherUser(list.userId)">{{list.firstName}}
                                        {{list.lastName}}</div>
                                    <div class="manage-requests-designation">{{list.userTitle}}</div>
                                    <div class="manage-requests-like">Liked by
                                        <strong>indian_travelpedia </strong> and
                                        <strong>26
                                            others</strong>
                                    </div>
                                    <div class="manage-requests-day">2 days ago</div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="manage-request-button">
                                    <span class="manage-decline-button
                                        cursor_pointer" (click)="onCancelFollowerRequest(list.userId,
                                        pi)">DECLINE</span>
                                    <span>
                                        <button (click)="accept(list.userId,
                                            pi)" class="btn btn-blue-border btn-sm
                                            btn-rounded with-icon accept-btn"><span
                                                class="manage-accept-button">ACCEPT</span>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="manage-seeall"
                            *ngIf="pendingInvitationsList.length> 3">SEE ALL
                            {{pendingInvitationsList.length}} requests</div> -->
                    </div>
                </div>

                <!-- Suggestions -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="activeTab === 'pendingInvitations' &&
                    suggestionsList.length> 0">
                    <div class="card-body manage-body">
                        <div class="clearfix">
                            <div class="manage-aboutTitle float-left">Suggestions
                            </div>
                        </div>
                        <div class="row" style="margin-bottom:50px;" *ngFor="let
                            list of suggestionsList; let i=index;">
                            <div class="col-md-1 col-lg-1">
                                <!-- <img src="./assets/images/request1.png"
                                    class="manage-requests-image" alt="" /> -->
                                <img style="width: 64px;" *ngIf="!list.userPhotoPath"
                                    src="{{environment.userPhotoHost}}default-profile-200x150.png"
                                    class="manage-requests-image" alt="" />
                                <img style="width: 64px;" *ngIf="list.userPhotoPath"
                                    [src]="getProfilePicPath(list.id, list.userPhotoPath)" class="manage-requests-image"
                                    alt="" />
                            </div>
                            <div class="col-md-5 col-lg-5">
                                <div class="manage-requests-details">
                                    <div class="manage-requests-name cursor_pointer" (click)="viewOtherUser(list.id)">
                                        {{list.firstName}}
                                        {{ list.lastName}}</div>
                                    <div class="manage-requests-designation" *ngIf="list.userTitle">{{list.userTitle}}
                                    </div>
                                    <div class="manage-requests-day">2 mutual
                                        friends
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="manage-request-button">
                                    <!-- <span class="manage-decline-button">REMOVE</span> -->
                                    <span>
                                        <button (click)="onFollow(list.id, i)" class="btn btn-blue-border btn-sm
                                            btn-rounded with-icon accept-btn"><span
                                                class="manage-accept-button">Follow</span>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- profile view -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="profileViewsData.length> 0 && activeTab ===
                    'profileView'">
                    <div class="card-body">
                        <div class="clearfix">
                            <div class="manage-aboutTitle float-left">Profile
                                Views
                            </div>
                            <span class="badge badge-light float-left
                                manage-request-number">{{profileViewsData.length}}</span>
                        </div>
                        <div class="row" style="margin-bottom:50px;" *ngFor="let
                            list of profileViewsData; let p=index;">
                            <div class="col-md-1 col-lg-1">
                                <img style="width: 64px;" *ngIf="list.userPhotoPath == null"
                                    class="manage-requests-image" alt=""
                                    src="{{environment.userPhotoHost}}default-profile-200x150.png" />

                                <img style="width: 64px;" *ngIf="list.userPhotoPath != null"
                                    class="manage-requests-image" alt=""
                                    src="{{environment.userPhotoHost}}{{list.userPhotoPath}}" />
                            </div>
                            <div class="col-md-5 col-lg-5">
                                <div class="manage-requests-details">
                                    <div class="manage-requests-name cursor_pointer"
                                        (click)="viewOtherUser(list.userId)">
                                        {{list.firstName}}
                                        {{list.lastName}}
                                    </div>
                                    <div class="manage-requests-designation">{{list.userTitle}}</div>
                                    <div class="manage-requests-like">Profile Views
                                        Count
                                        <strong>{{list.profileViewCount}}</strong>
                                    </div>
                                    <div class="manage-requests-day">
                                        {{list.lastViewedOn | dateAgo}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="manage-request-button">

                                    <span class="manage-decline-button
                                        cursor_pointer" *ngIf="list.followerStatus === 'Accept'" (click)="onCancelFollowerRequest(list.userId,
                                        p)">DECLINE</span>
                                    <button *ngIf="list.followerStatus === 'Accept'" (click)="accept(list.userId, p)"
                                        class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                                            class="manage-accept-button">ACCEPT</span>
                                    </button>
                                    <button *ngIf="list.followingStatus == null
                                        &&
                                        list.followerStatus == null" (click)="onFollow(list.userId, p)" class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                                            class="manage-accept-button">Follow</span>
                                    </button>


                                    <button *ngIf="list.followingStatus ===
                                        'Following'
                                        &&
                                        list.followerStatus == null" (click)="onUnfollowFollowing(list.userId,
                                        p)" class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                                            class="manage-accept-button">UNFOLLOW</span>
                                    </button>

                                    <button *ngIf="list.followingStatus
                                        === 'Pending'" (click)="onCancelFollowingRequest(list.userId,
                                        p)" class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span class="manage-accept-button">Cancel
                                            Request</span>
                                    </button>
                                    <button *ngIf="list.followingStatus === null
                                        && list.followerStatus === 'Follower'" (click)="onFollow(list.userId, p)"
                                        class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span class="manage-accept-button">FOLLOW
                                            BACK</span>
                                    </button>

                                    <button *ngIf="list.followingStatus ===
                                        'Following'
                                        && list.followerStatus === 'Follower'" (click)="onMessage(list.userId)" class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                                            class="manage-accept-button">Message</span>
                                    </button>

                                    <button *ngIf="list.followingStatus ===
                                        'Following'
                                        &&
                                        list.followerStatus == null" (click)="onMessage(list.userId)" class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                                            class="manage-accept-button">Message</span>
                                    </button>

                                    <button *ngIf="list.followingStatus == null
                                        &&
                                        list.followerStatus === 'Follower'" (click)="onMessage(list.userId)" class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                                            class="manage-accept-button">Message</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- followers list -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="followersList.length === 0 && activeTab ===
                    'followers'">
                    <div class="clearfix">
                        <div class="manage-aboutTitle1">No Followers Found
                        </div>
                    </div>
                </div>
                <div class="card profileCard" style="margin-top:24px;" *ngIf="followersList.length> 0 && activeTab ===
                    'followers'">
                    <div class="card-body">
                        <div class="clearfix">
                            <div class="manage-aboutTitle float-left">Followers
                            </div>
                            <span class="badge badge-light float-left
                                manage-request-number">{{userStats.followers}}</span>
                        </div>
                        <div class="row" style="margin-bottom:50px;" *ngFor="let
                            followers of followersList; let f=index;">
                            <div class="col-md-1 col-lg-1">
                                <img style="width: 64px;" *ngIf="followers.userProfilePhotoPath ==
                                    null" class="manage-requests-image" alt=""
                                    src="{{environment.userPhotoHost}}default-profile-200x150.png" />

                                <img style="width: 64px;" *ngIf="followers.userProfilePhotoPath !=
                                    null" class="manage-requests-image" alt=""
                                    src="{{environment.userPhotoHost}}{{followers.userProfilePhotoPath}}" />
                            </div>
                            <div class="col-md-5 col-lg-5">
                                <div class="manage-requests-details">
                                    <div class="manage-requests-name cursor_pointer"
                                        (click)="viewOtherUser(followers.userId)">{{followers.firstName}}
                                        {{followers.lastName}}</div>
                                    <div class="manage-requests-designation">{{followers.userTitle}}</div>
                                    <div class="manage-requests-day">{{followers.mutualFriendsCount}}
                                        mutual
                                        friends
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="manage-request-button">
                                    <span>
                                        <button *ngIf="followers.followingStatus
                                            === 'UNFOLLOW'" (click)="onUnfollowFollower(followers.userId,
                                            f)" class="btn btn-blue-border btn-sm
                                            btn-rounded with-icon"><span
                                                class="manage-accept-button">Unfollow</span></button>
                                        <button *ngIf="followers.followingStatus
                                            === 'CANCEL_REQUEST'" (click)="onCancelFollowingRequest(followers.userId,
                                            f)" class="btn btn-blue-border btn-sm
                                            btn-rounded with-icon"><span class="manage-accept-button">CANCEL
                                                REQUEST</span></button>

                                        <button *ngIf="followers.followingStatus
                                            === 'FOLLOW_BACK'" (click)="onFollow(followers.userId,
                                            f)" class="btn btn-blue-border btn-sm
                                            btn-rounded with-icon"><span class="manage-accept-button">FOLLOW
                                                BACK</span></button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- following users list -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="followingUsersList.length === 0 && activeTab ===
                    'followingUsers'">
                    <div class="clearfix">
                        <div class="manage-aboutTitle1">No Following
                            Users Found
                        </div>
                    </div>
                </div>
                <div class="card profileCard" style="margin-top:24px;" *ngIf="followingUsersList.length> 0 && activeTab ===
                    'followingUsers'">
                    <div class="card-body">
                        <div class="clearfix">
                            <div class="manage-aboutTitle float-left">Following
                                Users
                            </div>
                            <span class="badge badge-light float-left
                                manage-request-number">{{userStats.following}}</span>
                        </div>
                        <div style="margin-bottom:50px; display: flex; margin-right: auto;" *ngFor="let
                            following of followingUsersList; let ff=index;">
                            <div>
                                <img *ngIf="following.userProfilePhotoPath ==
                                null" class="manage-requests-image" style="width: 64px;" alt=""
                                    src="{{environment.userPhotoHost}}default-profile-200x150.png" />

                                <img style="width: 64px;" *ngIf="following.userProfilePhotoPath !=
                                null" class="manage-requests-image" alt=""
                                    src="{{environment.userPhotoHost}}{{following.userProfilePhotoPath}}" />
                            </div>
                            <div class="manage-requests-details">
                                <div class="manage-requests-name cursor_pointer"
                                    (click)="viewOtherUser(following.userId)">{{following.firstName}}
                                    {{following.lastName}}</div>
                                <div class="manage-requests-designation">{{following.userTitle}}</div>
                                <div class="manage-requests-day">{{following.mutualFriendsCount}}
                                    mutual
                                    friends
                                </div>
                            </div>
                            <div style="display: flex; margin-left: auto;">
                                <div class="manage-request-button">
                                    <span> <button (click)="onUnfollowFollowing(following.userId,
                                        ff)" class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon"><span
                                                class="manage-accept-button">Unfollow</span></button>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="col-md-1 col-lg-1">
                                <img *ngIf="following.userProfilePhotoPath ==
                                    null"
                                    class="manage-requests-image" alt=""
                                    src="{{environment.userPhotoHost}}default-profile-200x150.png"
                                    />

                                <img *ngIf="following.userProfilePhotoPath !=
                                    null"
                                    class="manage-requests-image" alt=""
                                    src="{{environment.userPhotoHost}}{{following.userProfilePhotoPath}}"
                                    />
                            </div>
                            <div class="col-md-6 col-lg-6
                                manage-requests-details">
                                <div class="manage-requests-name cursor_pointer"
                                    (click)="viewOtherUser(following.userId)">{{following.firstName}}
                                    {{following.lastName}}</div>
                                <div class="manage-requests-designation">{{following.userTitle}}</div>
                                <div class="manage-requests-day">{{following.mutualFriendsCount}}
                                    mutual
                                    friends
                                </div>
                            </div> -->
                            <!-- <div class="col-md-4 col-lg-4">
                                <div class="manage-request-button">
                                    <span> <button
                                            (click)="onUnfollowFollowing(following.userId,
                                            ff)"
                                            class="btn btn-blue-border btn-sm
                                            btn-rounded with-icon"><span
                                                class="manage-accept-button">Unfollow</span></button>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="manage-seeall" *ngIf="userStats.followers>
                            5">SEE ALL {{userStats.followers}} followers</div> -->
                    </div>
                </div>








                <!-- Your posts/Saved posts list -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="activeTab ===
                    'savedPosts' || activeTab ===
                    'yourPosts'">
                    <div class="card-body">
                        <div class="clearfix">
                            <div *ngIf="activeTab ===
                                'yourPosts'" class="manage-aboutTitle
                                float-left">Your
                                Posts
                            </div>
                            <div *ngIf="activeTab ===
                                'savedPosts'" class="manage-aboutTitle
                                float-left">Saved
                                Posts
                            </div>
                        </div>
                        <div class="row" style="padding:0px 50px 0px 50px;">
                            <div class="col-md-1 col-sm-12 col-lg-1">
                            </div>
                            <div class="col-md-10 col-sm-12 col-lg-10 scroll">
                                <!--Active Post -->
                                <div class="card profileCard" style="border: 1px
                                    solid
                                    #E0E0E0;" *ngFor="let activity of
                                    activityPostList; let
                                    i=index;">
                                    <div class="card-body" style="min-height:
                                        0;">
                                        <div class="row">
                                            <div class="col-md-1">
                                                <a (click)="viewOtherUser(activity?.activityPostedByUserId)"><img
                                                        class="network-userImage" [src]="api.getInitials(activity?.activityPostedByUser?.firstName
                                                        +
                                                        ' ' +
                                                        activity?.activityPostedByUser?.lastName)" alt="" /></a>
                                            </div>
                                            <div class="col-md-7">
                                                <a (click)="viewOtherUser(activity?.activityPostedByUserId)">
                                                    <span
                                                        class="post-something">{{activity?.activityPostedByUser?.firstName}}
                                                        {{activity?.activityPostedByUser?.lastName}}</span>
                                                    <span class="project-manager" *ngIf="activity?.activityPostedByUser?.userTitle
                                                        !=
                                                        null">{{activity?.activityPostedByUser?.userTitle}}
                                                    </span></a>
                                            </div>
                                            <div class="col-md-3">
                                                <span class="network-datetime">
                                                    {{activity.createdOn |
                                                    dateAgo}}</span>
                                            </div>


                                            <div class="col-md-1">

                                                <div class="action-dots
                                                    clearfix">
                                                    <div class="dropdown" data-toggle="dropdown"
                                                        *ngIf="activity.activityOwner">
                                                        <img class="cursor_pointer" src="./assets/images/dots.svg"
                                                            alt="" />
                                                        <ul class="dropdown-menu" style="left: -74%
                                                            !important">
                                                            <li (click)="onDeleteActivity(activity.activityId)">
                                                                <a><span>
                                                                        <img class="dropdown-menu-trash" alt=""
                                                                            src="./assets/images/trash.svg" />
                                                                    </span><span>Delete</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div class="dropdown" data-toggle="dropdown"
                                                        *ngIf="!activity.activityOwner"
                                                        (click)="getConnectionStatus(activity.activityPostedByUserId)">
                                                        <img class="cursor_pointer" src="./assets/images/dots.svg"
                                                            alt="" />
                                                        <ul *ngIf="!activity.activityOwner" class="dropdown-menu" style="left: -74%
                                                            !important">
                                                            <li style="text-align:
                                                                center;"><a *ngIf="connectionStatus
                                                                    != null">
                                                                    <span *ngIf="connectionStatus
                                                                        ===
                                                                        'Follow'" (click)="onFollow(activity.activityPostedByUserId,
                                                                        i)">Follow</span>
                                                                    <span *ngIf="connectionStatus
                                                                        ===
                                                                        'Accept'" (click)="accept(activity.activityPostedByUserId,
                                                                        i)">Accept</span>
                                                                    <span *ngIf="connectionStatus
                                                                        ===
                                                                        'Follow
                                                                        Back'" (click)="onFollow(activity.activityPostedByUserId,
                                                                        i)">Follow
                                                                        Back</span>
                                                                    <span *ngIf="connectionStatus
                                                                        ===
                                                                        'Cancel
                                                                        Request'" (click)="onCancelFollowingRequest(activity.activityPostedByUserId,
                                                                        i)">Cancel
                                                                        Request</span>
                                                                    <span *ngIf="connectionStatus
                                                                        ===
                                                                        'Unfollow'" (click)="onUnfollowFollowing(activity.activityPostedByUserId,
                                                                        i)">Unfollow</span>
                                                                </a>

                                                                <i *ngIf="connectionStatus
                                                                    == null" class="fa
                                                                    fa-spinner
                                                                    fa-spin" aria-hidden="true"></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="middle-content">
                                            <span class="network-postdescription">{{activity.postText}}
                                            </span>
                                            <!-- 1 img -->
                                            <div class="post-image1" *ngIf="activity.type == 1">
                                                <div *ngIf="activity?.activityPostsPaths.length
                                                    === 1">
                                                    <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                                        alt="" />
                                                </div>
                                            </div>

                                            <!-- 2 img -->
                                            <div class="row" *ngIf="activity.type == 1 &&
                                                activity?.activityPostsPaths.length
                                                === 2">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- 3 img -->
                                            <div class="row" *ngIf="activity.type == 1 &&
                                                activity?.activityPostsPaths.length
                                                === 3">
                                                <div class="col-md-12
                                                    col-lg-12">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- 4 img -->
                                            <div class="row" *ngIf="activity.type == 1 &&
                                                activity?.activityPostsPaths.length
                                                === 4">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[3].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- 5 img -->
                                            <div class="row" *ngIf="activity.type == 1 &&
                                                activity?.activityPostsPaths.length
                                                === 5">
                                                <div class="col-md-12
                                                    col-lg-12">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[3].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="network-modal-uploaded-image">
                                                        <img src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[4].path}}"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="post-pdf" *ngIf="activity.type
                                                == 2">
                                                <div class="clearfix">
                                                    <img class="float-left
                                                        post-pdf-image" src="assets/images/pdf.png" alt="" />
                                                    <div class="float-left
                                                        post-pdf-body">
                                                        <strong class="post-pdf-title">10
                                                            Leadership values</strong>
                                                        <p class="post-pdf-subtitle">PDF
                                                            Document
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="post-like">
                                                <div class="clearfix">
                                                    <img *ngIf="activity.isActivityLike"
                                                        src="assets/images/like-filled.svg" class="post-likeBtn
                                                        cursor_pointer" (click)="onUnLike(activity.activityId,
                                                        i)" alt="" />
                                                    <img *ngIf="!activity.isActivityLike" src="assets/images/like.svg"
                                                        class="post-likeBtn
                                                        cursor_pointer" (click)="onLike(activity.activityId,
                                                        i)" alt="" />
                                                    <span *ngIf="activity.likedCounts>
                                                        0" class="post-likedBy">Liked
                                                        by <strong *ngIf="activity.isActivityLike">You</strong><strong
                                                            *ngIf="!activity.isActivityLike">{{activity?.lastLikedBy}}</strong>
                                                        <strong *ngIf="activity.likedCounts>
                                                            1" (click)="allLikedUser(activity.activityId)">
                                                            and
                                                            {{activity.likedCounts
                                                            - 1}}
                                                            others</strong></span>
                                                    <div class="float-right">
                                                        <img class="post-shareBtn
                                                            cursor_pointer" src="assets/images/share1.svg" alt="" />
                                                        <img class="post-saveBtn
                                                            cursor_pointer" *ngIf="activity.isActivitySave" (click)="onRemoveFromSavedCollection(activity.activityId,
                                                            i)" src="assets/images/bookmark1-filled.svg" alt="" />
                                                        <img class="post-saveBtn
                                                            cursor_pointer" *ngIf="!activity.isActivitySave" (click)="onSave(activity.activityId,
                                                            i)" src="assets/images/bookmark1.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body" style="border-top:
                                        1px solid
                                        #E0E0E0;
                                        min-height: 0;" *ngIf="activity.allowCommentForPost">
                                        <div class="clearfix">
                                            <div class="float-left">
                                                <img *ngIf="!user.userDPUrl" class="post-profilepic" alt=""
                                                    src="{{environment.userPhotoHost}}default-profile-200x150.png" />
                                                <img *ngIf="user.userDPUrl" class="post-profilepic" alt=""
                                                    src="{{environment.userPhotoHost}}{{user.userDPUrl}}" />
                                            </div>
                                            <div class="float-left" style="width: 92%;">
                                                <div class="rounded rounded-pill
                                                    shadow-sm
                                                    post-comment-box">
                                                    <form [formGroup]="commentOnPostFormGroup">
                                                        <div class="input-group">
                                                            <textarea placeholder="{{constants.add_comment}}"
                                                                aria-describedby="button-addon2" class="form-control
                                                                border-0
                                                                postInput
                                                                my-auto
                                                                post-comment-text-box"
                                                                formControlName="comment"></textarea>
                                                            <div class="input-group-append
                                                                post-comment">
                                                                <a (click)="onComment(activity.activityId,
                                                                    i)">POST</a>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="comments">
                                            <div class="clearfix
                                                comment-section" *ngFor="let
                                                comment of
                                                activity?.activityComments.reverse()">
                                                <div class="float-left" style="width:
                                                    auto">
                                                    <strong class="post-comment-author">{{comment.firstName}}
                                                        {{comment.lastName}}</strong>
                                                </div>
                                                <div class="float-left">
                                                    <span class="post-comment-text">{{comment.commentsText}}
                                                        -
                                                        {{comment.createdOn |
                                                        dateAgo}}</span>
                                                </div>
                                            </div>
                                            <a *ngIf="activity?.totalComments>
                                                activity.initialCommentsList" class="view-all-comments" (click)="onLoadNextFewComments(activity.activityId,
                                                i)">View all
                                                {{activity?.totalComments-
                                                activity.initialCommentsList}}
                                                comments</a>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="activityPageNumber-1 !== totalPage" style="margin-left:
                                    50%;">
                                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                </div>
                                <div align="center" *ngIf="activityPageNumber-1
                                    ===
                                    totalPage">
                                    <p>All caught up !!!</p>
                                </div>
                            </div>
                            <div class="col-md-1 col-sm-12 col-lg-1">
                            </div>
                        </div>
                    </div>
                </div>



                <!-- Shortlisted Jobs section -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="activeTab ===
                    'shortlistedJobs'">
                    <div class="card-body">
                        <div class="clearfix">
                            <div class="manage-aboutTitle float-left">Shortlisted
                                Jobs &nbsp;&nbsp;
                                <i *ngIf="loader &&
                                    activeTab==='shortlistedJobs'" class="fa
                                    fa-spinner fa-spin" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-lg-4 jobs-job-card" *ngFor="let list of
                                shortlistedJobsList; let s=index;">
                                <div class="card featured-jobs-card">
                                    <div class="card-body">
                                        <div class="clearfix">
                                            <img *ngIf="list.companyLogoPath !=
                                                null" (click)="onViewJobDetails(list.jobId)"
                                                class="jobImg cursor_pointer"
                                                src="{{environment.activityPhotoHost}}{{list?.companyLogoPath}}"
                                                alt="job image" />
                                            <img *ngIf="list.companyLogoPath ==
                                                null" (click)="onViewJobDetails(list.jobId)"
                                                class="jobImg1 cursor_pointer"
                                                src="./assets/images/defaultCompanyLogo.jpg" alt="job image" />
                                            <div class="featured-jobs-favourites">
                                                <img class="icon cursor_pointer" src="./assets/images/times.png" alt=""
                                                    (click)="removeShortlistedJob(list.jobId,
                                                    s)" />
                                            </div>
                                        </div>

                                        <h5 class="card-title job-title
                                            cursor_pointer" (click)="onViewJobDetails(list.jobId)">{{list.jobTitle}}
                                        </h5>
                                        <a class="job_companyName
                                        cursor_pointer" target="_blank" href="{{list.jobId}}">{{list.companyName}}</a>
                                        <p class="job_location">{{list.cityName}},
                                            {{list.country}}
                                        </p>
                                        <p class="job_recent">
                                            {{list.createdOn | dateAgo}}
                                            <i *ngIf="list.appliedStatus" class="applied-class">applied</i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- jobs posted section -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="activeTab ===
                    'jobsPosted'">
                    <div class="card-body">
                        <div class="clearfix">
                            <div class="manage-aboutTitle float-left">Posted
                                Jobs &nbsp;&nbsp;
                                <i *ngIf="loader &&
                                    activeTab==='jobsPosted'" class="fa
                                    fa-spinner fa-spin" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-lg-4 jobs-job-card" *ngFor="let list of
                                postedJobsList; let p=index;">
                                <div class="card featured-jobs-card">
                                    <div class="card-body">
                                        <div class="clearfix">
                                            <img *ngIf="list.companyLogoPath !=
                                                null" class="jobImg
                                                cursor_pointer"
                                                src="{{environment.activityPhotoHost}}{{list?.companyLogoPath}}"
                                                alt="job image" (click)="onViewJobDetails(list.jobId)" />

                                            <img *ngIf="list.companyLogoPath ==
                                                null" class="jobImg1
                                                cursor_pointer" src="./assets/images/defaultCompanyLogo.jpg"
                                                alt="job image" (click)="onViewJobDetails(list.jobId)" />
                                            <div class="featured-jobs-favourites">
                                                <img class="icon cursor_pointer" src="./assets/images/trash.svg" alt=""
                                                    (click)="onDeleteJob(list.jobId,
                                                    p)" />
                                            </div>
                                        </div>

                                        <h5 class="card-title job-title
                                            cursor_pointer" (click)="onViewJobDetails(list.jobId)">{{list.jobTitle}}
                                        </h5>
                                        <a class="
                                            cursor_pointer" target="_blank"
                                            href="{{list.jobId}}">{{list.companyName}}</a>
                                        <p class="job_location">{{list.cityName}},
                                            {{list.country}}
                                        </p>
                                        <p class="job_recent">
                                            {{list.createdOn | dateAgo}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Applied Jobs section -->
                <div class="card profileCard" style="margin-top:24px;" *ngIf="activeTab ===
                    'jobsApplied'">
                    <div class="card-body">
                        <div class="clearfix">
                            <div class="manage-aboutTitle float-left">Applied
                                Jobs &nbsp;&nbsp;
                                <i *ngIf="loader &&
                                    activeTab==='jobsApplied'" class="fa
                                    fa-spinner fa-spin" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-lg-4 jobs-job-card" *ngFor="let list of
                                appliedJobsList; let a=index;">
                                <div class="card featured-jobs-card">
                                    <div class="card-body">
                                        <div class="clearfix">
                                            <img *ngIf="list.companyLogoPath !=
                                                null" class="jobImg
                                                cursor_pointer"
                                                src="{{environment.activityPhotoHost}}{{list?.companyLogoPath}}"
                                                alt="job image" (click)="onViewJobDetails(list.jobId)" />

                                            <img *ngIf="list.companyLogoPath ==
                                                null" class="jobImg1
                                                cursor_pointer" src="./assets/images/defaultCompanyLogo.jpg"
                                                alt="job image" (click)="onViewJobDetails(list.jobId)" />
                                        </div>

                                        <h5 class="card-title job-title
                                            cursor_pointer" (click)="onViewJobDetails(list.jobId)">{{list.jobTitle}}
                                        </h5>
                                        <a class="job_companyName
                                        cursor_pointer" target="_blank" href="{{list.jobId}}">{{list.companyName}}</a>
                                        <p class="job_location">{{list.cityName}},
                                            {{list.country}}
                                        </p>
                                        <p class="job_recent">
                                            {{list.createdOn | dateAgo}}
                                            <i class="applied-class">applied</i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">
                <div class="manage-profile">
                    <img *ngIf="!user.userDPUrl" class="card-img-top"
                        src="{{environment.userPhotoHost}}default-profile-200x150.png" alt="{{user.username}}">
                    <img *ngIf="user.userDPUrl" src="{{environment.userPhotoHost}}{{user.userDPUrl}}"
                        class="manage-profile-image" alt="{{user.username}}" />
                    <div class="manage-profile-name">{{user.fname}}
                        {{user.lname}}
                    </div>
                    <div class="manage-profile-designation" *ngIf="companyName
                        != null">{{jobTitle}}<br>{{companyName}}</div>
                    <a routerLink="/{{routerConstants.user}}/{{routerConstants.setUpProfile}}/{{user.id}}" class="btn btn-blue-border btn-sm btn-rounded
                        with-icon manageCompleteBtn"><span>Edit PROFILE</span></a>
                </div>
                <div class="manage-categories" *ngIf="userStats != null">
                    <div class="cursor_pointer" (click)="activeTab=
                        'yourPosts'; getActivityPosts(true, 'yourPosts');">
                        <img src="./assets/images/right.png" class="manage-categories-icon" alt="" /><span
                            class="manage-categories-label">Your Posts</span>
                        &nbsp;&nbsp; {{userStats.yourPosts}}&nbsp;&nbsp;
                        <i *ngIf="loader &&
                            activeTab==='yourPosts'" class="fa
                            fa-spinner fa-spin" aria-hidden="true"></i>
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section cursor_pointer" (click)="activeTab=
                        'savedPosts'; getActivityPosts(true, 'savedPosts');">
                        <img src="./assets/images/savepost.svg" class="manage-categories-icon-save" alt="" /><span
                            class="manage-categories-label"> Saved Posts</span>
                        &nbsp;&nbsp; {{userStats.savedPosts}}&nbsp;&nbsp;
                        <i *ngIf="loader &&
                            activeTab==='savedPosts'" class="fa
                            fa-spinner fa-spin" aria-hidden="true"></i>
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section cursor_pointer" (click)="activeTab=
                        'shortlistedJobs'; getShortlistedJobs();">
                        <img src="./assets/images/love.png" class="manage-categories-icon" alt="" /><span
                            class="manage-categories-label">Shortlisted Jobs</span>
                        &nbsp;&nbsp; {{userStats.shortlistedJobs}}&nbsp;&nbsp;
                        <i *ngIf="loader &&
                            activeTab==='shortlistedJobs'" class="fa
                            fa-spinner fa-spin" aria-hidden="true"></i>
                    </div>
                    <hr style="background-color: #C8C8C8;" *ngIf="userStats.postedJobs> 0">
                    <div class="manage-categories-section cursor_pointer"
                        (click)="getPostedJobs(); activeTab= 'jobsPosted'" *ngIf="userStats.postedJobs>
                        0">
                        <img src="./assets/images/right.png" class="manage-categories-icon" alt="" /><span
                            class="manage-categories-label">Posted Jobs</span>
                        &nbsp;&nbsp; {{userStats.postedJobs}}&nbsp;&nbsp;
                        <i *ngIf="loader &&
                            activeTab==='jobsPosted'" class="fa
                            fa-spinner fa-spin" aria-hidden="true"></i>
                    </div>
                    <hr style="background-color: #C8C8C8;" *ngIf="userStats.appliedJobs> 0">
                    <div class="manage-categories-section cursor_pointer"
                        (click)="getAppliedJobs(); activeTab= 'jobsApplied'" *ngIf="userStats.appliedJobs> 0">
                        <img src="./assets/images/manage-job.svg" class="manage-categories-icon" alt="" /><span
                            class="manage-categories-label">Applied Jobs</span>
                        &nbsp;&nbsp; {{userStats.appliedJobs}}&nbsp;&nbsp;
                        <i *ngIf="loader &&
                            activeTab==='jobsApplied'" class="fa
                            fa-spinner fa-spin" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <div class="manage-categories-section clearfix">
    <img src="./assets/images/savepost.svg" class="manage-categories-icon float-left" />
    <span class="manage-categories-label float-left"> Saved Posts</span>
</div>
<hr style="background-color: #C8C8C8;">
<div class="manage-categories-section clearfix">
    <img src="./assets/images/manage-shortlisted.svg" class="manage-categories-icon float-left" /><span class="manage-categories-label float-left">Shortlisted Jobs</span>
</div>
<hr style="background-color: #C8C8C8;">
<div class="manage-categories-section clearfix">
    <img src="./assets/images/manage-job.svg" class="manage-categories-icon float-left" />
    <span class="manage-categories-label float-left">Applied Jobs</span>
</div> -->