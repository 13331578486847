import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { retry, catchError, map, tap } from 'rxjs/operators';
import { RouterStateSnapshot, Router } from '@angular/router';
import { RouterConstants } from 'src/app/constants/router.contants';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  state: any;
  routerConst = RouterConstants;
  constructor(private auth: AuthenticationService,
              private router: Router) {
    this.state = this.router.routerState.snapshot;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getSecureKey()}`,
        // 'Content-Type': 'application/json', // browser will takecare of it by default
       // Accept: 'application/json',
        'Cache-control': 'no-cache'
      }
    });
    // return next.handle(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.auth.getUserObj().username) {
          alert('Session timed out, Please login');
          this.auth.logout({ queryParams: { returnUrl: this.state.url } });
        } else {
          return this.handleError(error);
        }
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    // alert(error.status);
    // console.log(typeof (error.status));

    switch (+error.status) {
      case 401:
        this.router.navigateByUrl(this.routerConst.signIn);
        break;
      case 500:
      case 0:
        //this.router.navigateByUrl(this.routerConst.error);
        alert("Some Technical error occured. Please contact admistrator.");
        break;
      default:
        console.error(error.error.message);
        break;
    }
    return throwError(error);
  }

}
