import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import { ActivityModel } from 'src/app/models/NetworkModel';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
declare var $: any;
import { AlertBarService } from 'src/app/services/alert-bar.service';
import { NetworkModuleConstants } from '../../constants/globalConstants';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { RouterConstants } from '../../constants/router.contants';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

export class VideoMaster {
  int: number;
  Videos: string;
}
@Component({
  selector: 'app-activity-post-details',
  templateUrl: './activity-post-details.component.html',
  styleUrls: ['./activity-post-details.component.scss']
})
export class ActivityPostDetailsComponent implements OnInit {
  routerConstants = RouterConstants;
  constants = NetworkModuleConstants;
  commentOnPostFormGroup: FormGroup;
  createPostFormGroup: FormGroup;
  user: UserDetailsModel;
  environment = environment;

  loading = false;
  activityPostList: ActivityModel[];
  uploadedFiles: any[] = [];
  files: any[] = [];
  postType = null;
  postViewDefaultSet = 'Anyone';
  postView = [{ value: 'Anyone' }, { value: 'Only Followers' }];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageBase64String = '';
  cropImageIndex = null;
  uploadedImagesEvent = [];
  showImageCropContainer = false;
  postBtnLoader = false;
  videoPath = null;
  OtherUser = [];
  connectionStatus = null;
  relevantJobsList = [];

  @ViewChild('videoPlayer') videoplayer: ElementRef;
  myVideos: Observable<VideoMaster[]>;

  constructor(
    private route: ActivatedRoute,
    private authSvc: AuthenticationService, public api: ApiCallService,
    private fb: FormBuilder, private alertbar: AlertBarService, private router: Router) { }

  ngOnInit(): void {
    this.createForm();
    this.user = this.authSvc.getUserObj();

    this.getRelevantJobs();
    this.route.url.subscribe(res => {
      if (res[1] !== undefined) {
        switch (res[1].path) {
          case 'activity-details':
            this.getActivityPost(Number(res[2].path));
            break;
          default:
            break;
        }
      }
    });
  }

  createForm() {
    this.commentOnPostFormGroup = this.fb.group({
      comment: ['', Validators.required],
    });

    this.createPostFormGroup = this.fb.group({
      postContent: ['', Validators.required],
    });
  }

  getActivityPost(activityId) {
    const url = '/api/activity/get-activity-by-activity-id/' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      if (res != null) {
        this.activityPostList = [];
        if (res.activityPostedByUser.username === this.user.username) {
          res.activityOwner = true;
        } else {
          res.activityOwner = false;
        }
        res.initialCommentsList = 5;
        this.activityPostList.push(res);
      }
      this.loading = false;
    }, (err) => {
      this.loading = false;
    });
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  allLikedUser(activityId) {
    this.api.getApiCall('/api/activity/all-liked-user/' + activityId + '/1').subscribe(response => {
      if (response != null) {
       // console.log(response);
      }
    }, (err) => {
    });
  }

  onLike(activityId, index) {
    const url = '/api/activity/like-post?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivityLike = true;
      this.activityPostList[index].likedCounts = this.activityPostList[index].likedCounts + 1;
      this.addNotification(this.activityPostList[index].activityPostedByUserId, 'PostLiked', activityId);
    }, (err) => {
      console.log(err);
    });
  }

  onUnLike(activityId, index) {
    const url = '/api/activity/undo-like-post?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivityLike = false;
      this.activityPostList[index].likedCounts = this.activityPostList[index].likedCounts - 1;
    }, (err) => {
      console.log(err);
    });
  }

  onSave(activityId, index) {
    const url = '/api/activity/save-activity?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivitySave = true;
      this.addNotification(this.activityPostList[index].activityPostedByUserId, 'savePost', activityId);
    }, (err) => {
      console.log(err);
    });
  }

  addNotification(id, type, activityId) {
    if (id !== this.user.id) {
      const json = {
        type: type,
        createdOn: new Date(),
        read: false,
        userId: id,
        fromUserId: this.user.id,
        postId: activityId,
        jobId: null
      }
      const url = '/api/notifications/add-notification';
      this.api.postApiCall(url, json).subscribe((res) => {
      })
    }
  }

  onRemoveFromSavedCollection(activityId, index) {
    const url = '/api/activity/remove-saved-activity?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivitySave = false;
    }, (err) => {
      console.log(err);
    });
  }


  onComment(activityid, index) {
    const url = '/api/activity/post-comment';
    const jsonObj = {
      activityId: activityid,
      commentsText: this.commentOnPostFormGroup.value.comment
    };
    this.api.postApiCall(url, jsonObj).subscribe(res => {
      if (res != null) {
        this.addNotification(this.activityPostList[index].activityPostedByUserId, 'commentOnPost', activityid);
        this.commentOnPostFormGroup.reset();
        this.commentOnPostFormGroup.patchValue({});
        this.activityPostList[index].totalComments = this.activityPostList[index].totalComments + 1;
        if (this.activityPostList[index].totalComments > this.activityPostList[index].initialCommentsList) {
          let arr = [];
          arr = this.activityPostList[index].activityComments.reverse();
          arr.pop();
          this.activityPostList[index].activityComments = arr.reverse();
        }
        res.firstName = this.user.fname;
        res.lastName = this.user.lname;
        res.userName = this.user.username;
        this.activityPostList[index].activityComments.push(res);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onLoadNextFewComments(activityId, index) {
    this.activityPostList[index].initialCommentsList = this.activityPostList[index].initialCommentsList + 5;
    const pageNo = (this.activityPostList[index].initialCommentsList / 5);
    const url = '/api/activity/get-comments/' + activityId + '/' + pageNo;
    this.api.getApiCall(url).subscribe(res => {
      res.content.reverse().forEach(element => {
        this.activityPostList[index].activityComments.splice(0, 0, element);
      });
    }, (err) => {
      console.log(err);
    });
  }

  onDeleteActivity(activityId) {
    const url = '/api/activity/' + activityId;
    this.api.deleteApiCall(url).subscribe(res => {
    }, (err) => {
      console.log(err);
    });
  }


  onSharePost() {
    let hashTags = null;
    if (this.createPostFormGroup.value.postContent != null) {
      hashTags = hashTags + this.createPostFormGroup.value.postContent.split(' ').filter(v => v.startsWith('#'));
      if (hashTags === 'null') {
        hashTags = null;
      }
    }
    const jsonData = {
      isAllowCommentForPost: true,
      hashtags: hashTags,
      numberOfLikes: 0,
      postText: this.createPostFormGroup.value.postContent,
      type: 1,
      postPath: null,
      status: 1
    };
    const formData: FormData = new FormData();
    if (this.uploadedFiles.length > 0) {
      for (const fileData of this.files) {
        formData.append('file', fileData.fileData);
      }
    }
    this.postBtnLoader = true;
    formData.append('info', JSON.stringify(jsonData));
    this.api.postApiCall('/api/activity/post-user-activity/', formData).subscribe(res => {
      this.resetPostForm();
    }, (err) => {
      console.log(err);
    });
  }

  resetPostForm() {
    this.files = [];
    this.postType = null;
    $('#postModal').modal('hide');
    this.createPostFormGroup.reset();
    this.createPostFormGroup.patchValue({});
    this.postViewDefaultSet = 'Anyone';
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.imageBase64String = '';
    this.cropImageIndex = null;
    this.uploadedImagesEvent = [];
    this.showImageCropContainer = false;
    this.postBtnLoader = false;
  }

  onFileUpload(event, type) {
    // if (type === 'video') {
    //   this.videoPath = event;
    //   return;
    // }
    this.files = [];
    this.uploadedFiles = [];
    this.postType = null;
    this.uploadedFiles = event.target.files;
    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      const filesAmount = this.uploadedFiles.length;
      if (filesAmount < 6) {
        for (let i = 0; i < filesAmount; i++) {
          const fineName = this.uploadedFiles[i].name;
          const reader = new FileReader();
          reader.onload = (subevent: any) => {
            const json = {
              fileName: fineName,
              content: subevent.target.result,
              fileType: (type === 'image') ? 'img' : 'doc',
              fileData: this.uploadedFiles[i]
            };
            this.files.push(json);
            this.postType = (type === 'image') ? 1 : 2;
          };
          reader.readAsDataURL(this.uploadedFiles[i]);
        }
        $('#postModal').modal('show');
      } else {
        alert('Sorry,  Max u can upload only 5 images/files');
      }
    }
  }

  removeSelectedFile(index) {
    this.showImageCropContainer = false;
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.files.splice(index, 1);
    if (this.files.length === 0) {
      this.postType = null;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  cropSelectedImage(index) {
    this.cropImageIndex = index;
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.imageChangedEvent = this.files[index].content;
    this.showImageCropContainer = true;
  }

  onImageCrop() {
    this.files[this.cropImageIndex].content = this.croppedImage;
    this.files[this.cropImageIndex].fileData = this.base64ToFile(this.croppedImage, this.files[this.cropImageIndex].fileName);
    this.showImageCropContainer = false;
    this.croppedImage = '';
  }

  viewOtherUser(userId) {
    const url = '/api/user/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: res.id }
      };
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.profileExternal], navigationExtras);
    }, (err) => {
    });

    if (userId !== this.user.id) {
      this.addNotification(userId, 'profileView', null);
      const url = '/api/user/save-profile-views/' + userId;
      this.api.postApiCall(url, null).subscribe(res => {
      }, (err) => {
      });
    }
  }

  getConnectionStatus(userId) {
    this.connectionStatus = null;
    const url = '/api/user/follow/get-connection-status/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      if (res != null) {
        this.connectionStatus = res.status;
      } else {
        this.connectionStatus = null;
      }
    }, (err) => {
    });
  }

  onFollow(userId) {
    this.api.postApiCall(`/api/user/follow/${userId}`, null).subscribe(res => {
    });
  }

  accept(userId) {
    const url = '/api/user/follow/accept/' + userId;
    this.api.putApiCall(url, null).subscribe(res => {
    }, (err) => {
    });
  }

  onUnfollowFollower(userId) {
    this.api.deleteApiCall(`/api/user/follow/unfollow-follower/${userId}`).subscribe(res => {
    });
  }

  onUnfollowFollowing(userId) {
    this.api.deleteApiCall(`/api/user/follow/unfollow-following/${userId}`).subscribe(res => {
    });
  }

  onCancelFollowingRequest(userId) {
    this.api.deleteApiCall(`/api/user/follow/cancel-following-request/${userId}`).subscribe(res => {
    });
  }

  getRelevantJobs() {
    this.api.getApiCall(`/api/feed/get-relevant-jobs`).subscribe(res => {
      if (res != null) {
        this.relevantJobsList = res;
      } else {
        this.relevantJobsList = [];
      }
    });
  }

  onViewJobDetails(jobId) {
    this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.jobDetails + '/' + jobId]);
  }


}
