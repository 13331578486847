import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ApiCallService } from "../../services/api-call-svc/api-call.service";
import { environment } from 'src/environments/environment';
import { RouterConstants } from 'src/app/constants/router.contants';
import { AuthenticationService } from "../../services/authentication/authentication.service";

@Component({
  selector: 'app-profile-external',
  templateUrl: './profile-external.component.html',
  styleUrls: ['./profile-external.component.scss']
})
export class ProfileExternalComponent implements OnInit {
  environment = environment;
  loginUser: any;
  profileExternalId: any;
  users: any;
  profilePhoto: any;
  fname: any;
  lname: any;
  followersCount: any;
  followingCount: any;
  appliedJobsCount: any;
  DesignationAndCompanyName: any;
  basicProfiles: any;
  educationalInfo: any;
  aboutUser: any;
  username: any;
  userProfilePhoto: any;
  userBackgroundPhotoPath: any;
  requestedButton = false;
  profileViewsData = [];
  followingStatus: string;
  pageNumber = 1;
  followerStatus: any;
  pendingInvitationsList = [];
  followingUsersList = [];
  companyName: any;
  jobTitle: any;
  openForHiring = false;
  routerConstants = RouterConstants;

  constructor(private activatedRoute: ActivatedRoute,
    private api: ApiCallService,
    private authSvc: AuthenticationService, private router: Router) {
    let _this = this;
    this.activatedRoute.queryParams.subscribe(params => {
      this.profileExternalId = params.id;
      const url = '/api/user/' + params.id;
      this.api.getApiCall(url).subscribe((res: any) => {
        this.users = res;
        this.username = res.username;
        this.profilePhoto = res.userPhotoPath;
        this.fname = res.firstName;
        this.lname = res.lastName;
        if(_this.router.url.indexOf(this.routerConstants.profileExternal) != -1){   
          this.ngOnInit();
        } 
      });
    });
  }

  ngOnInit(): void {
    this.loginUser = this.authSvc.getUserObj();
    this.getDesignationAndCompanyName();
    this.getBasicInfo();
    this.getEducationalInfo();
    this.getFollowersCount();
    this.getFollowingCount();
    this.getAppliedJobsCount();
    this.getProfileViewsData();
    this.getPendingInvitations(1);
    this.getFollowingUsers();
  }

  getFollowersCount() {
    const url = '/api/user/follow/followers-count/' + this.profileExternalId;
    this.api.getApiCall(url).subscribe(res => {
      this.followersCount = res;
      // console.log(res);
    }, (err) => {
    });
  }

  getFollowingCount() {
    const url = '/api/user/follow/following-count/' + this.profileExternalId;
    this.api.getApiCall(url).subscribe(res => {
      this.followingCount = res;
      // console.log(res);
    }, (err) => {
    });
  }

  getAppliedJobsCount() {
    const url = '/api/applied-job/count-by-user/' + this.profileExternalId;
    this.api.getApiCall(url).subscribe(res => {
      this.appliedJobsCount = res;
      // console.log(res);
    }, (err) => {
    });
  }

  getDesignationAndCompanyName() {
    const url = '/api/user/professional-info/' + this.profileExternalId;
    this.api.getApiCall(url).subscribe(res => {
      if (res != null) {
        this.DesignationAndCompanyName = res;
        this.companyName = (res[res.length - 1] != null) ? res[res.length - 1].companyName : null;
        this.jobTitle = (res[res.length - 1] != null) ? res[res.length - 1].jobTitle : null;
        // console.log(res);
      }
    }, (err) => {
    });
  }



  getBasicInfo() {
    const url = '/api/user/' + this.profileExternalId;
    this.api.getApiCall(url).subscribe(res => {
      this.basicProfiles = res;
      this.openForHiring = res.isSearchingForJob;
      this.aboutUser = res.aboutUser;
      this.userProfilePhoto = res.userPhotoPath;
      this.userBackgroundPhotoPath = res.userBackgroundPhotoPath;
      // console.log(res);
    }, (err) => {
    });
  }

  getEducationalInfo() {
    const url = '/api/user/educational-info/' + this.profileExternalId;
    this.api.getApiCall(url).subscribe(res => {
      this.educationalInfo = res;
      // console.log(res);
    }, (err) => {
    });
  }

  onFollow() {
    const url = '/api/user/follow/' + this.profileExternalId;
    const json = { followingStatus: 'Pending' }
    this.api.postApiCall(url, json).subscribe(res => {
      this.followingStatus = 'Pending'
      this.getPendingInvitations(1);
    });
    this.addNotifications();
  }

  addNotifications() {
    const url = '/api/notifications/add-notification';
    const jsonData = {
      type: 'following_notification',
      createdOn: new Date(),
      read: false,
      userId: this.loginUser.id,
      postId: this.profileExternalId
    }
    this.api.postApiCall(url, jsonData).subscribe((response: any) => {
      // console.log(response);
    });
  }

  requested() {
    this.requestedButton = false;
  }

  onUnfollowFollowing() {
    const url = "/api/user/follow/unfollow-user/" + this.profileExternalId;
    this.api.deleteApiCall(url).subscribe(res => {
      //    this.followingUsersList.splice(index, 1);
    });
  }

  onCancelFollowerRequest() {
    const url = '/api/user/follow/cancel-follower-request/' + this.loginUser.id;
    this.api.deleteApiCall(url).subscribe(res => {
      this.followingStatus = this.followingStatus;
      this.followerStatus = this.followerStatus;
    });
  }


  onMessage(id) {
    this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.chatbox+'/'+this.basicProfiles.id]);
  }

  getProfileViewsData() {
    const url = '/api/user/get-profile-viewed-users';
    this.api.getApiCall(url).subscribe((res) => {
      this.profileViewsData = res;
      res.forEach((list) => {
        if (list.userId == this.profileExternalId) {
          this.followingStatus = list.followingStatus;
        }
      })
    })
  }


  onCancelFollowingRequest() {
    const url = '/api/user/follow/cancel-following-request/' + this.profileExternalId;
    this.api.deleteApiCall(url).subscribe(res => {
      this.followingStatus = null;
    });
  }

  accept() {
    const url = '/api/user/follow/accept/' + this.profileExternalId;
    this.api.putApiCall(url, null).subscribe(res => {
      this.followingStatus = 'Following';
    });
  }

  getPendingInvitations(pageNumber) {
    this.api.getApiCall(`/api/user/follow/needs-approval/${pageNumber}`).subscribe(res => {
    });
  }

  getFollowingUsers() {
    this.api.getApiCall(`/api/user/follow/following/1`).subscribe(res => {
      this.followingUsersList = res;
      res.forEach((list) => {
        if (list.userId == this.profileExternalId) {
          this.followingStatus = list.followingStatus;
        }
      });
    });
  }

  getUserInfo(): string{
    return this.jobTitle & this.companyName ? (this.jobTitle+", "+this.companyName) :
             this.jobTitle ? this.jobTitle : (this.companyName ? this.companyName : "");
  }

}
