import { Component, OnInit } from '@angular/core';
import { ShareSocialUserService } from 'src/app/services/share-social-user/share-social-user.service';
import { Router } from '@angular/router';
import { RouterConstants } from 'src/app/constants/router.contants';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import {SocialAuthService, SocialUser} from 'angularx-social-login';
import {FormGroup} from '@angular/forms';
import {ApiCallService} from '../../services/api-call-svc/api-call.service';

@Component({
  selector: 'app-g-sign-up',
  templateUrl: './g-sign-up.component.html',
  styleUrls: ['./g-sign-up.component.scss']
})
export class GSignUpComponent implements OnInit {
  user: SocialUser;
  routerConstants = RouterConstants;
  formGroup: FormGroup;
  private form: any;
  loading = false;
  private signupResponse: any = '';
  loggedIn: boolean;

  constructor(private socialUserSvc: ShareSocialUserService,
              private apiCallService: ApiCallService,
              private authService: SocialAuthService,
              private authSvc: AuthenticationService,
              private router: Router) {
  }

  ngOnInit() {
    this.user = this.socialUserSvc.getSocialUser();

    if (this.user === undefined || this.user === null) {
      this.router.navigate([this.routerConstants.signIn]);
    }
  }

  gSignIn(post) {
    this.authService.authState.subscribe((User) => {
      this.user = User;
      this.loggedIn = (User != null);
      console.log(this.user);
      this.socialUserSvc.setSocialUser(User);
      sessionStorage.setItem('userId', User.id);
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.network]);
    });
  }


}
