import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { RouterConstants } from 'src/app/constants/router.contants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuradService implements CanActivate {

  routerConstant = RouterConstants;
  constructor(private authSvc: AuthenticationService,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | import('@angular/router').UrlTree
              | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
              | Promise<boolean | import('@angular/router').UrlTree> {
    return this.authSvc.checkUserlogin() ? true : this.router.navigateByUrl(this.routerConstant.signIn);
  }
}
