<app-navigation-bar></app-navigation-bar>
<!-- Banner -->
<section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <h1 class="bannerTitle">Make meaningful connections and find awesome jobs!
                </h1>
                <!-- Search -->
                <div class="row">
                    <div class="col-md-9">
                        <div class="p-1 rounded rounded-pill shadow-sm mb-4 searchBackground">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <button id="button-addon2" type="button" class="btn btn-link text-dark">
                                      <img src="./assets/images/search.png" alt="search "/>
                                  </button>
                                </div>
                                <input type="search" placeholder="{{jobConst.search_job_placeholder}}" [(ngModel)]="searchKey" aria-describedby="button-addon2" class="form-control border-0 searchBackground form-control-searchText">
                            </div>
                        </div>

                    </div>
                    <div class="col-md-3">
                        <button class="btn btn-primary btn-rounded primary-backgroundcolor searchBtn" (click)="onSearch()"><span>Search</span> &nbsp;&nbsp;<em class="fa fa-spinner
                            fa-spin"
                            *ngIf="searchBtnLoader"></em></button>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <img class="bannerImg" src="./assets/images/banner_image.png" alt="Banner Image" />
            </div>
        </div>
    </div>
</section>

<!-- Featured Jobs -->
<section class="featuredJobs">
    <div class="container">
        <div class="clearfix">
            <h2 class="featured-jobs float-left">Featured Jobs</h2>
            <a class="upload-your-resume float-right" routerLink="/{{routerConst.signIn}}">UPLOAD YOUR RESUME</a>
        </div>
        <!-- Featured Jobs List -->
        <div align="center">
            <i *ngIf="getJobsloader" class="fa
            fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
       
        <div align="center" *ngIf="jobPostList?.length === 0">No Jobs Found
        </div>
        <div class="row" *ngIf="jobPostList?.length>0">
            <div class="col-md-3 col-lg-3" *ngFor="let job of jobPostList; let i=index;">
                <div class="card featured-jobs-card">
                    <div class="card-body">
                        <div class="clearfix">

                            <img *ngIf="job.companyLogoPath == null"
                            class="jobImg1" src="./assets/images/defaultCompanyLogo.jpg"
                            alt="job image" />

                            <img *ngIf="job.companyLogoPath != null"
                            class="jobImg"
                            src="{{environment.activityPhotoHost}}{{job?.companyLogoPath}}"
                            alt="job image" />

                            <div class="featured-jobs-favourites">
                                <a routerLink="/{{routerConst.signIn}}">
                                    <img style="height: 16px; width: 18px;margin-right: 5px;" src="./assets/images/favourites.png" alt=""/></a>
                                <a routerLink="/{{routerConst.signIn}}"><img class="icon" src="./assets/images/times.png" alt=""/></a>
                            </div>
                        </div>
                        <a routerLink="/{{routerConst.signIn}}">
                            <h5 class="card-title job-title">{{job.jobTitle}}</h5>
                        </a>
                        <p class="job_companyName">{{job.companyName}}</p>
                        <p class="job_location">{{job.cityName}}, {{job.country}}</p>
                        <p class="job_new" *ngIf="job.createdOn === 'New'">New</p>
                        <p class="job_recent" *ngIf="job.createdOn != 'New'">{{job.createdOn | dateAgo}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <p class="checkout-our-compete">Checkout our compete list of jobs, grouped by location, experience, salary range and much more.</p>

                    </div>
                    <div class="card-footer">
                        <div class="clearfix">
                            <div class="float-left">
                                <a routerLink="/{{routerConst.signIn}}"><span class="view-all">View All</span></a>
                            </div>
                            <div style="float: right;">
                                <a routerLink="/{{routerConst.signIn}}"><img style="height: 26px;width: 32px;" src="./assets/images/rightarrow.png" alt="arrow" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="row">
            <div class="col-md-3 col-lg-3">
                <div class="card featured-jobs-card">
                    <div class="card-body">
                        <div class="clearfix">
                            <img class="jobImg" src="./assets/images/netflix.png" alt="job image" />
                            <div class="featured-jobs-favourites">
                                <a routerLink="/{{routerConst.signIn}}"><img style="height: 14px; width: 16px;margin-right: 7px;" src="./assets/images/favourites.png" style="margin-right: 5px; " /></a>
                                <a routerLink="/{{routerConst.signIn}}"><img class="icon" src="./assets/images/times.png" /></a>
                            </div>
                        </div>
                        <a routerLink="/{{routerConst.signIn}}">
                            <h5 class="card-title job-title">Ceative Partnershps and Promotions
                            </h5>
                        </a>
                        <p class="job_companyName">Netflix India</p>
                        <p class="job_location">Pune, Maharashtra</p>
                        <p class="job_recent">A week ago</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">
                <div class="card featured-jobs-card">
                    <div class="card-body">
                        <div class="clearfix">
                            <img class="jobImg" src="./assets/images/ibm.png" alt="job image" />
                            <div class="featured-jobs-favourites">
                                <a routerLink="/{{routerConst.signIn}}"><img style="height: 14px; width: 16px;margin-right: 7px;" src="./assets/images/favourites.png" style="margin-right: 5px; " /></a>
                                <a routerLink="/{{routerConst.signIn}}"><img class="icon" src="./assets/images/times.png" /></a>
                            </div>
                        </div>
                        <a routerLink="/{{routerConst.signIn}}">
                            <h5 class="card-title job-title">Associate Developer</h5>
                        </a>
                        <p class="job_companyName">IBM</p>
                        <p class="job_location">Pune, Maharashtra</p>
                        <p class="job_recent">3 weeks ago</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">
                <div class="card featured-jobs-card">
                    <div class="card-body">
                        <div class="clearfix">
                            <img class="jobImg" src="./assets/images/alvara.png" alt="job image" />
                            <div class="featured-jobs-favourites">
                                <a routerLink="/{{routerConst.signIn}}"><img style="height: 14px; width: 16px;margin-right: 7px;" src="./assets/images/favourites.png" style="margin-right: 5px; " /></a>
                                <a routerLink="/{{routerConst.signIn}}"><img class="icon" src="./assets/images/times.png" /></a>
                            </div>
                        </div>
                        <a routerLink="/{{routerConst.signIn}}">
                            <h5 class="card-title job-title">Product Manager</h5>
                        </a>
                        <p class="job_companyName">Alvara</p>
                        <p class="job_location">Pune, Maharashtra</p>
                        <p class="job_recent">3 weeks ago</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <p class="checkout-our-compete">Checkout our compete list of jobs, grouped by location, experience, salary range and much more.</p>

                    </div>
                    <div class="card-footer">
                        <div class="clearfix">
                            <div class="float-left">
                                <a routerLink="/{{routerConst.signIn}}"><span class="view-all">View All</span></a>
                            </div>
                            <div style="float: right;">
                                <a routerLink="/{{routerConst.signIn}}"><img style="height: 26px;width: 32px;" src="./assets/images/rightarrow.png" alt="arrow" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>

<!-- post a job banner -->

<section class="superstar_banner">
    <div class="container">


        <div class="row" style="position: relative;">
            <div class="col-md-6 col-lg-6">
                <h2 class="find-your-next-superstar">Find your next<br />Superstar
                </h2>
                <p class="these-days-recruitm">These days recruitment is not only about finding the right candidates, its about building a lasting relationship. Every candidate that you interact with, is an influencer for your company.</p>
            </div>
            <div class="col-md-4 col-lg-4">
                <select class="custom-select my-select alignBottom">
                    <option>Select Industry</option>
                  <option *ngFor="let industries of industryLists" value="{{industries.id}}">
                    {{industries.industry}}
                  </option>
                </select>
            </div>
            <div class="col-md-2 col-lg-2">
                <button routerLink="/{{routerConst.signIn}}" class="btn btn-outline-primary btn-rounded post-job-btn "><span class="post-a-job">POST A JOB</span></button>
            </div>
        </div>

    </div>
</section>

<!-- how we do it better section -->
<section class="how-better" id="why_us">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h3 class="how-better-title">Why us </h3>
                <h4 class="how-better-tagline">Here are some of the things<br />that we do differently</h4>
                <div>
                    <img class="how-better-image" src="./assets/images/illustration_personal-connect.png" />
                </div>

            </div>
            <div class="col-md-6">

                <h4 class="how-better-01">01</h4>
                <h2 class="personalConnectTitle">
                    Personal Connect
                </h2>
                <p class="personalConnectDesc">According to the theory of six degrees of separation, you need only six human connections to meet anyone on the planet. What is stopping you from meeting your perfect employee or employer in just those six connections? </p>
                <a routerLink="/{{routerConst.signIn}}">
                    <p class="personalConnectFooter">Let's make those connections!</p>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h4 class="how-better-02">02</h4>
                    <h2 class="personalConnectTitle">
                        We work on the details
                    </h2>
                    <p class="personalConnectDesc">Remember the small details when you meet somebody that make a lasting impression. These small details make a big difference in how you seek for a job too.</p>
                    <a routerLink="/{{routerConst.signIn}}">
                        <p class="personalConnectFooter">Let's impress my potential recruiters</p>
                    </a>

                </div>
            </div>
            <div class="col-md-6">
                <div align="center">

                    <img class="how-better-image02" src="./assets/images/illustration_details.png" />

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div>
                    <img class="how-better-image03" src="./assets/images/illustration_relationships.png" />
                </div>

            </div>
            <div class="col-md-6">

                <h4 class="how-better-03">03</h4>
                <!-- <img class="personalIcon" src="./assets/images/messenger.png" alt="personal-contact" /> -->
                <h2 class="personalConnectTitle">
                    Build lasting relationships
                </h2>
                <p class="personalConnectDesc">Recruitment in these days is not only about attracting and finding the right candidates, it is also about building lasting relationships. Every candidate that you interact with is a market influencer for your company values.</p>
                <a routerLink="/{{routerConst.signIn}}">
                    <p class="personalConnectFooter">I want to find the right candidates</p>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h4 class="how-better-04">04</h4>
                    <h2 class="personalConnectTitle">
                        Earn with every referral
                    </h2>
                    <p class="personalConnectDesc">We are passionate to serve you and everybody you know with the same uncompromising passion for success. Help us reach out to everybody and spread the message.</p>
                    <a routerLink="/{{routerConst.signIn}}">
                        <p class="personalConnectFooter">I want to refer</p>
                    </a>

                </div>
            </div>
            <div class="col-md-6">
                <div align="center">
                    <img class="how-better-image04" src="./assets/images/how-better4.png" />

                </div>
            </div>
        </div>
    </div>
</section>