import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import { Router, NavigationExtras } from '@angular/router';
import { RouterConstants } from '../../constants/router.contants';
import { NetworkModuleConstants } from '../../constants/globalConstants';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { SnackbarUtil } from '../../utils';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit, OnDestroy {

  environment = environment;
  routerConstants = RouterConstants;
  constants = NetworkModuleConstants;
  user: UserDetailsModel;
  userprofdetails: any;

  pendingInvitationsList = [];
  followersList = [];
  followingUsersList = [];

  activeTab = 'pendingInvitations';
  suggestionsList = [];
  activityPostList = [];
  profileViewsData = [];
  shortlistedJobsList = [];
  postedJobsList = [];
  appliedJobsList = [];
  loader = false;
  userStats = null;
  jobTitle: any;
  companyName: any;
  activityPageNumber = 1;
  totalPage: number;
  connectionStatus = null;
  commentOnPostFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private snackbarUtil: SnackbarUtil,
    private authSvc: AuthenticationService,
    public api: ApiCallService, private router: Router) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.createForm();
    this.user = this.authSvc.getUserObj();
    this.getUserStats();
    this.getProfDetails();
    this.getPendingInvitations(1);
    this.getSuggestions();
  }

  createForm() {
    this.commentOnPostFormGroup = this.fb.group({
      comment: ['', Validators.required],
    });
  }

  getUserStats() {
    const url = '/api/feed/get-user-stats';
    this.api.getApiCall(url).subscribe(res => {
      this.userStats = res;
    }, (err) => {
    });
  }

  getPendingInvitations(pageNumber) {
    this.resetData();
    this.api.getApiCall(`/api/user/follow/needs-approval/${pageNumber}`).subscribe(res => {
      if (res != null) {
        this.pendingInvitationsList = res;
      } else {
        this.pendingInvitationsList = [];
      }
    });
  }

  getSuggestions() {
    this.resetData();
    this.loader = true;
    this.api.getApiCall(`/api/user/get-friend-suggestions`).subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.suggestionsList = res;
      } else {
        this.suggestionsList = [];
      }
    });
  }

  getProfDetails() {
    const url = '/api/user/professional-info/' + this.user.id;
    this.api.getApiCall(url).subscribe(res => {
      if (res != null) {
        this.userprofdetails = res;
        this.companyName = (res[res.length - 1] != null) ? res[res.length - 1].companyName : null;
        this.jobTitle = (res[res.length - 1] != null) ? res[res.length - 1].jobTitle : null;
      }
    }, (err) => {
    });
  }

  accept(userId, index) {
    const url = '/api/user/follow/accept/' + userId;
    this.api.putApiCall(url, null).subscribe(res => {
      if (this.activeTab === 'profileView') {
        this.profileViewsData[index].followingStatus = 'Following';
        this.userStats.followers = this.userStats.followers + 1;
      } else if (this.activeTab === 'pendingInvitations') {
        this.pendingInvitationsList.splice(index, 1);
        this.userStats.followers = this.userStats.followers + 1;
      } else if (this.activeTab === 'savedPosts') {
        this.userStats.followers = this.userStats.followers + 1;
      }
    }, (err) => {
    });
  }

  onUnfollowFollower(userId, index) {
    this.api.deleteApiCall(`/api/user/follow/unfollow-follower/${userId}`).subscribe(res => {
      if (this.activeTab === 'followers') {
        this.followersList.splice(index, 1);
        if (this.userStats.followers > 0) {
          this.userStats.followers = this.userStats.followers - 1;
        }
      } else if (this.activeTab === 'savedPosts') {
        this.userStats.followers = this.userStats.followers - 1;
      }

    });
  }

  onUnfollowFollowing(userId, index) {
    this.api.deleteApiCall(`/api/user/follow/unfollow-following/${userId}`).subscribe(res => {
      if (this.activeTab === 'followingUsers') {
        this.followingUsersList.splice(index, 1);
        if (this.userStats.following > 0) {
          this.userStats.following = this.userStats.following - 1;
        }
      } else if (this.activeTab === 'profileView') {
        if (this.userStats.following > 0) {
          this.userStats.following = this.userStats.following - 1;
          this.profileViewsData[index].followingStatus = null;
        }
      } else if (this.activeTab === 'savedPosts') {
        this.userStats.followers = this.userStats.followers - 1;
      }
    });
  }

  onCancelFollowerRequest(userId, index) {
    this.api.deleteApiCall(`/api/user/follow/cancel-follower-request/${userId}`).subscribe(res => {
      if (this.activeTab === 'profileView') {
        this.profileViewsData[index].followingStatus = null;
        this.profileViewsData[index].followerStatus = null;
      } else if (this.activeTab === 'pendingInvitations') {
        this.pendingInvitationsList.splice(index, 1);
      }
    });
  }

  onCancelFollowingRequest(userId, index) {
    this.api.deleteApiCall(`/api/user/follow/cancel-following-request/${userId}`).subscribe(res => {
      if (this.activeTab === 'profileView') {
        this.profileViewsData[index].followingStatus = null;
      } else if (this.activeTab === 'followers') {
        this.followersList[index].followingStatus = 'FOLLOW_BACK';
      }
    });
  }

  getFollowers() {
    this.resetData();
    this.loader = true;
    this.activeTab = 'followers';
    this.api.getApiCall(`/api/user/follow/followers/1`).subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.followersList = res;
      } else {
        this.followersList = [];
      }
    });
  }

  getFollowingUsers() {
    this.resetData();
    this.loader = true;
    this.activeTab = 'followingUsers';
    this.api.getApiCall(`/api/user/follow/following/1`).subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.followingUsersList = res;
      } else {
        this.followingUsersList = [];
      }
    });
  }


  onFollow(userId, index) {
    this.api.postApiCall(`/api/user/follow/${userId}`, null).subscribe(res => {
      if (this.activeTab === 'profileView') {
        this.profileViewsData[index].followingStatus = 'Pending';
        this.profileViewsData[index].followerStatus = null;
      } else if (this.activeTab === 'followers') {
        this.followersList[index].followingStatus = 'CANCEL_REQUEST';
      } else if (this.activeTab === 'pendingInvitations') {
        this.suggestionsList.splice(index, 1);
        this.userStats.suggestions = this.userStats.suggestions - 1;
      }
    });
  }

  onMessage(id) {
    this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.chatbox+'/'+id]);
  }

  getProfileViewsData() {
    this.resetData();
    this.loader = true;
    const url = '/api/user/get-profile-viewed-users';
    this.api.getApiCall(url).subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.profileViewsData = res;
      } else {
        this.profileViewsData = [];
      }
    }, (err) => {
    });
  }

  viewOtherUser(userId) {
    const url = '/api/user/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: res.id }
      };
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.profileExternal], navigationExtras);
    }, (err) => {
    });

    if (userId !== this.user.id) {
      this.addNotification(userId, 'profileView');
      this.api.postApiCall('/api/user/save-profile-views/' + userId, null).subscribe(res => {
      }, (err) => {
      });
    }
  }

  addNotification(id, type) {
    const json = {
      type: type,
      createdOn: new Date(),
      read: false,
      userId: id,
      fromUserId: this.user.id,
      postId: null,
      jobId: null
    }
    const url = '/api/notifications/add-notification';
    this.api.postApiCall(url, json).subscribe((res) => {
    })
  }

  getShortlistedJobs() {
    this.resetData();
    this.loader = true;
    const url = '/api/feed/shortlisted-jobs';
    this.api.getApiCall(url).subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.shortlistedJobsList = res;
      } else {
        this.shortlistedJobsList = [];
      }
    }, (err) => {
    });
  }

  removeShortlistedJob(jobId, index) {
    this.loader = true;
    const url = '/api/feed/remove-saved-shortlisted-jobs/' + jobId;
    this.api.deleteApiCall(url).subscribe(res => {
      this.loader = false;
      this.shortlistedJobsList.splice(index, 1);
      if (this.userStats.shortlistedJobs > 0) {
        this.userStats.shortlistedJobs = this.userStats.shortlistedJobs - 1;
      }
    }, (err) => {
    });
  }

  getPostedJobs() {
    this.resetData();
    this.loader = true;
    const url = '/api/feed/posted-by-me';
    this.api.getApiCall(url).subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.postedJobsList = res;
      } else {
        this.postedJobsList = [];
      }
    }, (err) => {
    });
  }

  getAppliedJobs() {
    this.resetData();
    this.loader = true;
    const url = '/api/feed/applied-by-me';
    this.api.getApiCall(url).subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.appliedJobsList = res;
      } else {
        this.appliedJobsList = [];
      }
    }, (err) => {
    });
  }


  getActivityPosts(initialFlag, type) {
    if (initialFlag) {
      this.resetData();
    }
    this.loader = true;
    let url = '';
    if (type === 'savedPosts') {
      url = '/api/activity/get-saved-activity/' + this.activityPageNumber;
    } else {
      url = '/api/activity/get-posted-activity/' + this.activityPageNumber;
    }
    this.api.getApiCall(url).subscribe(res => {
      if (res != null && this.activityPageNumber > 1) {
        const activityLocal = [];
        res.content.forEach(activity => {
          if (activity.activityPostedByUser.username === this.user.username) {
            activity.activityOwner = true;
          } else {
            activity.activityOwner = false;
          }
          activity.initialCommentsList = 5;
          activityLocal.push(activity);
        });
        this.activityPostList = this.activityPostList.concat(activityLocal);
      } else {
        this.activityPostList = [];
        res.content.forEach(activity => {
          if (activity.activityPostedByUser.username === this.user.username) {
            activity.activityOwner = true;
          } else {
            activity.activityOwner = false;
          }
          activity.initialCommentsList = 5;
          this.activityPostList.push(activity);
        });
      }
      this.totalPage = res.totalPages;
      this.activityPageNumber = this.activityPageNumber + 1;
      this.loader = false;
    }, (err) => {
      this.loader = false;
    });
  }

  scrollEvent = (event: any): void => {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    if (event.target.scrollTop >= (limit - 100) && this.totalPage >= this.activityPageNumber) {
      if ((this.activeTab === 'savedPosts' || this.activeTab === 'yourPosts') && !this.loader) {
        this.getActivityPosts(false, this.activeTab);
      }
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }



  resetData() {
    this.appliedJobsList = [];
    this.postedJobsList = [];
    this.shortlistedJobsList = [];
    this.profileViewsData = [];
    this.activityPostList = [];
    this.followingUsersList = [];
    this.followersList = [];
    this.pendingInvitationsList = [];
    this.activityPageNumber = 1;
  }


  onViewJobDetails(jobId) {
    if (this.activeTab === 'jobsPosted') {
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.postJob + '/details/' + jobId]);
    } else {
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.jobDetails + '/' + jobId]);
    }
  }


  onLike(activityId, index) {
    const url = '/api/activity/like-post?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivityLike = true;
      this.activityPostList[index].likedCounts = this.activityPostList[index].likedCounts + 1;
    }, (err) => {
      console.log(err);
    });
  }

  onUnLike(activityId, index) {
    const url = '/api/activity/undo-like-post?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivityLike = false;
      this.activityPostList[index].likedCounts = this.activityPostList[index].likedCounts - 1;
    }, (err) => {
      console.log(err);
    });
  }

  allLikedUser(activityId) {
    this.api.getApiCall('/api/activity/all-liked-user/' + activityId + '/1').subscribe(response => {
      if (response != null) {
       // console.log(response);
      }
    }, (err) => {
    });
  }

  onSave(activityId, index) {
    const url = '/api/activity/save-activity?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivitySave = true;
      this.userStats.savedPosts = this.userStats.savedPosts + 1;
    }, (err) => {
      console.log(err);
    });
  }

  onRemoveFromSavedCollection(activityId, index) {
    const url = '/api/activity/remove-saved-activity?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.userStats.savedPosts = this.userStats.savedPosts - 1;
      if (this.activeTab === 'savedPosts') {
        this.activityPostList.splice(index, 1);
      } else {
        this.activityPostList[index].isActivitySave = false;
      }
    }, (err) => {
      console.log(err);
    });
  }


  onComment(activityid, index) {
    const url = '/api/activity/post-comment';
    const jsonObj = {
      activityId: activityid,
      commentsText: this.commentOnPostFormGroup.value.comment
    };
    this.api.postApiCall(url, jsonObj).subscribe(res => {
      if (res != null) {
        this.commentOnPostFormGroup.reset();
        this.commentOnPostFormGroup.patchValue({});
        this.activityPostList[index].totalComments = this.activityPostList[index].totalComments + 1;
        if (this.activityPostList[index].totalComments > this.activityPostList[index].initialCommentsList) {
          let arr = [];
          arr = this.activityPostList[index].activityComments.reverse();
          arr.pop();
          this.activityPostList[index].activityComments = arr.reverse();
        }
        res.firstName = this.user.fname;
        res.lastName = this.user.lname;
        res.userName = this.user.username;
        this.activityPostList[index].activityComments.push(res);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onLoadNextFewComments(activityId, index) {
    this.activityPostList[index].initialCommentsList = this.activityPostList[index].initialCommentsList + 5;
    const pageNo = (this.activityPostList[index].initialCommentsList / 5);
    const url = '/api/activity/get-comments/' + activityId + '/' + pageNo;
    this.api.getApiCall(url).subscribe(res => {
      res.content.reverse().forEach(element => {
        this.activityPostList[index].activityComments.splice(0, 0, element);
      });
    }, (err) => {
      console.log(err);
    });
  }

  onDeleteActivity(activityId) {
    const url = '/api/activity/' + activityId;
    this.api.deleteApiCall(url).subscribe(res => {
      this.activityPageNumber = 1;
      this.getActivityPosts(true, this.activeTab);
      this.getUserStats();
    }, (err) => {
      console.log(err);
    });
  }

  getConnectionStatus(userId) {
    this.connectionStatus = null;
    const url = '/api/user/follow/get-connection-status/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      if (res != null) {
        this.connectionStatus = res.status;
      } else {
        this.connectionStatus = null;
      }
    }, (err) => {
    });
  }

  onDeleteJob(id, index) {
    const postDto = {
      jobId: id,
      isActive: false
    };
    this.api.postApiCall('/api/feed/', postDto).subscribe(
      res => {
        this.postedJobsList.splice(index, 1);
        this.userStats.postedJobs = this.userStats.postedJobs - 1;
        this.snackbarUtil.openSnackBar('Deleted successfully', null);
      },
      err => {
      }
    );
  }

  getProfilePicPath(userId, userPhotoPath): string{
    if(userId == this.authSvc.getUserId()){
      return this.environment.userPhotoHost+this.authSvc.getUserObj().userDPUrl;
    } else{
      return this.environment.userPhotoHost+userPhotoPath;
    }
  }

}
