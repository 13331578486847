<section>
    <div class="chatbox chatbox--tray chatbox--empty" [id]="'chatbox_'+instanceId" [style]=cssStyle *ngIf="showPopup">
        <div class="chatbox__title" [id]="'chatbox__title_'+instanceId" (click)="expand()">
            <h5 class="ml-4"><a [ngClass]="{'chatbox_active':!expandChat}" class="ml-1 text-truncate">{{slaveObj?slaveObj.userName:'MESSAGES'}}</a></h5>
            <button type="button" class="close mt-n1" style="color:white" aria-label="Close" *ngIf="instanceId != 'master'" (click)="closePopup()">
                    <span aria-hidden="true">&times;</span>
                  </button>
        </div>
        <app-chatbox [isMessgaeWindow]="isMessgaeWindow" [slaveObj]="slaveObj" [chatWindowInstanceId]="instanceId"></app-chatbox>
    </div>

    <!-- <app-chatbox [isMessgaeWindow]="isMessgaeWindow"></app-chatbox> -->
</section>
<section>

    <div class="user-chatbox user-chatbox--tray user-chatbox--empty">
        <div class="user-chatbox__title">
            <span><a>{{openUserChats}} </a><label
                  class="user-chatbox-online"></label></span>
            <button class="user-chatbox__title__close">
              <img src="./assets/images/icons_cross.png" />
          </button>
        </div>
        <div class="user-chatbox__body">
            <div class="user-chatbox__body__message
              user-chatbox__body__message--left">
                <img *ngIf="!user.userDPUrl" src="{{environment.userPhotoHost}}default-profile-200x150.png" class="chatbox-image" alt="Picture">
                <img *ngIf="user.userDPUrl" src="{{environment.userPhotoHost}}{{user.userDPUrl}}" class="chatbox-image" alt="Picture">
                <div class="user-chatbox-name">{{user.fname}} {{user.lname}}<span class="user-chatbox-user-time">14:30</span></div>
                <div class="user-chatbox-user-contain">Hey how have you been?</div>
            </div>
            <div class="user-chatbox__body__message
              user-chatbox__body__message--left">
                <img src="{{environment.userPhotoHost}}{{userPhotoPath}}" class="user-chatbox-image" alt="Picture">
                <div class="user-chatbox-name">{{openUserChats}}<span class="user-chatbox-user-time">15:30</span></div>
                <div class="user-chatbox-user-contain">I am good. How have you been?
                </div>
            </div>
            <div class="user-chatbox-user-day">Aug 10</div>
            <div class="user-chatbox__body__message
              user-chatbox__body__message--left">
                <img *ngIf="!user.userDPUrl" src="{{environment.userPhotoHost}}default-profile-200x150.png" class="chatbox-image" alt="Picture">
                <img *ngIf="user.userDPUrl" src="{{environment.userPhotoHost}}{{user.userDPUrl}}" class="chatbox-image" alt="Picture">
                <div class="user-chatbox-name">{{user.fname}} {{user.lname}}<span class="user-chatbox-user-time">10:30</span></div>
                <div class="user-chatbox-user-contain">Listen I wanted to talk to you about something.
                </div>
            </div>
            <form [formGroup]="chatForm">
                <textarea class="user-chatbox__message" formControlName="message" placeholder="Write a
              message.."></textarea>
            </form>
            <div>
                <button class="btn btn-blue-border btn-sm btn-rounded with-icon"><span
                      class="user-chatbox-send"  (click)="sendMessage()">SEND</span></button>
            </div>
        </div>
    </div>

</section>