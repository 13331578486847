import { Component, OnInit } from '@angular/core';
import { RouterConstants } from 'src/app/constants/router.contants';
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiCallService } from "../../services/api-call-svc/api-call.service";
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { SocketService } from "../../services/socket-service/socket.service";
import * as socketConfig from "../../services/socket-service/socket-config"

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
declare var $: any;

@Component({
  selector: 'app-navigation-bar-users',
  templateUrl: './navigation-bar-users.component.html',
  styleUrls: ['./navigation-bar-users.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class NavigationBarUsersComponent implements OnInit {
  routerConstants = RouterConstants;
  user: UserDetailsModel;
  environment = environment;
  serverUrl = this.environment.apiUrl + '/api/open-websocket';
  stompClient: any;
  isLoaded = false;
  count = 0;

  searchText: String;
  closeResult = '';
  externalUsers = [];
  usersId: any;
  pageNumber = 1;
  totalPage: number;
  allNotifications = [];
  content: any;
  postId: any;
  userId: any;
  notifications = [];
  notificationsCount = [];
  showNotification = true;
  searchKey: any;
  notificationCountFromSocket;
  // showNotificationCount = false;
  showMaster = true;
  showSlave1 = false;
  showSlave2 = false;
  slave1Obj = { "userId": 0, "userName": "", "pic": "" };
  slave2Obj = { "userId": 0, "userName": "", "pic": "" };
  lastOpenSlave = "slave2";
  suggestions = [];
  recentSearches = [];
  constructor(private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private api: ApiCallService,
    private socketService: SocketService
  ) {
    const _this = this;
    this.api.subscribeMessagesEvents.subscribe((event: any) => {
      let chatWinInstace;
      let slaveObj;
      if ((_this.showSlave1 && event.userId == _this.slave1Obj.userId) || (_this.showSlave2 && event.userId == _this.slave2Obj.userId)) {
        return;
      }

      if (!_this.showSlave1 || _this.lastOpenSlave == "slave2") {
        slaveObj = _this.slave1Obj;
        _this.showSlave1 = true;
        chatWinInstace = 'slave1';
        _this.lastOpenSlave = 'slave1';
      } else if (!_this.showSlave2 || _this.lastOpenSlave == "slave1") {
        slaveObj = _this.slave2Obj;
        _this.showSlave2 = true;
        chatWinInstace = 'slave2';
        _this.lastOpenSlave = 'slave2'
      }

      slaveObj.userId = event.userId;
      slaveObj.userName = event.userName;
      slaveObj.pic = event.pic;

      _this.socketService.reloadChatWindow.next({ "slaveObj": slaveObj, "chatWinInstace": chatWinInstace });


    });

    authSvc.profileChangeSubject.subscribe((event: any) => {
      //console.log('navigation bar',event);
      this.user.userDPUrl = event;
    });
  }

  ngOnInit(): void {
    this.user = this.authSvc.getUserObj();
    const _this = this;
    if (!this.socketService.stompClient) {
      this.socketService.initializeSocketForUser().then((callBack) => {
        _this.stompClient = _this.socketService.stompClient;
        _this.socketService.subscribeChatboxSockets.next();
        _this.stompClient.subscribe(socketConfig.GET_NOTIFICATION_SUBSCRIPTION, function (calResult) {
          let calObj = JSON.parse(calResult.body);
          _this.notificationCountFromSocket = calObj;
        });
      }).catch((error) => {
        console.log('error occured!!! socket connection')
      });
    }
  }

  formatter = (result: any) => result.category;
  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      // switchMap((searchText) => this.api.getApiCall(`/api/user/find-user/${searchText}/${this.pageNumber}`)),
      switchMap((searchText) => searchText.length < 3 ? [] :
        this.api.getApiCall(`/api/job-category/`)),
      catchError(() => {
        return [];
      })
    );
  }

  closeChatWinPopUp($event) {
    if ($event == 'slave1') {
      this.lastOpenSlave = 'slave2';
      this.slave1Obj.userId = 0;
      this.showSlave1 = false;
    } else if ($event == 'slave2') {
      this.lastOpenSlave = 'slave1';
      this.slave2Obj.userId = 0;
      this.showSlave2 = false;
    }
   // console.log($event);
  }
  selectedItem(item) {
    this.router.navigateByUrl('/home/aboutuser/gangadhar.b.jawali');
  }

  getUserSearchDetails() {
    if (this.searchKey !== undefined && this.searchKey.length > 2) {
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.searchResult + '/' + this.searchKey])
    }
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //console.log(this.closeResult)
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  onLogout() {
    this.authSvc.logout();
    this.socketService.stompClient.disconnect();
    this.socketService.stompClient = null;
  }

  openChat() {
    setTimeout(() => {
      this.socketService.subscribeChatboxSockets.next();
    }, 100);
  }

  readNotifications() {
    this.notificationCountFromSocket = 0;
    this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.notifications]);
  }

  openGlobalSocket() {

  }
  
  handleResult(message) {
    if (message.body) {
      const messageResult: any = message.body;
      this.count = message.body;
    }
  }

  onKeydown(event){
    if(this.validateKey(event.which)){
      this.recentSearches = [];
      let searchText = $('#srchbx').val();
       // console.log('by id',$('#srchbx').val());
       if(searchText){
        this.api.getApiCall('/api/es/search/'+searchText).subscribe(res=>{        
          this.suggestions = [];
          this.suggestions = res;        
        });
      }else{
        this.suggestions = [];
        this.fetchRecentSearch();
      }
    }     
  }

  validateKey(key){
    //https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes/
    const invalidArr = [9,13,16,17,18,20,27,33,34,35,36,37,38,39,40,44,45,46,91,92,93,112,113,114,115,116,117,118,119,120,121,122,123,144,145,173,174,175,181,182,183];
    return invalidArr.filter(objKey => {
      return objKey === key;
    }).length > 0 ? false : true;
  }

  viewOtherUser(userId) {
    this.suggestions = [];
    const url = '/api/user/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: res.id }
      };
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.profileExternal], navigationExtras);
    }, (err) => {
    });

    if (userId !== this.user.id) {
      this.addNotification(userId, 'profileView', null);
      const url = '/api/user/save-profile-views/' + userId;
      this.api.postApiCall(url, null).subscribe(res => {
      }, (err) => {
      });
    }

    console.log('new logic');
   // window.location.href = '/user/profile-external-details?id='+userId;

  }

  viewJob(jobId){
    this.suggestions = [];
    // const navigationExtras: NavigationExtras = {
    //     queryParams: { id: jobId }
    //   };
    //   this.router.navigate([this.routerConstants.user + '/' +this.routerConstants.jobDetails+ '/' +jobId], navigationExtras);
    //window.location.href = '/user/job-details/'+jobId;
    if(this.router.url.indexOf(this.routerConstants.jobDetails) == -1){   
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.jobDetails + '/' + jobId]);
    } else{
      this.api.subscribeJobSearchEvents.next(jobId);
    }
    console.log('new logic');

  }

  addNotification(id, type, activityId) {
    if (id !== this.user.id) {
      const json = {
        type: type,
        createdOn: new Date(),
        read: false,
        userId: id,
        fromUserId: this.user.id,
        postId: activityId,
        jobId: null
      }
      const url = '/api/notifications/add-notification';
      this.api.postApiCall(url, json).subscribe((res) => {
      })
    }
  }

  onClick(event){
   // console.log('on click event trigger..');
    this.suggestions = [];
    this.recentSearches = [];
  }

  openSearchDetails(text) {
    this.suggestions = [];
    let searchText = text?text:$('#srchbx').val();
    if (searchText !== undefined && searchText.length > 2) {
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.searchResult + '/' + searchText])
    }
  }

  fetchRecentSearch(){
    let searchText = $('#srchbx').val();
    if (!searchText) {
      this.api.getApiCall('/api/es/recent-search').subscribe(res=>{        
        console.log(res);  
        this.recentSearches = [];
        if(res && res.length > 0){
          res.sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());
        }
        this.recentSearches = res;   
      });
    }
  }
  

}
