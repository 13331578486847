import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiCallService } from "../../services/api-call-svc/api-call.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { environment } from 'src/environments/environment';
import { Subscription, interval } from 'rxjs';
import { RouterConstants } from '../../constants/router.contants';
import { Router, NavigationExtras } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
declare var $: any;
import { SocketService } from '../../services/socket-service/socket.service';
import * as socketConfig from '../../services/socket-service/socket-config';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  environment = environment;
  routerConstants = RouterConstants;
  loginUser: UserDetailsModel;
  externalUsers = [];
  usersId: any;
  pageNumber = 1;
  totalPage: number;
  allNotifications = [];
  content: any;
  postId: any;
  userId: any;
  notifications = [];
  notificationsCount = [];
  userProfilePhoto: any;
  userStats: any;
  profileViewsData: any;
  pendingInvitationsList: any;

  newNotifications = [];
  earlierNotifications = [];
  sub: Subscription;
  loader = false;

  createBirthDayWishFormGroup: FormGroup;
  stompClient: any;
  birthDayWishToUserId: any;


  constructor(
    private socketService: SocketService,
    private router: Router,
    private api: ApiCallService,
    private fb: FormBuilder,
    private authSvc: AuthenticationService) {
    const _this = this;
    this.stompClient = this.socketService.stompClient;
  }

  ngOnInit(): void {
    this.createForm();
    this.loginUser = this.authSvc.getUserObj();
    this.getNewNotifications();
    this.getEarlierNotifications();
  }

  createForm() {
    this.createBirthDayWishFormGroup = this.fb.group({
      birthDayWishContent: ['', Validators.required],
    });
  }

  getNewNotifications() {
    this.loader = true;
    const url = '/api/notifications/get-new-notifications';
    this.api.getApiCall(url).subscribe(response => {
      this.loader = false;
      if (response != null && response.length > 0) {
        this.newNotifications = response;
      } else {
        this.newNotifications = [];
      }
    });
    this.updateNewNotifications();
  }

  updateNewNotifications() {
    this.sub = interval(10000).subscribe(val => {
      const url = '/api/notifications/get-new-notifications';
      this.api.getApiCall(url).subscribe(response => {
        if (response != null && response.length > 0) {
          this.newNotifications = response;
        } else {
          this.newNotifications = [];
        }
      });
    })
  }


  getEarlierNotifications() {
    const url = '/api/notifications/get-earlier-notifications';
    this.api.getApiCall(url).subscribe(response => {
      if (response != null && response.length > 0) {
        this.earlierNotifications = response;
      } else {
        this.earlierNotifications = [];
      }
    });
  }

  accept(userId, index) {
    const url = '/api/user/follow/accept/' + userId;
    const json = {
      userId: this.loginUser.id,
      followingId: userId,
      isApproved: true,
    }
    this.api.putApiCall(url, json).subscribe(res => {
      this.newNotifications[index].currentFollowStatus = 'Accepted';
      this.addNotification(userId, 'acceptedFollowRequest')
    }, (err) => {
    });
  }


  decline(userId, index) {
    const url = '/api/user/follow/cancel-follower-request/' + userId;
    this.api.deleteApiCall(url).subscribe(res => {
      this.newNotifications[index].currentFollowStatus = null;
      this.addNotification(userId, 'cancelFollowerRequest')
    }, (err) => {
    });
  }

  onViewJobDetails(jobId, type) {
    if (type === 'jobsPosted') {
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.postJob + '/details/' + jobId]);
    } else {
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.jobDetails + '/' + jobId]);
    }
  }

  viewOtherUser(userId) {
    const url = '/api/user/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: res.id }
      };
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.profileExternal], navigationExtras);
    }, (err) => {
    });

    if (userId !== this.loginUser.id) {
      this.addNotification(userId, 'profileView');
      this.api.postApiCall('/api/user/save-profile-views/' + userId, null).subscribe(res => {
      }, (err) => {
      });
    }
  }

  addNotification(id, type) {
    const json = {
      type: type,
      createdOn: new Date(),
      read: false,
      userId: id,
      fromUserId: this.loginUser.id,
      postId: null,
      jobId: null
    }
    const url = '/api/notifications/add-notification';
    this.api.postApiCall(url, json).subscribe((res) => {
    })
  }

  ngOnDestroy() {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }

  viewActivityPost(activityId) {
    this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.network + '/activity-details/' + activityId]);
  }

  onBirthDayWish() {
    this.stompClient.send(socketConfig.SEND_PRIVATE_MESSAGE_EVENT, {}, JSON.stringify({
      'fromUserId': this.loginUser.id
      , 'toUserId': this.birthDayWishToUserId, 'message': this.createBirthDayWishFormGroup.value.birthDayWishContent
    }));
    $('#wishHappyBirthDayModal').modal('hide');
    this.createBirthDayWishFormGroup.reset();
    this.createBirthDayWishFormGroup.patchValue({});
  }
}
