<section class="applied-jobs-section">
    <div class="container">
        <h3 class="applied-jobs-title">Shortlisted Jobs</h3>
        <div class="row">
            <div class="col-md-9 col-lg-9">
                <div class="applied-jobs">

                    <div class="card profile-card applied-jobs-info">
                        <div class="row">
                            <div class="col-md-1 col-lg-1">
                                <img src="./assets/images/ibm.png" />
                            </div>
                            <div class="col-md-8 col-lg-8">
                                <div style="margin-left: 9px">
                                    <h4 class="applied_role">Sr. Manager, Social and other media</h4>
                                    <p class="applied_company">IBM</p>
                                    <p class="applied_location">Pune, Maharashtra</p>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="action-dots clearfix">
                                    <div class="dropdown" data-toggle="dropdown">
                                        <img class="" src="./assets/images/dots.svg" />
                                        <ul class="dropdown-menu" style="left: -74% !important">
                                            <li><a><span>
                                                <img class="dropdown-menu-trash" src="./assets/images/trash.svg" />
                                            </span><span>Delete</span> </a></li>
                                        </ul>
                                    </div>
                                    <p>Applied 3 days ago</p>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="card profile-card applied-jobs-info">
                        <div class="row">
                            <div class="col-md-1 col-lg-1">
                                <img src="./assets/images/ibm.png" />
                            </div>
                            <div class="col-md-8 col-lg-8">
                                <div style="margin-left: 9px">
                                    <h4 class="applied_role">Sr. Manager, Social and other media</h4>
                                    <p class="applied_company">IBM</p>
                                    <p class="applied_location">Pune, Maharashtra</p>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">

                                <div class="action-dots clearfix">
                                    <div class="dropdown" data-toggle="dropdown">
                                        <img class="" src="./assets/images/dots.svg" />
                                        <ul class="dropdown-menu" style="left: -74% !important">
                                            <li><a><span>
                                                <img class="dropdown-menu-trash" src="./assets/images/trash.svg" />
                                            </span><span>Delete</span> </a></li>
                                        </ul>
                                    </div>
                                    <p>Applied 3 days ago</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">

                <!-- <div class="manage-categories" *ngIf="userStats != null">
                    <div class="cursor_pointer" (click)="getAllActivityPosts();
                        activeTab=
                        'yourPosts'">
                        <img src="./assets/images/right.png" class="manage-categories-icon" alt="" /><span class="manage-categories-label">Your Posts</span> &nbsp;&nbsp; {{userStats.yourPosts}}
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section cursor_pointer" (click)="getAllActivityPosts(); activeTab=
                        'savedPosts'">
                        <img src="./assets/images/icons_save.png" class="manage-categories-icon-save" alt="" /><span class="manage-categories-label"> Saved Posts</span> &nbsp;&nbsp; {{userStats.savedPosts}}
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section cursor_pointer" (click)="activeTab= 'shortlistedJobs'">
                        <img src="./assets/images/love.png" class="manage-categories-icon" alt="" /><span class="manage-categories-label">Shortlisted Jobs</span> &nbsp;&nbsp; {{userStats.shortlistedJobs}}
                    </div>
                    <hr style="background-color: #C8C8C8;" *ngIf="userStats.postedJobs> 0">
                    <div class="manage-categories-section cursor_pointer" (click)="activeTab= 'jobsPosted'" *ngIf="userStats.postedJobs>
                        0">
                        <img src="./assets/images/right.png" class="manage-categories-icon" alt="" /><span class="manage-categories-label">Posted Jobs</span> &nbsp;&nbsp; {{userStats.postedJobs}}
                    </div>
                    <hr style="background-color: #C8C8C8;" *ngIf="userStats.appliedJobs> 0">
                    <div class="manage-categories-section cursor_pointer" (click)="activeTab= 'jobsApplied'" *ngIf="userStats.appliedJobs> 0">
                        <img src="./assets/images/right.png" class="manage-categories-icon" alt="" /><span class="manage-categories-label">Applied Jobs</span> &nbsp;&nbsp; {{userStats.appliedJobs}}
                    </div>
                </div> -->


                <div class="manage-categories applied-jobs-categories">
                    <div class="manage-categories-section clearfix">
                        <img src="./assets/images/mypost.svg" class="manage-categories-icon float-left" />
                        <span class="manage-categories-label float-left">Your Posts</span>
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section clearfix">
                        <img src="./assets/images/savepost.svg" class="manage-categories-icon float-left" />
                        <span class="manage-categories-label float-left"> Saved Posts</span>
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section clearfix">
                        <img src="./assets/images/manage-shortlisted.svg" class="manage-categories-icon float-left" /><span class="manage-categories-label float-left">Shortlisted Jobs</span>
                    </div>
                    <hr style="background-color: #C8C8C8;">
                    <div class="manage-categories-section clearfix">
                        <img src="./assets/images/manage-job.svg" class="manage-categories-icon float-left" />
                        <span class="manage-categories-label float-left">Applied Jobs</span>
                    </div>
                </div>
                <div class="card profileCard">
                    <app-add-box></app-add-box>
                </div>
                <hr class="network-linebreak">
                <div class="network-right-side-info">
                    <span>About US</span>
                    <span style="margin-left: 28px;margin-right: 28px;">Contact US</span>
                    <span>Privacy Policy</span>
                </div>
                <div class="network-socialMedia" align="center">
                    <img src="./assets/images/facebook_grey.png" class="network-facebook" alt="facebook" />
                    <img src="./assets/images/twitter_grey.png" class="network-twitter" alt="twitter" />
                    <img src="./assets/images/instagram_grey.png" class="network-instagram" alt="instagram" />
                </div>
            </div>
        </div>
    </div>
</section>
