import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import {UserDetailsModel} from "../../models/UserDetailsModel";
import { environment } from 'src/environments/environment';
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {SocketService} from '../../services/socket-service/socket.service'
declare var $:any;

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  allOnlineUsers: any;
  unreadMsgCount:any;
  chatSummaryList:any;
  messagesByFromIdAndToId:any;
  pageNumber = 1;
  totalPage: number;
  user: UserDetailsModel;
  environment = environment;
  openUserChats:any;
  userPhotoPath:any;
  chatForm: FormGroup;
  isMessgaeWindow=true;
  @Input() cssStyle;
  @Input() instanceId;
  @Input() showPopup;
  @Input() slaveObj;
  expandChat = false;
  @Output() closeWinPopEvent = new EventEmitter<any>();
  constructor( private authSvc: AuthenticationService, private api: ApiCallService, private formBuilder:FormBuilder, private socketServic:SocketService) { }

  ngOnInit(): void {
    this.user = this.authSvc.getUserObj();
    this.chatForm = this.formBuilder.group({
      message: [null, [Validators.required]]
    })  
  }

  expand(){
    this.expandChat = !this.expandChat;
    if(this.instanceId == 'master'){
      this.socketServic.getUserList();
    } 
    $('#chatbox_'+this.instanceId).toggleClass('chatbox-openchat');
    $('#chatbox_'+this.instanceId).toggleClass('chatbox--tray');
    if(this.expandChat){
      $("#chatbox__title_"+this.instanceId).css("border-radius", "10px 10px 0 0"); 
    } else{
      $("#chatbox__title_"+this.instanceId).css("border-radius", "50px")
    }
  }

  closePopup(){
    this.showPopup=false
    this.closeWinPopEvent.next(this.instanceId);
  }

  getAllOnlineUsers() {
    const url = '/api/auth/get-online-users';
    this.api.getApiCall(url).subscribe(res => {
      this.allOnlineUsers = res;
      // console.log("alluserOnline-----" + res)
    }, (err) => {
    });
  }

  getUnreadMsgCount() {
    const url = '/api/chat/get-unread-message-count';
    this.api.getApiCall(url).subscribe(res => {
      this.unreadMsgCount = res;
      // console.log("unreadMsgCount-----" + res)
    }, (err) => {
    });
  }

  getChatSummaryList() {
    const url = '/api/chat/get-summary/' + this.pageNumber + '/';
    this.api.getApiCall(url).subscribe(res => {
      if (res != null && this.pageNumber > 0) {
        this.chatSummaryList = res;
        // console.log("chatSummaryList-----" + res)
      }

    }, (err) => {
    });
  }

  getMessagesByFromIdAndToId(toId) {
    const url = '/api/chat/get-history/'+ toId + '/' + this.pageNumber + '/';
    this.api.getApiCall(url).subscribe(res => {
      // if (res != null && this.pageNumber > 1) {
        this.messagesByFromIdAndToId = res;
       // console.log(res);
      // }
      this.totalPage = res.totalPages;
      this.pageNumber = this.pageNumber + 1;
    }, (err) => {
    });
  }

  openUserChat(id) {
    const url = '/api/user/'+ id;
   this.api.getApiCall(url).subscribe((response:any) => {
     this.openUserChats = response.username;
     this.userPhotoPath = response.userPhotoPath;
     this.getMessagesByFromIdAndToId(id);
   });
  }

  sendMessage() {
   // console.log(this.chatForm.value);
  }

}
