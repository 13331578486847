export const CHAT_SERVICE_TOPIC_NAME = '/happy-connect-chatter';
export const GET_USER_LIST_EVENT = "/app/getUserList";
export const SEND_PRIVATE_MESSAGE_EVENT = "/app/sendPrivateMessage";
export const BROADCAST_MESSAGE_EVENT = "/app/broadCastMessage";
export const GET_CHAT_HISTORY_EVENT = "/app/getChatHistory";
export const SEND_TYPING_EVENT = "/app/sendTypingEvent";
export const SEND_HEART_BEAT_EVENT = "/app/userHeartBeat";
export const SEND_READ_ALL_MSG_EVENT = "/app/readAllMessage";
export const RESPONSE_SUBSCRIPTION = "/user/queue/response";
export const RECIEVE_BRODCAST_SUBSCRIPTION = "/user/queue/rcvbroadCast";
export const ACK_SUBSCRIPTION = "/user/queue/ack";
export const CONNECTED_USERS_SUBSCRIPTION = "/user/queue/connectedUserList";
export const CHAT_HISTORY_SUBSCRIPTION = "/user/queue/chatHistory";
export const TYPING_SUBSCRIPTION = "/user/queue/typing";
export const GET_STATUS_SUBSCRIPTION = "/user/queue/getStatus";
export const GET_NOTIFICATION_SUBSCRIPTION = "/user/queue/notification";

