<!-- Search Job -->


<section class="jobPage">
    <div class="container">
            <div class="row">
                <div class="col-md-9 col-lg-9">
                    <div class="jobPageBorderBox">
                        <h2 class="jobPageTitle">Search for an<br />exciting new
                            job!</h2>
                        <div class="row">
                            <div class="col-md-9">
                                <div class="p-1 rounded rounded-pill shadow-sm
                                    mb-4 searchBackground" style="height:
                                    56px;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button id="button-addon2"
                                                type="button" class="btn
                                                btn-link text-dark">
                                                <img
                                                    src="./assets/images/search.png"
                                                    alt="search" />
                                            </button>
                                        </div>
                                        <input type="text"
                                            placeholder="{{jobConst.search_job_placeholder}}"
                                            class="form-control border-0
                                            searchBackground
                                            form-control-searchText"
                                            [(ngModel)]="searchKey"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-primary btn-rounded
                                    primary-backgroundcolor searchBtn"
                                    style="height: 56px;" (click)="getAllJobs(true)"><span
                                        style="font-size: 15px; line-height:
                                        24px;">FIND JOBS</span></button>
                            </div>
                        </div>

                        <div>
                            <span class="dropdown show">
                                <button class="btn btn-sm my-2 my-sm-0
                                    btn-rounded btn-outline-light-dark"
                                    id="dropdownLocationLink"
                                    data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <span>Location</span>
                                    <i class="fas fa-chevron-down filtericons"></i>
                                </button>
                                <div class="dropdown-menu dropdownFilter"
                                    aria-labelledby="dropdownLocationLink">
                                    <div class="dropdown-item" *ngFor="let
                                        location of
                                        topJobsLocations;">
                                        <input type="checkbox"
                                            (click)="onLocationCheck($event,
                                            location)" /> <label>{{location.city}}
                                            ({{location.totalJobs}})</label>
                                    </div>

                                    <div class="float-right filterBtns">
                                        <button class="btn btn-outline-primary
                                            btn-rounded my-2 my-sm-0
                                            cancelFilterBtn">CANCEL</button>
                                        <button class="btn btn-primary
                                            btn-rounded applyFilterBtn"
                                            (click)="getAllJobs(true)">APPLY</button>
                                    </div>
                                </div>
                            </span>
                            <!-- Experience -->
                            <span class="dropdown show">
                                <button class="btn btn-sm btn-outline-light-dark
                                    my-2 my-sm-0 btn-rounded"
                                    id="dropdownExperienceLink"
                                    data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <span>Experience</span>
                                    <i class="fas fa-chevron-down filtericons"></i>
                                </button>

                                <div class="dropdown-menu dropdownFilter"
                                    aria-labelledby="dropdownExperienceLink"
                                    *ngIf="topJobsByExperiance.length> 0">
                                    <div class="dropdown-item">
                                        <input type="radio" name="exp" (click)="onExperianceCheck(0)"/> <label>Fresher
                                            ({{topJobsByExperiance[0].fresher}})</label>
                                    </div>
                                    <div class="dropdown-item">
                                        <input type="radio" name="exp" (click)="onExperianceCheck(1)"/> <label>2-5
                                            Years({{topJobsByExperiance[1].betweenTwoAndFive}})</label>
                                    </div>
                                    <div class="dropdown-item">
                                        <input type="radio" name="exp" (click)="onExperianceCheck(2)"/> <label>6-10
                                            Years
                                            ({{topJobsByExperiance[2].betweenSixAndTen}})</label>
                                    </div>
                                    <div class="dropdown-item">
                                        <input type="radio" name="exp" (click)="onExperianceCheck(3)"/> <label>10+
                                            yrs
                                            ({{topJobsByExperiance[3].aboveTen}})</label>
                                    </div>

                                    <div class="float-right filterBtns">
                                        <button class="btn btn-outline-primary
                                            btn-rounded my-2 my-sm-0
                                            cancelFilterBtn">CANCEL</button>
                                        <button class="btn btn-primary
                                            btn-rounded applyFilterBtn"
                                            (click)="getAllJobs(true)">APPLY</button>
                                    </div>
                                </div>
                            </span>


                                <!-- Salary range -->
                            <span class="dropdown show">
                                <button class="btn btn-sm btn-outline-light-dark
                                my-2 my-sm-0 btn-rounded"
                                id="dropdownSalaryRangeLink"
                                    data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"><span>Salary Range</span>
                                <i class="fas fa-chevron-down filtericons"></i></button>

                                <div class="dropdown-menu dropdownFilter"
                                    aria-labelledby="dropdownSalaryRangeLink"
                                    *ngIf="topJobsBySalaryRange.length> 0">
                                    <div class="dropdown-item">
                                        <input type="radio" name="salaryRage" (click)="onSalaryRangeCheck(0)"/> <label>0 - 2L
                                            ({{topJobsBySalaryRange[0].belowTwoLakh}})</label>
                                    </div>
                                    <div class="dropdown-item">
                                        <input type="radio" name="salaryRage" (click)="onSalaryRangeCheck(1)"/> <label>2 - 8L({{topJobsBySalaryRange[1].betweenThreeLakhAndEightLakh}})</label>
                                    </div>
                                    <div class="dropdown-item">
                                        <input type="radio" name="salaryRage" (click)="onSalaryRangeCheck(2)"/> <label>8 - 15L
                                            ({{topJobsBySalaryRange[2].betweenNineLakhAndFifteenLakh}})</label>
                                    </div>
                                    <div class="dropdown-item">
                                        <input type="radio" name="salaryRage" (click)="onSalaryRangeCheck(3)"/> <label>15L above
                                            ({{topJobsBySalaryRange[3].aboveFifteenLakh}})</label>
                                    </div>

                                    <div class="float-right filterBtns">
                                        <button class="btn btn-outline-primary
                                            btn-rounded my-2 my-sm-0
                                            cancelFilterBtn">CANCEL</button>
                                        <button class="btn btn-primary
                                            btn-rounded applyFilterBtn"
                                            (click)="getAllJobs(true)">APPLY</button>
                                    </div>
                                </div>
                            </span>


                            <button data-toggle="modal"
                                data-target="#allFilters" class="btn btn-sm
                                btn-outline-light-dark my-2 my-sm-0
                                btn-rounded"><span>More Filters</span><i
                                    class="fas fa-chevron-right filtericons"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3">
                    <div class="card profileCard">
                        <app-add-box></app-add-box>
                    </div>
                </div>
            </div>
    </div>
</section>

<!-- Filters all jons -->
<section class="list-filters">
    <div class="container scroll">
        <div class="clearfix">
            <h2 class="all-jobs">All Jobs </h2>
            <span class="showing-pages" *ngIf="jobPostList?.length> 0">Showing
                {{pageNumber -1}} of {{totalPage}} pages.</span>
            <div style="float: right; margin-top: 18px;">
                <span class="sort-relevance">Sort By: Relevance</span>
                <img src="./assets/images/chevron.png" alt="" />
            </div>
        </div>


        <div class="row" *ngIf="jobPostList?.length>0">
            <div class="col-md-3 col-lg-3 jobs-job-card" *ngFor="let job of
                jobPostList">
                <div class="card featured-jobs-card">
                    <div class="card-body">
                        <div class="clearfix">
                            <img *ngIf="job.companyLogoPath == null"
                                class="jobImg1"
                                src="./assets/images/defaultCompanyLogo.jpg"
                                alt="job image" />
                            <img *ngIf="job.companyLogoPath != null"
                                class="jobImg"
                                src="{{environment.activityPhotoHost}}{{job?.companyLogoPath}}"
                                alt="job image" />
                            <div class="featured-jobs-favourites"
                                *ngIf="!job?.isShortlistedJob">
                                <img (click)="onShortlistJob(job.jobId, job.jobCreatedBy)"
                                    class="cursor_pointer" style="height: 16px;
                                    width: 18px;margin-right: 5px;"
                                    src="./assets/images/favourites.png"
                                    alt="" />
                            </div>
                        </div>

                        <a
                            routerLink="/{{routerConst.user}}/{{routerConst.jobDetails}}/{{job.jobId}}}"><h5
                                class="card-title job-title">{{job.designation.designation}}</h5></a>
                        <p class="job_companyName cursor_pointer"
                            routerLink="/{{routerConst.user}}/{{routerConst.jobDetails}}/{{job.jobId}}}">{{job?.company}}</p>
                        <p class="job_location">{{job?.location?.cityName}},
                            {{job?.location?.countryName}}</p>
                        <p class="job_recent">{{job.jobCreatedDate | dateAgo}}
                            <i *ngIf="job?.isAppliedJob" class="applied-class">applied</i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="pageNumber-1 !== totalPage"
        style="margin-left:
        50%;">
        <i class="fa fa-spinner fa-spin"
            aria-hidden="true"></i>
    </div>
    <div align="center" *ngIf="pageNumber-1
        ===
        totalPage">
        <p>All caught up !!!</p>
    </div>
</section>


<div class="modal" id="allFilters" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-full modal-lg" role="document">
        <div class="modal-content">
            <div class="container" style="max-width: 1280px !important;">
                <form [formGroup]="advancesearchformGroup" class="form">
                    <div class="allFilters">
                        <h3>All Filters</h3>
                        <div class="row">
                            <div class="col-md-4 col-lg-4">
                                <h4>Location</h4>
                                <div style="width: 80% !important;">
                                    <ng-multiselect-dropdown
                                        [placeholder]="'Select location'"
                                        [data]="jobLocations"
                                        formControlName="Locations"
                                        [settings]="locdropdownSettings">
                                    </ng-multiselect-dropdown>
                                </div>

                                <div style="padding: 20px 0px 0px 8px">
                                    <div *ngFor="let location of
                                        topJobsLocations;">
                                        <input type="checkbox"
                                            (click)="onLocationCheck($event,
                                            location)" /> <label>{{location.city}}
                                            ({{location.totalJobs}})</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-lg-4">
                                <h4>Company</h4>
                                <div style="width: 80% !important;">
                                    <ng-multiselect-dropdown
                                        [placeholder]="'Select company'"
                                        [data]="companiesList"
                                        formControlName="companies"
                                        [settings]="companydropdownSettings">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div style="padding: 20px 0px 0px 8px">
                                    <div *ngFor="let company of
                                        topJobsCompanies">
                                        <input type="checkbox"
                                            (click)="onCompanyCheck($event,
                                            company)" /> <label>{{company.company}}
                                            ({{company.totalJobs}})</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-lg-4">
                                <h4>Industry</h4>
                                <div style="width: 80% !important;">
                                    <ng-multiselect-dropdown
                                        [placeholder]="'Select Indusrty'"
                                        [data]="industry"
                                        formControlName="Indusrties"
                                        [settings]="industrydropdownSettings">
                                    </ng-multiselect-dropdown>
                                </div>

                                <div style="padding: 20px 0px 0px 8px">
                                    <div *ngFor="let industry of
                                        topJobsIndustries">
                                        <input type="checkbox"
                                            (click)="onIndustryCheck($event,
                                            industry)" /> <label>{{industry.industry}}
                                            ({{industry.totalJobs}})</label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row" style="margin-top: 32px;">
                            <div class="col-md-4 col-lg-4">
                                <h4>Experience</h4>
                                <div style="padding: 10px 0px 20px 8px;"
                                    *ngIf="topJobsByExperiance.length> 0">
                                    <div>
                                        <input type="radio" name="exp" (click)="onExperianceCheck(0)" /> <label>Fresher
                                            ({{topJobsByExperiance[0].fresher}})</label>
                                    </div>
                                    <div>
                                        <input type="radio" name="exp" (click)="onExperianceCheck(1)"/> <label>2-5
                                            Years({{topJobsByExperiance[1].betweenTwoAndFive}})</label>
                                    </div>
                                    <div>
                                        <input type="radio" name="exp" (click)="onExperianceCheck(2)"/> <label>6-10
                                            Years
                                            ({{topJobsByExperiance[2].betweenSixAndTen}})</label>
                                    </div>
                                    <div>
                                        <input type="radio" name="exp" (click)="onExperianceCheck(3)"/> <label>10+
                                            yrs
                                            ({{topJobsByExperiance[3].aboveTen}})</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <h4>Date Posted</h4>
                                <div style="padding: 10px 0px 20px 8px;"
                                    *ngIf="topJobsByPostedDate.length> 0">
                                    <div>
                                        <input type="radio" name="datePosted" (click)="onDatePostedCheck(0)"/> <label>New
                                            ({{topJobsByPostedDate[0].newJobsCount}})</label>
                                    </div>
                                    <div>
                                        <input type="radio" name="datePosted" (click)="onDatePostedCheck(1)"/> <label>Past
                                            Week
                                            ({{topJobsByPostedDate[1].lastWeekCount}})</label>
                                    </div>
                                    <div>
                                        <input type="radio" name="datePosted" (click)="onDatePostedCheck(2)"/> <label>Past
                                            Month
                                            ({{topJobsByPostedDate[2].lastMonthCount}})</label>
                                    </div>
                                    <div>
                                        <input type="radio" name="datePosted" (click)="onDatePostedCheck(3)"/> <label>Anytime
                                            ({{topJobsByPostedDate[3].allJobCount}})</label>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </form>
            </div>
            <!-- row.// -->
            <div class="modal-footer jobFooter">
                <div class="container clearfix">
                    <!-- <div class="float-left">
                        <a href="javascript;;" class="clearAllFilters">Clear all
                            (3)</a>

                    </div> -->
                    <div class="float-right">
                        <button class="btn btn-outline-primary btn-rounded my-2
                            my-sm-0 cancelBtn" data-dismiss="modal">CANCEL</button>
                        <button class="btn btn-primary btn-rounded
                            modalApplyFilterBtn"
                            (click)="getAllJobs(true)" data-dismiss="modal">APPLY</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


