<div class="card profileCard networkProfile">
    <img *ngIf="!user.userDPUrl" class="card-img-top" src="{{environment.userPhotoHost}}default-profile-200x150.png" alt="{{user.username}}" >
    <img *ngIf="user.userDPUrl" class="card-img-top" src="{{environment.userPhotoHost}}{{user.userDPUrl}}" alt="Card image cap">
    <div class="card-body">
        <h5 class="card-title network-profilename">{{user.fname}} {{user.lname}}</h5>
        <p class="network-profiledesignation">{{this.jobTitle}} <br> {{this.companyName}}</p>
        <!-- <button class="btn btn-outline-primary btn-rounded btn-sm network-editProfileBtn"><span>Edit Profile</span></button> -->
        <div align="center">
            <a routerLink="/{{routerConstants.user}}/{{routerConstants.profile}}/{{user.id}}" class="viewprofile-link">VIEW PROFILE</a>
        </div>
        <hr class="network-profileRectangeBorder" />
        <div class="followers">
            <ul class="list-group">
                <li *ngIf="followersCount > 0" class="list-group-item d-flex justify-content-between align-items-center">
                    Followers
                    <span class="">{{followersCount}}</span>
                </li>
                <li *ngIf="followingCount > 0" class="list-group-item d-flex justify-content-between align-items-center">
                    Following
                    <span class="">{{followingCount}}</span>
                </li>
                <li *ngIf="appliedJobsCount > 0" class="list-group-item d-flex justify-content-between align-items-center">
                    Jobs Applied
                    <span class="">{{appliedJobsCount}}</span>
                </li>
                <li *ngIf="postedJobsCount > 0" class="list-group-item d-flex justify-content-between align-items-center">
                    Jobs Posted
                    <span class="">{{postedJobsCount}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
