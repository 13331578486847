<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 col-lg-4 left-side-bg">
      <div class="container">
        <div
          [ngClass]="{
            'basic-arrow': basicPage,
            'experience-arrow': experincePage,
            'education-arrow': educationPage
          }"
        >
          <img
            src="./assets/images/setup-logo.svg"
            class="logo"
            (click)="open(content)"
            alt="logo"
          />
          <div class="setup">Setup Your Profile</div>
          <div class="left-tab clearfix" (click)="basic()">
            <div
              class="float-left"
              align="center"
              style="min-height: 32px; min-width: 32px"
            >
              <img
                *ngIf="!basicPage"
                src="./assets/images/user.svg"
                alt="details"
              />
              <img
                *ngIf="basicPage"
                src="./assets/images/user_active.svg"
                alt="details"
              />
            </div>
            <label
              [ngClass]="{ 'active-tab': basicPage }"
              class="other-label"
              style="cursor: pointer"
              >Basic Details</label
            >
          </div>

          <div class="left-tab clearfix" (click)="experience()">
            <div
              class="float-left"
              align="center"
              style="min-height: 26px; min-width: 26px"
            >
              <img
                *ngIf="experincePage"
                src="./assets/images/star_active.svg"
                alt="experience"
              />
              <img
                *ngIf="!experincePage"
                src="./assets/images/star.svg"
                alt="experience"
              />
            </div>
            <label
              [ngClass]="{ 'active-tab': experincePage }"
              class="experience-label"
              style="cursor: pointer"
              >Experience</label
            >
          </div>
          <div class="left-tab" (click)="education()">
            <div
              class="float-left"
              align="center"
              style="min-height: 32px; min-width: 32px"
            >
              <img
                *ngIf="!educationPage"
                src="./assets/images/award.svg"
                alt="education"
              />
              <img
                *ngIf="educationPage"
                src="./assets/images/award_active.svg"
                alt="education"
              />
            </div>

            <label
              [ngClass]="{ 'active-tab': educationPage }"
              class="other-label"
              style="cursor: pointer"
              >Education</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-lg-8 details-side">
      <!-- display basic details -->
      <div class="basic-page" *ngIf="basicPage">
        <div class="basic">Basic Details</div>
        <form [formGroup]="basicForm">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-firstname">
                <input
                  type="text"
                  maxlength="12"
                  class="basic-input form-control form-control-lg input"
                  formControlName="fname"
                  [(ngModel)]="user.fname"
                  disabled="disabled"
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-lastname">
                <input
                  type="text"
                  maxlength="12"
                  class="basic-input form-control form-control-lg input"
                  formControlName="lname"
                  [(ngModel)]="user.lname"
                  disabled="disabled"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  formControlName="email"
                  [(ngModel)]="user.email"
                  disabled="disabled"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  formControlName="dateOfBirth"
                  placeholder="Date of Birth"
                  onfocus="(this.type='date')" 
                  onblur="(this.type='text')"
                  id="date"
                  min='01-01-1970' 
                  max='31-12-2000'
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  minlength="9"
                  maxlength="11"
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  formControlName="phoneNumber"
                  placeholder="Phone Number*"
                  (keypress)="validateNo($event)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="text"
                  readonly
                  class="basic-input form-control form-control-lg input"
                  formControlName="jobLocationCountry"
                  [(ngModel)]="jobLocationCountry"
                  placeholder="Country"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <select
                  formControlName="jobLocationCity"
                  [(ngModel)]="jobLocationCity"
                  class="custom-select my-select"
                  placeholder="City*"
                  required
                >
                  <option class="active" [ngValue]="null" [disabled]="true">
                    City
                  </option>
                  <option class="" value="Bangalore">Bangalore</option>
                  <option class="" value="Mumbai">Mumbai</option>
                  <option class="" value="Chennai">Chennai</option>
                  <option class="" value="Hyderabad">Hyderabad</option>
                  <option class="" value="Ludhiana">Ludhiana</option>
                  <option class="" value="Delhi">Delhi</option>
                  <option class="" value="Allahabad">Allahabad</option>
                  <option class="" value="Pune">Pune</option>
                  <option class="" value="Coimbatore">Coimbatore</option>
                  <option class="" value="Kolkata">Kolkata</option>
                </select>
                <!-- <input
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  formControlName="jobLocationCity"
                  [(ngModel)]="this.jobLocationCity"
                  placeholder="City"
                /> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="md-form md-outline form-lg basic-info basic-text">
                <textarea
                  maxlength="255"
                  class="basic-input form-control form-control-lg input"
                  formControlName="aboutUser"
                  placeholder="About/Summary*"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  formControlName="websiteLink"
                  placeholder="Website / Link (Optional)"
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- experince page -->
      <div class="experince-page" *ngIf="experincePage">
        <div class="experince-title">Experience</div>
        <div *ngFor="let experience of experienceArray; let i = index">
          <div>
            <a (click)="deleteExperienceRow(0)"
              ><img
                style="height: 14px; margin-left: 858px"
                src="assets/images/times.png"
                alt=""
            /></a>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="text"
                  maxlength="80"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="experienceArray[i].companyName"
                  placeholder="Company Name*"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="text"
                  maxlength="80"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="experienceArray[i].jobTitle"
                  placeholder="Designation*"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="text"
                  maxlength="60"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="experienceArray[i].jobLocationCity"
                  placeholder="Location*"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <select
                  class="custom-select my-select"
                  [(ngModel)]="experienceArray[i].employementType"
                >
                  <option class="active" value="" disabled>
                    Employment Type*
                  </option>
                  <option class="active" value="Full-Time">Full-Time</option>
                  <option class="active" value="Part-Time">Part-Time</option>
                  <option class="active" value="Contract">Contract</option>
                </select>
              </div>
            </div>
          </div>
          <div class="currently-working">
            <input
              type="checkbox"
              checked
              [(ngModel)]="experienceArray[i].isCurrentJob"
            />
            <span class="currently">I am currently working here</span>
          </div>
          <!-- <div class="experience-date">
            <span class="start">Start Date*</span>
            <span class="end">End Date</span>
          </div> -->
          <div class="row">
            <div class="col-md-3 ol-lg-3">
              <div class="experience-date">
                <span class="start">Month*</span>
              </div>
              <div class="md-form md-outline form-lg basic-info">
                <!--                          <input type="month" class="basic-input form-control form-control-lg input" formControlName="startDate">-->
                <select
                  class="custom-select my-select"
                  [(ngModel)]="experienceArray[i].startMonth"
                >
                  <option class="active" disabled>Month</option>
                  <option *ngFor="let month of months" value="{{ month }}">
                    {{ month }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 ol-lg-3">
                <div class="experience-date">
                    <span class="start">Year*</span>
                  </div>
              <div class="md-form md-outline form-lg basic-info">
                <select
                  class="custom-select my-select"
                  [(ngModel)]="experienceArray[i].startYear"
                >
                  <option class="active" disabled>Year</option>
                  <option *ngFor="let year of years" value="{{ year }}">
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-lg-3">
              <div class="experience-date">
                <span class="end" style="margin-left: 15px;">End Date</span>
              </div>
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="date"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="experienceArray[i].endDate"
                  placeholder="Present"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div
                class="
                  md-form md-outline
                  form-lg
                  basic-info
                  experience-textarea
                "
              >
                <textarea
                  maxlength="255"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="experienceArray[i].description"
                  placeholder="Description*"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="break-line"></div>
          <div class="add-internal">
            <a (click)="addExperienceRow(0)">Add Internal Journey (Optional)</a>
          </div>
        </div>
      </div>
      <div class="add-company" *ngIf="experincePage">
        <a (click)="addExperienceRow(0)"
          ><img src="./assets/images/plus.png" alt="add" width="10px" />Add
          Another Company (Optional)</a
        >
      </div>

      <!-- Education display -->
      <div class="experince-page" *ngIf="educationPage">
        <div class="experince-title">Education</div>
        <div *ngFor="let education of educationArray; let i = index">
          <div>
            <a (click)="deleteEducationRow(0)"
              ><img
                style="height: 14px; margin-left: 858px"
                src="assets/images/times.png"
                alt=""
            /></a>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  maxlength="80"
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="educationArray[i].instituteUniversityName"
                  placeholder="Name of the Institute / University / School*"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  maxlength="80"
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="educationArray[i].certificateDegreeName"
                  placeholder="Degree (Optional)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  maxlength="80"
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="educationArray[i].major"
                  placeholder="Field of study (Optional)"
                />
              </div>
            </div>
          </div>
          <!-- <div class="experience-date">
            <span class="start">Start Year*</span>
            <span class="education-end">Actual/Expected End Year*</span>
          </div> -->
          <div class="row">
            <div class="col-md-3 ol-lg-3">
              <div class="experience-date">
                <span class="start">Start Year*</span>
              </div>
              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="date"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="educationArray[i].startDate"
                />
              </div>
            </div>
            <div class="col-md-3 ol-lg-3">
              <div class="experience-date">
                <span class="education-end" style="margin-left: 15px"
                  >Actual/Expected End Year*</span
                >
              </div>

              <div class="md-form md-outline form-lg basic-info">
                <input
                  type="date"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="educationArray[i].completionDate"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="md-form md-outline form-lg basic-info">
                <input
                  maxlength="40"
                  type="text"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="educationArray[i].grade"
                  placeholder="Grade (Optional)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div
                class="
                  md-form md-outline
                  form-lg
                  basic-info
                  experience-textarea
                "
              >
                <textarea
                  maxlength="255"
                  class="basic-input form-control form-control-lg input"
                  [(ngModel)]="educationArray[i].description"
                  placeholder="Description (Optional)"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="break-line"></div>
          <br />
        </div>
      </div>
      <div class="add-another" *ngIf="educationPage">
        <a (click)="addEducationRow(0)"
          ><img src="./assets/images/plus.png" alt="add" width="10px" />Add
          Another (Optional)</a
        >
      </div>

      <div class="clearfix footer-alignment container">
        <button
          class="btn btn-blue-border btn-sm btn-rounded with-icon"
          *ngIf="basicPage"
          (click)="updateBasicProfile()"
        >
          <span>
            Continue &nbsp;<i
              *ngIf="loading"
              class="fa fa-spinner fa-spin"
              aria-hidden="false"
            ></i
          ></span>
        </button>
        <div *ngIf="experincePage">
          <a
            class="btn btn-blue-border btn-sm btn-rounded with-icon"
            (click)="UpdateExperienceForm()"
            ><span>
              Continue &nbsp;<i
                *ngIf="loading"
                class="fa fa-spinner fa-spin"
                aria-hidden="false"
              ></i></span
          ></a>
          <a
            class="btn btn-white-border btn-sm btn-rounded with-icon"
            (click)="basic()"
            ><span> Previous </span></a
          >
        </div>
        <div *ngIf="educationPage">
          <a
            class="btn btn-blue-border btn-sm btn-rounded with-icon"
            (click)="onUpdateEducationForm()"
            ><span>
              Finish &nbsp;<i
                *ngIf="loading"
                class="fa fa-spinner fa-spin"
                aria-hidden="false"
              ></i></span
          ></a>
          <a
            class="btn btn-white-border btn-sm btn-rounded with-icon"
            (click)="experience()"
            ><span> Previous </span></a
          >
        </div>
      </div>
      <p style="color: red">{{ errorMsg }}</p>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-body modalPosition">
      <div class="input-group rounded rounded-pill shadow-sm">
        <input
          type="search"
          class="search-input form-control form-control-lg input"
          placeholder="Search for people,job title,comapany or skill"
        />
        <div class="my-auto search-bar">
          <a routerLink="/{{ routerConst.searchResult }}">SEARCH</a>
        </div>
      </div>
      <div class="search-info">
        <div class="recent-search">Recent Search</div>
        <div class="search-user">
          <img
            src="./assets/images/request1.png"
            class="search-image"
            alt="search-image"
          /><span>Promod Mahajan</span>
        </div>
        <div class="search-user">
          <img
            src="./assets/images/refresh.svg"
            class="search-image"
            alt="search-image"
          /><span>Proton India</span>
        </div>
        <div class="search-user">
          <img
            src="./assets/images/refresh.svg"
            class="search-image"
            alt="search-image"
          /><span>Product Designer</span>
        </div>
        <div class="break-line"></div>
        <div class="search-user">
          <img
            src="./assets/images/request2.png"
            class="search-image"
            alt="search-image"
          /><span>Pro<strong>mod Gill</strong></span>
        </div>
        <div class="search-user">
          <img
            src="./assets/images/request3.png"
            class="search-image"
            alt="search-image"
          /><span>Pro<strong>bhodhini Institute</strong></span>
        </div>
        <div class="search-user">
          <img
            src="./assets/images/search.svg"
            class="search-icon"
            alt="search-image"
          /><span>Adobe</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
