import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router } from '@angular/router';
import { RouterConstants } from 'src/app/constants/router.contants';
import { ApiCallService } from '../../services/api-call-svc/api-call.service';

import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { environment } from 'src/environments/environment';
import { JobsModuleConstants } from '../../constants/globalConstants';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  routerConst = RouterConstants;
  jobConst = JobsModuleConstants;
  user: UserDetailsModel;
  environment = environment;
  industryLists: any;
  followersCount: any;
  jobPostList: any[];
  searchKey = null;
  getJobsloader = false;
  searchBtnLoader = false;

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    public api: ApiCallService) { }

  ngOnInit(): void {
    this.user = this.authSvc.getUserObj();
    const isLoggedIn = this.authSvc.checkUserlogin();
    if (isLoggedIn) {
      this.router.navigateByUrl(this.routerConst.user);
    }

    this.getIndustryList();
    this.getAllJobs('');
  }

  getIndustryList() {
    const url = '/api/industry/';
    this.api.getApiCall(url).subscribe(res => {
      let list = res;
      list.sort((a, b) => (a.industry > b.industry) ? 1 : -1)
      this.industryLists = list;
      // console.log('Industry List:', this.industryLists, res);
    }, (err) => {
    });
  }

  getAllJobs(searchKey) {
    this.getJobsloader = true;
    const url = '/api/feed/featured-jobs?searchKey=' + searchKey;
    this.api.getApiCall(url).subscribe(res => {
      this.getJobsloader = false;
      if (res != null) {
        this.jobPostList = res;
      } else {
        this.jobPostList = [];
      }
    }, (err) => {
    });
  }

  onSearch() {
    if (this.searchKey != null) {
      this.searchBtnLoader = true;
      const url = '/api/feed/featured-jobs?searchKey=' + this.searchKey;
      this.api.getApiCall(url).subscribe(res => {
        this.searchBtnLoader = false;
        if (res != null) {
          this.jobPostList = res;
        } else {
          this.jobPostList = [];
        }
      }, (err) => {
      });
    }
  }


}
