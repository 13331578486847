<footer class="footer mt-auto" id="footer">
  <div class="container">
    <div class="clearfix" style="margin-bottom: 45px">
      <ul class="footer-links">
        <li>
          <a
            target="_blank"
            class="onmouseover"
            data-toggle="modal"
            data-target="#postModalAbout"
            >About Us</a
          >
        </li>
        <li>
          <a
            target="_blank"
            class="onmouseover"
            data-toggle="modal"
            data-target="#postModalContactus"
            >Contact Us</a
          >
        </li>
        <li><a (click)="openDocs()">Privacy Policy</a></li>
      </ul>
    </div>
    <div class="clearfix">
      <ul class="social-icons">
        <li>
          <a href="https://www.facebook.com/happy.connects" target="_blank"
            ><img
              style="height: 21px; width: 10px"
              src="../assets/images/facebook.png "
              alt="facebook "
          /></a>
        </li>
        <li>
          <a href="https://twitter.com/ConnectsHappy" target="_blank"
            ><img
              style="height: 17px; width: 20px"
              src="../assets/images/twitter.png "
              alt="twitter "
          /></a>
        </li>
        <li>
          <a href="https://www.instagram.com/happyconnects/" target="_blank"
            ><img
              style="height: 21px; width: 21px"
              src="../assets/images/instagram.png "
              alt="instagram "
          /></a>
        </li>
      </ul>
      <div style="float: right">
        <app-logo [logoColor]="'white'"></app-logo>
        <!-- <img src="../assets/images/footerlogo.png " style=" height: 22px; width: 128px; " /> -->
      </div>
    </div>
    <!-- <p class="float-right ">
            <a href="# ">Back to top</a>
        </p> -->
  </div>
</footer>

<div
  class="modal fade"
  id="postModalContactus"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #2b44ff">
        <h5
          class="modal-title letspost"
          id="exampleModalLabel"
          style="color: white"
        >
          CONTACT DETAILS
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><img style="height: 24px" src="assets/images/times.png" alt=""
          /></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="clearfix post-userDetail">
          <div class="float-left">
            <div>
              <span
                >Company Name - Happy Connects Technologies (OPC) Pvt Ltd.</span
              >
            </div>
            <br />
            <div>
              <i
                class="fa fa-envelope"
                aria-hidden="true"
                style="color: #2b44ff"
              ></i
              >&nbsp;<span>Email ID - </span
              >welcome@happyconnects.com<br /><br />

              <i
                class="fa fa-mobile"
                aria-hidden="true"
                style="color: #2b44ff"
              ></i
              >&nbsp;<span> Contact - </span>+91 9742075099
            </div>
          </div>
          <div class="modalWriteInput"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="postModalAbout"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #2b44ff">
          <h5
            class="modal-title letspost"
            id="exampleModalLabel"
            style="margin-left: 325px; color: white"
          >
            ABOUT US
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><img style="height: 24px" src="assets/images/times.png" alt=""
            /></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="clearfix post-userDetail">
            <div class="float-left">
              <p style="margin-left: 350px; color: blue">MISSION</p>
              <u style="margin-left: 225px">Your mission</u>&nbsp;
              <u style="margin-left: 110px">Our mission</u><br />
              <span style="margin-left: 180px">COMING TOGETHER</span>&nbsp;
              <span style="margin-left: 60px">YOUR SUCCESS</span><br /><br />
              <p style="margin-left: 180px">
                “Coming together is a <span>BEGINNING</span>; staying together
                is <br /><span>PROGRESS</span>; working together is
                <span>SUCCESS</span>.”-Henry Ford
              </p>
              <p style="margin-left: 180px">
                It is your success in the making, we are
                <span>COMMITTED</span> to help you <br />all the way,<span
                  >EVERY TIME</span
                >.
              </p>
              <br />

              <p style="margin-left: 350px; color: blue">THE STORY</p>
              <p>
                The sense of occupation is in many ways responsible for our
                evolution as is the sense of survival. It is this fact that
                ignited the purpose for our team. Our purpose is to help you
                succeed in your occupation or profession. WE WILL EVOVLE
                TOGETHER.
              </p>
              <p>
                As a team we have an average experience of X years in our
                respective jobs. Each of us believes, like many of you, that
                collaboration is the means to find and sustain success. Our
                collective experience tells us that collaboration comes in many
                forms, but it starts in only one way- COMING TOGETHER AND
                OPENING UP. We have built HAPPY CONNECTS to give you the
                platform for collaboration at a human level. You can SHARE,
                SEEK, INSPIRE, MOTIVATE, MENTOR andmore. You must come together.
              </p>
              <br />
              <p style="margin-left: 340px; color: blue">CORE VALUES</p>
              <p>
                1. <span>COMMITMENT</span>: We perceive commitment as the most
                important aspect of our engagement with our customers. It is a
                means for us to keep your well being and success in our vision
                always.
              </p>
              <p>
                2. <span>TRUST</span>: We want to build a lasting relationship
                with our customers. A famous quote says “Trust takes years to
                build, seconds to break, and forever to repair. We are not in
                the business of breaking or repairing.
              </p>
              <p>
                3. <span>TRANSPARENCY</span>: In our passion for your success we
                are committed to do everything that is right and just. We are
                fundamentally a social networking platform, transparency in our
                actions keeps our motive unhidden, that is the only way to gain
                trust.
              </p>
              <p>
                4. <span>Empathy</span>: The ability to listen and understand
                people without biasat an intellectual level or an emotional
                level is the basis for innovation. Our platform is driven by
                this concept, every detail we put in our product captures all
                views and needs without bias or prejudice.
              </p>
            </div>
          </div>
          <div class="modalWriteInput"></div>
        </div>
      </div>
    </div>
  </div>
</div>
