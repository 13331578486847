import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {ApiCallService} from "../../services/api-call-svc/api-call.service";
import {environment as env} from "../../../environments/environment";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  environment = env;
  searchList: any[];
  jobsList: any[];
  loading = false;
  pageNumber = 1;
  totalPage: number;
  searchKey: any;
  constructor(private api:ApiCallService,
              private route: ActivatedRoute,) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.route.url.subscribe(res => {
      this.searchKey = res[1].path;
      this.search();
    });
  }

  page = 1;

  getPageSymbol(current: number) {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'][current - 1];
  }

  search() {
    if(this.searchKey){
      this.api.getApiCall('/api/es/search-result/'+this.searchKey).subscribe(res=>{        
        this.searchList = [];
        if(res){
          this.searchList = res;  
        }      
      });
    }else{
      this.searchList = [];
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    if (event.target.scrollTop >= limit && this.totalPage >= this.pageNumber) {
      this.search();
    }
  }

  openPage(search){
   // console.log(search);
    if(search.documentType === 1){
      this.viewOtherUser(search.uuid);
    } else if(search.documentType === 2){
      this.viewJob(search.uuid);
    }
  }

  viewOtherUser(userId) {
    // const url = '/api/user/' + userId;
    // this.api.getApiCall(url).subscribe(res => {
    //   const navigationExtras: NavigationExtras = {
    //     queryParams: { id: res.id }
    //   };
    //   this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.profileExternal], navigationExtras);
    // }, (err) => {
    // });

    if (userId) {      
      const url = '/api/user/save-profile-views/' + userId;
      this.api.postApiCall(url, null).subscribe(res => {
      }, (err) => {
      });
    }

    window.location.href = '/user/profile-external-details?id='+userId;

  }

  viewJob(jobId){
    // const navigationExtras: NavigationExtras = {
    //     queryParams: { id: jobId }
    //   };
    //   this.router.navigate([this.routerConstants.user + '/' +this.routerConstants.jobDetails+ '/' +jobId], navigationExtras);
    window.location.href = '/user/job-details/'+jobId;
  }


}
