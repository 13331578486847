import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import { JobModel } from 'src/app/models/jobModel';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertBarService } from 'src/app/services/alert-bar.service';
import { SnackbarUtil } from '../../utils';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrls: ['./post-job.component.scss']
})
export class PostJobComponent implements OnInit {

  environment = environment;
  jobDesignation: any;
  industry: any;
  jobCategory: any;
  jobSkills: any[];
  jobLocations: any;
  postJobLoading = false;
  btnValue = 'POST JOB';
  formSubmitted: boolean;
  job: JobModel;
  submitted = false;
  validationVal = false;
  isCreate = true;
  isEdit = false;
  jobDetails: any;
  appliedUsersCount: any;
  uploadedLogo: any[] = [];
  logo: any[] = [];
  formGroup: FormGroup;

  // This is for the editor to post the editor option
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '10rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter the job details',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote'
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1'
      }
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']]
  };

  constructor(
    private fb: FormBuilder, private api: ApiCallService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertBarService,
    private snackbarUtil: SnackbarUtil
  ) {
  }

  get fval() {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
    this.createForm();
    this.getLocations();
    this.getDesignations();
    this.getIndustries();
    this.getJobCategories();
    this.getJobSkils();
    this.route.url.subscribe(res => {
      if (res[1] !== undefined) {
        switch (res[1].path) {
          case 'post-job':
            this.isCreate = true;
            break;
          case 'details':
            this.isCreate = false;
            this.getJobDetails(Number(res[2].path));
            this.checkJobOwner(Number(res[2].path));
            break;
          default:
            break;
        }
      }
    });
  }

  createForm() {
    this.formGroup = this.fb.group({
      walkinJob: [false],
      jobTitle: [null, Validators.required],
      jobDesc: [null],
      jobDesignation: [null, Validators.required],
      jobIndustryId: [null, Validators.required],
      jobCategoryId: [null, Validators.required],
      numberOfOpennings: [null],
      jobLocation: [null, Validators.required],
      minYearsOfExp: [null],
      maxYearsOfExp: [null],
      minSalary: [null],
      maxSalary: [null],
      walkInDate: [null],
      jobExpiryDate: [null],
      showCompanyDetails: [false],
      company: [null, Validators.required],
      companyUrl: [null],
      skillIds: [null, Validators.required],
    });
  }

  getJobDetails(jobId) {
    this.api.getApiCall(`/api/feed/` + jobId + '/').subscribe(res => {
      this.jobDetails = res;
      setTimeout(() => {
        if (this.jobDetails.jobExpiryDate != null) {
          this.jobDetails.jobExpiryDate = this.getDateObject(new Date(this.jobDetails.jobExpiryDate));
        }
        this.jobDetails.jobDesignation = this.jobDetails.designationId;
        this.jobDetails.jobLocation = this.jobDetails.locationId;
        this.jobDetails.jobCategoryId = this.jobDetails.categoryId;
        this.jobDetails.jobIndustryId = this.jobDetails.industryId;
        this.jobDetails.skillIds = this.jobDetails.skillSetList ? this.getJobSkillsByFilter(this.jobDetails.skillSetList) : [];
        this.formGroup.patchValue(this.jobDetails);
        this.formGroup.disable();
      }, 1000);
    });
  }

  getDateObject(d) {
    const m = d.getMonth() + 1;
    const dt = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
    const mm = m < 10 ? '0' + m : m;
    return d.getFullYear() + '-' + mm + '-' + dt;
  }

  getJobSkillsByFilter(skills) {
    const skillsList = [];
    skills.forEach(element => {
      let sl = [];
      sl = this.jobSkills.filter(skill => skill.skillSetName === element.skillSetName);
      if (sl.length > 0 && sl[0].skillSetName === element.skillSetName) {
        skillsList.push(sl[0].skillId);
      }
    });
    return skillsList;
  }


  onSubmit(postData: JobModel, type) {
    this.formSubmitted = true;
    if (this.formGroup.valid) {
      if (this.formValidation(postData)) {
        postData.isActive = true;
        this.postJobLoading = true;
        if (this.isEdit) {
          postData.jobId = this.jobDetails.jobId;
          const formData: FormData = new FormData();
          if (this.uploadedLogo != null) {
            formData.append('file', this.uploadedLogo[0]);
            formData.append('jobExpiryDate', postData.jobExpiryDate);
          }
          postData.jobExpiryDate = null;
          formData.append('info', JSON.stringify(postData));
          this.api.postApiCall('/api/feed/directly-db/', formData).subscribe(
            res => {
              this.postJobLoading = false;
              this.snackbarUtil.openSnackBar('Job Updated successfully', null);
              this.isEdit = false;
              this.formGroup.disable();
            },
            err => {
              this.postJobLoading = false;
            }
          );
        } else {
          const formData: FormData = new FormData();
          if (this.uploadedLogo != null) {
            formData.append('file', this.uploadedLogo[0]);
            formData.append('jobExpiryDate', postData.jobExpiryDate);
          }
          postData.jobExpiryDate = null;
          formData.append('info', JSON.stringify(postData));
          this.api.postApiCall('/api/feed/directly-db/', formData).subscribe(
            res => {
              this.postJobLoading = false;
              this.router.navigate(['/user/jobs']);
              this.snackbarUtil.openSnackBar('Job Posted successfully', null);
            },
            err => {
              this.postJobLoading = false;
            }
          );
        }
      }
    }
  }

  formValidation(data) {
    if (data.minSalary != null && data.maxSalary != null) {
      if (Number(data.minSalary) > Number(data.maxSalary)) {
        this.snackbarUtil.openSnackBar('Max salary should be greater than min salary', null);
        return false;
      }
    }
    if (data.minYearsOfExp != null && data.maxYearsOfExp != null) {
      if (Number(data.minYearsOfExp) > Number(data.maxYearsOfExp)) {
        this.snackbarUtil.openSnackBar('Max years of exp should be greater than Min years of exp', null);
        return false;
      }
    }
    if (data.minSalary == null && data.maxSalary != null) {
      this.snackbarUtil.openSnackBar('Please enter Minimum salary', null);
      return false;
    }
    if (data.minSalary != null && data.maxSalary == null) {
      this.snackbarUtil.openSnackBar('Please enter Maximum salary', null);
      return false;
    }
    if (data.minYearsOfExp == null && data.maxYearsOfExp != null) {
      this.snackbarUtil.openSnackBar('Please enter Minimum years of exp', null);
      return false;
    }
    if (data.minYearsOfExp != null && data.maxYearsOfExp == null) {
      this.snackbarUtil.openSnackBar('Please enter Maximum years of exp', null);
      return false;
    }
    return true;
  }


  discard_special_char_And_Numbers(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return k >= 48 && k <= 57;
  }




  getAllJobs(feedFilter?, search?) {
    if (feedFilter === undefined || feedFilter === null) {
      feedFilter = {};
    }
    const url = '/api/feed/get-all-jobs';
    this.api.postApiCall(url, feedFilter).subscribe(res => {
      //console.log('All jobs', res);
    });
  }

  getLocations() {
    this.api.getApiCall(`/api/util/all-cities/India`).subscribe(res => {
      if (res != null) {
        this.jobLocations = res;
      } else {
        this.jobLocations = [];
      }
    });
  }


  getDesignations() {
    this.api.getApiCall(`/api/util/get-designation/all`).subscribe(res => {
      if (res != null) {
        this.jobDesignation = res;
      } else {
        this.jobDesignation = [];
      }
    });
  }

  getIndustries() {
    this.api.getApiCall(`/api/industry/`).subscribe(res => {
      if (res != null) {
        this.industry = res;
      } else {
        this.industry = [];
      }
    });
  }

  getJobCategories() {
    this.api.getApiCall(`/api/job-category/`).subscribe(res => {
      if (res != null) {
        this.jobCategory = res;
      } else {
        this.jobCategory = [];
      }
    });
  }

  getJobSkils() {
    this.api.getApiCall(`/api/skill-set/`).subscribe(res => {
      if (res != null) {
        this.jobSkills = res;
      } else {
        this.jobSkills = [];
      }
    });
  }
  addJobSkills = (term) => ({ id: term, name: term });

  checkJobOwner(jobId) {
    this.api.getApiCall(`/api/applied-job/count-by-job/` + jobId + '/').subscribe(res => {
      this.appliedUsersCount = res;
    }, (err) => {
    });
  }

  onFileUpload(event) {
    this.uploadedLogo = event.target.files;
    if (this.uploadedLogo && this.uploadedLogo.length > 0) {
      const fineName = this.uploadedLogo[0].name;
      const reader = new FileReader();
      reader.onload = (subevent: any) => {
        const json = {
          fileName: fineName,
          content: subevent.target.result,
          fileType: 'img',
          fileData: this.uploadedLogo[0]
        };
        this.logo = [];
        this.logo.push(json);
      };
      reader.readAsDataURL(this.uploadedLogo[0]);
    }
  }

  onEditActive() {
    this.isEdit = true;
    this.formGroup.enable();
  }

  deleteJob() {
    const postDto = {
      jobId: this.jobDetails.jobId,
      isActive: false
    };
    this.api.postApiCall('/api/feed/', postDto).subscribe(
      res => {
        this.router.navigate(['/user/jobs']);
        this.snackbarUtil.openSnackBar('Deleted successfully', null);
      },
      err => {
      }
    );
  }

}