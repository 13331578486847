import { YourPostsComponent } from "./core-components/your-posts/your-posts.component";
import { SavedPostsComponent } from "./core-components/saved-posts/saved-posts.component";
import { ShortListedComponent } from "./core-components/short-listed/short-listed.component";
import { AppliedJobsComponent } from "./core-components/applied-jobs/applied-jobs.component";
import { JobDetailsComponent } from "./core-components/job-details/job-details.component";
import { UserDetailsComponent } from "./core-components/user-details/user-details.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RouterConstants } from "./constants/router.contants";
import { LandingComponent } from "./core-components/landing/landing.component";
import { SignInComponent } from "./core-components/sign-in/sign-in.component";
import { SignUpComponent } from "./core-components/sign-up/sign-up.component";
import { GSignUpComponent } from "./core-components/g-sign-up/g-sign-up.component";
import { ForgotPasswordComponent } from "./core-components/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./core-components/change-password/change-password.component";
import { NetworkComponent } from "./core-components/network/network.component";
import { UserComponent } from "./core-components/user/user.component";
import { JobListComponent } from "./core-components/job-list/job-list.component";
import { AuthGuradService } from "./services/auth-guard/auth-gurad.service";
import { ManageComponent } from "./core-components/manage/manage.component";
import { PostJobComponent } from "./core-components/post-job/post-job.component";
import { MessagesComponent } from "./core-components/messages/messages.component";
import { NotificationsComponent } from "./core-components/notifications/notifications.component";
import { ErrorComponent } from "./core-components/error/error.component";
import { SignupComponent } from "./core-components/signup/signup.component";
import { SetupProfileComponent } from "./core-components/setup-profile/setup-profile.component";
import { SearchResultComponent } from "./core-components/search-result/search-result.component";
import { ChatboxComponent } from "./core-components/chatbox/chatbox.component";
import { ProfileExternalComponent } from "./core-components/profile-external/profile-external.component";
import { ActivityPostDetailsComponent } from "./core-components/activity-post-details/activity-post-details.component";

const routes: Routes = [
  { path: RouterConstants.error, component: ErrorComponent },
  { path: RouterConstants.default, component: LandingComponent },
  { path: RouterConstants.signIn, component: SignInComponent },
  { path: RouterConstants.signUp, component: SignUpComponent },
  { path: RouterConstants.signup, component: SignupComponent },
  { path: RouterConstants.gSignUp, component: GSignUpComponent },
  { path: RouterConstants.forgotPassword, component: ForgotPasswordComponent },
  {
    path: RouterConstants.changePassword + "/:token",
    component: ChangePasswordComponent,
  },

  {
    path: RouterConstants.user,
    component: UserComponent,
    canActivate: [AuthGuradService],
    children: [
      {
        path: RouterConstants.default,
        redirectTo: RouterConstants.network,
        pathMatch: "full",
      },
      { path: RouterConstants.network, component: NetworkComponent },
      { path: RouterConstants.jobs, component: JobListComponent },
      { path: RouterConstants.manage, component: ManageComponent },
      { path: RouterConstants.postJob, component: PostJobComponent },
      {
        path: RouterConstants.postJob + "/details/:id",
        component: PostJobComponent,
      },
      { path: RouterConstants.chat, component: MessagesComponent },
      {
        path: RouterConstants.jobDetails + "/:id",
        component: JobDetailsComponent,
      },
      {
        path: RouterConstants.notifications,
        component: NotificationsComponent,
      },
      { path: RouterConstants.profile, component: UserDetailsComponent },
      {
        path: RouterConstants.profile + "/:id",
        component: UserDetailsComponent,
      },
      {
        path: RouterConstants.profileExternal,
        component: ProfileExternalComponent,
      },
      {
        path: RouterConstants.profileExternal + "/:id",
        component: ProfileExternalComponent,
      },
      { path: RouterConstants.setUpProfile, component: SetupProfileComponent },
      {
        path: RouterConstants.setUpProfile + "/:id",
        component: SetupProfileComponent,
      },
      { path: RouterConstants.searchResult, component: SearchResultComponent },
      {
        path: RouterConstants.searchResult + "/:searchKey",
        component: SearchResultComponent,
      },
      { path: RouterConstants.chatbox, component: ChatboxComponent },
      {
        path: RouterConstants.chatbox + "/:toUserId",
        component: ChatboxComponent,
      },
      { path: RouterConstants.appliedJobs, component: AppliedJobsComponent },
      {
        path: RouterConstants.shortlistedJobs,
        component: ShortListedComponent,
      },
      { path: RouterConstants.savedPosts, component: SavedPostsComponent },
      { path: RouterConstants.yourPosts, component: YourPostsComponent },
      {
        path: RouterConstants.network + "/activity-details/:id",
        component: ActivityPostDetailsComponent,
      },
    ],
  },

  // {
  //   path: RouterConstants.signIn,
  //   loadChildren: () => import('./core-components/sign-in/sign-in.component').then(m => m.SignInComponent),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
