<section class="main-network">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-12 col-lg-3">
        <app-user-profile-card></app-user-profile-card>
      </div>
      <div class="col-md-6 col-sm-12 col-lg-6 scroll">
        <!-- START POST -->
        <div class="card profileCard" style="
            border: 1px solid #e0e0e0;
            box-shadow: 0 2px 24px 0 #e0e0e0 !important;
            box-sizing: border-box;
          ">
          <div class="card-body" style="min-height: 0px; padding: 24px">
            <div class="clearfix d-flex">
              <img *ngIf="!user.userDPUrl" class="network-userpost" alt=""
                src="{{ environment.userPhotoHost }}default-profile-200x150.png" />
              <!--changed class-->
              <img *ngIf="user.userDPUrl" class="network-userpost" alt=""
                src="{{ environment.userPhotoHost }}{{ user.userDPUrl }}" />
              <!--changed class-->
              <div class="input-group rounded rounded-pill shadow-sm" data-toggle="modal" data-target="#postModal"
                style="background-color: #f2f2f2; margin-left: 16px">
                <input type="search" placeholder="{{ constants.post_something }}" aria-describedby="button-addon2"
                  class="form-control border-0 my-auto post_text_box" data-toggle="modal" data-target="#postModal"
                  readonly />
                <div class="my-auto">
                  <!-- <a data-toggle="modal" data-target="#postModal">
                    <img class="network-post-attachimage1 post-img-spac" src="./assets/images/attach.svg" alt="" />
                  </a> -->
                  <a data-toggle="modal" data-target="#postModal">
                    <img class="post-attachimage2 post-img-spac" src="./assets/images/video.svg" alt="" />
                  </a>
                  <a data-toggle="modal" data-target="#postModal">
                    <img class="post-attachimage2" src="./assets/images/camera.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Active Post -->
        <div class="card profileCard" style="border: 1px solid #e0e0e0"
          *ngFor="let activity of activityPostList; let i = index">
          <div class="card-body" style="min-height: 0">
            <div class="row">
              <div class="col-md-1">
                <a (click)="viewOtherUser(activity.activityPostedByUserId)">
                  <img class="network-userImage" *ngIf="activity?.activityPostedByUser.userPhotoPath == null" [src]="
                      api.getInitials(
                        activity?.activityPostedByUser?.firstName +
                          ' ' +
                          activity?.activityPostedByUser?.lastName
                      )
                    " alt="" />
                  <img class="network-userImage" *ngIf="activity?.activityPostedByUser.userPhotoPath != null" [src]="
                      getProfilePicPath(
                        activity.activityPostedByUserId,
                        activity?.activityPostedByUser.userPhotoPath
                      )
                    " alt="" />
                </a>
              </div>
              <div class="col-md-7">
                <a (click)="viewOtherUser(activity.activityPostedByUserId)">
                  <span class="post-something">{{ activity?.activityPostedByUser?.firstName }}
                    {{ activity?.activityPostedByUser?.lastName }}</span>
                  <span class="project-manager" *ngIf="activity?.activityPostedByUser?.userTitle != null">{{
                    activity?.activityPostedByUser?.userTitle }}
                  </span></a>
              </div>
              <div class="col-md-3">
                <span class="network-datetime">
                  {{ activity.createdOn + "Z" | dateAgo }}</span>
              </div>
              <div class="col-md-1">
                <div class="action-dots clearfix">
                  <div class="dropdown" data-toggle="dropdown" *ngIf="activity.activityOwner">
                    <img class="cursor_pointer" src="./assets/images/dots.svg" alt="" />
                    <ul class="dropdown-menu" style="left: -74% !important">
                      <li (click)="onDeleteActivity(activity.activityId)">
                        <a><span>
                            <img class="dropdown-menu-trash" alt="" src="./assets/images/trash.svg" />
                          </span><span>Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="dropdown" data-toggle="dropdown" *ngIf="!activity.activityOwner" (click)="
                      getConnectionStatus(activity.activityPostedByUserId)
                    ">
                    <img class="cursor_pointer" src="./assets/images/dots.svg" alt="" />
                    <ul *ngIf="!activity.activityOwner" class="dropdown-menu" style="left: -74% !important">
                      <li style="text-align: center">
                        <a *ngIf="connectionStatus != null">
                          <span *ngIf="connectionStatus === 'Follow'"
                            (click)="onFollow(activity.activityPostedByUserId)">Follow</span>
                          <span *ngIf="connectionStatus === 'Accept'"
                            (click)="accept(activity.activityPostedByUserId)">Accept</span>
                          <span *ngIf="connectionStatus === 'Follow Back'"
                            (click)="onFollow(activity.activityPostedByUserId)">Follow Back</span>
                          <span *ngIf="connectionStatus === 'Cancel Request'" (click)="
                              onCancelFollowingRequest(
                                activity.activityPostedByUserId
                              )
                            ">Cancel Request</span>
                          <span *ngIf="connectionStatus === 'Unfollow'" (click)="
                              onUnfollowFollowing(
                                activity.activityPostedByUserId
                              )
                            ">Unfollow</span>
                        </a>

                        <i *ngIf="connectionStatus == null" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="middle-content">
              <span class="network-postdescription">{{ activity.postText }}
              </span>
              <!-- share activity  -->
              <div class="card profileCard" style="border: 1px solid #e0e0e0; margin-top: 15px"
                *ngIf="activity?.shareActivityObject != null">
                <div class="card-body" style="min-height: 0">
                  <div class="row">
                    <div class="col-md-1">
                      <a (click)="
                          viewOtherUser(activity?.shareActivityObject.id)
                        ">
                        <img class="network-userImage" *ngIf="
                            activity?.shareActivityObject?.userPhotoPath == null
                          " [src]="
                            api.getInitials(
                              activity?.shareActivityObject?.firstName +
                                ' ' +
                                activity?.shareActivityObject?.lastName
                            )
                          " alt="" />
                        <img class="network-userImage" *ngIf="
                            activity?.shareActivityObject?.userPhotoPath != null
                          " [src]="
                            getProfilePicPath(
                              activity?.shareActivityObject.id,
                              activity?.shareActivityObject?.userPhotoPath
                            )
                          " alt="" />
                      </a>
                    </div>
                    <div class="col-md-7">
                      <a (click)="viewOtherUser(activity.shareActivityObject.id)">
                        <span class="post-something">{{ activity?.shareActivityObject?.firstName }}
                          {{ activity?.shareActivityObject?.lastName }}</span>
                        <span class="project-manager" *ngIf="
                            activity?.shareActivityObject?.userTitle != null
                          ">{{ activity?.shareActivityObject?.userTitle }}
                        </span></a>
                    </div>
                    <div class="col-md-3">
                      <span class="network-datetime">
                        {{
                        activity.shareActivityObject.createdOn | dateAgo
                        }}</span>
                    </div>

                    <!-- yet to work -->
                    <div class="col-md-1">
                      <div class="action-dots clearfix">
                        <div class="dropdown" data-toggle="dropdown"
                          *ngIf="activity.shareActivityObject.id === user.id">
                          <img class="cursor_pointer" src="./assets/images/dots.svg" alt="" />
                          <ul class="dropdown-menu" style="left: -74% !important">
                            <li (click)="
                                onDeleteActivity(
                                  activity.shareActivityObject.activityId
                                )
                              ">
                              <a><span>
                                  <img class="dropdown-menu-trash" alt="" src="./assets/images/trash.svg" />
                                </span><span>Delete</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="dropdown" data-toggle="dropdown" *ngIf="activity.shareActivityObject.id !== user.id"
                          (click)="
                            getConnectionStatus(activity.shareActivityObject.id)
                          ">
                          <img class="cursor_pointer" src="./assets/images/dots.svg" alt="" />
                          <ul *ngIf="activity.shareActivityObject.id !== user.id" class="dropdown-menu"
                            style="left: -74% !important">
                            <li style="text-align: center">
                              <a *ngIf="connectionStatus != null">
                                <span *ngIf="connectionStatus === 'Follow'" (click)="
                                    onFollow(activity.shareActivityObject.id)
                                  ">Follow</span>
                                <span *ngIf="connectionStatus === 'Accept'" (click)="
                                    accept(activity.shareActivityObject.id)
                                  ">Accept</span>
                                <span *ngIf="connectionStatus === 'Follow Back'" (click)="
                                    onFollow(activity.shareActivityObject.id)
                                  ">Follow Back</span>
                                <span *ngIf="
                                    connectionStatus ===
                                    'Cancel
                                  Request'
                                  " (click)="
                                    onCancelFollowingRequest(
                                      activity.shareActivityObject.id
                                    )
                                  ">Cancel Request</span>
                                <span *ngIf="connectionStatus === 'Unfollow'" (click)="
                                    onUnfollowFollowing(
                                      activity.shareActivityObject.id
                                    )
                                  ">Unfollow</span>
                              </a>
                              <i *ngIf="connectionStatus == null" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="middle-content">
                    <span class="network-postdescription">{{ activity.shareActivityObject.postText }}
                    </span>
                    <!-- 1 img -->
                    <div class="post-image1" *ngIf="activity.shareActivityObject.type == 1">
                      <div *ngIf="
                          activity?.shareActivityObject?.activityPostsPaths
                            .length === 1
                        ">
                        <img src="{{ environment.activityPhotoHost }}{{
                            activity?.shareActivityObject.activityPostsPaths[0]
                              .path
                          }}" alt="" />
                      </div>
                    </div>

                    <!-- 2 img -->
                    <div class="row" *ngIf="
                        activity.shareActivityObject.type == 1 &&
                        activity?.shareActivityObject.activityPostsPaths
                          .length === 2
                      ">
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[0].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[1].path
                            }}" alt="" />
                        </div>
                      </div>
                    </div>

                    <!-- 3 img -->
                    <div class="row" *ngIf="
                        activity.shareActivityObject.type == 1 &&
                        activity?.shareActivityObject.activityPostsPaths
                          .length === 3
                      ">
                      <div class="col-md-12 col-lg-12">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[0].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[1].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[2].path
                            }}" alt="" />
                        </div>
                      </div>
                    </div>

                    <!-- 4 img -->
                    <div class="row" *ngIf="
                        activity.shareActivityObject.type == 1 &&
                        activity?.shareActivityObject.activityPostsPaths
                          .length === 4
                      ">
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[0].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[1].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[2].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[3].path
                            }}" alt="" />
                        </div>
                      </div>
                    </div>

                    <!-- 5 img -->
                    <div class="row" *ngIf="
                        activity.shareActivityObject.type == 1 &&
                        activity?.shareActivityObject.activityPostsPaths
                          .length === 5
                      ">
                      <div class="col-md-12 col-lg-12">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[0].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[1].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[2].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[3].path
                            }}" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="network-modal-uploaded-image">
                          <img src="{{ environment.activityPhotoHost }}{{
                              activity?.shareActivityObject
                                .activityPostsPaths[4].path
                            }}" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="post-image1" *ngIf="activity.shareActivityObject.type == 2">
                      <div>
                        <div class="card profileCard" style="border: 1px solid #e0e0e0; margin-left: -20px">
                          <div style="width: 100%">
                            <app-videobox [id]="getActivityPathId(activity)" [urlVideo]="
                                getVDOSrc(
                                  activity?.shareActivityObject
                                    .activityPostsPaths
                                )
                              " [urlPoster]="
                                getPoster(
                                  activity?.shareActivityObject
                                    .activityPostsPaths
                                )
                              "></app-videobox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="post-pdf"
                      *ngIf="activity.shareActivityObject.type
                      == 2">
                      <div class="clearfix">
                        <img class="float-left
                          post-pdf-image" src="assets/images/pdf.png" alt="" />
                        <div class="float-left
                          post-pdf-body">
                          <strong class="post-pdf-title">10
                            Leadership values</strong>
                          <p class="post-pdf-subtitle">PDF Document
                          </p>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>

              <!-- 1 img -->
              <div class="post-image1" *ngIf="activity.type == 1">
                <div *ngIf="activity?.activityPostsPaths.length === 1">
                  <img src="{{ environment.activityPhotoHost }}{{
                      activity?.activityPostsPaths[0].path
                    }}" alt="" />
                </div>
              </div>

              <!-- 2 img -->
              <div class="row" *ngIf="
                  activity.type == 1 &&
                  activity?.activityPostsPaths.length === 2
                ">
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[0].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[1].path
                      }}" alt="" />
                  </div>
                </div>
              </div>

              <!-- 3 img -->
              <div class="row" *ngIf="
                  activity.type == 1 &&
                  activity?.activityPostsPaths.length === 3
                ">
                <div class="col-md-12 col-lg-12">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[0].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[1].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[2].path
                      }}" alt="" />
                  </div>
                </div>
              </div>

              <!-- 4 img -->
              <div class="row" *ngIf="
                  activity.type == 1 &&
                  activity?.activityPostsPaths.length === 4
                ">
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[0].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[1].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[2].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[3].path
                      }}" alt="" />
                  </div>
                </div>
              </div>

              <!-- 5 img -->
              <div class="row" *ngIf="
                  activity.type == 1 &&
                  activity?.activityPostsPaths.length === 5
                ">
                <div class="col-md-12 col-lg-12">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[0].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[1].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[2].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[3].path
                      }}" alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="network-modal-uploaded-image">
                    <img src="{{ environment.activityPhotoHost }}{{
                        activity?.activityPostsPaths[4].path
                      }}" alt="" />
                  </div>
                </div>
              </div>

              <div class="post-image1" *ngIf="activity.type == 2 && !activity.shareActivityId">
                <div>
                  <div class="card profileCard" style="border: 1px solid #e0e0e0">
                    <div style="width: 100%">
                      <app-videobox [id]="getActivityPathId(activity)"
                        [urlVideo]="getVDOSrc(activity?.activityPostsPaths)"
                        [urlPoster]="getPoster(activity?.activityPostsPaths)"></app-videobox>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="post-pdf" *ngIf="activity.type
                == 2">
                <div class="clearfix">
                  <img class="float-left
                    post-pdf-image" src="assets/images/pdf.png" alt="" />
                  <div class="float-left
                    post-pdf-body">
                    <strong class="post-pdf-title">10
                      Leadership values</strong>
                    <p class="post-pdf-subtitle">PDF Document
                    </p>
                  </div>
                </div>
              </div> -->

              <div class="post-like">
                <div class="clearfix">
                  <img *ngIf="activity.isActivityLike" src="assets/images/like-filled.svg"
                    class="post-likeBtn cursor_pointer" (click)="onUnLike(activity.activityId, i)" alt="" />
                  <img *ngIf="!activity.isActivityLike" src="assets/images/like.svg" class="post-likeBtn cursor_pointer"
                    (click)="onLike(activity.activityId, i)" alt="" />
                  <span *ngIf="activity.likedCounts > 0" class="post-likedBy">Liked by
                    <strong *ngIf="activity.isActivityLike">You</strong><strong *ngIf="!activity.isActivityLike">{{
                      activity?.lastLikedBy
                      }}</strong>
                    <strong *ngIf="activity.likedCounts > 1" (click)="allLikedUser(activity.activityId)">
                      and
                      {{ activity.likedCounts - 1 }}
                      others</strong></span>
                  <div class="float-right">
                    <img class="post-shareBtn cursor_pointer" src="assets/images/share1.svg" alt=""
                      (click)="shareActivityObject = activityPostList[i]" data-toggle="modal"
                      data-target="#shareModal" />
                    <img class="post-saveBtn cursor_pointer" *ngIf="activity.isActivitySave" (click)="
                        onRemoveFromSavedCollection(activity.activityId, i)
                      " src="assets/images/bookmark1-filled.svg" alt="" />
                    <img class="post-saveBtn cursor_pointer" *ngIf="!activity.isActivitySave"
                      (click)="onSave(activity.activityId, i)" src="assets/images/bookmark1.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body" style="border-top: 1px solid #e0e0e0; min-height: 0"
            *ngIf="activity.allowCommentForPost">
            <div class="clearfix">
              <div class="float-left">
                <img *ngIf="!user.userDPUrl" class="post-profilepic" alt="" src="{{
                    environment.userPhotoHost
                  }}default-profile-200x150.png" />
                <img *ngIf="user.userDPUrl" class="post-profilepic" alt=""
                  src="{{ environment.userPhotoHost }}{{ user.userDPUrl }}" />
              </div>
              <div class="float-left" style="width: 92%">
                <div class="rounded rounded-pill shadow-sm post-comment-box">
                  <form [formGroup]="commentOnPostFormGroup">
                    <div class="input-group">
                      <textarea required="required" placeholder="{{ constants.add_comment }}"
                        aria-describedby="button-addon2" (ngModelChange)="onPostComment($event)"
                        class="
                          form-control
                          border-0
                          postInput
                          my-auto
                          post-comment-text-box
                        " formControlName="comment"></textarea>
                      <div class="input-group-append post-comment">
                        <a *ngIf="disablePostFlag" style=" pointer-events: none">POST</a>
                        <a *ngIf="!disablePostFlag" (click)="onComment(activity.activityId, i)">POST</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="comments">
              <div class="clearfix comment-section" *ngFor="let comment of activity?.activityComments.reverse()">
                <div class="float-left" style="width: auto">
                  <strong class="post-comment-author">{{ comment.firstName }} {{ comment.lastName }}</strong>
                </div>
                <div class="float-left">
                  <span class="post-comment-text">{{ comment.commentsText }}
                    -
                    {{ comment.createdOn | dateAgo }}</span>
                </div>
              </div>
              <a *ngIf="activity?.totalComments > activity.initialCommentsList" class="view-all-comments"
                (click)="onLoadNextFewComments(activity.activityId, i)">View all
                {{ activity?.totalComments - activity.initialCommentsList }}
                comments</a>
            </div>
          </div>
        </div>

        <!-- Lets post -->
        <div class="modal fade" id="postModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title letspost" id="exampleModalLabel">
                  Let's Post
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetPostForm()">
                  <span aria-hidden="true"><img style="height: 24px" src="assets/images/times.png" alt="" /></span>
                </button>
              </div>
              <div class="modal-body">
                <div class="clearfix post-userDetail">
                  <div class="float-left">
                    <img [src]="api.getInitials(user?.fname + ' ' + user?.lname)" alt="" />
                    <span>{{ user.fname }} {{ user.lname }} </span>
                  </div>
                </div>
                <div class="modalWriteInput">
                  <form [formGroup]="createPostFormGroup">
                    <textarea placeholder="{{ constants.post_about }}" class="network-modal-textarea"
                      formControlName="postContent"></textarea>
                  </form>
                  <div *ngIf="showImageCropContainer" class="crop-img-container">
                    <div class="row" style="margin-top: 15px">
                      <div class="text-center col-md-6">
                        <h5>Crop Image</h5>
                        <image-cropper [imageBase64]="imageChangedEvent" [maintainAspectRatio]="false" format="png"
                          multiple (imageCropped)="imageCropped($event)"></image-cropper>
                      </div>
                      <div class="text-center col-md-6 overflow-data">
                        <h5>Preview</h5>
                        <img [src]="croppedImage" alt="" style="width: 340px; height: 233px" />
                      </div>
                    </div>
                    <div class="postJobFormRow">
                      <div class="clearfix">
                        <button class="
                            btn btn-primary btn-rounded
                            modalPostBtn
                            float-right
                          " (click)="onImageCrop()">
                          Crop
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- files.length === 1 -->
                  <div class="row" *ngIf="files.length === 1 && files[0].fileType == 'image'">
                    <div class="col-md-12 col-lg-12">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[0].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(0)" class="
                              network-mo089ujn
                              dal-close-large
                              cursor_pointer
                            " alt="" src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(0)" class="network-modal-edit-large cursor_pointer"
                            src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Video preview -->
                  <div class="row" style="height: 200px" *ngIf="files.length === 1 && files[0].fileType == 'video'">
                    <i class="fa fa-spinner fa-spin" aria-hidden="false"
                      style="left: 50%; position: absolute; top: 200px" *ngIf="!showVideoPreview"></i>
                    <div class="col-md-12 col-lg-12" *ngIf="showVideoPreview">
                      <div class="network-modal-uploaded-image">
                        <!-- <img [src]="files[0].content" alt="" /> -->
                        <div>
                          <img [src]="imageUrl" style="
                              width: 300px;
                              height: 200px;
                              background-color: black;
                            " />
                          <div class="player" style="top: -129px; left: 123px">
                            <span>Play</span>
                          </div>
                          <div style="
                              right: 417px;
                              top: -263px;
                              position: relative;
                            ">
                            <img (click)="
                                removeSelectedFile(0); showVideoPreview = false
                              " class="network-modal-close-large cursor_pointer" alt=""
                              src="./assets/images/close.svg" />
                          </div>
                          <!-- <app-videobox [id]="'preview'" [urlVideo]="'test.mp4'" [urlPoster]="imageUrl"></app-videobox> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- files.length === 2 -->
                  <div class="row" *ngIf="files.length === 2 && files[0].fileType == 'image'">
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[0].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(0)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(0)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[1].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(1)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(1)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- files.length === 3 -->
                  <div class="row" *ngIf="files.length === 3 && files[0].fileType == 'image'">
                    <div class="col-md-12 col-lg-12">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[0].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(0)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(0)" class="network-modal-edit-large cursor_pointer"
                            src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[1].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(1)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(1)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[2].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(2)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(2)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- files.length === 4 -->
                  <div class="row" *ngIf="files.length === 4 && files[0].fileType == 'image'">
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[0].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(0)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(0)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[1].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(1)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(1)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[2].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(2)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(2)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[3].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(3)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(3)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- files.length === 5 -->
                  <div class="row" *ngIf="files.length === 5 && files[0].fileType == 'image'">
                    <div class="col-md-12 col-lg-12">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[0].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(0)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(0)" class="network-modal-edit-large cursor_pointer"
                            src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[1].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(1)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(1)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[2].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(2)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(2)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[3].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(3)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(3)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="network-modal-uploaded-image">
                        <img [src]="files[4].content" alt="" />
                        <div>
                          <img (click)="removeSelectedFile(4)" class="network-modal-close-large cursor_pointer" alt=""
                            src="./assets/images/close.svg" />
                          <img (click)="cropSelectedImage(4)" class="
                              network-modal-edit-large-two-in-row
                              cursor_pointer
                            " src="./assets/images/edit1.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="files.length === 1 && files[0].fileType == 'doc'">
                    <div class="col-6">
                      <div class="clearfix" *ngIf="files[0].fileExtension === 'pdf'">
                        <img class="float-left post-pdf-image" src="assets/images/pdf.png" alt="" />
                        <div class="float-left post-pdf-body">
                          <strong class="post-pdf-title">{{
                            files[0].fileName
                            }}</strong>
                          <p class="post-pdf-subtitle">PDF Document</p>
                        </div>
                      </div>

                      <div class="clearfix" *ngIf="files[0].fileExtension !== 'pdf'">
                        <img class="float-left post-pdf-image" src="assets/images/word.png" alt="" />
                        <div class="float-left post-pdf-body">
                          <strong class="post-pdf-title">{{
                            files[0].fileName
                            }}</strong>
                          <p class="post-pdf-subtitle">Word Document</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="clearfix">
                        <div class="float-left post-pdf-body">
                          <button type="button" class="btn btn_close" data-dismiss="modal"
                            (click)="removeSelectedFile(0)">
                            <em class="fa fa-times"></em>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row" *ngIf="videoPath != null">
                      <div class="col-12">
                          <video width="270" height="220"   controls disabled="true" #videoPlayer>
                              <source [src]="videoPath" type="video/mp4">
                            </video>
                      </div>
                      </div> -->
                </div>
                <div>
                  <select class="article-modal my-select" [(ngModel)]="postViewDefaultSet">
                    <option *ngFor="let view of postView" [ngValue]="view.value">
                      {{ view.value }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="modal-footer postModalFooter">
                <div class="clearfix">
                  <div class="float-left post-attachments" style="padding-top: 7px">
                    <!-- <label class="cursor_pointer" style="padding-right: 8px" *ngIf="postType === null"><img
                        src="./assets/images/attach.svg" alt="" /><input type="file"
                        class="display-none network-post-attachimage1" (change)="onFileUpload($event, 'doc')"
                        accept=".pdf, .doc, .docx" /></label> -->
                    <label class="cursor_pointer" *ngIf="postType === null" style="padding-right: 18px"><img
                        style="height: 24px; width: 24px" src="assets/images/video.svg" alt="" /><input type="file"
                        class="display-none network-post-attachimage1" (change)="onFileUpload($event, 'video')"
                        accept="video/mp4,video/x-m4v,video/*" /></label>
                    <label class="cursor_pointer" *ngIf="postType === null || postType === 1"><img
                        style="height: 24px; width: 24px" src="assets/images/camera.svg" alt="" /><input type="file"
                        class="display-none network-post-attachimage1" multiple=""
                        (change)="onFileUpload($event, 'image')" accept="image/*" /></label>
                  </div>
                  <button class="btn btn-primary btn-rounded modalPostBtn float-right"
                    [disabled]="!createPostFormGroup.valid" (click)="onSubmitPost()">
                    POST &nbsp;
                    <em class="fa fa-spinner fa-spin" *ngIf="postBtnLoader"></em>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Post activity popup -->
        <div class="modal fade" id="postModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title letspost" id="exampleModalLabel">
                  Let's Post
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><img style="height: 24px" src="assets/images/times.png" alt="" /></span>
                </button>
              </div>
              <div class="modal-body">
                <div class="clearfix post-userDetail">
                  <div class="float-left">
                    <img *ngIf="!user.userDPUrl" src="{{
                        environment.userPhotoHost
                      }}default-profile-200x150.png" alt="" />
                    <img *ngIf="user.userDPUrl" src="{{ environment.userPhotoHost }}{{ user.userDPUrl }}" alt="" />
                    <span>{{ user.fname }}
                      {{ user.lname }}
                    </span>
                  </div>
                </div>
                <div class="modalWriteInput">
                  <form [formGroup]="createPostFormGroup">
                    <textarea placeholder="{{ constants.post_about }}" class="network-modal-textarea"
                      formControlName="postContent"></textarea>
                  </form>
                </div>

                <div *ngFor="let url of files; let i = index">
                  <img *ngIf="url.fileType == 'image' && files.length === 1" alt="" class="
                      tdw-card-normal tdw-avatar-56 tdw-layout-align-middle
                    " [src]="url.content" style="max-height: 160px; width: 100%" />
                  <button *ngIf="url.fileType == 'image' && files.length === 1" type="button" class="btn btn_close"
                    data-dismiss="modal" (click)="removeSelectedFile(i)">
                    <em class="fa fa-times"></em>
                  </button>
                  <div *ngIf="files.length > 1 && url.fileType == 'img'" class="row">
                    <div class="col-4">
                      {{ url.fileName }}
                    </div>
                    <div class="col-8">
                      <img alt="" class="
                          tdw-card-normal tdw-avatar-56 tdw-layout-align-middle
                        " [src]="url.content" style="height: 50px; width: 70px" />
                      <button type="button" class="btn btn_close" data-dismiss="modal" (click)="removeSelectedFile(i)">
                        <em class="fa fa-times"></em>
                      </button>
                    </div>
                  </div>
                  <div class="row" *ngIf="url.fileType == 'doc'">
                    <div class="col-6">
                      {{ url.fileName }}
                    </div>
                    <div class="col-6">
                      <button type="button" class="btn btn_close" data-dismiss="modal" (click)="removeSelectedFile(i)">
                        <em class="fa fa-times"></em>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer postModalFooter">
                <div class="clearfix">
                  <div class="float-left post-attachments" style="padding-top: 7px">
                    <label *ngIf="
                        postType == null ? true : postType === 2 ? true : false
                      ">
                      <img class="post-attachimage1" src="./assets/images/attachment.png" alt="" /><input type="file"
                        class="display-none" multiple="" (change)="onFileUpload($event, 'doc')" accept=".xlsx,.xls,.doc,
                        .docx,.ppt,
                        .pptx,.txt,.pdf" /></label>
                    <label *ngIf="
                        postType == null ? true : postType === 1 ? true : false
                      "><img class="post-attachimage2" src="./assets/images/uploadimage.png" alt="" /><input
                        type="file" class="display-none" multiple="" (change)="onFileUpload($event, 'image')"
                        accept="image/*" /></label>
                  </div>
                  <button class="btn btn-primary btn-rounded modalPostBtn float-right"
                    [disabled]="!createPostFormGroup.valid" (click)="onSubmitPost()">
                    POST
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="pageNumber - 1 !== totalPage" style="margin-left: 50%">
          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
        <div align="center" *ngIf="pageNumber - 1 === totalPage">
          <p>All caught up !!!</p>
        </div>

        <!-- share activity popup -->
        <div *ngIf="shareActivityObject != null">
          <div class="modal fade" id="shareModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title letspost" id="exampleModalLabel">
                    Share
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><img style="height: 24px" src="assets/images/times.png" alt="" /></span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="clearfix post-userDetail">
                    <div class="float-left">
                      <img *ngIf="!user.userDPUrl" src="{{
                          environment.userPhotoHost
                        }}default-profile-200x150.png" alt="" />
                      <img *ngIf="user.userDPUrl" src="{{ environment.userPhotoHost }}{{
                          user.userDPUrl
                        }}" alt="" />
                      <span>{{ user.fname }}
                        {{ user.lname }}
                      </span>
                    </div>
                  </div>
                  <div class="modalWriteInput">
                    <form [formGroup]="createPostFormGroup">
                      <textarea placeholder="{{ constants.post_about }}" class="share-modal-textarea"
                        formControlName="postContent"></textarea>
                    </form>
                  </div>

                  <div class="card shareCard" style="border: 1px solid #e0e0e0">
                    <div class="row">
                      <div class="col-md-1">
                        <img class="network-userImage" *ngIf="
                            shareActivityObject.activityPostedByUser
                              .userPhotoPath == null
                          " src="{{
                            environment.userPhotoHost
                          }}default-profile-200x150.png" alt="" />
                        <img class="network-userImage" *ngIf="
                            shareActivityObject.activityPostedByUser
                              .userPhotoPath != null
                          " src="{{ environment.userPhotoHost }}{{
                            shareActivityObject.activityPostedByUser
                              .userPhotoPath
                          }}" alt="" />
                      </div>
                      <div class="col-md-7">
                        <span class="post-something">{{
                          shareActivityObject.activityPostedByUser.firstName
                          }}
                          {{
                          shareActivityObject.activityPostedByUser.lastName
                          }}</span>
                        <span class="post-manager">{{
                          shareActivityObject.activityPostedByUser.userTitle
                          }}
                        </span>
                      </div>
                      <div class="col-md-3">
                        <span class="network-datetime">
                          {{ shareActivityObject.createdOn | dateAgo }}</span>
                      </div>
                      <div class="col-md-1">
                        <div class="action-dots clearfix">
                          <div class="dropdown" data-toggle="dropdown" *ngIf="shareActivityObject.activityOwner">
                            <img class="cursor_pointer" src="./assets/images/dots.svg" alt="" />
                            <ul class="dropdown-menu">
                              <li (click)="
                                  onDeleteActivity(
                                    shareActivityObject.activityId
                                  );
                                  resetPostForm()
                                ">
                                <a><span>
                                    <img class="dropdown-menu-trash" alt="" src="./assets/images/trash.svg" />
                                  </span><span>Delete</span>
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div class="dropdown" data-toggle="dropdown" *ngIf="!shareActivityObject.activityOwner"
                            (click)="
                              getConnectionStatus(
                                shareActivityObject.activityPostedByUserId
                              )
                            ">
                            <img class="cursor_pointer" src="./assets/images/dots.svg" alt="" />
                            <ul *ngIf="!shareActivityObject.activityOwner" class="dropdown-menu">
                              <li style="text-align: center">
                                <a *ngIf="connectionStatus != null">
                                  <span *ngIf="connectionStatus === 'Follow'" (click)="
                                      onFollow(
                                        shareActivityObject.activityPostedByUserId
                                      )
                                    ">Follow</span>
                                  <span *ngIf="connectionStatus === 'Accept'" (click)="
                                      accept(
                                        shareActivityObject.activityPostedByUserId
                                      )
                                    ">Accept</span>
                                  <span *ngIf="
                                      connectionStatus ===
                                      'Follow
                                    Back'
                                    " (click)="
                                      onFollow(
                                        shareActivityObject.activityPostedByUserId
                                      )
                                    ">Follow Back</span>
                                  <span *ngIf="
                                      connectionStatus ===
                                      'Cancel
                                    Request'
                                    " (click)="
                                      onCancelFollowingRequest(
                                        shareActivityObject.activityPostedByUserId
                                      )
                                    ">Cancel Request</span>
                                  <span *ngIf="connectionStatus === 'Unfollow'" (click)="
                                      onUnfollowFollowing(
                                        shareActivityObject.activityPostedByUserId
                                      )
                                    ">Unfollow</span>
                                </a>

                                <i *ngIf="connectionStatus == null" class="fa fa-spinner fa-spin"
                                  aria-hidden="true"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="middle-content">
                      <span class="network-postdescription">{{
                        shareActivityObject.postText
                        }}</span>
                      <div class="post-image1">
                        <!-- 1 img -->
                        <div class="post-image1" *ngIf="shareActivityObject.type == 1">
                          <div *ngIf="
                              shareActivityObject?.activityPostsPaths.length ===
                              1
                            ">
                            <img src="{{ environment.activityPhotoHost }}{{
                                shareActivityObject?.activityPostsPaths[0].path
                              }}" alt="" />
                          </div>
                        </div>

                        <!-- 2 img -->
                        <div class="row" *ngIf="
                            shareActivityObject.type == 1 &&
                            shareActivityObject?.activityPostsPaths.length === 2
                          ">
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[0]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[1]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                        </div>

                        <!-- 3 img -->
                        <div class="row" *ngIf="
                            shareActivityObject.type == 1 &&
                            shareActivityObject?.activityPostsPaths.length === 3
                          ">
                          <div class="col-md-12 col-lg-12">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[0]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[1]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[2]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                        </div>

                        <!-- 4 img -->
                        <div class="row" *ngIf="
                            shareActivityObject.type == 1 &&
                            shareActivityObject?.activityPostsPaths.length === 4
                          ">
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[0]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[1]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[2]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[3]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                        </div>

                        <!-- 5 img -->
                        <div class="row" *ngIf="
                            shareActivityObject.type == 1 &&
                            shareActivityObject?.activityPostsPaths.length === 5
                          ">
                          <div class="col-md-12 col-lg-12">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[0]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[1]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[2]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[3]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <div class="network-modal-uploaded-image">
                              <img src="{{ environment.activityPhotoHost }}{{
                                  shareActivityObject?.activityPostsPaths[4]
                                    .path
                                }}" alt="" />
                            </div>
                          </div>
                        </div>

                        <div class="post-image1" *ngIf="shareActivityObject.type == 2">
                          <div class="col-md-12 col-lg-12">
                            <div class="network-modal-uploaded-image">
                              <!-- <img [src]="files[0].content" alt="" /> -->
                              <div>
                                <img [src]="
                                    getPoster(
                                      shareActivityObject?.activityPostsPaths
                                    )
                                  " style="
                                    width: 300px;
                                    height: 200px;
                                    background-color: black;
                                  " />
                                <div class="player" style="top: -129px; left: 123px">
                                  <span>Play</span>
                                </div>
                                <!-- <app-videobox [id]="'preview'" [urlVideo]="'test.mp4'" [urlPoster]="imageUrl"></app-videobox> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="post-pdf" *ngIf="shareActivityObject.type
                          == 2">
                          <div class="clearfix">
                            <img class="float-left
                              post-pdf-image" src="assets/images/pdf.png" alt=""
                              />
                            <div class="float-left
                              post-pdf-body">
                              <strong class="post-pdf-title">10
                                Leadership values</strong>
                              <p class="post-pdf-subtitle">PDF Document
                              </p>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <span class="dropdown show">
                        <!-- <button class="btn btn-sm my-2 my-sm-0
                          btn-rounded btn-outline-light-dark"
                          id="dropdownLocationLink"
                          data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false">
                          <span>Only Followers</span>
                                            <i class="fas fa-chevron-down filtericons"></i>
                                            </button> -->
                        <select class="article-modal my-select" [(ngModel)]="postViewDefaultSet">
                          <option *ngFor="let view of postView" [ngValue]="view.value">
                            {{ view.value }}
                          </option>
                        </select>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="modal-footer postModalFooter">
                  <div class="clearfix">
                    <button class="
                        btn btn-primary btn-rounded
                        modalPostBtn
                        float-right
                      " [disabled]="!createPostFormGroup.valid" (click)="
                        onSharePost(
                          shareActivityObject.activityId,
                          shareActivityObject.type
                        )
                      ">
                      Share
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3">
        <!-- <div class="rightSidebarAdvert">
          <img src="./assets/images/new2.png" alt=" image" />
        </div>
        -->
        <!---->
        <div class="card profileCard">
          <app-add-box></app-add-box>
        </div>
        <!---->
        <div class="card jobCard" style="margin-bottom: 20px" *ngFor="let job of relevantJobsList">
          <div class="card-body">
            <div class="clearfix">
              <img style="float: left" (click)="onViewJobDetails(job.jobId)" *ngIf="job.companyLogoPath == null"
                class="jobImg1 cursor_pointer" src="./assets/images/defaultCompanyLogo.jpg" alt="job image" />

              <img style="float: left" (click)="onViewJobDetails(job.jobId)" *ngIf="job.companyLogoPath != null"
                class="jobImg cursor_pointer" src="{{ environment.activityPhotoHost }}{{
                  job?.companyLogoPath
                }}" alt="job image" />
            </div>
            <h5 class="card-title job-title cursor_pointer" (click)="onViewJobDetails(job.jobId)">
              {{ job.jobTitle }}
            </h5>
            <p class="job_companyName cursor_pointer" (click)="onViewJobDetails(job.jobId)">
              {{ job.companyName }}
            </p>
            <p class="job_location">{{ job.cityName }}, {{ job.country }}</p>
            <p class="job_new" *ngIf="job.createdOn === 'New'">New</p>
            <p class="job_recent" *ngIf="job.createdOn != 'New'">
              {{ job.createdOn | dateAgo }}
            </p>
          </div>
        </div>
        <div class="network-right-view-job cursor_pointer" *ngIf="relevantJobsList.length > 0"
          routerLink="/{{ routerConstants.user }}/{{ routerConstants.jobs }}">
          VIEW ALL JOBS
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="postModalAbout" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #2b44ff">
        <h5 class="modal-title letspost" id="exampleModalLabel" style="margin-left: 325px; color: white">
          ABOUT US
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img style="height: 24px" src="assets/images/times.png" alt="" /></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="clearfix post-userDetail">
          <div class="float-left">
            <p style="margin-left: 350px; color: blue">MISSION</p>
            <u style="margin-left: 225px">Your mission</u>&nbsp;
            <u style="margin-left: 110px">Our mission</u><br />
            <span style="margin-left: 180px">COMING TOGETHER</span>&nbsp;
            <span style="margin-left: 60px">YOUR SUCCESS</span><br /><br />
            <p style="margin-left: 180px">
              “Coming together is a <span>BEGINNING</span>; staying together is
              <br /><span>PROGRESS</span>; working together is
              <span>SUCCESS</span>.”-Henry Ford
            </p>
            <p style="margin-left: 180px">
              It is your success in the making, we are <span>COMMITTED</span> to
              help you <br />all the way,<span>EVERY TIME</span>.
            </p>
            <br />

            <p style="margin-left: 350px; color: blue">THE STORY</p>
            <p>
              The sense of occupation is in many ways responsible for our
              evolution as is the sense of survival. It is this fact that
              ignited the purpose for our team. Our purpose is to help you
              succeed in your occupation or profession. WE WILL EVOVLE TOGETHER.
            </p>
            <p>
              As a team we have an average experience of X years in our
              respective jobs. Each of us believes, like many of you, that
              collaboration is the means to find and sustain success. Our
              collective experience tells us that collaboration comes in many
              forms, but it starts in only one way- COMING TOGETHER AND OPENING
              UP. We have built HAPPY CONNECTS to give you the platform for
              collaboration at a human level. You can SHARE, SEEK, INSPIRE,
              MOTIVATE, MENTOR andmore. You must come together.
            </p>
            <br />
            <p style="margin-left: 340px; color: blue">CORE VALUES</p>
            <p>
              1. <span>COMMITMENT</span>: We perceive commitment as the most
              important aspect of our engagement with our customers. It is a
              means for us to keep your well being and success in our vision
              always.
            </p>
            <p>
              2. <span>TRUST</span>: We want to build a lasting relationship
              with our customers. A famous quote says “Trust takes years to
              build, seconds to break, and forever to repair. We are not in the
              business of breaking or repairing.
            </p>
            <p>
              3. <span>TRANSPARENCY</span>: In our passion for your success we
              are committed to do everything that is right and just. We are
              fundamentally a social networking platform, transparency in our
              actions keeps our motive unhidden, that is the only way to gain
              trust.
            </p>
            <p>
              4. <span>Empathy</span>: The ability to listen and understand
              people without biasat an intellectual level or an emotional level
              is the basis for innovation. Our platform is driven by this
              concept, every detail we put in our product captures all views and
              needs without bias or prejudice.
            </p>
          </div>
        </div>
        <div class="modalWriteInput"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="postModalContactus" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #2b44ff">
        <h5 class="modal-title letspost" id="exampleModalLabel" style="color: white">
          CONTACT DETAILS
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img style="height: 24px" src="assets/images/times.png" alt="" /></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="clearfix post-userDetail">
          <div class="float-left">
            <span> Company Name - </span>Happy Connects Technologies (OPC) Pvt
            Ltd.<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <i class="fa fa-envelope" aria-hidden="true" style="color: green"></i>&nbsp;<span>Email ID -
            </span>welcome@happyconnects.com<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <i class="fa fa-phone" aria-hidden="true" style="color: green"></i>&nbsp;<span> Phone - </span>+91
            9742075099
          </div>
        </div>
        <div class="modalWriteInput"></div>
      </div>
    </div>
  </div>
</div>