<section class="postJob">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-lg-9">
                <div class="card profileCard">
                    <div class="card-body">
                        <form [formGroup]="formGroup" class="form">

                            <div class="row">
                                <div class="col-6">
                                    <h1 class="postJobTitle">Post a Job</h1>
                                </div>
                                <div class="col-6">
                                    <div *ngIf="!isCreate" style="margin-top:
                                        8px;
                                        font-size: 17px;">
                                        <a class="float-right
                                            cursor_pointer"
                                            (click)="deleteJob()"><span
                                                style="color:
                                                blue;">
                                                Delete
                                            </span>
                                        </a>
                                        <a class="float-right
                                            cursor_pointer"
                                            (click)="onEditActive()"
                                            *ngIf="!isEdit"><span style="color:
                                                blue;">
                                                Edit
                                            </span> |
                                        </a>
                                        <span
                                            class="float-right">Users
                                            applied : {{appliedUsersCount}}
                                            &nbsp;|</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row postJobFormRow">
                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg">
                                        <input id="form-lg" class="form-control
                                            form-control-lg input postJobInput"
                                            placeholder="Job Title*"
                                            type="text" autocomplete="off"
                                            formControlName="jobTitle" required>
                                        <span class="postJob-errorhelpblock"
                                            *ngIf="fval.jobTitle.invalid &&
                                            (fval.jobTitle.touched ||
                                            formSubmitted)">Job
                                            Title is required</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <ng-select [items]="jobDesignation"
                                        placeholder="Job Designation*"
                                        bindLabel="designation"
                                        bindValue="id"
                                        formControlName="jobDesignation">
                                    </ng-select>
                                    <span style="margin-top: 4px;"
                                        class="postJob-errorhelpblock"
                                        *ngIf="fval.jobDesignation.invalid
                                        &&
                                        (fval.jobDesignation.touched ||
                                        formSubmitted)">Designation
                                        is required</span>
                                </div>
                            </div>

                            <div class="row postJobFormRow">
                                <div class="col-md-6 col-lg-6">
                                    <ng-select [items]="jobLocations"
                                        placeholder="Job Location*"
                                        bindLabel="cityName"
                                        bindValue="id"
                                        formControlName="jobLocation">
                                    </ng-select>
                                    <span style="margin-top: 4px;"
                                        class="postJob-errorhelpblock"
                                        *ngIf="fval.jobLocation.invalid &&
                                        (fval.jobLocation.touched ||
                                        formSubmitted)">Job Location
                                        is required</span>
                                </div>

                                <div class="col-md-6 col-lg-6">
                                    <ng-select [items]="jobCategory"
                                        placeholder="Job Category*"
                                        bindLabel="category"
                                        bindValue="id"
                                        formControlName="jobCategoryId">
                                    </ng-select>
                                    <span style="margin-top: 4px;"
                                        class="postJob-errorhelpblock"
                                        *ngIf="fval.jobCategoryId.invalid &&
                                        (fval.jobCategoryId.touched ||
                                        formSubmitted)">Category
                                        is required</span>
                                </div>
                            </div>

                            <div class="row postJobFormRow">
                                <div class="col-md-6 col-lg-6">
                                    <ng-select [items]="industry"
                                        placeholder="Industry*"
                                        bindLabel="industry"
                                        bindValue="id"
                                        formControlName="jobIndustryId">
                                    </ng-select>
                                    <span style="margin-top: 4px;"
                                        class="postJob-errorhelpblock"
                                        *ngIf="fval.jobIndustryId.invalid &&
                                        (fval.jobIndustryId.touched ||
                                        formSubmitted)">Industry
                                        is required</span>
                                </div>

                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg
                                        basic-info">
                                        <input type="date" style="color: rgb(73, 80, 87);" class="basic-input
                                            form-control form-control-lg input"
                                            formControlName="jobExpiryDate"
                                            placeholder="Expiry Date" />
                                    </div>
                                </div>
                            </div>


                            <!-- Experience -->
                            <div class="row postJobFormRow">
                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg">
                                        <input id="form-lg" class="form-control
                                            form-control-lg input postJobInput"
                                            type="text" autocomplete="off"
                                            formControlName="minYearsOfExp"
                                            (keypress)="discard_special_char_And_Numbers($event)"
                                            placeholder="Min. Experience*" required>
                                            
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg">
                                        <input id="form-lg" class="form-control
                                            form-control-lg input postJobInput"
                                            type="text" autocomplete="off"
                                            formControlName="maxYearsOfExp"
                                            (keypress)="discard_special_char_And_Numbers($event)"
                                            placeholder="Max. Experience*" required>
                                    </div>
                                </div>
                            </div>

                            <!-- CTC -->
                            <div class="row postJobFormRow">
                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg">
                                        <input id="form-lg" class="form-control
                                            form-control-lg input postJobInput"
                                            type="text" autocomplete="off"
                                            formControlName="minSalary"
                                            (keypress)="discard_special_char_And_Numbers($event)"
                                            placeholder="Min. CTC*" required>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg">
                                        <input id="form-lg" class="form-control
                                            form-control-lg input postJobInput"
                                            type="text" autocomplete="off"
                                            formControlName="maxSalary"
                                            (keypress)="discard_special_char_And_Numbers($event)"
                                            placeholder="Max. CTC*" required>
                                    </div>
                                </div>
                            </div>
                            <div class="postJob-hr"></div>
                            <div class="row postJobFormRow">
                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg">
                                        <input id="form-lg" class="form-control
                                            form-control-lg input postJobInput"
                                            type="text" autocomplete="off"
                                            formControlName="company"
                                            placeholder="Company Name*"
                                            required>
                                        <span class="postJob-errorhelpblock"
                                            *ngIf="fval.company.invalid &&
                                            (fval.company.touched ||
                                            formSubmitted)">Company
                                            is required</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg">
                                        <input id="form-lg" class="form-control
                                            form-control-lg input postJobInput"
                                            type="text" autocomplete="off"
                                            formControlName="companyUrl"
                                            placeholder="Website">
                                    </div>
                                </div>
                            </div>

                            <div class="row postJobFormRow">
                                <div class="col-md-6 col-lg-6">
                                    <ng-select [items]="jobSkills"
                                        style="height: 50px !important;"
                                        placeholder="Job Skills*"
                                        bindLabel="skillSetName"
                                        bindValue="skillId"
                                        [addTag]="addJobSkills"
                                        [multiple]="true"
                                        formControlName="skillIds">
                                    </ng-select>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="md-form md-outline form-lg">
                                        <input id="form-lg" class="form-control
                                            form-control-lg input postJobInput"
                                            type="text" autocomplete="off"
                                            formControlName="numberOfOpennings"
                                            (keypress)="discard_special_char_And_Numbers($event)"
                                            placeholder="Number Of Openings">
                                    </div>
                                </div>
                            </div>

                            <angular-editor formControlName="jobDesc"
                                [config]="editorConfig"></angular-editor>
                            <div class="postJobFormRow">
                                <div class="clearfix">
                                    <div class="fileUploadContainer float-left">
                                        <label *ngIf="logo.length === 0 && jobDetails?.companyLogoPath == null"
                                            class="cursor_pointer"><img
                                                style="height: 24px; width:
                                                24px;"
                                                src="assets/images/camera.svg"
                                                alt="" /> Upload Company Logo<input
                                                type="file"
                                                class="display-none
                                                network-post-attachimage1"
                                                (change)="onFileUpload($event)"
                                                accept="image/*"></label>
                                        <div *ngIf="logo.length> 0"
                                            class="profile-image">
                                            <img class="logo-edit
                                                cursor_pointer"
                                                (click)="coverUpload.click()"
                                                src="./assets/images/edit1.svg"
                                                alt="" />
                                            <a><img class="profile-profilepic"
                                                    [src]="logo[0].content"
                                                    alt=""></a>
                                            <input type="file" name="coverImage"
                                                accept='image/*' #coverUpload
                                                (change)="onFileUpload($event)"
                                                hidden="true">
                                        </div>

                                        <div *ngIf="jobDetails?.companyLogoPath != null && logo.length === 0"
                                            class="profile-image">
                                            <img *ngIf="!isCreate && isEdit" class="logo-edit
                                                cursor_pointer"
                                                (click)="coverUpload.click()"
                                                src="./assets/images/edit1.svg"
                                                alt="" />
                                            <a><img class="profile-profilepic"
                                                    src="{{environment.activityPhotoHost}}{{jobDetails?.companyLogoPath}}"
                                                    alt=""></a>
                                            <input type="file" name="coverImage"
                                                accept='image/*' #coverUpload
                                                (change)="onFileUpload($event)"
                                                hidden="true">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="postJobFormRow" *ngIf="isCreate ||
                                isEdit">
                                <div class="modal-footer postModalFooter">
                                    <div class="clearfix">
                                        <button *ngIf="isCreate"
                                            [disabled]="!formGroup.valid"
                                            class="btn btn-primary
                                            btn-rounded modalPostBtn
                                            float-right"
                                            (click)="onSubmit(formGroup.value,
                                            'add')">Post
                                            &nbsp;
                                            <em class="fa fa-spinner fa-spin"
                                                *ngIf="postJobLoading"></em></button>
                                        <button *ngIf="!isCreate && isEdit"
                                            [disabled]="!formGroup.valid"
                                            class="btn btn-primary
                                            btn-rounded modalPostBtn
                                            float-right"
                                            (click)="onSubmit(formGroup.value,
                                            'update')">Update
                                            &nbsp;
                                            <em class="fa fa-spinner
                                                fa-spin"
                                                *ngIf="postJobLoading"></em></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">
                <app-add-box></app-add-box>
            </div>
        </div>
    </div>
</section>
