import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogProgressComponent } from './dialog-progress/dialog-progress.component';
@Injectable({ providedIn: 'root' })
export class DialogProgressUtil {

    constructor(public dialog: MatDialog) {
    }

    dialogProgressRef: MatDialogRef<DialogProgressComponent, any>;

    openDialogProgress(message: string) {
        this.dialogProgressRef = this.dialog.open(DialogProgressComponent, {
            data: message,
            disableClose: true
        });
    }

    closeDialogProgress() {
        if (this.dialogProgressRef) {
            this.dialogProgressRef.close();
        }
    }
}
