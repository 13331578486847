<!-- navigation -->
<section class="navigation">

    <nav class="navbar navbar-expand-lg fixed-top navbar-light">

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">

                <li class="nav-item">
                    <app-logo class="nav-link"></app-logo>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/{{routerConstants.user}}/{{routerConstants.network}}" routerLinkActive="active">Network <span class="sr-only">(current)</span></a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/{{routerConstants.setUpProfile}}">Profile</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" routerLink="/{{routerConstants.user}}/{{routerConstants.jobs}}" routerLinkActive="active">Jobs</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/{{routerConstants.user}}/{{routerConstants.manage}}" routerLinkActive="active">Manage</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/{{routerConstants.user}}/{{routerConstants.postJob}}" routerLinkActive="active">
                        <img src="./assets/images/plus.png" class="icon d-inline-block align-top" alt=""> <span>Post a
                  Job</span>
                    </a>
                </li>
                <li class="nav-item">
                    <div class="p-1 rounded rounded-pill shadow-sm mb-4 searchBackground navSearch" style="height: 56px;">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button id="button-addon2" type="button" class="btn btn-link text-dark" (click)="openSearchDetails(null)">
                                    <img src="./assets/images/search.png" alt="search " />
                                </button>
                            </div>
                            <input type="search" id="srchbx" #searchText (input)="onKeydown($event)" (keyup.enter)="openSearchDetails(null)" (click)="fetchRecentSearch()" autocomplete="off" placeholder="Search for people, job title, company or skill" aria-describedby="button-addon2" class="form-control border-0 searchBackground form-control-searchText">
                        </div>
                        <div class="card card-body" *ngIf="recentSearches.length > 0 && suggestions.length == 0" auto-hide-popup >  
                            <div class="recent-search">Recent Search</div>                          
                            <div *ngFor="let recentSearch of recentSearches">

                                <div (click)="openSearchDetails(recentSearch.searchText)" class="recent-search-child"><span class="search-text">{{recentSearch.searchText}}</span><span class="search-time">{{recentSearch.dateTime | dateAgo}}</span></div>    
                            </div>
                          </div>
                        <div class="card card-body" *ngIf="suggestions.length > 0 && recentSearches.length == 0" auto-hide-popup >                            
                            <div *ngFor="let suggestion of suggestions">
                                <div class="search-child" (click)="suggestion.documentType == 1 ? viewOtherUser(suggestion.uuid):viewJob(suggestion.uuid)"><img *ngIf="suggestion.imageURL && suggestion.imageURL != 'null'" [src]="(suggestion.documentType == 1 ? environment.userPhotoHost : environment.activityPhotoHost) + suggestion.imageURL" class="search-image" /><span class="search-text">{{suggestion.title}}</span></div>    
                            </div>
                          </div>
                    </div>
                </li>

            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link mr-0 position-relative notification-badge" (click)="readNotifications()">

                        <img class="icon" src="./assets/images/notification.png" class="d-inline-block align-top" alt="">
                        <sup *ngIf="notificationCountFromSocket > 0">{{notificationCountFromSocket}}</sup>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/{{routerConstants.user}}/{{routerConstants.chatbox}}" style="margin-right: 0;" (click)="openChat()">
                        <img class="icon" src="./assets/images/chat.png" class="d-inline-block align-top" alt="">
                    </a>
                </li>
                <li class="nav-item dropdown" style="padding:28px 0px;">
                    <div data-toggle="dropdown" class="rounded rounded-pill shadow-sm searchBackground postBackgroundNav" style="margin-left: 15px;">
                        <div class="input-group" style="width: 140px;">

                            <input disabled placeholder="{{user.fname}}" aria-describedby="button-addon2" class="form-control border-0 searchBackground postInputNav">
                            <div class="input-group-append" style="padding-right:0px;">
                                <button id="button-addon3" type="button" class="btn btn-link text-dark">
                      
                                    <img *ngIf="!user.userDPUrl" style="height: 24px; width: 24px;border-radius: 50%;"
                        src="{{environment.userPhotoHost}}default-profile-200x150.png"/>
                        <img *ngIf="user.userDPUrl" style="height: 24px; width: 24px;border-radius: 50%;"
                        [src]="environment.userPhotoHost+user.userDPUrl"/>
                      </button>
                            </div>
                        </div>
                    </div>
                    <ul class="dropdown-menu" style="left: -32% !important">
                        <li><a routerLink="/{{routerConstants.user}}/{{routerConstants.profile}}">View Profile </a></li>
                        <li><a routerLink="/{{routerConstants.user}}/{{routerConstants.setUpProfile}}">setup Profile </a></li>
                        <li><a (click)="onLogout()">Logout </a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>

</section>
<app-messages [instanceId]="'master'" [showPopup]="showMaster"></app-messages>
<app-messages [instanceId]="'slave1'" [slaveObj]="slave1Obj" (closeWinPopEvent)="closeChatWinPopUp($event)" [cssStyle]="'position: fixed;right: 400px;'" [showPopup]="showSlave1"></app-messages>
<app-messages [instanceId]="'slave2'" [slaveObj]="slave2Obj" (closeWinPopEvent)="closeChatWinPopUp($event)" [cssStyle]="'position: fixed;right: 770px;'" [showPopup]="showSlave2"></app-messages>
<ng-template #content let-modal>
    <div class="modal-body modalPosition">
        <div class="input-group rounded rounded-pill shadow-sm search">
            <input type="search" class="search-input form-control form-control-lg input search-input-modal" [(ngModel)]="searchKey" [ngModelOptions]="{standalone: true}" placeholder="Search for people, job title, company or skill" />
            <div class="my-auto search-bar">
                <a (click)="getUserSearchDetails()">SEARCH</a>
            </div>
        </div>
        <div class="search-info">
            <div class="recent-search">Recent Search</div>
            <div class="search-user"><img src="./assets/images/request1.png" class="search-image" alt="search-image" /><span>Promod Mahajan</span></div>
            <div class="search-user"><img src="./assets/images/refresh.svg" class="search-image" alt="search-image" /><span>Proton India</span></div>
            <div class="search-user"><img src="./assets/images/refresh.svg" class="search-image" alt="search-image" /><span>Product Designer</span></div>
            <div class="break-line"></div>
            <div class="search-user"><img src="./assets/images/request2.png" class="search-image" alt="search-image" /><span>Pro<strong>mod Gill</strong></span></div>
            <div class="search-user"><img src="./assets/images/request3.png" class="search-image" alt="search-image" /><span>Pro<strong>bhodhini Institute</strong></span></div>
            <div class="search-user"><img src="./assets/images/search.svg" class="search-icon" alt="search-image" /><span>Adobe</span></div>
        </div>
    </div>
</ng-template>