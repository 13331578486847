
export class User {
  public email: string;
  public password: string;
  public firstName: string;
  public lastName: string;
  public phoneNumber: string;
  public countryCode: string;

}
