<section class="profile-section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6" style="margin-top: 25px">
        <div class="profile-image">
          <img (click)="cropSelectedImage('profile-profilepic', true)" class="profile-edit"
            src="./assets/images/edit1.svg" *ngIf="user.userDPUrl" />
          <a><img *ngIf="user.userDPUrl" (click)="coverUpload.click()" class="profile-profilepic"
              id="profile-profilepic" [src]="environment.userPhotoHost + user.userDPUrl" /></a>
          <a><img *ngIf="!user.userDPUrl" (click)="coverUpload.click()" class="profile-profilepic"
              src="./assets/images/upload_profile.png" style="border-style: dashed; border-width: thin" /></a>
          <input type="file" name="coverImage" accept="image/*" #coverUpload
            (change)="getCoverImageFile(coverUpload, $event)" hidden="true" />
          <h1 class="profile-username">
            {{ user.fname }}&nbsp;{{ user.lname }}
          </h1>
          <h6 *ngIf="jobTitle != null" class="profile-userInfo">
            {{ this.jobTitle ? this.jobTitle + "," : "" }}<br />{{
            this.companyName
            }}
          </h6>
          <div class="profile-followers">
            <span>{{ followersCount }}</span>
            <span>Followers</span>
            <span>{{ followingCount }}</span>
            <span>Following</span>
            <span>{{ appliedJobsCount }}</span>
            <span>Jobs Applied</span>
          </div>
          <div>
            <a routerLink="/{{ routerConstants.user }}/{{
                routerConstants.setUpProfile
              }}/{{ user.id }}" class="
                btn btn-primary btn-rounded
                primary-backgroundcolor
                smallBtn smallBtn
              "><span>EDIT</span></a>
            <a (click)="shareProfile()" class="
                btn btn-primary btn-rounded
                primary-backgroundcolor
                smallBtn
              "><span>SHARE</span></a>
            <a class="
                btn btn-default btn-sm btn-rounded
                with-icon
                nav-item
                dropdown
                open-hiring-btn
              " data-toggle="dropdown"><span class="profile-open-hiring">
                <img *ngIf="this.openToHire" class="onlineIcon" src="./assets/images/online.png" />
                <img *ngIf="!this.openToHire" class="onlineIcon" src="./assets/images/online1.png" />
                OPEN FOR HIRING
                <img class="profile-edit-img" src="./assets/images/editpencil.svg" /></span>
            </a>
            <span class="dropdown show open-hiring">
              <div class="dropdown-menu hiring-box dropdownFilter">
                <span class="hiring">Open for Hiring? </span>
                <label class="switch">
                  <input type="checkbox" (change)="openForHiring()" checked />
                  <span class="slider round"></span>
                </label>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6">
        <div style="position: relative">
          <img src="./assets/images/blue-dot.png" class="profile-coverimage-upper-dot" />
          <img src="./assets/images/blue-dot.png" class="profile-coverimage-bottom-dot" />
          <a><img *ngIf="user.userBgUrl" class="profile-coverimage" id="profile-bgpic"
              [src]="environment.userPhotoHost + user.userBgUrl" (click)="coverUpload1.click()" /></a>
          <a><img *ngIf="!user.userBgUrl" src="./assets/images/cover_page.png" class="profile-coverimage"
              (click)="coverUpload1.click()" style="border-style: dashed; border-width: thin" /></a>
          <img (click)="cropSelectedImage('profile-bgpic', false)" src="./assets/images/edit1.svg"
            class="profile-coverimage-edit" *ngIf="user.userBgUrl" />
          <input type="file" name="coverImage1" accept="image/*" id="coverImage1" #coverUpload1
            (change)="getCoverImageFile1(coverUpload1, $event)" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="profile-detailedInfo">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-lg-9">
        <div class="card profileCard" style="margin-top: 48px; border: none !important">
          <div class="card-body">
            <h3 class="profile-aboutTitle">About</h3>
            <p class="profile-aboutDesc" *ngIf="this.aboutUser">{{ this.aboutUser }}</p>
            <p class="profile-aboutDesc" *ngIf="!this.aboutUser">Please add more about yourself</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="card profileCard" style="margin-top: 48px; border: none !important; height: 80%">
          <div class="card-body">
            <div class="profile-experience-position">Profile Completion</div>
            <div class="progress" style="margin-top: 16px">
              <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                [style.width.%]="this.totalProgress"></div>
            </div>
            <div class="progress-value" style="margin-bottom: 60%">
              {{ this.totalProgress }}% Complete
            </div>
            <a routerLink="/{{ routerConstants.user }}/{{
              routerConstants.setUpProfile
            }}/{{ user.id }}" class="btn btn-blue-border btn-sm btn-rounded
                with-icon profileCompleteBtn" *ngIf="!(this.totalProgress === 100)"><span>COMPLETE
                NOW</span></a>
            <a href="javascript:;" class="
                btn btn-blue-border btn-sm btn-rounded
                with-icon
                profileCompleteBtn
              " *ngIf="this.totalProgress === 100" data-target="#myModal"><span>COMPLETED</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Experience -->
<section class="profile-detailedInfo">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-lg-9">
        <div class="card profileCard" style="border: none !important">
          <div class="card-body">
            <label class="profile-aboutTitle">Experience</label>
            <label class="profile-add">
              <a style="display: flex; align-items: center;" routerLink="/{{ routerConstants.user }}/{{
                  routerConstants.setUpProfile
                }}/{{ user.id }}"><img src="./assets/images/plus.png" style="height: 16px; width: 16px" />
                <span style="margin-left: 4px;">ADD NEW</span></a>
            </label>
            <div class="row" style="margin-bottom: 24px" *ngFor="let proInfo of DesignationAndCompanyName">
              <div class="col-md-2 col-lg-2">
                <img src="./assets/images/ibm.png" class="profile-experience-image" />
              </div>
              <div class="col-md-9 col-lg-9">
                <div class="profile-experience-position">
                  {{ proInfo.jobTitle }}
                </div>
                <div class="profile-experience-company">
                  {{ proInfo.companyName }}
                </div>
                <div class="profile-experience-date">
                  {{ proInfo.startDate | date: "MMM y" }} - Present |
                  {{ this.experienceYear }} Years
                </div>
                <div class="profile-experience-city">
                  {{ proInfo.jobLocationCity }}
                </div>
                <div class="profile-experience-desc">
                  {{ proInfo.description }}
                </div>
              </div>
              <div class="col-md-1 col-lg-1">
                <div class="profile-experience-ellipse">
                  <a routerLink="/{{ routerConstants.user }}/{{
                      routerConstants.setUpProfile
                    }}/{{ user.id }}"><img src="./assets/images/profile-edit.png"
                      class="profile-experience-edit" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="card profileCard">
          <div class="card-body resume-body">
            <div class="resume">Resume</div>
            <div *ngIf="!resumePathStatus">
              <!-- <a href="{{this.resumeLink}}" target="_blank"><img src="./assets/images/pdf.png" alt="pdf" width="20" height="20"></a>&nbsp; -->
              <a href="{{ this.resumeLink }}" target="_blank"><u>{{ user.username}}</u></a>
            </div>
            <div>
              <button type="button" class="btn btn-primary btn-rounded modalPostBtn" (click)="fileSelect.click()">
                Upload
              </button>
              <label class="my-resume"><input type="file" #fileSelect class="display-none network-post-attachimage1"
                  (change)="onFileUpload($event, 'doc')" accept=".pdf,.doc,.docx" hidden="true" /></label>
              <!-- <a *ngIf="!resumePathStatus" href="{{this.resumeLink}}" target="_blank"><i class="fa fa-download" style="color: #2B44FF" aria-hidden="true"></i></a> &nbsp; &nbsp; &nbsp; -->
              <a *ngIf="!resumePathStatus" (click)="deleteResume(user.id)"><i class="fa fa-trash" style="color: #2b44ff"
                  aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <app-add-box></app-add-box>
      </div>
    </div>
  </div>
</section>

<section class="profile-detailedInfo">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-lg-9">
        <div class="card profileCard" style="border: none !important">
          <div class="card-body">
            <h3 class="profile-aboutTitle">Education</h3>
            <div *ngIf="educationalInfo && educationalInfo !== undefined && educationalInfo.length > 0">
              <div class="row" *ngFor="let education of educationalInfo">
                <div class="col-md-2 col-lg-2">
                  <div class="profile-education-image-background">
                    <img src="./assets/images/university.png" class="profile-education-image" />
                  </div>
                </div>
                <div class="col-md-9 col-lg-9">
                  <div class="profile-experience-position">
                    {{ education.instituteUniversityName }}
                  </div>
                  <div class="profile-experience-date">
                    {{ education.certificateDegreeName }}
                  </div>
                  <div class="profile-experience-city">
                    {{ education.startDate | date: "y" }} -
                    {{ education.completionDate | date: "y" }}
                  </div>
                </div>
                <div class="col-md-1 col-lg-1"></div>
              </div>

            </div>
            <div *ngIf="educationalInfo.length === 0">
              <p class="profile-aboutDesc">Looks like you have not added any education yet.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content modal-align">
      <div class="modal-header">
        <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
        <span class="modal-title">Edit Experience</span>
        <a class="close" data-dismiss="modal"><img src="./assets/images/close_black.svg" /></a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 col-lg-6 modal-input">
            <div class="md-form md-outline form-lg">
              <input id="form-lg1" class="form-control form-control-lg input postJobInput" type="text"
                placeholder="Company name" value="IBM" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6 modal-input">
            <div class="md-form md-outline form-lg">
              <input id="form-lg2" class="form-control form-control-lg input postJobInput" type="text"
                placeholder="Designation" value="" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6 modal-input">
            <div class="md-form md-outline form-lg">
              <input id="form-lg3" class="form-control form-control-lg input postJobInput" type="text"
                placeholder="Location" value="" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6 modal-input">
            <div class="md-form md-outline form-lg">
              <select class="custom-select my-select-modal">
                <option class="active">Full Time</option>
              </select>
            </div>
          </div>
        </div>
        <div class="currently-working">
          <input type="checkbox" checked />
          <span class="currently">I am currently working here</span>
        </div>
        <div class="experience-date">
          <span class="start">Start Date</span>
          <span class="end">End Date</span>
        </div>
        <div class="row modal-date">
          <div class="col-md-3 ol-lg-3">
            <div class="md-form md-outline form-lg modal-input">
              <select class="custom-select my-select-modal">
                <option class="active">Month</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 ol-lg-3">
            <div class="md-form md-outline form-lg modal-input">
              <select class="custom-select my-select-modal">
                <option class="active">Year</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-lg-3">
            <div class="md-form md-outline form-lg modal-input">
              <input type="text" class="
                  postJobInput
                  basic-input
                  form-control form-control-lg
                  input
                " placeholder="Present" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="md-form md-outline form-lg basic-info experience-textarea">
              <textarea class="basic-input form-control form-control-lg input" placeholder="Description"></textarea>
            </div>
          </div>
        </div>
        <div class="break-line"></div>
        <div class="add-journey" data-toggle="modal" data-target="#myModal1">
          Add Internal Journey (Optional)
        </div>
      </div>
      <div class="modal-footer">
        <a class="delete-experience">Delete This Experience</a>
        <a class="btn btn-blue-border-modal btn-sm btn-rounded with-icon"><span>Save</span></a>
        <a class="btn btn-white-border btn-sm btn-rounded with-icon"><span>Cancel</span></a>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div id="myModal1" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content modal-align">
      <div class="modal-header">
        <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
        <span class="modal-title">Edit Experience</span>
        <a class="close" data-dismiss="modal"><img src="./assets/images/close_black.svg" /></a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 col-lg-6 modal-input">
            <div class="md-form md-outline form-lg">
              <input id="form-lg4" class="form-control form-control-lg input postJobInput" type="text"
                placeholder="Company Name" value="IBM" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6 modal-input">
            <div class="md-form md-outline form-lg">
              <input id="form-lg5" class="form-control form-control-lg input postJobInput" type="text"
                placeholder="Designation" value="Maanager UI/UX Designer (Product)" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6 modal-input">
            <div class="md-form md-outline form-lg">
              <input id="form-lg6" class="form-control form-control-lg input postJobInput" type="text"
                placeholder="Location" value="Pune, Maharastra" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6 modal-input">
            <div class="md-form md-outline form-lg">
              <select class="custom-select my-select-modal">
                <option class="active">Full Time</option>
              </select>
            </div>
          </div>
        </div>
        <div class="currently-working">
          <input type="checkbox" checked />
          <span class="currently">I am currently working here</span>
        </div>
        <div class="experience-date">
          <span class="start">Start Date</span>
          <span class="end">End Date</span>
        </div>
        <div class="row modal-date">
          <div class="col-md-3 ol-lg-3">
            <div class="md-form md-outline form-lg modal-input">
              <select class="custom-select my-select-modal">
                <option class="active">Month</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 ol-lg-3">
            <div class="md-form md-outline form-lg modal-input">
              <select class="custom-select my-select-modal">
                <option class="active">Year</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-lg-3">
            <div class="md-form md-outline form-lg modal-input">
              <input type="text" class="
                  postJobInput
                  basic-input
                  form-control form-control-lg
                  input
                " placeholder="Present" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="md-form md-outline form-lg basic-info experience-textarea">
              <textarea class="basic-input form-control form-control-lg input" placeholder="Description"></textarea>
            </div>
          </div>
        </div>
        <div class="internal-journey">Internal Journey</div>
        <div class="row internal-modal-scroll">
          <div class="col-md-1 col-lg-1 vertical-line"></div>
          <div class="col-md-11 col-lg-11 internal-section-modal">
            <div class="row">
              <div class="col-md-6 col-lg-6 modal-input">
                <div class="md-form md-outline form-lg">
                  <input id="form-lg11" class="form-control form-control-lg input postJobInput" type="text"
                    placeholder="Company Name" value="UI/UX Designer" />
                </div>
              </div>
              <div class="col-md-6 col-lg-6">
                <img src="./assets/images/trash.svg" class="modal-trash" alt="delete" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-6 modal-input">
                <div class="md-form md-outline form-lg">
                  <input id="form-lg8" class="form-control form-control-lg input postJobInput" type="text"
                    placeholder="Location" value="Pune, Maharastra" />
                </div>
              </div>
            </div>
            <div class="experience-date">
              <span class="start">Start Date</span>
              <span class="internal-end">End Date</span>
            </div>
            <div class="row modal-date">
              <div class="col-md-3 ol-lg-3">
                <div class="md-form md-outline form-lg modal-input">
                  <select class="custom-select my-select-modal">
                    <option class="active">Mar</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 ol-lg-3">
                <div class="md-form md-outline form-lg modal-input">
                  <select class="custom-select my-select-modal">
                    <option class="active">2016</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 ol-lg-3">
                <div class="md-form md-outline form-lg modal-input">
                  <select class="custom-select my-select-modal">
                    <option class="active">Apr</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 ol-lg-3">
                <div class="md-form md-outline form-lg modal-input">
                  <select class="custom-select my-select-modal">
                    <option class="active">2017</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="break-line"></div>
            <div class="row">
              <div class="col-md-6 col-lg-6 modal-input">
                <div class="md-form md-outline form-lg">
                  <input id="form-lg10" class="form-control form-control-lg input postJobInput" type="text"
                    placeholder="Company Name" value="UI/UX Designer" />
                </div>
              </div>
              <div class="col-md-6 col-lg-6">
                <img src="./assets/images/trash.svg" class="modal-trash" alt="delete" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-6 modal-input">
                <div class="md-form md-outline form-lg">
                  <input id="form-lg12" class="form-control form-control-lg input postJobInput" type="text"
                    placeholder="Location" value="Pune, Maharastra" />
                </div>
              </div>
            </div>
            <div class="experience-date">
              <span class="start">Start Date</span>
              <span class="internal-end">End Date</span>
            </div>
            <div class="row modal-date">
              <div class="col-md-3 ol-lg-3">
                <div class="md-form md-outline form-lg modal-input">
                  <select class="custom-select my-select-modal">
                    <option class="active">Mar</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 ol-lg-3">
                <div class="md-form md-outline form-lg modal-input">
                  <select class="custom-select my-select-modal">
                    <option class="active">2016</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 ol-lg-3">
                <div class="md-form md-outline form-lg modal-input">
                  <select class="custom-select my-select-modal">
                    <option class="active">Apr</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 ol-lg-3">
                <div class="md-form md-outline form-lg modal-input">
                  <select class="custom-select my-select-modal">
                    <option class="active">2017</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="delete-experience">Delete This Experience</a>
        <a class="btn btn-blue-border-modal btn-sm btn-rounded with-icon"><span>Save</span></a>
        <a class="btn btn-white-border btn-sm btn-rounded with-icon"><span>Cancel</span></a>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cropImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title letspost" id="exampleModalLabel">
          Edit Your Snap!
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" click="resetPostForm()"
          style="top: -45px; position: relative">
          <span aria-hidden="true"><img style="height: 24px" src="assets/images/times.png" alt="" /></span>
        </button>
      </div>
      <div class="modal-body">
        <image-cropper [imageBase64]="imageChangedEvent" [maintainAspectRatio]="false" format="jpeg" multiple
          (imageCropped)="imageCropped($event)"></image-cropper>
      </div>
      <div class="modal-footer postModalFooter">
        <div class="clearfix">
          <button class="btn btn-success btn-rounded crop-modal-btn float-right"
            (click)="isProfilePicCropper ? onImageCrop() : onBgImageCrop()" data-dismiss="modal">
            Crop & Save
          </button>
          <button class="btn btn-light btn-rounded crop-modal-btn float-right" data-dismiss="modal" aria-label="Close"
            (click)="onCancelCropImg()">
            Cancel &nbsp;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>