<div style="font-family: inherit;color: gray;font-size: 11px;margin: 0px 0px 1px 5px;">Sponsored</div>
<div style="position: relative;">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide><img src="./assets/images/11.JPG" href="www.aavishakar.com" class="network-explore-image"
        alt=""></ng-template>
    <ng-template carouselSlide><img src="./assets/images/12.JPG" class="network-explore-image" alt=""></ng-template>
    <ng-template carouselSlide><img src="./assets/images/Capture1.JPG" class="network-explore-image" alt="">
    </ng-template>
    <ng-template carouselSlide><img src="./assets/images/add2.JPG" class="network-explore-image" alt=""></ng-template>
    <ng-template carouselSlide><img src="./assets/images/add21.JPG" class="network-explore-image" alt=""></ng-template>
    <ng-template carouselSlide><img src="./assets/images/add22.JPG" class="network-explore-image" alt=""></ng-template>
    <ng-template carouselSlide><img src="./assets/images/add23.JPG" class="network-explore-image" alt=""></ng-template>

  </owl-carousel-o>
  <a href="http://www.aavishakar.com" style="z-index: 999;
  position: absolute;
  bottom: 10%;
  left: 18%;"
    target="_blank">www.aavishakar.com</a>
</div>