export const RouterConstants = {
  default: '',
  wildcard: '**',
  error: 'error',
  pageNotFound: 'page-not-found',
  signup: 'signup',
  signIn: 'sign-in',
  signUp: 'sign-up',
  gSignUp: 'oauth2/authorization/google',
  forgotPassword: 'forgot-password',
  changePassword: 'change-password',
  home: 'home',
  feed: 'feed',
  network: 'network',
  jobs: 'jobs',
  user: 'user',
  manage: 'manage',
  notifications: 'notifications',
  chat: 'chat',
  postJob: 'post-job',
  profile: 'user-details',
  profileExternal: 'profile-external-details',
  jobDetails: 'job-details',
  setUpProfile: 'setup-profile',
  searchResult: 'search-result',
  chatbox: 'chatbox',
  appliedJobs: 'applied-jobs',
  shortlistedJobs: 'shortlisted-jobs',
  savedPosts: 'saved-posts',
  yourPosts: 'your-posts',
};
