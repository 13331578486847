import { Component, OnInit } from '@angular/core';
import { RouterConstants } from 'src/app/constants/router.contants';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  routerConstants = RouterConstants;
  formGroup: FormGroup;
  isSubmitted = false;
  apiResponse: any = '';
  constructor(private formBuilder: FormBuilder,
              private apiCallSvc: ApiCallService,
              private authSvc: AuthenticationService,
              private router: Router) { }

  ngOnInit(): void {
    this.createForm();
  }

  get formControls() { return this.formGroup.controls; }

  createForm() {
    this.formGroup = this.formBuilder.group({
      phoneNumber: [null, [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]]
    });
  }

  forgotPasswordSubmit(formData) {
    this.isSubmitted = true;
    if (this.formGroup.invalid) {
      return;
    }
    
    const phoneno = /^\d{10}$/;
    if (formData.phoneNumber.match(phoneno) ) {
      this.apiCallSvc.getApiCall('/api/user/check-phoneNumber-for-forgot-password?phoneNumber=' + formData.phoneNumber).subscribe(res => {

      }, errorRes => {
        if (errorRes.error) {
          this.apiResponse = errorRes.error.message;
        }
      });
    } 
    else {
      const data = { emailId:formData.phoneNumber };
      this.apiCallSvc.postApiCall('/api/auth/send-change-password-email/',data).subscribe(res => {
        alert('Email has been sent, Please check your inbox and click on the link');
        this.router.navigate(['/']);
      }, errorRes => {
        if (errorRes.error) {
          this.apiResponse = errorRes.error.message;
        }
      });
    }

  }
}
