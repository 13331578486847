<section class="main-network">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-12 col-lg-3">
                <app-user-profile-card></app-user-profile-card>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-6">

                <!--Active Post -->
                <div class="card profileCard" style="border: 1px solid
                    #E0E0E0;" *ngFor="let activity of activityPostList; let
                    i=index;">
                    <div class="card-body" style="min-height: 0;">
                        <div class="row">
                            <div class="col-md-1">
                                <a
                                    (click)="viewOtherUser(activity.activityPostedByUserId)"><img
                                        class="network-userImage"
                                        [src]="api.getInitials(activity?.activityPostedByUser?.firstName
                                        +
                                        ' ' +
                                        activity?.activityPostedByUser?.lastName)"
                                        alt="" /></a>
                            </div>
                            <div class="col-md-7">
                                <a
                                    (click)="viewOtherUser(activity.activityPostedByUserId)">
                                    <span
                                        class="post-something">{{activity?.activityPostedByUser?.firstName}}
                                        {{activity?.activityPostedByUser?.lastName}}</span>
                                    <span class="project-manager"
                                        *ngIf="activity?.activityPostedByUser?.userTitle
                                        !=
                                        null">{{activity?.activityPostedByUser?.userTitle}}
                                    </span></a>
                            </div>
                            <div class="col-md-3">
                                <span class="network-datetime">
                                    {{activity.createdOn | dateAgo}}</span>
                            </div>
                            <div class="col-md-1">
                                <div class="action-dots
                                    clearfix">
                                    <div class="dropdown"
                                        data-toggle="dropdown"
                                        *ngIf="activity.activityOwner">
                                        <img class="cursor_pointer"
                                            src="./assets/images/dots.svg"
                                            alt="" />
                                        <ul
                                            class="dropdown-menu"
                                            style="left: -74%
                                            !important">
                                            <li
                                                (click)="onDeleteActivity(activity.activityId)"><a><span>
                                                        <img
                                                            class="dropdown-menu-trash"
                                                            alt=""
                                                            src="./assets/images/trash.svg"
                                                            />
                                                    </span><span>Delete</span>
                                                </a></li>
                                        </ul>
                                    </div>

                                    <div class="dropdown"
                                        data-toggle="dropdown"
                                        *ngIf="!activity.activityOwner"
                                        (click)="getConnectionStatus(activity.activityPostedByUserId)">
                                        <img class="cursor_pointer"
                                            src="./assets/images/dots.svg"
                                            alt="" />
                                        <ul *ngIf="!activity.activityOwner"
                                            class="dropdown-menu"
                                            style="left: -74%
                                            !important">
                                            <li style="text-align: center;"><a
                                                    *ngIf="connectionStatus !=
                                                    null">
                                                    <span
                                                        *ngIf="connectionStatus
                                                        === 'Follow'"
                                                        (click)="onFollow(activity.activityPostedByUserId)">Follow</span>
                                                    <span
                                                        *ngIf="connectionStatus
                                                        === 'Accept'"
                                                        (click)="accept(activity.activityPostedByUserId)">Accept</span>
                                                    <span
                                                        *ngIf="connectionStatus
                                                        === 'Follow Back'"
                                                        (click)="onFollow(activity.activityPostedByUserId)">Follow
                                                        Back</span>
                                                    <span
                                                        *ngIf="connectionStatus
                                                        === 'Cancel Request'"
                                                        (click)="onCancelFollowingRequest(activity.activityPostedByUserId)">Cancel
                                                        Request</span>
                                                    <span
                                                        *ngIf="connectionStatus
                                                        === 'Unfollow'"
                                                        (click)="onUnfollowFollowing(activity.activityPostedByUserId)">Unfollow</span>
                                                </a>

                                                <i *ngIf="connectionStatus ==
                                                    null" class="fa
                                                    fa-spinner fa-spin"
                                                    aria-hidden="true"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="middle-content">
                            <span class="network-postdescription">{{activity.postText}}
                            </span>


                            <!-- share activity  -->
                            <div class="card profileCard" style="border: 1px
                                solid
                                #E0E0E0; margin-top: 15px;"
                                *ngIf="activity?.shareActivityObject
                                != null">
                                <div class="card-body" style="min-height: 0;">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <a
                                                (click)="viewOtherUser(activity?.shareActivityObject.id)"><img
                                                    class="network-userImage"
                                                    [src]="api.getInitials(activity?.shareActivityObject?.firstName
                                                    +
                                                    ' ' +
                                                    activity?.shareActivityObject?.lastName)"
                                                    alt="" /></a>
                                        </div>
                                        <div class="col-md-7">
                                            <a
                                                (click)="viewOtherUser(activity.shareActivityObject.id)">
                                                <span
                                                    class="post-something">{{activity?.shareActivityObject?.firstName}}
                                                    {{activity?.shareActivityObject?.lastName}}</span>
                                                <span class="project-manager"
                                                    *ngIf="activity?.shareActivityObject?.userTitle
                                                    !=
                                                    null">{{activity?.shareActivityObject?.userTitle}}
                                                </span></a>
                                        </div>
                                        <div class="col-md-3">
                                            <span class="network-datetime">
                                                {{activity.shareActivityObject.createdOn
                                                | dateAgo}}</span>
                                        </div>

                                        <!-- yet to work -->
                                        <div class="col-md-1">
                                            <div class="action-dots
                                                clearfix">
                                                <div class="dropdown"
                                                    data-toggle="dropdown"
                                                    *ngIf="activity.shareActivityObject.id
                                                    === user.id">
                                                    <img class="cursor_pointer"
                                                        src="./assets/images/dots.svg"
                                                        alt="" />
                                                    <ul
                                                        class="dropdown-menu"
                                                        style="left: -74%
                                                        !important">
                                                        <li
                                                            (click)="onDeleteActivity(activity.shareActivityObject.activityId)"><a><span>
                                                                    <img
                                                                        class="dropdown-menu-trash"
                                                                        alt=""
                                                                        src="./assets/images/trash.svg"
                                                                        />
                                                                </span><span>Delete</span>
                                                            </a></li>
                                                    </ul>
                                                </div>
                                                <div class="dropdown"
                                                    data-toggle="dropdown"
                                                    *ngIf="activity.shareActivityObject.id
                                                    !== user.id"
                                                    (click)="getConnectionStatus(activity.shareActivityObject.id)">
                                                    <img class="cursor_pointer"
                                                        src="./assets/images/dots.svg"
                                                        alt="" />
                                                    <ul
                                                        *ngIf="activity.shareActivityObject.id
                                                        !==
                                                        user.id"
                                                        class="dropdown-menu"
                                                        style="left: -74%
                                                        !important">
                                                        <li style="text-align:
                                                            center;"><a
                                                                *ngIf="connectionStatus
                                                                != null">
                                                                <span
                                                                    *ngIf="connectionStatus
                                                                    ===
                                                                    'Follow'"
                                                                    (click)="onFollow(activity.shareActivityObject.id)">Follow</span>
                                                                <span
                                                                    *ngIf="connectionStatus
                                                                    ===
                                                                    'Accept'"
                                                                    (click)="accept(activity.shareActivityObject.id)">Accept</span>
                                                                <span
                                                                    *ngIf="connectionStatus
                                                                    === 'Follow
                                                                    Back'"
                                                                    (click)="onFollow(activity.shareActivityObject.id)">Follow
                                                                    Back</span>
                                                                <span
                                                                    *ngIf="connectionStatus
                                                                    === 'Cancel
                                                                    Request'"
                                                                    (click)="onCancelFollowingRequest(activity.shareActivityObject.id)">Cancel
                                                                    Request</span>
                                                                <span
                                                                    *ngIf="connectionStatus
                                                                    ===
                                                                    'Unfollow'"
                                                                    (click)="onUnfollowFollowing(activity.shareActivityObject.id)">Unfollow</span>
                                                            </a>
                                                            <i
                                                                *ngIf="connectionStatus
                                                                == null"
                                                                class="fa
                                                                fa-spinner
                                                                fa-spin"
                                                                aria-hidden="true"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="middle-content">
                                        <span class="network-postdescription">{{activity.shareActivityObject.postText}}
                                        </span>
                                        <!-- 1 img -->
                                        <div class="post-image1"
                                            *ngIf="activity.shareActivityObject.type
                                            == 1">
                                            <div
                                                *ngIf="activity?.shareActivityObject?.activityPostsPaths.length
                                                === 1">
                                                <img
                                                    src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[0].path}}"
                                                    alt="" />
                                            </div>
                                        </div>

                                        <!-- 2 img -->
                                        <div class="row"
                                            *ngIf="activity.shareActivityObject.type
                                            ==
                                            1 &&
                                            activity?.shareActivityObject.activityPostsPaths.length
                                            === 2">
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[0].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[1].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- 3 img -->
                                        <div class="row"
                                            *ngIf="activity.shareActivityObject.type
                                            ==
                                            1 &&
                                            activity?.shareActivityObject.activityPostsPaths.length
                                            === 3">
                                            <div class="col-md-12 col-lg-12">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[0].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[1].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[2].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- 4 img -->
                                        <div class="row"
                                            *ngIf="activity.shareActivityObject.type
                                            ==
                                            1 &&
                                            activity?.shareActivityObject.activityPostsPaths.length
                                            === 4">
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[0].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[1].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[2].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[3].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- 5 img -->
                                        <div class="row"
                                            *ngIf="activity.shareActivityObject.type
                                            ==
                                            1 &&
                                            activity?.shareActivityObject.activityPostsPaths.length
                                            === 5">
                                            <div class="col-md-12 col-lg-12">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[0].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[1].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[2].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[3].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div
                                                    class="network-modal-uploaded-image">
                                                    <img
                                                        src="{{environment.activityPhotoHost}}{{activity?.shareActivityObject.activityPostsPaths[4].path}}"
                                                        alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="post-pdf"
                                            *ngIf="activity.shareActivityObject.type
                                            == 2">
                                            <div class="clearfix">
                                                <img class="float-left
                                                    post-pdf-image"
                                                    src="assets/images/pdf.png"
                                                    alt="" />
                                                <div class="float-left
                                                    post-pdf-body">
                                                    <strong
                                                        class="post-pdf-title">10
                                                        Leadership values</strong>
                                                    <p
                                                        class="post-pdf-subtitle">PDF
                                                        Document
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <!-- 1 img -->
                            <div class="post-image1" *ngIf="activity.type == 1">
                                <div *ngIf="activity?.activityPostsPaths.length
                                    === 1">
                                    <img
                                        src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                        alt="" />
                                </div>
                            </div>

                            <!-- 2 img -->
                            <div class="row" *ngIf="activity.type == 1 &&
                                activity?.activityPostsPaths.length === 2">
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}"
                                            alt="" />
                                    </div>
                                </div>
                            </div>

                            <!-- 3 img -->
                            <div class="row" *ngIf="activity.type == 1 &&
                                activity?.activityPostsPaths.length === 3">
                                <div class="col-md-12 col-lg-12">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}"
                                            alt="" />
                                    </div>
                                </div>
                            </div>

                            <!-- 4 img -->
                            <div class="row" *ngIf="activity.type == 1 &&
                                activity?.activityPostsPaths.length === 4">
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[3].path}}"
                                            alt="" />
                                    </div>
                                </div>
                            </div>

                            <!-- 5 img -->
                            <div class="row" *ngIf="activity.type == 1 &&
                                activity?.activityPostsPaths.length === 5">
                                <div class="col-md-12 col-lg-12">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[0].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[1].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[2].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[3].path}}"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="network-modal-uploaded-image">
                                        <img
                                            src="{{environment.activityPhotoHost}}{{activity?.activityPostsPaths[4].path}}"
                                            alt="" />
                                    </div>
                                </div>
                            </div>

                            <div class="post-pdf" *ngIf="activity.type
                                == 2">
                                <div class="clearfix">
                                    <img class="float-left
                                        post-pdf-image"
                                        src="assets/images/pdf.png" alt="" />
                                    <div class="float-left
                                        post-pdf-body">
                                        <strong class="post-pdf-title">10
                                            Leadership values</strong>
                                        <p class="post-pdf-subtitle">PDF
                                            Document
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="post-like">
                                <div class="clearfix">
                                    <img *ngIf="activity.isActivityLike"
                                        src="assets/images/like-filled.svg"
                                        class="post-likeBtn
                                        cursor_pointer"
                                        (click)="onUnLike(activity.activityId,
                                        i)"
                                        alt="" />
                                    <img *ngIf="!activity.isActivityLike"
                                        src="assets/images/like.svg"
                                        class="post-likeBtn
                                        cursor_pointer"
                                        (click)="onLike(activity.activityId, i)"
                                        alt="" />
                                    <span *ngIf="activity.likedCounts> 0"
                                        class="post-likedBy">Liked
                                        by <strong
                                            *ngIf="activity.isActivityLike">You</strong><strong
                                            *ngIf="!activity.isActivityLike">{{activity?.lastLikedBy}}</strong>
                                        <strong *ngIf="activity.likedCounts> 1"
                                            (click)="allLikedUser(activity.activityId)">
                                            and
                                            {{activity.likedCounts - 1}}
                                            others</strong></span>
                                    <div class="float-right">
                                        <img class="post-shareBtn
                                            cursor_pointer"
                                            src="assets/images/share1.svg"
                                            alt="" data-toggle="modal"
                                            data-target="#shareModal" />
                                        <img class="post-saveBtn
                                            cursor_pointer"
                                            *ngIf="activity.isActivitySave"
                                            (click)="onRemoveFromSavedCollection(activity.activityId,
                                            i)"
                                            src="assets/images/bookmark1-filled.svg"
                                            alt="" />
                                        <img class="post-saveBtn
                                            cursor_pointer"
                                            *ngIf="!activity.isActivitySave"
                                            (click)="onSave(activity.activityId,
                                            i)"
                                            src="assets/images/bookmark1.svg"
                                            alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body" style="border-top: 1px solid
                        #E0E0E0;
                        min-height: 0;" *ngIf="activity.allowCommentForPost">
                        <div class="clearfix">
                            <div class="float-left">
                                <img *ngIf="!user.userDPUrl"
                                    class="post-profilepic" alt=""
                                    src="{{environment.userPhotoHost}}default-profile-200x150.png"
                                    />
                                <img *ngIf="user.userDPUrl"
                                    class="post-profilepic" alt=""
                                    src="{{environment.userPhotoHost}}{{user.userDPUrl}}"
                                    />
                            </div>
                            <div class="float-left" style="width: 92%;">
                                <div class="rounded rounded-pill
                                    shadow-sm
                                    post-comment-box">
                                    <form [formGroup]="commentOnPostFormGroup">
                                        <div class="input-group">
                                            <textarea
                                                placeholder="{{constants.add_comment}}"
                                                aria-describedby="button-addon2"
                                                class="form-control
                                                border-0
                                                postInput my-auto
                                                post-comment-text-box"
                                                formControlName="comment"></textarea>
                                            <div class="input-group-append
                                                post-comment">
                                                <a
                                                    (click)="onComment(activity.activityId,
                                                    i)">POST</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="comments">
                            <div class="clearfix comment-section" *ngFor="let
                                comment of
                                activity?.activityComments.reverse()">
                                <div class="float-left" style="width:
                                    auto">
                                    <strong class="post-comment-author">{{comment.firstName}}
                                        {{comment.lastName}}</strong>
                                </div>
                                <div class="float-left">
                                    <span class="post-comment-text">{{comment.commentsText}}
                                        -
                                        {{comment.createdOn | dateAgo}}</span>
                                </div>
                            </div>
                            <a *ngIf="activity?.totalComments>
                                activity.initialCommentsList"
                                class="view-all-comments"
                                (click)="onLoadNextFewComments(activity.activityId,
                                i)">View all
                                {{activity?.totalComments-
                                activity.initialCommentsList}}
                                comments</a>
                        </div>
                    </div>
                </div>

                <!-- share activity popup -->
                <div class="modal fade" id="shareModal" tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title letspost"
                                    id="exampleModalLabel">Share
                                </h5>
                                <button type="button" class="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true"><img
                                            style="height:
                                            24px;"
                                            src="assets/images/times.png"
                                            alt=""
                                            /></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="clearfix post-userDetail">
                                    <div class="float-left">
                                        <img *ngIf="!user.userDPUrl"
                                            src="{{environment.userPhotoHost}}default-profile-200x150.png"
                                            alt="" />
                                        <img *ngIf="user.userDPUrl"
                                            src="{{environment.userPhotoHost}}{{user.userDPUrl}}"
                                            alt="" />
                                        <span>{{user.fname}}
                                            {{user.lname}}
                                        </span>
                                    </div>
                                </div>
                                <div class="modalWriteInput">
                                    <form [formGroup]="createPostFormGroup">
                                        <textarea
                                            placeholder="{{constants.post_about}}"
                                            class="share-modal-textarea"
                                            formControlName="postContent"></textarea>
                                    </form>
                                </div>

                                <div class="card shareCard" style="border: 1px
                                    solid
                                    #E0E0E0;">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <img class="network-userImage"
                                                src="{{environment.userPhotoHost}}{{user.userDPUrl}}">
                                        </div>
                                        <div class="col-md-7">
                                            <span class="post-something">{{user.fname}}
                                                {{user.lname}}</span>
                                            <span class="post-manager">Software
                                                Engineer </span>
                                        </div>
                                        <div class="col-md-3">
                                            <span class="network-datetime"> 2
                                                weeks ago</span>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="action-dots clearfix">
                                                <img
                                                    src="./assets/images/dots.svg"
                                                    /></div>
                                        </div>
                                    </div>

                                    <div class="middle-content">
                                        <span class="network-postdescription">Sample</span>
                                        <div class="post-image1">
                                            <div>
                                                <img
                                                    src="https://store-activity-contents-dev.s3.ap-south-1.amazonaws.com/147/photos/new2.png.png"
                                                    width="95%" height="435px"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <span class="dropdown show">
                                            <button class="btn btn-sm my-2
                                                my-sm-0
                                                btn-rounded
                                                btn-outline-light-dark"
                                                id="dropdownLocationLink"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <span>Only Followers</span>
                                                <i class="fas fa-chevron-down
                                                    filtericons"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer postModalFooter">
                                <div class="clearfix">

                                    <div class="float-left
                                        post-attachments" style="padding-top:
                                        7px;">
                                        <label class="cursor_pointer"
                                            style="padding-right: 8px;"
                                            *ngIf="(postType === null ||
                                            postType === 2)"><img
                                                src="./assets/images/attach.svg"
                                                alt=""><input
                                                type="file"
                                                class="display-none
                                                network-post-attachimage1"
                                                (change)="onFileUpload($event,
                                                'doc')"
                                                accept=".pdf"></label>
                                        <label class="cursor_pointer"
                                            *ngIf="postType === null"
                                            style="padding-right: 18px;"><img
                                                style="height: 24px;
                                                width:
                                                24px;"
                                                src="assets/images/video.svg"
                                                alt="" /><input
                                                type="file"
                                                class="display-none
                                                network-post-attachimage1"
                                                (change)="onFileUpload($event,
                                                'video')"
                                                accept="video/mp4,video/x-m4v,video/*"></label>
                                        <label class="cursor_pointer"
                                            *ngIf="(postType === null ||
                                            postType === 1)"><img style="height:
                                                24px; width:
                                                24px;"
                                                src="assets/images/camera.svg"
                                                alt="" /><input
                                                type="file"
                                                class="display-none
                                                network-post-attachimage1"
                                                multiple=""
                                                (change)="onFileUpload($event,
                                                'image')"
                                                accept="image/*"></label>
                                    </div>
                                    <button class="btn btn-primary
                                        btn-rounded
                                        modalPostBtn
                                        float-right"
                                        [disabled]="!createPostFormGroup.valid"
                                        (click)="onSharePost()">Share</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-lg-3">
                <div class="card profileCard">
                    <app-add-box></app-add-box>
                </div>
                <!---->
                <div class="card jobCard" style="margin-bottom: 20px;"
                    *ngFor="let job of relevantJobsList">
                    <div class="card-body">
                        <div class="clearfix">

                            <img style="float: left;"
                                (click)="onViewJobDetails(job.jobId)"
                                *ngIf="job.companyLogoPath == null"
                                class="jobImg1 cursor_pointer"
                                src="./assets/images/defaultCompanyLogo.jpg"
                                alt="job image" />

                            <img style="float: left;"
                                (click)="onViewJobDetails(job.jobId)"
                                *ngIf="job.companyLogoPath != null"
                                class="jobImg cursor_pointer"
                                src="{{environment.activityPhotoHost}}{{job?.companyLogoPath}}"
                                alt="job image" />
                        </div>
                        <h5 class="card-title job-title cursor_pointer"
                            (click)="onViewJobDetails(job.jobId)">{{job.jobTitle}}</h5>
                        <p class="job_companyName cursor_pointer"
                            (click)="onViewJobDetails(job.jobId)">{{job.companyName}}</p>
                        <p class="job_location">{{job.cityName}},
                            {{job.country}}</p>
                        <p class="job_new" *ngIf="job.createdOn === 'New'">New</p>
                        <p class="job_recent" *ngIf="job.createdOn != 'New'">{{job.createdOn
                            | dateAgo}}</p>
                    </div>
                </div>
                <div class="network-right-view-job cursor_pointer"
                    *ngIf="relevantJobsList.length> 0"
                    routerLink="/{{routerConstants.user}}/{{routerConstants.jobs}}">VIEW
                    ALL JOBS</div>
                <hr class="network-linebreak">
                <div class="network-right-side-info">
                    <span>About US</span>
                    <span style="margin-left: 28px;margin-right: 28px;">Contact
                        US</span>
                    <span>Privacy Policy</span>
                </div>
                <div class="network-socialMedia" align="center">
                    <img src="./assets/images/facebook_grey.png"
                        class="network-facebook"
                        alt="facebook" />
                    <img src="./assets/images/twitter_grey.png"
                        class="network-twitter"
                        alt="twitter" />
                    <img src="./assets/images/instagram_grey.png"
                        class="network-instagram" alt="instagram" />
                </div>
            </div>
        </div>
    </div>
</section>
