import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RouterConstants } from 'src/app/constants/router.contants';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { GlobalConstants } from './../../constants/globalConstants';
import {ApiCallService} from '../../services/api-call-svc/api-call.service';
import { AlertBarService } from 'src/app/services/alert-bar.service';
import {User} from '../../entities/user';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [User]
})
export class SignupComponent implements OnInit {
  globalConstants = GlobalConstants;
  loading = false;
  constructor( private authSvc: AuthenticationService,
               private formBuilder: FormBuilder,
               private router: Router,
               private apiCallService: ApiCallService,
               private alertbar: AlertBarService,
               ) { }

  get formControls() { return this.formGroup.controls; }
  formGroup: FormGroup;
  routerConstants = RouterConstants;
  isSubmitted = false;
  signupResponse: any = '';
  private username: string;
  private password: string;

  ngOnInit(): void {
    this.createForm();
    this.username = sessionStorage.getItem('username');
    this.password = sessionStorage.getItem('password');
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      phoneNumber:[null, [Validators.required]]
    });
  }

  signup(post) {
    this.isSubmitted = true;
    // Adding this step to handle form pending status issue
    for (const el in this.formGroup.controls) {
      if (this.formGroup.controls[el].errors) {
        return;
      }
    }
    const user: User = new User();
    user.email = this.username;
    user.password = this.password;
    user.firstName = post.firstname;
    user.lastName = post.lastname;
    user.phoneNumber = post.phoneNumber;
    user.countryCode ="+91";
    // console.log(user);
    this.loading = true;
    this.signupResponse = '';

    this.apiCallService.postApiCall('/api/auth/create', user).subscribe(
      (res:any) => {
        this.loading = false;
        this.router.navigate(['/sign-in']);
        // this.showSuccess = true;
      },
      err => {
        this.signupResponse = err.error.message;
        this.loading = false;
      });
    // this.router.navigate(['/sign-in']);
  }

  validateNo(e): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }

  SendVerificationMail() {
    const url='/api/email/test-email';
    this.apiCallService.getApiCall(url).subscribe((res) => {
      // console.log('mail sent');
    });
  }
}
