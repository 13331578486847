import { Injectable } from '@angular/core';
import { SocialUser } from 'angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class ShareSocialUserService {
  user: SocialUser;

  constructor() { }

  setSocialUser(usr: SocialUser) {
    this.user = usr;
  }

  getSocialUser(): SocialUser {
    return this.user;
  }

}
