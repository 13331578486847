<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-6">
      <div class="container">
        <div class="signupstep1">
          <img src="./assets/images/logo.png" />
          <div class="signupstep1-welcome">
            <h1 class="welcomeTitle">Welcome Back!</h1>
            <p class="welcomSubtitle">We missed you.</p>
          </div>
          <div class="signupstep1-welcomeimage">
            <img src="./assets/images/banner_image.png" alt="Welcome-Image" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 signup-step1background">
      <!-- Large outline input -->
      <div class="container">
        <div class="clearfix">
          <div class="signup-step1-siginTitle float-right">
            <p>Don't have an account yet? <a class="signin-" href="">GET STARTED</a></p>
          </div>
        </div>
        <div class="signup-form">
          <h2 class="signuptitle">New Password</h2>

          <form [formGroup]="formGroup" (ngSubmit)=changePassword(formGroup.value)>
            <div class="md-form md-outline form-lg">
              <input type="password" id="form-lg" class="form-control" [ngClass]="{'is-invalid':(isSubmitted && formControls.password.errors) ||
                    (formGroup.controls['password'].touched && formControls.password.errors)
                }" class="form-control form-control-lg input signUpInput" formControlName="password">
              <label for="form-lg1" class="signUpLabel">Password</label>
              <div *ngIf="formControls.password.errors" class="invalid-feedback">
                <div>{{ getErrorPassword() }}</div>
              </div>
            </div>

            <div class="md-form md-outline form-lg">
              <input type="password" id="form-lg1" class="form-control" [ngClass]="{'is-invalid':(isSubmitted && formControls.confirmedPassword.errors) ||
                    (formGroup.controls['confirmedPassword'].touched && formControls.confirmedPassword.errors)
                }" class="form-control form-control-lg input signUpInput" formControlName="confirmedPassword">
              <label for="form-lg1" class="signUpLabel">Confirmed Password</label>
              <div *ngIf="formControls.confirmedPassword.errors" class="invalid-feedback">
                <div>{{ getErrorConfirmPassword() }}</div>
              </div>
            </div>
            <div align="right">
              <button type="submit"
                class="btn btn-primary btn-rounded primary-backgroundcolor signInFormBtn"><span>SUBMIT</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
