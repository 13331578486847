<div *ngIf="isMessgaeWindow">
    <i class="fa fa-spinner fa-spin" aria-hidden="false" style="left: 50%; position: absolute; top: 30%;" *ngIf="connectedUsers.length == 0 && showChatBox"></i>
    <div *ngIf="chatWindowInstanceId == 'master'" style="height:400px; overflow:scroll;">
        <div class="row chat-user" *ngFor="let user of connectedUsers | arraySortPipe" style="cursor:pointer;margin-left: 13px;" (click)="openMsgBox(user)">
            <div class="col-md-2">
                <img [src]=user.pic?(s3PhotoHost+user.pic):apiCall.getInitials(user.userName) class="chatbox-image" alt="Picture">
            </div>
            <div class="col-md-10">
                <div class="chatbox-name"><strong>{{user.userName}}</strong><label [class]="user.status?(user.status == 1 ?'user-chatbox-online-list':(user.status == 2?'user-chatbox-away-list':'user-chatbox-offline-list')):'user-chatbox-offline-list'"></label><span class="chat-date">{{user.lastChatTime}}</span>
                    <span
                        style="position: absolute; right: 100px; top: -6px;" class="badge badge-light float-left manage-request-number" *ngIf="user.unreadMessageCount">{{user.unreadMessageCount}}</span>
                </div>
            </div>
            <div class="row" style="width:100%">
                <div class="col-md-2">&nbsp;</div>
                <div class="col-md-10">
                    <div class="chatbox-user-contain" style="margin-top: -20px; margin-left: 16px;">{{user.lastChatFromId == authService.getUserId()?'You: ':''}} {{user.lastChatMessage && user.lastChatMessage.length > 40?(user.lastChatMessage | slice:0:40)+'...':user.lastChatMessage}} </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-7 col-lg-7 chatbox-list" style="margin-left: 0px;position: absolute;top: 57px;" *ngIf="chatWindowInstanceId != 'master'">
        <div class="scroll-chat" style="max-height: 285px;width: 335px;height: 284px; overflow:scroll;" #scrollMe>
            <i class="fa fa-spinner fa-spin" aria-hidden="false" style="left: 75%; position: absolute; top: 30%;" *ngIf="showLoader"></i>

            <div *ngFor="let msg of msgArray[toUserId]">
                <div class="d-flex justify-content-start mb-4" *ngIf="msg.fromUserId != authService.getUserId()">
                    <div class="img_cont_msg">
                        <img [src]=toUserPic?(s3PhotoHost+toUserPic):apiCall.getInitials(toUserName) class="rounded-circle user_img_msg">
                    </div>
                    <div class="msg_cotainer">
                        {{msg.message}}
                        <span class="msg_time">{{msg.time}}</span>
                    </div>

                </div>
                <div class="d-flex justify-content-end mb-4" *ngIf="msg.fromUserId === authService.getUserId()">
                    <div class="msg_cotainer_send">
                        {{msg.message}}
                        <span class="msg_time_send">{{msg.time}}</span>
                    </div>
                    <div class="img_cont_msg">
                        <img [src]=authService.getUserObj().userDPUrl?(s3PhotoHost+authService.getUserObj().userDPUrl):apiCall.getInitials(authService.getFullName()) class="rounded-circle user_img_msg">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-start mb-4" *ngIf="userTypingStatus">
                <div class="img_cont_msg">
                    <img src="{{s3PhotoHost}}{{toUserPic}}" class="rounded-circle user_img_msg">
                </div>
                <div class="msg_cotainer">
                    Typing...
                </div>
            </div>

        </div>
        <div class="row">
            <div class="chat-user-box" style="position:fixed;width: 250px;height: 56px;margin-top: 1px; margin-left: 22px;">
                <input type="text" class="basic-input" [(ngModel)]="userMsg" placeholder="Type here.." style="position: fixed; margin-left: 2px; width: 245px;" (keyup.enter)="onEnter()" (keydown)="onKeydown($event)" />
            </div>
        </div>
    </div>



</div>

<section class="chatbox-banner" *ngIf="!isMessgaeWindow">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-lg-4 chatbox-list">
                <div class="clearfix">
                    <div class="message">Message</div>
                    <div class="input-group">
                        <input type="text" placeholder="Search..." name="" class="form-control search">
                        <div class="input-group-prepend">
                            <span class="input-group-text search_btn"><i class="fas fa-search"></i></span>
                        </div>
                    </div>
                </div>
                <div class="break-line"></div>
                <div class="scroll-user" style="height:400px; overflow:scroll;">
                    <i class="fa fa-spinner fa-spin" aria-hidden="false" style="left: 50%; position: absolute; top: 30%;" *ngIf="connectedUsers.length == 0"></i>
                    <div class="row chat-user" *ngFor="let user of connectedUsers | arraySortPipe" style="cursor:pointer" (click)="openChatBox(user)" [style]="toUserId == user.userId ? 'background: aliceblue;':''">

                        <div class="col-md-2 col-lg-2">
                            <img [src]=user.pic?(s3PhotoHost+user.pic):apiCall.getInitials(user.userName) class="chatbox-image" alt="Picture">
                        </div>
                        <div class="col-md-10 col-lg-10">
                            <div class="chatbox-name"><strong>{{user.userName}}</strong><label [class]="user.status?(user.status == 1 ?'user-chatbox-online-list':(user.status == 2?'user-chatbox-away-list':'user-chatbox-offline-list')):'user-chatbox-offline-list'"></label>
                                <span class="chat-date">{{user.lastChatTime }}</span><span style="position: absolute; right: 100px; top: -6px;" class="badge badge-light float-left manage-request-number" *ngIf="user.unreadMessageCount">{{user.unreadMessageCount}}</span></div>
                            <div class="chatbox-user-contain">{{user.lastChatFromId == authService.getUserId()?'You: ':''}} {{user.lastChatMessage && user.lastChatMessage.length > 40?(user.lastChatMessage | slice:0:40)+'...':user.lastChatMessage}} </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-7 col-lg-7 chatbox-list" *ngIf="showChatBox">
                <div class="message-history">Message history with <span>{{toUserName}}</span><img src="./assets/images/dots.svg" alt="more"></div>
                <div class="break-line"></div>
                <div class="scroll-chat" style="height:327px; overflow:scroll;" #scrollMe>
                    <i class="fa fa-spinner fa-spin" aria-hidden="false" style="left: 50%; position: absolute; top: 30%;" *ngIf="showLoader"></i>
                    <div *ngFor="let msg of msgArray[toUserId]">
                        <div class="d-flex justify-content-start mb-4" *ngIf="msg.fromUserId != authService.getUserId()">
                            <div class="img_cont_msg">
                                <img [src]=toUserPic?(s3PhotoHost+toUserPic):apiCall.getInitials(toUserName) class="rounded-circle user_img_msg">
                            </div>
                            <div class="msg_cotainer">
                                {{msg.message}}
                                <span class="msg_time">{{msg.time}}</span>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end mb-4" *ngIf="msg.fromUserId === authService.getUserId()">
                            <div class="msg_cotainer_send">
                                {{msg.message}}
                                <span class="msg_time_send">{{msg.time}} </span>
                            </div>
                            <div class="img_cont_msg">
                                <img [src]=authService.getUserObj().userDPUrl?(s3PhotoHost+authService.getUserObj().userDPUrl):apiCall.getInitials(authService.getFullName()) class="rounded-circle user_img_msg">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start mb-4" *ngIf="userTypingStatus">
                        <div class="img_cont_msg">
                            <img src="{{s3PhotoHost}}{{toUserPic}}" class="rounded-circle user_img_msg">
                        </div>
                        <div class="msg_cotainer">
                            Typing...
                        </div>
                    </div>

                </div>
                <div class="row chatRow">
                    <div class="chat-user-box">
                        <input type="text" class="basic-input" [(ngModel)]="userMsg" placeholder="Type here.." (keyup.enter)="onEnter()" (keydown)="onKeydown($event)" />
                        <a class="btn btn-white-border btn-rounded with-icon sendBtn"><span (click)="sendPrivateMessage()" >SEND</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>