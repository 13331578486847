import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { NetworkModuleConstants } from 'src/app/constants/globalConstants';
import { RouterConstants } from 'src/app/constants/router.contants';
import { ActivityModel } from 'src/app/models/NetworkModel';
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { AlertBarService } from 'src/app/services/alert-bar.service';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { VideoMaster } from '../network/network.component';

@Component({
  selector: 'app-saved-posts',
  templateUrl: './saved-posts.component.html',
  styleUrls: ['./saved-posts.component.scss']
})
export class SavedPostsComponent implements OnInit {
  routerConstants = RouterConstants;
  constants = NetworkModuleConstants;
  commentOnPostFormGroup: FormGroup;
  createPostFormGroup: FormGroup;
  user: UserDetailsModel;
  environment = environment;

  loading = false;
  pageNumber = 1;
  totalPage: number;
  activityPostList: ActivityModel[];
  uploadedFiles: any;
  files: any[] = [];
  postType = null;
  postViewDefaultSet = 'Anyone';
  postView = [{ value: 'Anyone' }, { value: 'Only Followers' }];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageBase64String = '';
  cropImageIndex = null;
  uploadedImagesEvent = [];
  showImageCropContainer = false;
  postBtnLoader = false;
  videoPath = null;
  OtherUser = [];
  // initialCommentsList = 5;

  @ViewChild('videoPlayer') videoplayer: ElementRef;
  myVideos: Observable<VideoMaster[]>;

  constructor(
    private authSvc: AuthenticationService, public api: ApiCallService,
    private fb: FormBuilder, private alertbar: AlertBarService, private router: Router) { }

  ngOnInit(): void {
    this.createForm();
    window.addEventListener('scroll', this.scrollEvent, true);
    this.user = this.authSvc.getUserObj();
    this.getAllActivityPosts();
  }

  createForm() {
    this.commentOnPostFormGroup = this.fb.group({
      comment: ['', Validators.required],
    });

    this.createPostFormGroup = this.fb.group({
      postContent: ['', Validators.required],
    });
  }

  getAllActivityPosts() {
    const url = '/api/activity/' + this.pageNumber;
    this.api.getApiCall(url).subscribe(res => {
      if (res != null && this.pageNumber > 1) {
        const activityLocal = [];
        res.content.forEach(activity => {

          if (activity.activityPostedByUser.username === this.user.username) {
            activity.activityOwner = true;
          } else {
            activity.activityOwner = false;
          }
          activity.initialCommentsList = 5;
          activityLocal.push(activity);
        });
        this.activityPostList = this.activityPostList.concat(activityLocal);
      } else {
        this.activityPostList = [];
        res.content.forEach(activity => {
          // console.log(activity);
          if (activity.activityPostedByUser.username === this.user.username) {
            activity.activityOwner = true;
          } else {
            activity.activityOwner = false;
          }
          activity.initialCommentsList = 5;
          this.activityPostList.push(activity);
        });
      }
      this.totalPage = res.totalPages;
      this.pageNumber = this.pageNumber + 1;
      this.loading = false;
    }, (err) => {
      this.loading = false;
    });
  }

  scrollEvent = (event: any): void => {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    if (event.target.scrollTop >= (limit - 100) && this.totalPage >= this.pageNumber) {
      this.getAllActivityPosts();
    }
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }


  allLikedUser(activityId) {
    this.api.getApiCall('/api/activity/all-liked-user/' + activityId + '/1').subscribe(response => {
      if (response != null) {
       // console.log(response);
      }
    }, (err) => {
    });
  }

  onLike(activityId, index) {
    const url = '/api/activity/like-post?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivityLike = true;
      this.activityPostList[index].likedCounts = this.activityPostList[index].likedCounts + 1;
    }, (err) => {
      console.log(err);
    });
  }

  onUnLike(activityId, index) {
    const url = '/api/activity/undo-like-post?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivityLike = false;
      this.activityPostList[index].likedCounts = this.activityPostList[index].likedCounts - 1;
    }, (err) => {
      console.log(err);
    });
  }

  onSave(activityId, index) {
    const url = '/api/activity/save-activity?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivitySave = true;
    }, (err) => {
      console.log(err);
    });
  }

  onRemoveFromSavedCollection(activityId, index) {
    const url = '/api/activity/remove-saved-activity?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivitySave = false;
    }, (err) => {
      console.log(err);
    });
  }


  onComment(activityid, index) {
    const url = '/api/activity/post-comment';
    const jsonObj = {
      activityId: activityid,
      commentsText: this.commentOnPostFormGroup.value.comment
    };
    this.api.postApiCall(url, jsonObj).subscribe(res => {
      if (res != null) {
        this.commentOnPostFormGroup.reset();
        this.commentOnPostFormGroup.patchValue({});
        this.activityPostList[index].totalComments = this.activityPostList[index].totalComments + 1;
        if (this.activityPostList[index].totalComments > this.activityPostList[index].initialCommentsList) {
          let arr = [];
          arr = this.activityPostList[index].activityComments.reverse();
          arr.pop();
          this.activityPostList[index].activityComments = arr.reverse();
        }
        res.firstName = this.user.fname;
        res.lastName = this.user.lname;
        res.userName = this.user.username;
        this.activityPostList[index].activityComments.push(res);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onLoadNextFewComments(activityId, index) {
    this.activityPostList[index].initialCommentsList = this.activityPostList[index].initialCommentsList + 5;
    const pageNo = (this.activityPostList[index].initialCommentsList / 5);
    const url = '/api/activity/get-comments/' + activityId + '/' + pageNo;
    this.api.getApiCall(url).subscribe(res => {
      res.content.reverse().forEach(element => {
        this.activityPostList[index].activityComments.splice(0, 0, element);
      });
    }, (err) => {
      console.log(err);
    });
  }

  onDeleteActivity(activityId) {
    const url = '/api/activity/' + activityId;
    this.api.deleteApiCall(url).subscribe(res => {
      this.pageNumber = 1;
      this.getAllActivityPosts();
    }, (err) => {
      console.log(err);
    });
  }


  onSubmitPost() {
    let hashTags = null;
    if (this.createPostFormGroup.value.postContent != null) {
      hashTags = hashTags + this.createPostFormGroup.value.postContent.split(' ').filter(v => v.startsWith('#'));
      if (hashTags === 'null') {
        hashTags = null;
      }
    }
    const jsonData = {
      isAllowCommentForPost: true,
      hashtags: hashTags,
      numberOfLikes: 0,
      postText: this.createPostFormGroup.value.postContent,
      type: 1,
      postPath: null,
      status: 1
    };
    const formData: FormData = new FormData();
    if (this.uploadedFiles != null) {
      for (const fileData of this.uploadedFiles) {
        formData.append('file', fileData);
      }
    }
    this.postBtnLoader = true;
    formData.append('info', JSON.stringify(jsonData));
    this.api.postApiCall('/api/activity/post-user-activity/', formData).subscribe(res => {
      this.resetPostForm();
      this.pageNumber = 1;
      this.getAllActivityPosts();
    }, (err) => {
      console.log(err);
    });
  }

  resetPostForm() {
    this.files = [];
    this.postType = null;
    // $('#postModal').modal('hide');
    this.createPostFormGroup.reset();
    this.createPostFormGroup.patchValue({});
    this.postViewDefaultSet = 'Anyone';
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.imageBase64String = '';
    this.cropImageIndex = null;
    this.uploadedImagesEvent = [];
    this.showImageCropContainer = false;
    this.postBtnLoader = false;
  }

  onFileUpload(event, type) {
    // if (type === 'video') {
    //   this.videoPath = event;
    //   return;
    // }
    this.files = [];
    this.uploadedFiles = [];
    this.postType = null;
    this.uploadedFiles = event.target.files;
    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      const filesAmount = this.uploadedFiles.length;
      if (filesAmount < 6) {
        for (let i = 0; i < filesAmount; i++) {
          const fineName = this.uploadedFiles[i].name;
          const reader = new FileReader();
          reader.onload = (subevent: any) => {
            const json = {
              fileName: fineName,
              content: subevent.target.result,
              fileType: (type === 'image') ? 'img' : 'doc',
              fileData: this.uploadedFiles[i]
            };
            this.files.push(json);
            this.postType = (type === 'image') ? 1 : 2;
          };
          reader.readAsDataURL(this.uploadedFiles[i]);
        }
        // $('#postModal').modal('show');
      } else {
        alert('Sorry,  Max u can upload only 5 images/files');
      }
    }
  }

  removeSelectedFile(index) {
    this.showImageCropContainer = false;
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.files.splice(index, 1);
    if (this.files.length === 0) {
      this.postType = null;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropSelectedImage(index) {
    this.cropImageIndex = index;
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.imageChangedEvent = this.files[index].content;
    this.showImageCropContainer = true;

  }

  onImageCrop() {
    this.files[this.cropImageIndex].content = this.croppedImage;
    this.showImageCropContainer = false;
    this.croppedImage = '';
  }

  showToastAlert(msg) {
    this.alertbar.show(msg, {
      classname: 'bg-success text-light',
      delay: 3000,
      autohide: true
    });
  }

  viewOtherUser(userId) {
    const url = '/api/user/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: res.id }
      };
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.profileExternal], navigationExtras);
    }, (err) => {
    });

    if (userId !== this.user.id) {
      this.addNotification(userId, 'profileView');
      const url = '/api/user/save-profile-views/' + userId;
      this.api.postApiCall(url, null).subscribe(res => {
      }, (err) => {
      });
    }
  }

  addNotification(id, type) {
    const json = {
      type: type,
      createdOn: new Date(),
      read: false,
      userId: id,
      fromUserId: this.user.id,
      postId: null,
      jobId: null
    }
    const url = '/api/notifications/add-notification';
    this.api.postApiCall(url, json).subscribe((res) => {
    })
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

}
