import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';

import { RouterConstants } from 'src/app/constants/router.contants';
import { ShareSocialUserService } from 'src/app/services/share-social-user/share-social-user.service';
import { Router } from '@angular/router';
import {FormGroup, Validators, FormBuilder, FormArray} from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import {User} from '../../entities/user';
import { GlobalConstants } from './../../constants/globalConstants';
import {Observable} from 'rxjs';


// @ts-ignore
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [User]
})
export class SignUpComponent implements OnInit {
  socialUser: SocialUser;
  private usernamePassword: any;
  private passwordsValidation: any;

  constructor(private formBuilder: FormBuilder,
              private apiCallSvc: ApiCallService,
              private authService: SocialAuthService,
              private socialUserSvc: ShareSocialUserService,
              private authSvc: AuthenticationService,
              private router: Router,
              private user: User) { }

  get formControls() { return this.formGroup.controls; }
  formGroup: FormGroup;
  routerConstants = RouterConstants;
  isSubmitted = false;
  signupResponse: any = '';

  ngOnInit(): void {
    this.createForm();
    this.authSvc.clearSessionStorage();


    this.passwordsValidation = this.formGroup.controls.password.valueChanges.subscribe(
      () => {
        if (this.checkPassword(this.formGroup.controls.password)) {
          if (
            this.checkPassword(this.formGroup.controls.password)
              .requirements === true
          ) {
            this.formGroup.get('password').setErrors({ requirements: true });
            this.getErrorPassword();
          }
        }
      }
    );
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      // tslint:disable-next-line:max-line-length
      username: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]), this.checkInUseEmail],
      password: [null, [Validators.required, Validators.minLength(8)],  this.checkPassword]
    });
  }

  checkInUseEmail = control => {
    return new Observable(observer => {
      this.apiCallSvc
        .getApiCall(`/api/auth/check-for-email?email=${control.value}`)
        .subscribe(
          res => {
            return null;
          },
          err => {
            observer.next({ alreadyInUse: true });
            observer.complete();
          }
        );
    });
  }

  checkPassword = control => {
    const enteredPassword = control.value;
    const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return !passwordCheck.test(enteredPassword) && enteredPassword
      ? { requirements: true }
      : null;
  }

  checkInphoneNumber = control => {

    return new Observable(observer => {
      if (control.value.length !== 10) {
        observer.next({ invalid: true });
        observer.complete();
        return;
      }
      this.apiCallSvc
        .getApiCall(`/api/auth/check-for-phoneNumber?phoneNumber=${control.value}`)
        .subscribe(
          res => {
            return null;
          },
          err => {
            observer.next({ alreadyInUse: true });
            observer.complete();
          }
        );
    });
  }

  getErrorUsername() {
    return this.formGroup.get('username').hasError('required')
      ? 'Email or phone number is required'
      : this.formGroup.get('username').hasError('pattern')
        ? 'Not a valid email address or phone number'
        : this.formGroup.get('username').hasError('alreadyInUse')
          ? 'Email Already exists'
            : '';

  }

  getErrorPassword() {
      return this.formGroup.get('password').hasError('required')
        ? 'Password is required'
        : this.formGroup.get('password').hasError('requirements')
          ? 'Password needs to be at least eight characters, one uppercase letter and one number'
          : '';
  }



  signUp(signupData): string {
    this.isSubmitted = true;
    // Adding this step to handle form pending status issue
    for (const el in this.formGroup.controls) {
      if (this.formGroup.controls[el].errors) {
        return;
      }
    }
    sessionStorage.setItem('username' , this.formGroup.get('username').value);
    sessionStorage.setItem('password' , this.formGroup.get('password').value);

    this.router.navigate([this.routerConstants.signup]);
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      this.socialUserSvc.setSocialUser(x);
     // console.log(this.socialUserSvc.getSocialUser());
      this.router.navigate([this.routerConstants.gSignUp]);
    });
   // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    //this.authService.authState.subscribe((sUser) => {
      // this.socialUser = sUser;
      //this.socialUserSvc.setSocialUser(sUser);
      // console.log(sUser);
      // this.router.navigate([this.routerConstants.gSignUp]);
  // });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

}
