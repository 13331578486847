import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraySortPipe',
  pure: false
})
export class ArraySortPipePipe implements PipeTransform {

  transform(value: any) {
    value.sort((a: any, b: any) => {
      if(!a.lastChatTime){
        return 1;
      }else if(!b.lastChatTime){
        return -1;
      }
      return <any>new Date(b.lastChatTime) - <any>new Date(a.lastChatTime)
    });
    return value;
}

}
