<!-- step 2 -->

<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-6">
      <div class="container">
        <div class="signupstep1">
          <img src="./assets/images/logo.png" />
          <div class="signupstep1-welcome">
            <h1 class="welcomeTitle">Welcome!</h1>
            <p class="one-step">You are only a few steps away from finding your dream job!</p>
          </div>
          <div class="signupstep1-welcomeimage">
            <img src="./assets/images/welcomeimage.png" alt="Welcome-Image" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 signup-step1background">
      <!-- Large outline input -->

      <div class="container">
        <div class="clearfix">
          <div class="signup-step1-siginTitle float-right">
            <p>Already have an account? <a class="signin-" href="">SIGN IN</a></p>
          </div>
        </div>
        <div class="signup-form">
          <h2 class="signuptitle">Sign Up</h2>

          <form [formGroup]="formGroup" (ngSubmit)="signup(formGroup.value)">
            <div class="md-form md-outline form-lg">
              <input type="text" maxlength="12" formControlName="firstname" [ngClass]="{'is-invalid':(isSubmitted && formControls.firstname.errors) ||
                    (formGroup.controls['firstname'].touched && formControls.firstname.errors)
                }" class="form-control form-control-lg input signUpInput" placeholder="First Name"
                     [ngClass]="{ 'is-invalid': isSubmitted && formControls.firstname.errors }" />
              <div *ngIf="isSubmitted && formControls.firstname.errors" class="invalid-feedback">
                <div *ngIf="formControls.firstname.errors.required">
                  First name required
                </div>
              </div>
            </div>
            <div class="md-form md-outline form-lg" style="margin-bottom: 16px;">
              <input type="text" maxlength="12" formControlName="lastname" [ngClass]="{'is-invalid':(isSubmitted && formControls.lastname.errors) ||
                    (formGroup.controls['lastname'].touched && formControls.lastname.errors)
                }"  class="form-control form-control-lg input signUpInput" placeholder="Last Name" />
              <div *ngIf="isSubmitted && formControls.lastname.errors" class="invalid-feedback">
                <div *ngIf="formControls.lastname.errors.required">
                 Last name required
                </div>
              </div>
            </div>
            <div class="md-form md-outline form-lg" style="margin-bottom: 16px;">
              <input type="text" maxlength="11" minlength="9"
              (keypress)="validateNo($event)" formControlName="phoneNumber" [ngClass]="{'is-invalid':(isSubmitted && formControls.phoneNumber.errors) ||
                    (formGroup.controls['phoneNumber'].touched && formControls.phoneNumber.errors)
                }"  class="form-control form-control-lg input signUpInput" placeholder="phone Number" />
              <div *ngIf="isSubmitted && formControls.phoneNumber.errors" class="invalid-feedback">
                <div *ngIf="formControls.phoneNumber.errors.required">
                  Phone Number required
                </div>
              </div>
              <div class="invalid-feedback" style="display: block">
                {{signupResponse}}
              </div>
            </div>
            <div align="right">
              <button type="submit"
                class="btn btn-primary btn-rounded primary-backgroundcolor continueBtn"><span style="margin-top: 0 !important;"> Continue <i *ngIf="loading" class="fa fa-spinner fa-spin" aria-hidden="false"></i></span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
