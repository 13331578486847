<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 col-lg-6">
            <div class="container">
                <div class="signupstep1">
                    <app-logo></app-logo>
                    <div class="signupstep1-welcome">
                        <h1 class="welcomeTitle">Welcome Back!</h1>
                        <p class="welcomSubtitle">We missed you.</p>
                    </div>
                    <div class="signupstep1-welcomeimage">
                        <img src="./assets/images/banner_image.png" alt="Welcome-Image" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-6 signup-step1background">
            <!-- Large outline input -->
            <div class="container">
                <div class="clearfix">
                    <div class="signup-step1-siginTitle float-right">
                        <p>Don't have an account yet? <a class="signin-" routerLink="/{{routerConstants.signUp}}" routerLinkActive="active">GET STARTED</a></p>
                    </div>
                </div>
                <div class="signup-form">
                    <h2 class="signuptitle">Sign In</h2>
                    <!-- <p *ngIf="loginResponse" class="has-error">{{loginResponse}}</p> -->
                    <div class="has-error1"  *ngIf="isSubmitted && (customError || formControls.username.errors)">
                        <img class="has-error1-icon" src="assets/images/icons_error.png" />
                        <span class="has-error-text">Username or Password is incorrect</span>
                    </div>
                    <form [formGroup]="formGroup" (ngSubmit)="login(formGroup.value)">
                        <div class="md-form md-outline form-lg">
                            <input id="form-lg" [ngClass]="{ 'is-invalid': isSubmitted && formControls.username.errors }" class="form-control form-control-lg input signUpInput" type="text" formControlName="username">
                            <label for="form-lg" class="signUpLabel">Email or phone number</label>
                            <div *ngIf="isSubmitted && formControls.username.errors" class="invalid-feedback">
                                <div *ngIf="formControls.username.errors.required">Email is required</div>
                                <div *ngIf="formControls.username.errors.email">Not a valid email address or phone number</div>
                            </div>
                        </div>
                        <div class="md-form md-outline form-lg">
                            <input id="form-lg1" [ngClass]="{ 'is-invalid': isSubmitted && formControls.password.errors }" class="form-control form-control-lg input signUpInput" type="password" formControlName="password">
                            <label for="form-lg1" class="signUpLabel">Password</label>
                            <div *ngIf="isSubmitted && formControls.password.errors" class="invalid-feedback">
                                <div *ngIf="formControls.password.errors.required">Password is required</div>
                                <!-- <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div> -->
                            </div>
                        </div>
                        <!-- <div class="md-form md-outline form-lg">
              <input type="checkbox" formControlName="rememberMe" />
              <label class="signUpLabel">Remember me</label>
            </div> -->
                        <div align="right">
                            <a class="signin-forgotPasswordText" routerLink="/{{routerConstants.forgotPassword}}" routerLinkActive="active">Forgot Password?</a>
                            <button type="submit" class="btn btn-primary btn-rounded primary-backgroundcolor signInFormBtn"><span>SIGN IN <i *ngIf="loading" class="fa fa-spinner fa-spin" aria-hidden="false"></i></span></button>
                        </div>
                    </form>
                    <div>

                        <h4 class="or"><span>Or</span></h4>
                        <a  (click)="signInWithGoogle()" routerLinkActive="active" class="btn btn-light btn-rounded signUpGoogleBtn">
                            <div class="clearfix siginInGoogleBtn">
                                <img class="float-left" src="assets/images/logo_google.png" />
                                <span class="float-left">Sign In with Google</span>
                            </div>
                            <!-- <img style="height: 25.09px;width: 174px;" src="./assets/images/siginwithgoogle.png" /> Something -->
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
