import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationBarComponent } from './shared-components/navigation-bar/navigation-bar.component';
import { FooterComponent } from './shared-components/footer/footer.component';
import { LandingComponent } from './core-components/landing/landing.component';
import { SignInComponent } from './core-components/sign-in/sign-in.component';
import { SignUpComponent } from './core-components/sign-up/sign-up.component';
import { GSignUpComponent } from './core-components/g-sign-up/g-sign-up.component';
import { ChangePasswordComponent } from './core-components/change-password/change-password.component';
import { ForgotPasswordComponent } from './core-components/forgot-password/forgot-password.component';
import { JobListComponent } from './core-components/job-list/job-list.component';
import { NetworkComponent } from './core-components/network/network.component';
import { NavigationBarUsersComponent } from './shared-components/navigation-bar-users/navigation-bar-users.component';
import { UserProfileCardComponent } from './shared-components/user-profile-card/user-profile-card.component';
import { UserComponent } from './core-components/user/user.component';
import { ManageComponent } from './core-components/manage/manage.component';
import { PostJobComponent } from './core-components/post-job/post-job.component';
import { NotificationsComponent } from './core-components/notifications/notifications.component';
import { MessagesComponent } from './core-components/messages/messages.component';
import { UserDetailsComponent } from './core-components/user-details/user-details.component';
import { LogoComponent } from './shared-components/logo/logo.component';
import { HttpInterceptorService } from './services/interceptor-svc/http-interceptor.service';
import { ErrorComponent } from './core-components/error/error.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SignupComponent } from './core-components/signup/signup.component';
import { ScrollEventModule } from 'ngx-scroll-event';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProfileExternalComponent } from './core-components/profile-external/profile-external.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { JobDetailsComponent } from './core-components/job-details/job-details.component';
import { SetupProfileComponent } from './core-components/setup-profile/setup-profile.component';
import { SearchResultComponent } from './core-components/search-result/search-result.component';
import { ChatboxComponent } from './core-components/chatbox/chatbox.component';
import { TagInputModule } from 'ngx-chips';
import { ShortListedComponent } from './core-components/short-listed/short-listed.component';
import { AppliedJobsComponent } from './core-components/applied-jobs/applied-jobs.component';
import { SavedPostsComponent } from './core-components/saved-posts/saved-posts.component';
import { YourPostsComponent } from './core-components/your-posts/your-posts.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {SocketService} from './services/socket-service/socket.service'
// import { DialogProgressComponent } from './utils/dialog-progress/dialog-progress.component';
import {ToastrModule} from "ngx-toastr";
import { ArraySortPipePipe } from './pipes/array-sort-pipe.pipe';
import { ActivityPostDetailsComponent } from './core-components/activity-post-details/activity-post-details.component';
import { VideoboxComponent } from './core-components/videobox/videobox/videobox.component';
import { AddBoxComponent } from './core-components/add-box/add-box.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    FooterComponent,
    LandingComponent,
    SignInComponent,
    SignUpComponent,
    GSignUpComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    JobListComponent,
    NetworkComponent,
    NavigationBarUsersComponent,
    UserProfileCardComponent,
    UserComponent,
    ManageComponent,
    PostJobComponent,
    NotificationsComponent,
    MessagesComponent,
    UserDetailsComponent,
    LogoComponent,
    ErrorComponent,
    DateAgoPipe,
    SignupComponent,
    ProfileExternalComponent,
    JobDetailsComponent,
    SetupProfileComponent,
    SearchResultComponent,
    ChatboxComponent,
    ShortListedComponent,
    AppliedJobsComponent,
    SavedPostsComponent,
    YourPostsComponent,
    ArraySortPipePipe,
    ActivityPostDetailsComponent,
    VideoboxComponent,
    AddBoxComponent,
    // DialogProgressComponent,
  ],
  imports: [
    ScrollEventModule,
    ImageCropperModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SocialLoginModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    NgxSpinnerModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    ToastrModule.forRoot(),
    TagInputModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    CarouselModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  
  providers: [SocketService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('1093510456944-0njiu4jgsq8q3ips8edpvoftbglv9a37.apps.googleusercontent.com'),
          },
          {
            // for FB login for future use if required
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


