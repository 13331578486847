<section class="profile-detailedInfo">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-lg-9">
        <div class="card notificationCard" style="margin-top: 48px;">
          <div class="card-body">
            <div class="clearfix">
              <div class="notification-aboutTitle float-left">Notifications</div>
            </div>
            <div *ngIf="loader" align="center">
              <i class="fa
                fa-spinner fa-spin" aria-hidden="true"></i>
            </div>

            <div *ngIf="newNotifications.length === 0">
              No data found
            </div>

            <div *ngFor="let
              notification of newNotifications; let i= index">
              <!-- activity posted notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'activityPosted'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Posted new
                      Activity</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- saveActivityPost notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'savePost'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #F3CA3E !important;"><img src="./assets/images/save.png"
                      class="notification-icon" alt="" /></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Saved your
                      post</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- like post notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'PostLiked'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #FF7396 !important;"><img src="./assets/images/like.png"
                      class="notification-icon" alt="" /></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Liked your
                      post</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- comment on post notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'commentOnPost'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Commented on your
                      post</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- shared post notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'sharedPost'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #96F2A3 !important;"><img src="./assets/images/share.png"
                      class="notification-icon" alt="" /></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Shared your
                      post</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- profile view notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'profileView'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #96F2A3 !important;"></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewOtherUser(notification.userId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Viewed your profile</small>
                  </div>
                  <div class="notification-designation">{{notification.userTitle}}</div>
                  <a routerLink="/{{routerConstants.user}}/{{routerConstants.manage}}" class="btn btn-blue-border
                    btn-sm btn-rounded
                    with-icon notificationHappyCompleteBtn"><span>See all views
                    </span></a>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- birth day notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'BirthDay'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewOtherUser(notification.userId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}'s
                    Birthday
                    today!</div>
                  <div class="notification-designation">18 Aug</div>
                  <a class="btn btn-blue-border btn-sm btn-rounded
                    with-icon notificationHappyCompleteBtn" data-toggle="modal" data-target="#wishHappyBirthDayModal"
                    (click)="birthDayWishToUserId = notification.userId"><span>Wish Happy
                      Birthday
                    </span></a>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- job shortlisted-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'jobShortlisted'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #FF7396 !important;"><img src="./assets/images/love.png"
                      class="notification-icon" alt="" /></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                    'jobsPosted')">{{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Shortlisted your job</small>
                  </div>
                  <div class="notification-designation">{{notification.userTitle}}</div>
                  <div class="notification-name">{{notification.jobTitle}},
                    &nbsp;
                    <small class="view-job cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                      'jobsPosted')">View Job</small>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- job applied-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'jobShortlisted'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                    'jobsPosted')">{{notification.firstName}}&nbsp;{{notification.lastName}}
                  </div>
                  <div class="notification-designation">{{notification.userTitle}}</div>
                  <div class="notification-name">{{notification.jobTitle}},
                    &nbsp;
                    <small class="view-job cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                      'jobsPosted')">View Job</small> &nbsp;&nbsp;<i class="job-applied">applied</i>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- job posted-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'jobPosted'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                    'jobDetails')">{{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Posted job</small>
                  </div>
                  <div class="notification-designation">{{notification.userTitle}}</div>
                  <div class="notification-name">{{notification.jobTitle}},
                    &nbsp;
                    <small class="view-job cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                      'jobDetails')">View Job</small>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- follow request-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'followRequest'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewOtherUser(notification.userId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Sent follow request</small>
                  </div>
                  <div style="margin-top: 10px;" *ngIf="notification.currentFollowStatus === 'Pending'">
                    <span class="notification-decline-button cursor_pointer"
                      (click)="decline(notification.userId, i)">DECLINE</span>
                    <span> <a class="btn btn-blue-border btn-sm
                        btn-rounded with-icon"><span class="notification-accept-button"
                          (click)="accept(notification.userId, i)">ACCEPT</span></a>
                    </span>
                  </div>
                  <div style="margin-top: 10px;" *ngIf="notification.currentFollowStatus === 'Accepted'">
                    <i class="job-applied">Accepted</i>
                  </div>

                  <div style="margin-top: 10px;" *ngIf="notification.currentFollowStatus === null">
                    <i class="request-declined">Declined</i>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- accepted/ decline follow request-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'acceptedFollowRequest'
                || notification.notificationType === 'cancelFollowerRequest'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewOtherUser(notification.userId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small *ngIf="notification.notificationType ===
                      'acceptedFollowRequest'">Accepted your follow request</small>
                    <small *ngIf="notification.notificationType ===
                      'cancelFollowerRequest'">Declined your follow request</small>
                  </div>
                  <div style="margin-top: 10px;" *ngIf="notification.notificationType ===
                    'acceptedFollowRequest'">
                    <i class="job-applied">Accepted</i>
                  </div>

                  <div style="margin-top: 10px;" *ngIf="notification.notificationType ===
                    'cancelFollowerRequest'">
                    <i class="request-declined">Declined</i>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <app-add-box></app-add-box>
      </div>
    </div>
  </div>
</section>

<section class="profile-detailedInfo" *ngIf="earlierNotifications.length> 0">
  <div class="container" style="margin-bottom:50px;">
    <div class="row">
      <div class="col-md-9 col-lg-9">
        <div class="card notificationCard">
          <div class="card-body">
            <h3 class="notification-aboutTitle-earlier">Earlier</h3>

            <div *ngFor="let
              notification of earlierNotifications; let i= index">

              <!-- activity posted notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'activityPosted'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Posted new
                      Activity</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- saveActivityPost notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'savePost'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #F3CA3E !important;"><img src="./assets/images/save.png"
                      class="notification-icon" alt="" /></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Saved your
                      post</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- like post notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'PostLiked'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #FF7396 !important;"><img src="./assets/images/like.png"
                      class="notification-icon" alt="" /></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Liked your
                      post</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- comment on post notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'commentOnPost'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Commented on your
                      post</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- shared post notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'sharedPost'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #96F2A3 !important;"><img src="./assets/images/share.png"
                      class="notification-icon" alt="" /></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewActivityPost(notification.activityId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Shared your
                      post</small>
                  </div>
                  <div class="notification-designation cursor_pointer"
                    (click)="viewActivityPost(notification.activityId)">{{notification.postText}}</div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- profile view notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'profileView'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #96F2A3 !important;"></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewOtherUser(notification.userId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Viewed your profile</small>
                  </div>
                  <div class="notification-designation">{{notification.userTitle}}</div>
                  <a href="#" class="btn btn-blue-border btn-sm btn-rounded
                    with-icon notificationHappyCompleteBtn"><span>See all views
                    </span></a>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- birth day notification -->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'BirthDay'">
                <div class="col-md-1 col-lg-1">
                  <img [src]="environment.userPhotoHost+notification.userPhotoPath" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewOtherUser(notification.userId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}'s
                    Birthday
                    today!</div>
                  <div class="notification-designation">18 Aug</div>
                  <a class="btn btn-blue-border btn-sm btn-rounded
                    with-icon notificationHappyCompleteBtn" data-toggle="modal" data-target="#wishHappyBirthDayModal"
                    (click)="birthDayWishToUserId = notification.userId"><span>Wish Happy
                      Birthday
                    </span></a>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- job shortlisted-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'jobShortlisted'">
                <div class="col-md-1 col-lg-1">
                  <img src="./assets/images/request4.png" class="notification-image" alt="" />
                  <span class="badge badge-light notification-image-icon"
                    style="background-color: #FF7396 !important;"><img src="./assets/images/love.png"
                      class="notification-icon" alt="" /></span>
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                    'jobsPosted')">{{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Shortlisted your job</small>
                  </div>
                  <div class="notification-designation">{{notification.userTitle}}</div>
                  <div class="notification-name">{{notification.jobTitle}},
                    &nbsp;
                    <small class="view-job cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                      'jobsPosted')">View Job</small>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- job applied-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'jobShortlisted'">
                <div class="col-md-1 col-lg-1">
                  <img src="./assets/images/request4.png" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                    'jobsPosted')">{{notification.firstName}}&nbsp;{{notification.lastName}}
                  </div>
                  <div class="notification-designation">{{notification.userTitle}}</div>
                  <div class="notification-name">{{notification.jobTitle}},
                    &nbsp;
                    <small class="view-job cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                      'jobsPosted')">View Job</small> &nbsp;&nbsp;<i class="job-applied">applied</i>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- job posted-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'jobPosted'">
                <div class="col-md-1 col-lg-1">
                  <img src="./assets/images/request4.png" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                    'jobDetails')">{{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Posted job</small>
                  </div>
                  <div class="notification-designation">{{notification.userTitle}}</div>
                  <div class="notification-name">{{notification.jobTitle}},
                    &nbsp;
                    <small class="view-job cursor_pointer" (click)="onViewJobDetails(notification.jobId,
                      'jobDetails')">View Job</small>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- follow request-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'followRequest'">
                <div class="col-md-1 col-lg-1">
                  <img src="./assets/images/request4.png" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewOtherUser(notification.userId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small>Sent follow request</small>
                  </div>
                  <div style="margin-top: 10px;" *ngIf="notification.currentFollowStatus === 'Pending'">
                    <span class="notification-decline-button cursor_pointer"
                      (click)="decline(notification.userId, i)">DECLINE</span>
                    <span> <a class="btn btn-blue-border btn-sm
                        btn-rounded with-icon"><span class="notification-accept-button"
                          (click)="accept(notification.userId, i)">ACCEPT</span></a>
                    </span>
                  </div>
                  <div style="margin-top: 10px;" *ngIf="notification.currentFollowStatus === 'Accepted'">
                    <i class="job-applied">Accepted</i>
                  </div>

                  <div style="margin-top: 10px;" *ngIf="notification.currentFollowStatus === null">
                    <i class="request-declined">Declined</i>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

              <!-- accepted/ decline follow request-->
              <div class="row" style="margin-bottom:50px;" *ngIf="notification.notificationType === 'acceptedFollowRequest'
                || notification.notificationType === 'cancelFollowerRequest'">
                <div class="col-md-1 col-lg-1">
                  <img src="./assets/images/request4.png" class="notification-image" alt="" />
                </div>
                <div class="col-md-8 col-lg-8 notification-details">
                  <div class="notification-name cursor_pointer" (click)="viewOtherUser(notification.userId)">
                    {{notification.firstName}}&nbsp;{{notification.lastName}}
                    <small *ngIf="notification.notificationType ===
                      'acceptedFollowRequest'">Accepted your follow request</small>
                    <small *ngIf="notification.notificationType ===
                      'cancelFollowerRequest'">Declined your follow request</small>
                  </div>
                  <div style="margin-top: 10px;" *ngIf="notification.notificationType ===
                    'acceptedFollowRequest'">
                    <i class="job-applied">Accepted</i>
                  </div>

                  <div style="margin-top: 10px;" *ngIf="notification.notificationType ===
                    'cancelFollowerRequest'">
                    <i class="request-declined">Declined</i>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2">
                  <div class="notification-day">{{notification.createdOn |
                    dateAgo}}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<!-- Wish Happy birthday popup -->
<div class="modal fade" id="wishHappyBirthDayModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title letspost" id="exampleModalLabel">Wish Happy B'day
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img style="height:
              24px;" src="assets/images/times.png" alt="" /></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modalWriteInput">
          <form [formGroup]="createBirthDayWishFormGroup">
            <textarea placeholder="Happy Birthday!" class="share-modal-textarea"
              formControlName="birthDayWishContent"></textarea>
          </form>
        </div>
      </div>
      <div class="modal-footer postModalFooter">
        <div class="clearfix">
          <button class="btn btn-primary
            btn-rounded
            modalPostBtn
            float-right" [disabled]="!createBirthDayWishFormGroup.valid" (click)="onBirthDayWish()">Post</button>
        </div>
      </div>
    </div>
  </div>
</div>