<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-6">
      <div class="container">
        <div class="signupstep1">
          <app-logo></app-logo>
          <div class="signupstep1-welcome">
            <h1 class="welcomeTitle">Welcome!</h1>
            <p class="one-step">You are Just one step away from finding your dream job!</p>
          </div>
          <div class="signupstep1-welcomeimage">
            <img src="./assets/images/welcomeimage.png" alt="Welcome-Image" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 signup-step1background">
      <!-- Large outline input -->
      <div class="container">
        <div class="clearfix">
          <div class="signup-step1-siginTitle float-right">
            <p>Already have an account? <a class="signin-" href="">SIGN IN</a></p>
          </div>
        </div>
        <div class="signup-form">
          <div class="clearfix">
            <img class="logo-google float-left" src="assets/images/logo_google.png" />
            <h2 class="signuptitle">Sign Up with Google</h2>
          </div>
          <form #form = "ngForm" (ngSubmit) = "gSignIn(form.value)">
<!--             {{user?.email}}-->
            <div class="md-form md-outline form-lg">
              <input id="form-lg1" class="form-control form-control-lg input signUpInput"
              type="text" [(ngModel)]="user.email" name="email" id="email" readonly>
<!--               <label for="form-lg" class="signUpLabel">Email</label>-->
            </div>
            <div class="md-form md-outline form-lg">
              <input id="form-lg2" class="form-control form-control-lg input signUpInput" type="text"
              [(ngModel)]="user.firstName" name="firstName" id="firstName" readonly>
<!--               <label for="form-lg" class="signUpLabel">First Name</label>-->
            </div>
            <div class="md-form md-outline form-lg" style="margin-bottom: 16px;">
              <input id="form-lg3" class="form-control form-control-lg input signUpInput" type="text"
              [(ngModel)]="user.lastName" name="lastName" id="lastName" readonly>
<!--               <label for="form-lg1" class="signUpLabel">Last Name</label>-->
            </div>
            <p class="by-clicking-agree">By clicking Agree & Continue, you agree to the Happy Connect <a href="#">User
                Agreement, Privacy Policy,</a> and <a href="#">Cookie Policy</a>.</p>

            <div align="right">
              <button type="submit"
                class="btn btn-primary btn-rounded primary-backgroundcolor agreeContinueBtn"><span>Agree &
                  Continue</span></button>
            </div>
          </form>
          <div>

            <h4 class="or"><span>Or</span></h4>
            <a class="btn btn-light btn-rounded signUpGoogleBtn">
              <img style="height: 25.09px;width: 174px;" src="./assets/images/google.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
