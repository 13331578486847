import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import { environment } from 'src/environments/environment';
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { JobModel } from 'src/app/models/jobModel';
import { RouterConstants } from 'src/app/constants/router.contants';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { JobsModuleConstants } from '../../constants/globalConstants';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {

  user: UserDetailsModel;
  environment = environment;
  routerConst = RouterConstants;
  jobConst = JobsModuleConstants;
  jobPostList: JobModel[];

  pageNumber = 1;
  totalPage: number;
  loading = false;

  searchformGroup: FormGroup;
  advancesearchformGroup: FormGroup;
  jobDesignation: any;
  industry: any;
  jobCategory: any;
  jobSkills: any[];
  jobLocations: any;
  companiesList: any;

  dropdownList = [];
  selectedLocations: any = [];
  selectedCompany: any = [];
  selectedIndusrty: any = [];
  locdropdownSettings: IDropdownSettings;
  companydropdownSettings: IDropdownSettings;
  industrydropdownSettings: IDropdownSettings;

  topJobsLocations = [];
  topJobsCompanies = [];
  topJobsIndustries = [];
  topJobsByExperiance = [];
  topJobsBySalaryRange = [];
  topJobsByPostedDate = [];

  locationFilterValue= [];
  companyFilterValue = [];
  industryFilterValue = [];
  experianceFilterValue = null;
  datePostedFilterValue = null;
  salaryRangeFilterValue = null;

  searchKey = null;

  constructor(
    private authSvc: AuthenticationService,
    private api: ApiCallService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.user = this.authSvc.getUserObj();
    window.addEventListener('scroll', this.scrollEvent, true);
    this.locdropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'cityAndTotalJobs',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.companydropdownSettings = {
      singleSelection: false,
      idField: 'companyName',
      textField: 'companyAndTotalJobs',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.industrydropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'industryAndTotalJobs',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };



    this.getAllJobs(false);
    this.createForm();
    this.createAdvanceForm();
    // this.getDesignations();

    this.getTopJobsLocations();
    this.getTopJobsCompanies();
    this.getTopJobsIndustries();
    this.getTopJobsByExperience();
    this.getTopJobsBySalaryRange();
    this.getTopJobsByPostedDate();

    this.getAllLocations();
    this.getAllCompanies();
    this.getAllIndustries();
  }

  createForm() {
    this.searchformGroup = this.fb.group({
      searchKey: [Validators.required],
      advanceSearch: [this.createAdvanceForm()]
    });
  }

  createAdvanceForm() {
    this.advancesearchformGroup = this.fb.group({
      Locations: [this.selectedLocations],
      companies: [this.selectedCompany],
      Indusrties: [this.selectedIndusrty]
    });
  }

  getDesignations() {
    this.api.getApiCall(`/api/util/get-designation/all`).subscribe(res => {
      this.jobDesignation = res;
    });
  }

  getJobCategories() {
    this.api.getApiCall(`/api/job-category/`).subscribe(res => {
      this.jobCategory = res;
    });
  }

  getJobSkils() {
    this.api.getApiCall(`/api/skill-set/`).subscribe(res => {
      this.jobSkills = res;
    });
  }

  // findJobs() {
  //   const findjobs = this.searchformGroup.value;
  //   const url = '/api/feed/get-all-newjobs/' + this.pageNumber;
  //   this.api.postApiCall(url, findjobs).subscribe(res => {
  //     if (res != null && this.pageNumber > 1) {
  //       this.jobPostList = this.jobPostList.concat(res.content);
  //       this.totalPage = res.totalPages;
  //       this.pageNumber = this.pageNumber + 1;
  //       this.loading = false;
  //     } else {
  //       this.jobPostList = res.content;
  //       this.totalPage = res.totalPages;
  //       this.pageNumber = this.pageNumber + 1;
  //       this.loading = false;
  //     }
  //   }, (err) => {
  //     this.loading = false;
  //   });

  // }


  get fval() {
    return this.searchformGroup.controls;
  }

  getAllJobs(flag) {
    if (this.advancesearchformGroup != undefined) {
      this.advancesearchformGroup.get('Locations').value.forEach(element => {
        this.locationFilterValue.push(element.id);
      });
      this.advancesearchformGroup.get('companies').value.forEach(element => {
        this.companyFilterValue.push(element.companyName);
      });
      this.advancesearchformGroup.get('Indusrties').value.forEach(element => {
        this.industryFilterValue.push(element.id);
      });
    }
    if (flag) {
      this.totalPage = 0;
      this.pageNumber = 1;
      this.loading = false;
    }
    const filterObject: any = {
      searchText: this.searchKey,
      // locationIdList: [],
      // experience: null,
      // salaryRange: null,
      // companyList: [],
      // industryIdList: [],
      // datePosted: null
    }
    if (this.locationFilterValue.length > 0) {
      filterObject.locationIdList = [...new Set(this.locationFilterValue)];// this.locationFilterValue;
    }
    if (this.experianceFilterValue != null) {
      filterObject.experience = this.experianceFilterValue;
    }
    if (this.salaryRangeFilterValue != null) {
      filterObject.salaryRange = this.salaryRangeFilterValue;
    }
    if (this.companyFilterValue.length > 0) {
      filterObject.companyList = [...new Set(this.companyFilterValue)];//this.companyFilterValue;
    }
    if (this.industryFilterValue.length > 0) {
      filterObject.industryIdList = [...new Set(this.industryFilterValue)];// this.industryFilterValue;
    }
    if (this.datePostedFilterValue != null) {
      filterObject.datePosted = this.datePostedFilterValue;
    }
   // console.log(JSON.stringify(this.locationFilterValue));
    const url = '/api/feed/get-all-newjobs/' + this.pageNumber;
    this.loading = true;
    this.api.postApiCall(url, filterObject).subscribe(res => {
      if (res != null) {
        if (this.pageNumber > 1) {
          this.jobPostList = this.jobPostList.concat(res.content);
        } else {
          this.jobPostList = res.content;
        }
        this.totalPage = res.totalPages;
        this.pageNumber = this.pageNumber + 1;
        this.loading = false;
      }
    }, (err) => {
      this.loading = false;
    });
  }



  scrollEvent = (event: any): void => {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    if (event.target.scrollTop >= (limit - 100) && this.totalPage >= this.pageNumber && !this.loading) {
      this.getAllJobs(false);
    }
  }

  onShortlistJob(jobId, jobCreatedById) {
    console.log(jobId, jobCreatedById, this.user.id);
    const url = '/api/feed/save-shortlisted-jobs/' + jobId;
    this.api.postApiCall(url, null).subscribe(res => {
      this.addNotification(jobCreatedById, 'jobShortlisted', jobId)
    }, (err) => {
    });
  }

  getTopJobsLocations() {
    const url = '/api/feed/top-jobs-by-location';
    this.api.getApiCall(url).subscribe(res => {
      this.topJobsLocations = res;
    }, (err) => {
    });
  }

  getTopJobsCompanies() {
    const url = '/api/feed/top-jobs-by-company';
    this.api.getApiCall(url).subscribe(res => {
      this.topJobsCompanies = res;
    }, (err) => {
    });
  }

  getTopJobsIndustries() {
    const url = '/api/feed/top-jobs-by-industry';
    this.api.getApiCall(url).subscribe(res => {
      this.topJobsIndustries = res;
    }, (err) => {
    });
  }

  getTopJobsByExperience() {
    const url = '/api/feed/top-jobs-by-experience';
    this.api.getApiCall(url).subscribe(res => {
      this.topJobsByExperiance = res;
    }, (err) => {
    });
  }

  getTopJobsBySalaryRange() {
    const url = '/api/feed/top-jobs-by-salary';
    this.api.getApiCall(url).subscribe(res => {
      this.topJobsBySalaryRange = res;
    }, (err) => {
    });
  }


  getTopJobsByPostedDate() {
    const url = '/api/feed/top-jobs-by-posted-date';
    this.api.getApiCall(url).subscribe(res => {
      this.topJobsByPostedDate = res;
    }, (err) => {
    });
  }

  getAllLocations() {
    const url = '/api/feed/all-jobs-by-location';
    this.api.getApiCall(url).subscribe(res => {
      this.jobLocations = res;
    }, (err) => {
    });
  }

  getAllCompanies() {
    const url = '/api/feed/all-jobs-by-company';
    this.api.getApiCall(url).subscribe(res => {
      this.companiesList = res;
    }, (err) => {
    });
  }

  getAllIndustries() {
    const url = '/api/feed/all-jobs-by-industry';
    this.api.getApiCall(url).subscribe(res => {
      this.industry = res;
    }, (err) => {
    });
  }

  onLocationCheck(event, location) {
    if (event.currentTarget.checked) {
      this.locationFilterValue.push(location.locationId);
    } else {
      const index = this.findFilterIndex(this.locationFilterValue, location.locationId, 'location');
      if (index > -1) {
        this.locationFilterValue.splice(index, 1);
      }
    }
  }

  onCompanyCheck(event, company) {
    if (event.currentTarget.checked) {
      this.companyFilterValue.push(company.company);
    } else {
      const index = this.findFilterIndex(this.companyFilterValue, company.company, 'company');
      if (index > -1) {
        this.companyFilterValue.splice(index, 1);
      }
    }
  }

  onIndustryCheck(event, industry) {
    if (event.currentTarget.checked) {
      this.industryFilterValue.push(industry.industryId);
    } else {
      const index = this.findFilterIndex(this.industryFilterValue, industry.industryId, 'industry');
      if (index > -1) {
        this.industryFilterValue.splice(index, 1);
      }
    }
  }

  onExperianceCheck(index) {
    let val;
    if (index === 0) {
      val = 'fresher';
    } else if (index === 1) {
      val = 'betweenTwoAndFive';
    } else if (index === 2) {
      val = 'betweenSixAndTen';
    } else if (index === 3) {
      val = 'aboveTen';
    }
    this.experianceFilterValue = val;
  }

  onSalaryRangeCheck(index) {
    let val;
    if (index === 0) {
      val = 'belowTwoLakh';
    } else if (index === 1) {
      val = 'betweenThreeLakhAndEightLakh';
    } else if (index === 2) {
      val = 'betweenNineLakhAndFifteenLakh';
    } else if (index === 3) {
      val = 'aboveFifteenLakh';
    }
    this.salaryRangeFilterValue = val;
  }

  onDatePostedCheck(index) {
    let val;
    if (index === 0) {
      val = 'new';
    } else if (index === 1) {
      val = 'pastWeek';
    } else if (index === 2) {
      val = 'pastMonth';
    } else if (index === 3) {
      val = 'anytime';
    }
    this.datePostedFilterValue = val;
  }

  findFilterIndex(array, value, type) {
    for (var i = 0; i < array.length; i += 1) {
      if ((type === 'location' && array[i] === value) || (type === 'company' && array[i] === value) || (type === 'industry' && array[i] === value)) {
        return i;
      }
    }
    return -1;
  }

  applyAlladvanceFilters() {
    const filterObject = {
      searchKey: null,
      locationList: this.locationFilterValue,
      experience: this.experianceFilterValue,
      salaryRange: this.salaryRangeFilterValue,
      companyName: this.companyFilterValue,
      industry: this.industryFilterValue,
      datePosted: this.datePostedFilterValue
    }
   // console.log(JSON.stringify(filterObject));
  }

  addNotification(id, type, jobId) {
    if (id !== this.user.id) {
      const json = {
        type: type,
        createdOn: new Date(),
        read: false,
        userId: id,
        fromUserId: this.user.id,
        postId: null,
        jobId: jobId
      }
      const url = '/api/notifications/add-notification';
      this.api.postApiCall(url, json).subscribe((res) => {
      })
    }
  }

}
