import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import { ActivityModel } from 'src/app/models/NetworkModel';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
declare var $: any;
import { AlertBarService } from 'src/app/services/alert-bar.service';
import { NetworkModuleConstants } from '../../constants/globalConstants';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { RouterConstants } from '../../constants/router.contants';
import { Router, NavigationExtras } from '@angular/router';
import videojs from 'video.js';
import { HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

export class VideoMaster {
  int: number;
  Videos: string;
}

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit, AfterViewInit,OnDestroy {
  routerConstants = RouterConstants;
  constants = NetworkModuleConstants;
  commentOnPostFormGroup: FormGroup;
  createPostFormGroup: FormGroup;
  urlVideo = '';
  // tslint:disable-next-line:max-line-length
  urlPoster = '';
  user: UserDetailsModel;
  environment = environment;
  loading = false;
  pageNumber = 1;
  totalPage: number;
  activityPostList: ActivityModel[];
  uploadedFiles: any[] = [];
  files: any[] = [];
  postType = null;
  postViewDefaultSet = 'Anyone';
  postView = [{ value: 'Anyone' }, { value: 'Only Followers' }];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageBase64String = '';
  cropImageIndex = null;
  uploadedImagesEvent = [];
  showImageCropContainer = false;
  postBtnLoader = false;
  videoPath = null;
  OtherUser = [];
  connectionStatus = null;
  relevantJobsList = [];
  shareActivityObject = null;
  showVideoPreview = false;
  thumbNailByteArray;  
  imageUrl;
  myVideos: Observable<VideoMaster[]>;
  options = {
    root:null,
    rootMargin:'50px',
    threshold:1.0
  };
  disablePostFlag=true;
  observerArr = [];
  callback = (entries, observer)=>{

    entries.forEach(entry => {     
      if(entry.target.id.match("^videoplayer_.+$") && entry.target.player && !entry.target.player.isInPictureInPicture_){
          if(entry.isIntersecting){
            let vPlayer = videojs(entry.target.id);
            vPlayer.muted(this.isInPicturePlaying(entries));            
            vPlayer.play();
          } else{
            videojs(entry.target.id).pause();
          }
        }                  
    });
  };
  observer;
  constructor(
    private authSvc: AuthenticationService, public api: ApiCallService,
    private fb: FormBuilder, private alertbar: AlertBarService, private router: Router, private sanitizer:DomSanitizer) { 
      this.observer = new IntersectionObserver(this.callback, this.options);
     // console.log('network cons called..');
      authSvc.profileChangeSubject.subscribe((event: any) => {
       // console.log('network',event);
        this.user.userDPUrl = event;
        this.authSvc.getUserObj().userDPUrl = event;
      });
  }

  ngOnInit(): void {
    this.createForm();
    window.addEventListener('scroll', this.scrollEvent, true);
    this.user = this.authSvc.getUserObj();
   // console.log('!!!!ngoninit',this.user);
    this.getAllActivityPosts();
    this.getRelevantJobs();
    
  }

  isInPicturePlaying(entries): boolean{
    let result = false;
    this.observerArr.forEach(id=>{      
      if(videojs(id).player().isInPictureInPicture_){
        result = true;
      }
    })    
    return true;  
  }
  
  ngAfterViewInit(): void {
    
  }

  startObserveVideoJs(activityPosts:any[]) {   
    if(activityPosts){
      activityPosts.forEach(post=>{
        if(post.type == 2){         
         this.observerArr.push("#videoplayer_"+this.getActivityPathId(post));
         this.observer.observe(document.querySelector("#videoplayer_"+this.getActivityPathId(post)));
        }
      });
    }    
  }

  getActivityPathId(post:any): number{
    return post.activityId;
  }

  isMP4Video(path: string): boolean{
   return path && path.match(".+.mp4$")?true:false;
  }

  createForm() {
    this.commentOnPostFormGroup = this.fb.group({
      comment: ['', Validators.required],
    });

    this.createPostFormGroup = this.fb.group({
      postContent: ['', Validators.required],
    });
  }

  getAllActivityPosts() {
    const url = '/api/activity/' + this.pageNumber;
    this.api.getApiCall(url).subscribe(res => {

      if (res != null && this.pageNumber > 1) {
        const activityLocal = [];
        res.content.forEach(activity => {
          if (activity.activityPostedByUser.username === this.user.username) {
            activity.activityOwner = true;
          } else {
            activity.activityOwner = false;
          }
          activity.initialCommentsList = 5;
          activityLocal.push(activity);
        });
        this.activityPostList = this.activityPostList.concat(activityLocal);
        setTimeout(()=>{
          this.startObserveVideoJs(activityLocal);
        },500);
      } else {
        this.activityPostList = [];
        res.content.forEach(activity => {
          if (activity.activityPostedByUser.username === this.user.username) {
            activity.activityOwner = true;
          } else {
            activity.activityOwner = false;
          }
          activity.initialCommentsList = 5;
          this.activityPostList.push(activity);
        });
        setTimeout(()=>{
          this.startObserveVideoJs(this.activityPostList);
        },500);
      }
      
      

      this.totalPage = res.totalPages;
      this.pageNumber = this.pageNumber + 1;
      this.loading = false;
      
    }, (err) => {
      this.loading = false;
    });
  }

  scrollEvent = (event: any): void => {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    if (event.target.scrollTop >= (limit - 100) && this.totalPage >= this.pageNumber) {
      this.getAllActivityPosts();
    }
  }

  allLikedUser(activityId) {
    this.api.getApiCall('/api/activity/all-liked-user/' + activityId + '/1').subscribe(response => {
      if (response != null) {
        //console.log(response);
      }
    }, (err) => {
    });
  }

  onLike(activityId, index) {
    const url = '/api/activity/like-post?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivityLike = true;
      this.activityPostList[index].likedCounts = this.activityPostList[index].likedCounts + 1;
      this.addNotification(this.activityPostList[index].activityPostedByUserId, 'PostLiked', activityId);
    }, (err) => {
      console.log(err);
    });
  }

  onUnLike(activityId, index) {
    const url = '/api/activity/undo-like-post?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivityLike = false;
      this.activityPostList[index].likedCounts = this.activityPostList[index].likedCounts - 1;
    }, (err) => {
      console.log(err);
    });
  }

  onSave(activityId, index) {
    const url = '/api/activity/save-activity?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivitySave = true;
      this.addNotification(this.activityPostList[index].activityPostedByUserId, 'savePost', activityId);
    }, (err) => {
      console.log(err);
    });
  }

  addNotification(id, type, activityId) {
    if (id !== this.user.id) {
      const json = {
        type: type,
        createdOn: new Date(),
        read: false,
        userId: id,
        fromUserId: this.user.id,
        postId: activityId,
        jobId: null
      }
      const url = '/api/notifications/add-notification';
      this.api.postApiCall(url, json).subscribe((res) => {
      })
    }
  }

  onRemoveFromSavedCollection(activityId, index) {
    const url = '/api/activity/remove-saved-activity?activityId=' + activityId;
    this.api.getApiCall(url).subscribe(res => {
      this.activityPostList[index].isActivitySave = false;
    }, (err) => {
      console.log(err);
    });
  }

  onPostComment(comment) {
    comment ? this.disablePostFlag = false : this.disablePostFlag = true
    console.log(this.disablePostFlag);
  }


  onComment(activityid, index) {
    const url = '/api/activity/post-comment';
    const jsonObj = {
      activityId: activityid,
      commentsText: this.commentOnPostFormGroup.value.comment
    };
    this.api.postApiCall(url, jsonObj).subscribe(res => {
      if (res != null) {
        this.addNotification(this.activityPostList[index].activityPostedByUserId, 'commentOnPost', activityid);
        this.commentOnPostFormGroup.reset();
        this.commentOnPostFormGroup.patchValue({});
        this.activityPostList[index].totalComments = this.activityPostList[index].totalComments + 1;
        if (this.activityPostList[index].totalComments > this.activityPostList[index].initialCommentsList) {
          let arr = [];
          arr = this.activityPostList[index].activityComments.reverse();
          arr.pop();
          this.activityPostList[index].activityComments = arr.reverse();
        }
        res.firstName = this.user.fname;
        res.lastName = this.user.lname;
        res.userName = this.user.username;
        this.activityPostList[index].activityComments.push(res);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onLoadNextFewComments(activityId, index) {
    this.activityPostList[index].initialCommentsList = this.activityPostList[index].initialCommentsList + 5;
    const pageNo = (this.activityPostList[index].initialCommentsList / 5);
    const url = '/api/activity/get-comments/' + activityId + '/' + pageNo;
    this.api.getApiCall(url).subscribe(res => {
      res.content.reverse().forEach(element => {
        this.activityPostList[index].activityComments.splice(0, 0, element);
      });
    }, (err) => {
      console.log(err);
    });
  }

  onDeleteActivity(activityId) {
    const url = '/api/activity/' + activityId;
    this.api.deleteApiCall(url).subscribe(res => {
      this.pageNumber = 1;
      this.getAllActivityPosts();
    }, (err) => {
      console.log(err);
    });
  }

  getPoster(postArr : any[]): string{
    let path='';
    if(postArr){
      postArr.forEach(post=>{
        if(post && post.isThumbnail == 'Y'){
          path = post.path;
        }
      });
    }
    return this.environment.activityPhotoHost+path;
  }

  getVDOSrc(postArr : any[]): string{
    let path='';
    if(postArr){
      postArr.forEach(post=>{
        if(post && post.isThumbnail != 'Y'){
          path = post.path;
        }
      });
    }
    return this.environment.activityPhotoHost+path;
  }
 
  getThumbnail(fileData){
    const formData: FormData = new FormData();
    formData.append('file', fileData);
    let headers = new HttpHeaders();  
        headers.append('responseType', 'text');  
        const httpOptions = {  
            headers: headers  
        }; 
    this.imageUrl=null;
    this.api.http.post<any>(this.api.addHostToUrl('/api/activity/generate-thumbnail/'), formData, {responseType: 'blob' as 'json'}).subscribe(res => {
      console.log(res)
     // this.thumbNailByteArray = res;
     let unsafeImageUrl = URL.createObjectURL(res);
     this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl); 
     this.showVideoPreview = true;
      //this.getAllActivityPosts();
    }, (err) => {
      console.log(err);
    });
  }
 
  onSubmitPost() {
    let hashTags = null;
    if (this.createPostFormGroup.value.postContent != null) {
      hashTags = hashTags + this.createPostFormGroup.value.postContent.split(' ').filter(v => v.startsWith('#'));
      if (hashTags === 'null') {
        hashTags = null;
      }
    }
    const jsonData = {
      isAllowCommentForPost: true,
      hashtags: hashTags,
      numberOfLikes: 0,
      postText: this.createPostFormGroup.value.postContent,
      type: this.postType,
      postPath: null,
      status: 1
    };
    const formData: FormData = new FormData();
    if (this.uploadedFiles.length > 0) {
      for (const fileData of this.files) {
        formData.append('file', fileData.fileData);
      }
    }
    console.log(jsonData);
    this.postBtnLoader = true;
    formData.append('info', JSON.stringify(jsonData));
    this.api.postApiCall('/api/activity/post-user-activity/', formData).subscribe(res => {
      this.observerArr = [];
      this.resetPostForm();
      this.pageNumber = 1;
      this.getAllActivityPosts();
    }, (err) => {
      console.log(err);
    });
  }

  onSharePost(activityId, activityType) {
    let hashTags = null;
    if (this.createPostFormGroup.value.postContent != null) {
      hashTags = hashTags + this.createPostFormGroup.value.postContent.split(' ').filter(v => v.startsWith('#'));
      if (hashTags === 'null') {
        hashTags = null;
      }
    }
    const jsonData = {
      isAllowCommentForPost: true,
      hashtags: hashTags,
      numberOfLikes: 0,
      postText: this.createPostFormGroup.value.postContent,
      type: activityType,
      postPath: null,
      status: 1,
      shareActivityId: activityId
    };
    const formData: FormData = new FormData();
    if (this.uploadedFiles.length > 0) {
      for (const fileData of this.files) {
        formData.append('file', fileData.fileData);
      }
    }
    this.postBtnLoader = true;
    formData.append('info', JSON.stringify(jsonData));
    this.api.postApiCall('/api/activity/post-user-activity/', formData).subscribe(res => {
      this.observerArr = [];
      this.resetPostForm();
      this.pageNumber = 1;
      this.getAllActivityPosts();
    }, (err) => {
      console.log(err);
    });
  }

  resetPostForm() {
    this.files = [];
    this.postType = null;
    $('#postModal').modal('hide');
    $('#shareModal').modal('hide');
    this.createPostFormGroup.reset();
    this.createPostFormGroup.patchValue({});
    this.postViewDefaultSet = 'Anyone';
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.imageBase64String = '';
    this.cropImageIndex = null;
    this.uploadedImagesEvent = [];
    this.showImageCropContainer = false;
    this.postBtnLoader = false;
  }

  onFileUpload(event, type) {
    this.files = [];
    this.uploadedFiles = [];
    this.postType = null;
    this.uploadedFiles = event.target.files;
    let postType = this.getPostType(type);

    let fileName = this.uploadedFiles[0].name;
    /**
     * Video validation before processing
     */
    if(postType == 2 && this.uploadedFiles.length > 1){
      alert('Sorry,  Max u can upload only 1 mp4 video at a time.');
      return;        
    } 
    if(postType == 2 && this.uploadedFiles.length == 1 && this.uploadedFiles[0].size > 10485760){
      alert('Sorry,  You can upload maximum 10MB mp4 video.');
      return;        
    }   

    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      const filesAmount = this.uploadedFiles.length;            
      if (filesAmount < 6) {
        console.log(filesAmount);
        for (let i = 0; i < filesAmount; i++) {               
          const fineName = this.uploadedFiles[i].name;
          const reader = new FileReader();
          
          const fileExtension = fineName.split('.').pop();
          
          
          reader.onload = (subevent: any) => {
            const json = {
              fileName: fineName,
              fileExtension: fileExtension,
              content: subevent.target.result,
              fileType: this.getFileType(type),
              fileData: this.uploadedFiles[i]
            };
            this.files.push(json);
            this.postType = postType;
            if(this.postType === 2){
               this.getThumbnail(json.fileData);              
            }
          };
          reader.readAsDataURL(this.uploadedFiles[i]);

        }
        $('#postModal').modal('show');       
      } else {
        alert('Sorry,  Max u can upload only 5 images/files');
      }
    }
  }

  getFileType(type: string): string{
    console.log(type);
    let fileType=''; 
    if(type){
      fileType =  type === 'image'? 'img' : (type === 'video' ? type : 'doc');
    }
    return type;
  }

  
  getPostType(type: string): number{
    let postType=0; 
    if(type){
      postType =  type === 'image'? 1 : (type === 'video' ? 2 : 3);
    }
    return postType; 
  }

  removeSelectedFile(index) {
    this.showImageCropContainer = false;
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.files.splice(index, 1);
    if (this.files.length === 0) {
      this.postType = null;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  cropSelectedImage(index) {
    this.cropImageIndex = index;
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.imageChangedEvent = this.files[index].content;
    this.showImageCropContainer = true;

  }

  onImageCrop() {
    this.files[this.cropImageIndex].content = this.croppedImage;
    this.files[this.cropImageIndex].fileData = this.base64ToFile(this.croppedImage, this.files[this.cropImageIndex].fileName);
    this.showImageCropContainer = false;
    this.croppedImage = '';
  }

  showToastAlert(msg) {
    this.alertbar.show(msg, {
      classname: 'bg-success text-light',
      delay: 3000,
      autohide: true
    });
  }

  viewOtherUser(userId) {
    const url = '/api/user/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: res.id }
      };
      this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.profileExternal], navigationExtras);
    }, (err) => {
    });

    if (userId !== this.user.id) {
      this.addNotification(userId, 'profileView', null);
      const url = '/api/user/save-profile-views/' + userId;
      this.api.postApiCall(url, null).subscribe(res => {
      }, (err) => {
      });
    }
  }

  getConnectionStatus(userId) {
    this.connectionStatus = null;
    const url = '/api/user/follow/get-connection-status/' + userId;
    this.api.getApiCall(url).subscribe(res => {
      if (res != null) {
        this.connectionStatus = res.status;
      } else {
        this.connectionStatus = null;
      }
    }, (err) => {
    });
  }

  onFollow(userId) {
    this.api.postApiCall(`/api/user/follow/${userId}`, null).subscribe(res => {
    });
  }

  accept(userId) {
    const url = '/api/user/follow/accept/' + userId;
    this.api.putApiCall(url, null).subscribe(res => {
    }, (err) => {
    });
  }

  onUnfollowFollower(userId) {
    this.api.deleteApiCall(`/api/user/follow/unfollow-follower/${userId}`).subscribe(res => {
    });
  }

  onUnfollowFollowing(userId) {
    this.api.deleteApiCall(`/api/user/follow/unfollow-following/${userId}`).subscribe(res => {
    });
  }

  onCancelFollowingRequest(userId) {
    this.api.deleteApiCall(`/api/user/follow/cancel-following-request/${userId}`).subscribe(res => {
    });
  }

  getRelevantJobs() {
    this.api.getApiCall(`/api/feed/get-relevant-jobs`).subscribe(res => {
      if (res != null) {
        this.relevantJobsList = res;
      } else {
        this.relevantJobsList = [];
      }
    });
  }

  onViewJobDetails(jobId) {
    this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.jobDetails + '/' + jobId]);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
//    this.videoJSplayer.dispose();
  }

  getProfilePicPath(userId, userPhotoPath): string{
    if(userId == this.authSvc.getUserId()){
      return this.environment.userPhotoHost+this.authSvc.getUserObj().userDPUrl;
    } else{
      return this.environment.userPhotoHost+userPhotoPath;
    }
  }

  openDocs() {
    window.open('assets/document/HC Privacy Policy.pdf', '_blank');
  }

  getPdfDocName(url: string):string{
    let name = 'Pdf document';
    if(url){
      name = url.substring(url.lastIndexOf('/')+1, url.length-4);
    }
    return name;
  }

}
