import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { environment } from 'src/environments/environment';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import { RouterConstants } from '../../constants/router.contants';

@Component({
  selector: 'app-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrls: ['./user-profile-card.component.scss']
})
export class UserProfileCardComponent implements OnInit {
  routerConstants = RouterConstants;
  user: UserDetailsModel;
  environment = environment;
  followersCount: any;
  followingCount: any;
  postedJobsCount: any;
  appliedJobsCount: any;
  DesignationAndCompanyName: any;
  jobTitle: any;
  companyName: any;

  constructor(private authSvc: AuthenticationService, private api: ApiCallService
  ) { }

  ngOnInit(): void {
    this.user = this.authSvc.getUserObj();
    this.getFollowersCount();
    this.getFollowingCount();
    this.getPostedJobsCount();
    this.getAppliedJobsCount();
    this.getDesignationAndCompanyName();
  }

  getFollowersCount() {
    const url = '/api/user/follow/followers-count';
    this.api.getApiCall(url).subscribe(res => {
      this.followersCount = res;
    }, (err) => {
    });
  }

  getFollowingCount() {
    const url = '/api/user/follow/following-count';
    this.api.getApiCall(url).subscribe(res => {
      this.followingCount = res;
    }, (err) => {
    });
  }

  getPostedJobsCount() {
    const url = '/api/feed/jobs-posted-count';
    this.api.getApiCall(url).subscribe(res => {
      this.postedJobsCount = res;
    }, (err) => {
    });
  }

  getAppliedJobsCount() {
    const url = '/api/applied-job/count-by-user';
    this.api.getApiCall(url).subscribe(res => {
      this.appliedJobsCount = res;
    }, (err) => {
    });
  }

  getDesignationAndCompanyName() {
    const url = '/api/user/professional-info/' + this.user.id;
    this.api.getApiCall(url).subscribe(res => {
      if (res != null) {
        this.DesignationAndCompanyName = res;
        this.companyName = (res[res.length - 1] != null) ? res[res.length - 1].companyName : null;
        this.jobTitle = (res[res.length - 1] != null) ? res[res.length - 1].jobTitle : null;
      }
      // console.log(res);
    }, (err) => {
    });
  }

}
