import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RouterConstants } from "../../constants/router.contants";
import { ApiCallService } from "../../services/api-call-svc/api-call.service";
import { SocialAuthService } from "angularx-social-login";
import { ShareSocialUserService } from "../../services/share-social-user/share-social-user.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "../../entities/user";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  private passwordsValidation: any;
  loading = false;
  private signupResponse: any;

  constructor(
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    private authSvc: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  get formControls() {
    return this.formGroup.controls;
  }
  formGroup: FormGroup;
  routerConstants = RouterConstants;
  isSubmitted = false;
  token: any;

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.params.token;
    console.log(this.token);
    this.createForm();
    this.authSvc.clearSessionStorage();

    this.passwordsValidation =
      this.formGroup.controls.password.valueChanges.subscribe(() => {
        if (this.checkPassword(this.formGroup.controls.password)) {
          if (
            this.checkPassword(this.formGroup.controls.password)
              .requirements === true
          ) {
            this.formGroup.get("password").setErrors({ requirements: true });
            this.getErrorPassword();
          }
        }
      });
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      // tslint:disable-next-line:max-line-length
      password: [
        null,
        [Validators.required, Validators.minLength(8)],
        this.checkPassword,
      ],
      confirmedPassword: [null, [Validators.required, Validators.minLength(8)]],
    });
  }

  checkPassword = (control) => {
    const enteredPassword = control.value;
    const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return !passwordCheck.test(enteredPassword) && enteredPassword
      ? { requirements: true }
      : null;
  };

  getErrorPassword() {
    return this.formGroup.get("password").hasError("required")
      ? "Password is required"
      : this.formGroup.get("password").hasError("requirements")
      ? "Password needs to be at least eight characters, one uppercase letter and one number"
      : "";
  }

  getErrorConfirmPassword() {
    return this.formGroup.get("password").hasError("required")
      ? "Password is required"
      : this.formGroup.get("password").hasError("requirements")
      ? "Password needs to be at least eight characters, one uppercase letter and one number"
      : "";
  }

  changePassword(user) {
    this.isSubmitted = true;
    // Adding this step to handle form pending status issue
    for (const el in this.formGroup.controls) {
      if (this.formGroup.controls[el].errors) {
        return;
      }
    }
    const data = { newPassword: user.password };
    // this.loading = true;
    this.apiCallService
      .postForgotPasswordCall(`/api/user/update-forgot-password/${this.token}`, data, this.token)
      .subscribe(
        (res) => {
           console.log(res);
          this.loading = false;
          this.router.navigate(["/sign-in"]);
          // this.showSuccess = true;
        },
        (err) => {
          this.signupResponse = err.error.message;
          this.loading = false;
        }
      );

    // this.router.navigate(['/sign-in']);
  }
}
