<section class="navigation">
    <nav class="navbar navbar-expand-md navbar-light fixed-top">
        <!-- <nav class="navbar fixed-top navbar-expand-md navbar-light white double-nav scrolling-navbar" style="padding-left: unset;"> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">

                <li class="nav-item">
                    <app-logo class="nav-link"></app-logo>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/{{routerConstants.signIn}}">Network</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/{{routerConstants.signIn}}">Jobs</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#why_us">Why us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#footer">Get In Touch</a>
                </li>
            </ul>

            <a routerLink="/{{routerConstants.signIn}}" routerLinkActive="active" class="btn my-2 my-sm-0 btn-link signUpBtn"><span>SIGN IN</span></a>
            <a routerLink="/{{routerConstants.signUp}}" routerLinkActive="active" class="btn btn-outline-primary my-2 my-sm-0 signInBtn"><span>SIGNUP</span></a>
        </div>
    </nav>
</section>