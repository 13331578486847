<section class="profile-detailedInfo">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-lg-9">
                <div class="card profileCard profileInfo">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <img *ngIf="jobDetail?.companyLogoPath == null" class="jobDetails-image"
                                    src="./assets/images/defaultCompanyLogo.jpg" alt="job image" />
                                <img *ngIf="jobDetail?.companyLogoPath != null" class="jobDetails-image"
                                    src="{{environment.activityPhotoHost}}{{jobDetail?.companyLogoPath}}"
                                    alt="job image" />
                            </div>
                            <div class="col-md-9 col-lg-9">
                                <div class="jobDetails">
                                    <h4 class="position">{{jobDetail?.jobTitle}} ({{jobDetail?.jobId}})</h4>
                                    <span class="company">{{jobDetail?.company}}</span>
                                    <span class="company">
                                        {{jobDetail?.location?.cityName}},
                                        {{jobDetail?.location?.countryName}}</span>
                                    <span class="link"> <a href="#">{{jobDetail?.companyUrl}}</a></span>
                                </div>
                                <div class="applyJob">
                                    <div class="clearfix">
                                        <div class="jobPostedDate">
                                            <span>
                                                {{jobDetail?.jobCreatedDate |
                                                dateAgo}}
                                            </span>
                                        </div>
                                        <div class="applyJobBtns">
                                            <a *ngIf="jobDetail?.isShortlistedJob
                                                === null ||
                                                !jobDetail?.isShortlistedJob" class="btn btn-outline-primary
                                                btn-rounded my-2 my-sm-0
                                                shortlistBtn"
                                                (click)="onShortlistJob(jobDetail.jobId, jobDetail.jobCreatedBy)"><span>SHORTLIST</span></a>
                                            <a *ngIf="jobDetail?.isAppliedJob
                                                === null ||
                                                !jobDetail?.isAppliedJob" class="btn btn-primary
                                                btn-rounded
                                                primary-backgroundcolor
                                                applyBtn"
                                                (click)="onApplyJob(jobDetail.jobId, jobDetail.jobCreatedBy)"><span>Apply
                                                    Now</span></a>

                                            <a *ngIf="jobDetail?.isAppliedJob"
                                                style="color: green;"><span>Applied</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card profileCard" style="border: none !important;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8 col-lg-8">

                                <div>
                                    <h3 class="jobDetails-title">Job Description</h3>
                                    <p class="jobDetails-aboutDesc">
                                        {{jobDetail?.designation?.designation}}</p>
                                    <ul class="jobDetails-description">
                                        <li>{{jobDetail?.jobDesc}}</li>
                                    </ul>
                                </div>
                                <!-- Skills -->
                                <div>
                                    <h3 class="jobDetails-title">Job Skills</h3>
                                    <ul class="jobDetails-description" style="margin-bottom: .5rem !important;" *ngFor="let skill of
                                        jobDetail?.skillSetList">
                                        <li>{{skill?.skillSetName}}</li>
                                    </ul>
                                </div>
                                <!-- Additional Information -->
                                <!--                                <div>-->
                                <!--                                    <h3 class="jobDetails-title jobDetail-additionalInfo">Additional Information</h3>-->
                                <!--                                    <h4 class="jobDetails-idealCandidate">The Ideal Candidate</h4>-->
                                <!--                                    <h5 class=jobDetails-aboutYou>About You</h5>-->
                                <!--                                    <p class="jobDetails-aboutdesc">-->
                                <!--                                        Your portfolio full of consistently great work across different screens, be it mobile, tablet or desktop. You lead on projects, using your broad conceptual expertise and your technical knowledge to inspire others and help shape product strategy. Yes,-->
                                <!--                                        you can craft delightful designs. But you know improving user experience isn’t just about grafting away at the drawing board. You take a broader view – approaching design conceptually and thinking about the whole-->
                                <!--                                        experience. Whether you learned your skills on a design degree or on the job, you’re comfortable using them to explain design concepts to anyone, no matter how senior or inexperienced. And you know how to balance-->
                                <!--                                        what the user needs with what’s really achievable. You’re enthusiastic, curious and full of ideas. You keep up to speed with the latest design trends and technology developments. And you’re constantly fascinated-->
                                <!--                                        by what people really need, and how we can create the right experience to give it to them.</p>-->
                                <!--                                </div>-->
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <div class="moreJobDetailsBorder">
                                    <div class="moreJobDetails">
                                        <h4>Industry</h4>
                                        <p>{{jobDetail?.industry?.industry}}</p>
                                    </div>
                                    <div class="moreJobDetails">
                                        <h4>Experience</h4>
                                        <p>{{jobDetail?.minYearsOfExp}}-{{jobDetail?.maxYearsOfExp}} years
                                        </p>
                                    </div>
                                    <div class="moreJobDetails">
                                        <h4>CTC Range</h4>
                                        <p>{{jobDetail?.minSalary}}-{{jobDetail?.maxSalary}} L
                                        </p>
                                    </div>
                                    <div class="moreJobDetails" *ngIf="jobDetail?.numberOfOpennings !=
                                        null">
                                        <h4>Number Of Opennings</h4>
                                        <p>{{jobDetail?.numberOfOpennings}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-md-3 col-lg-3">

                <div class="card profileCard exploreCard">
                    <app-add-box></app-add-box>
                </div>
                <div class="card profileCard" *ngIf="resumePathStatus">
                    <div class="card-body resume-body" style="min-height: auto
                        !important">
                        <div style="display: flex; justify-content: center;">
                            <button type="button" style="display: flex; justify-content: center; align-items: center;" class="btn btn-primary
                                btn-rounded modalPostBtn" (click)="fileSelect.click()">Resume Upload
                                <em class="fa fa-spinner
                                    fa-spin" *ngIf="uploadResumeLoader"></em></button>
                            <label class="my-resume"><input type="file" #fileSelect class="display-none
                                    network-post-attachimage1" (change)="onResumeUpload($event)" accept=".pdf"
                                    hidden="true"></label>
                        </div>
                    </div>
                </div>

                <div class="card jobCard" style="margin-bottom: 20px;" *ngFor="let job of relevantJobsList">
                    <div class="card-body">
                        <div class="clearfix">

                            <img style="float: left;" (click)="onViewJobDetails(job.jobId)"
                                *ngIf="job.companyLogoPath == null" class="jobImg1 cursor_pointer"
                                src="./assets/images/defaultCompanyLogo.jpg" alt="job image" />
                            <img style="float: left;" (click)="onViewJobDetails(job.jobId)"
                                *ngIf="job.companyLogoPath != null" class="jobImg cursor_pointer"
                                src="{{environment.activityPhotoHost}}{{job?.companyLogoPath}}" alt="job image" />
                        </div>
                        <h5 class="card-title job-title cursor_pointer" (click)="onViewJobDetails(job.jobId)">
                            {{job.jobTitle}}</h5>
                        <p class="job_companyName cursor_pointer" (click)="onViewJobDetails(job.jobId)">
                            {{job.companyName}}</p>
                        <p class="job_location">{{job.cityName}}, {{job.country}}
                        </p>
                        <p class="job_new" *ngIf="job.createdOn === 'New'">New</p>
                        <p class="job_recent" *ngIf="job.createdOn != 'New'">{{job.createdOn | dateAgo}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>