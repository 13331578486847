import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {
  
  subscribeMessagesEvents = new Subject();

  subscribeJobSearchEvents = new Subject();

  constructor(public http: HttpClient) { }

  getApiCall(url: string): Observable<any> {
    url = this.addHostToUrl(url);
    return this.http.get<any>(url);
  }

  postApiCall(url: string, data: any): Observable<any> {
    url = this.addHostToUrl(url);
    return this.http.post<any>(url, data);
  }

  putApiCall(url: string, data: any): Observable<any> {
    url = this.addHostToUrl(url);
    return this.http.put<any>(url, data);
  }

  deleteApiCall(url: string): Observable<{}> {
    url = this.addHostToUrl(url);
    return this.http.delete(url);
  }

  addHostToUrl(url: string): string {
    return `${environment.apiUrl}${url}`;
  }

  postForgotPasswordCall(url: string, data: any, auth_token: string): Observable<any> {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    url = this.addHostToUrl(url);
    return this.http.post<any>(url, data);
  }


  getInitials(name) {
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    canvas.width = 35;
    canvas.height = 35;
    document.body.appendChild(canvas);
    const context = canvas.getContext('2d');
    context.fillStyle = '#476ce8';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = '15px Arial';
    context.fillStyle = '#FFFFFF';
    const nameArray = name.split(' ');
    let initials = '';
    for (let i = 0; i < nameArray.length; i++) {
      if (i <= 1) {
        initials = initials + nameArray[i][0];
      }
    }
    if (initials.length > 1) {
      context.fillText(initials.toUpperCase(), 7, 22);
    } else {
      context.fillText(initials.toUpperCase(), 10, 22);
    }
    const data = canvas.toDataURL();
    document.body.removeChild(canvas);
    return data;
  }
}
