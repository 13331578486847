import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from '../../services/api-call-svc/api-call.service';
import { environment } from 'src/environments/environment';
import { SnackbarUtil } from '../../utils';
import { UserDetailsModel } from 'src/app/models/UserDetailsModel';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnChanges {

  environment = environment;
  currentUrl: string;
  jobDetail: any;
  jobDesc: any;
  jobminSalary: any;
  jobmaxSalary: any;
  resumePathStatus = false;
  uploadedResume: any;
  uploadResumeLoader = false;
  relevantJobsList = [];
  user: UserDetailsModel;
  constructor(
    private authSvc: AuthenticationService,
    private snackbarUtil: SnackbarUtil,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private api: ApiCallService) {
    let _this = this;
    api.subscribeJobSearchEvents.subscribe((event: any) => {
      _this.currentUrl = event;
      this.initialize();
    });
  }

  ngOnInit() {
    this.currentUrl = this.router.url.split('/')[3].split('%')[0];
    this.initialize();
  }

  initialize() {
    this.user = this.authSvc.getUserObj();
    //this.currentUrl = this.router.url.split('/')[3].split('%')[0];
    // console.log('Current active route', this.currentUrl);

    const url = '/api/feed/' + this.currentUrl + '/';
    const url1 = '/api/feed/job-desc/' + this.currentUrl;
    this.getJobDetails(url);
    this.api.getApiCall(url1).subscribe(res1 => {
      this.jobDesc = res1;
    });
    this.getResumePathStatus();
    this.getRelevantJobs();
  }

  ngOnChanges() {
    console.log('ng in change');
  }

  getJobDetails(url) {
    this.api.getApiCall(url).subscribe(res => {
      this.jobDetail = res;
      // this.jobminSalary = (res.minSalary / 100000).toFixed(2);
      // this.jobmaxSalary = (res.maxSalary / 100000).toFixed(2);
    });
  }

  onShortlistJob(jobId, jobCreatedById) {
    const url = '/api/feed/save-shortlisted-jobs/' + jobId;
    this.api.postApiCall(url, null).subscribe(res => {
      this.jobDetail.isShortlistedJob = true;
      this.snackbarUtil.openSnackBar('Shortlisted successfully', null);
      this.addNotification(jobCreatedById, 'jobShortlisted', jobId);
    }, (err) => {
    });
  }


  onApplyJob(jobId, jobCreatedById) {
    if (this.resumePathStatus) {
      this.snackbarUtil.openSnackBar('Please upload your resume', null);
      return;
    }
    this.api.postApiCall(`/api/feed/apply-job/`, { jobId }).subscribe(res => {
      this.jobDetail.isAppliedJob = true;
      this.snackbarUtil.openSnackBar('applied successfully', null);
      this.addNotification(jobCreatedById, 'jobApplied', jobId);
    });
  }

  getResumePathStatus() {
    this.api.getApiCall(`/api/user/get-resume-path-status`).subscribe(res => {
      this.resumePathStatus = res;
    });
  }

  onResumeUpload(event) {

    const extension = event.target.files[0].name.split(".").pop();
    const fileSize = event.target.files[0].size;
    if (fileSize > 5000000) {
      alert('You can upload upto 5 MB');
    }
    if (extension === "pdf" || extension === "docx" || extension === "doc") {
      if (fileSize < 5000000) {
        this.uploadResumeLoader = true;
        const formData: FormData = new FormData();
        formData.append('file', event.target.files[0]);
        const url = '/api/user/upload-user-resume/';
        this.api.postApiCall(url, formData).subscribe((res) => {
          this.uploadResumeLoader = false;
          this.snackbarUtil.openSnackBar('Resume Uploaded successfully!', null);
          this.getResumePathStatus();
        }, (err) => {
          this.uploadResumeLoader = false;
          console.log(err);
          alert(err);
        });
      }
    } else {
      this.uploadResumeLoader = false;
      alert('Invalid File Format. We accept only pdf, doc and docx file.');
    }

  }

  getRelevantJobs() {
    this.api.getApiCall(`/api/feed/get-relevant-jobs`).subscribe(res => {
      if (res != null) {
        this.relevantJobsList = res;
      } else {
        this.relevantJobsList = [];
      }
    });
  }

  onViewJobDetails(jobId) {
    const url = '/api/feed/' + jobId + '/';
    this.router.navigate(['/user/job-details/' + jobId]);
    this.getJobDetails(url);
  }


  addNotification(id, type, jobId) {
    if (id !== this.user.id) {
      const json = {
        type: type,
        createdOn: new Date(),
        read: false,
        userId: id,
        fromUserId: this.user.id,
        postId: null,
        jobId: jobId
      }
      const url = '/api/notifications/add-notification';
      this.api.postApiCall(url, json).subscribe((res) => {
      })
    }
  }

}
