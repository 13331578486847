import { Component, HostListener, ViewChild } from '@angular/core';
import {SocketService} from './services/socket-service/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'HappyConnects-UI';  
  userActivity;
  timeOutScheduled = false;
  
  constructor(private socketService: SocketService){
     
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      this.socketService.onSendHeartBeat.next();
      this.timeOutScheduled = false;
    }, 1000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    if(!this.timeOutScheduled){
      this.setTimeout();
      this.timeOutScheduled = true;
    }
  }  
}
