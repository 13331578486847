<section class="search-result-banner">
  <div class="container">
    <div class="row" style="height:100%">
      <div class="offset-md-1 offset-lg-1 col-md-8 col-lg-8 search-list">
        <div class="people"><span>Showing {{searchList.length}} results</span><span>Search for "{{searchKey}}" &nbsp;</span></div>
        <div class="people" *ngIf="!searchList || searchList.length == 0"> No Result Found.</div> 
        <div class="row people-card" *ngFor="let search of searchList;" (click)=openPage(search);>
          <div class="col-md-1 col-lg-1">            
            <img [src]="environment.userPhotoHost+(search.imageURL && search.imageURL != 'null' ? search.imageURL : 'default-profile-200x150.png')" class="people-image" *ngIf="search.documentType == 1" />
            <img [src]="environment.activityPhotoHost+(search.imageURL && search.imageURL != 'null' ? search.imageURL : 'default_job.jpg')" class="people-image" *ngIf="search.documentType == 2" />
          </div>
          <div class="col-md-8 col-lg-8 people-details">
            <div class="people-name">{{search.title}}</div>
            <div class="people-designation" *ngIf="search.description && search.description != 'null'">{{search.description}}</div>
            <div class="people" *ngIf="search.documentType == 2" style="margin-top: -27px;left: -200px;position: relative;">
              <span><b>Posted Date:</b> {{search.postedDate | dateAgo}}</span>
              <span><b>Experience:</b> {{search.minExp && search.minExp != 'null'?search.minExp:'NA'}} to {{search.maxExp && search.maxExp != 'null'?search.maxExp:'NA'}} &nbsp;</span>
            </div>
                    
          </div>
        </div>        
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="card profileCard">
          <app-add-box></app-add-box>
        </div>
      </div>
    </div>
  </div>
</section>

