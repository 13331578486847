import { Component, OnInit } from '@angular/core';
import { RouterConstants } from 'src/app/constants/router.contants';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiCallService } from 'src/app/services/api-call-svc/api-call.service';
import {GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import {ShareSocialUserService} from '../../services/share-social-user/share-social-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  formGroup: FormGroup;
  routerConstants = RouterConstants;
  isSubmitted = false;
  loginResponse: any = '';
  user: SocialUser;
  loading = false;
  customError = false;
  constructor(private formBuilder: FormBuilder,
              private apiCallSvc: ApiCallService,
              private authSvc: AuthenticationService,
              private authService: SocialAuthService,
              private socialUserSvc: ShareSocialUserService,
              private router: Router) { }

  ngOnInit() {
    this.createForm();
    this.authSvc.clearSessionStorage();
  }

  get formControls() { return this.formGroup.controls; }

  createForm() {
    this.formGroup = this.formBuilder.group({
      username: [null, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: [null, [Validators.required]],
      rememberMe: [false]
    });
  }

  login(loginData) {
    this.isSubmitted = true;
    if (this.formGroup.invalid) {
      console.log(this.formGroup.value);
      return;
    }
    
    this.loading = true;
    this.customError = false;
    this.apiCallSvc.postApiCall('/api/auth/authenticate', loginData).subscribe(res => {
      this.loading = false;
      this.authSvc.setSecureKey(res.token.id_token);
      this.authSvc.setUserObj(res);
      sessionStorage.setItem('userId', res.id);

      this.router.navigate([this.routerConstants.user]);


     /* if (res.isPhoneVerified === true) {
        this.router.navigate([this.routerConstants.user]);
      } else {
        this.router.navigate(['/checkotp']);
      }*/

    }, errorRes => {
      this.loading = false;
      this.customError = true;
      if (errorRes.error) {
        this.loginResponse = errorRes.error.message;
      }
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialUser => {
      this.socialUserSvc.setSocialUser(socialUser);
      const {email, idToken, firstName, lastName} = socialUser;
      const data = {
        email: email,
        idToken: idToken,
        firstName: firstName,
        lastName: lastName
      }
      this.apiCallSvc.postApiCall('/api/auth/social-login',data).subscribe(res=>{
        this.authSvc.setSecureKey(res.token.id_token);
        this.authSvc.setUserObj(res);
        sessionStorage.setItem('userId', res.id);
        this.router.navigate([this.routerConstants.user + '/' + this.routerConstants.network]);
      },errorRes => {
        this.loading = false;
        this.customError = true;
        if (errorRes.error) {
          this.loginResponse = errorRes.error.message;
        }
      });
      
    });
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
    //   this.authSvc.setSecureKey(res.authToken);
    //   this.authSvc.setSocialUserObj(res);
    //   sessionStorage.setItem('userId', res.id);
    //   this.router.navigate([this.routerConstants.user]);
    // });
    // this.authService.authState.subscribe((User) => {
    //   this.socialUserSvc.setSocialUser(User);
    //   console.log(this.routerConstants.user);
    //
    // });

  }

}
