export const GlobalConstants = {
    siteName: 'Happy Connects',
    siteTitle: 'Happy Connects',
    formValidationError: [
        { required: 'Field is Required' }
    ]
};


export const NetworkModuleConstants = {
    add_comment: 'Add a comment...',
    post_about: 'What do you like to post about?',
    post_something: 'Post Something',
};

export const JobsModuleConstants = {
    search_job_placeholder: 'Search for job title, company or location',
};


