import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  path: string;
  constructor() { }

  ngOnInit(): void {
  }

  openDocs() {
    window.open('assets/document/HC Privacy Policy.pdf', '_blank');
  }

}
