<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-6">
      <div class="container">
        <div class="signupstep1">
          <app-logo></app-logo>
          <div class="signupstep1-welcome">
            <h1 class="welcomeTitle">Welcome!</h1>
            <p class="one-step">
              You are Just one step away from finding your dream job!
            </p>
          </div>
          <div class="signupstep1-welcomeimage">
            <img src="./assets/images/welcomeimage.png" alt="Welcome-Image" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 signup-step1background">
      <!-- Large outline input -->
      <div class="container">
        <div class="clearfix">
          <div class="signup-step1-siginTitle float-right">
            <p>
              Already have an account?
              <a
                class="signin-"
                routerLink="/{{ routerConstants.signIn }}"
                routerLinkActive="active"
                >SIGN IN</a
              >
            </p>
          </div>
        </div>
        <div class="signup-form">
          <h2 class="signuptitle">Sign Up</h2>

          <form [formGroup]="formGroup" (ngSubmit)="signUp(formGroup.value)">
            <div class="md-form md-outline form-lg">
              <input
                type="text"
                id="form-lg"
                [ngClass]="{
                  'is-invalid':
                    (isSubmitted && formControls.username.errors) ||
                    (formGroup.controls['username'].touched &&
                      formControls.username.errors)
                }"
                class="form-control form-control-lg input signUpInput"
                formControlName="username"
              />
              <label for="form-lg" class="signUpLabel"
                >Email or phone number</label
              >
              <div
                *ngIf="formControls.username.errors"
                class="invalid-feedback"
              >
                <div>{{ getErrorUsername() }}</div>
              </div>
            </div>
            <div class="md-form md-outline form-lg">
              <input
                type="password"
                id="form-lg1"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    (isSubmitted && formControls.password.errors) ||
                    (formGroup.controls['password'].touched &&
                      formControls.password.errors)
                }"
                class="form-control form-control-lg input signUpInput"
                formControlName="password"
              />
              <label for="form-lg1" class="signUpLabel">Password</label>
              <div
                *ngIf="formControls.password.errors"
                class="invalid-feedback"
              >
                <div>{{ getErrorPassword() }}</div>
              </div>
            </div>
            <p class="by-clicking-agree">
              By clicking Agree & Continue, you agree to the Happy Connect
              <a
                href="javascript:;"
                onclick="window.open('assets/document/HC Privacy Policy.pdf', '_blank');"
                >Privacy Policy</a
              >.
            </p>

            <div align="right">
              <button
                type="submit"
                class="
                  btn btn-primary btn-rounded
                  primary-backgroundcolor
                  agreeContinueBtn
                "
              >
                <span>Agree & Continue</span>
              </button>
            </div>
          </form>
          <div>
            <h4 class="or"><span>Or</span></h4>
            <button
              (click)="signInWithGoogle()"
              class="btn btn-light btn-rounded signUpGoogleBtn"
            >
              <img
                style="height: 25.09px; width: 174px"
                src="./assets/images/google.png"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
