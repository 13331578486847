<section class="profile-section">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-6" style="margin-top: 25px;">
                <div class="profile-image">
                  <img *ngIf="!this.userProfilePhoto" class="profile-profilepic" src="{{environment.userPhotoHost}}default-profile-200x150.png" />
                  <img *ngIf="this.userProfilePhoto" class="profile-profilepic" src="{{environment.userPhotoHost}}{{this.userProfilePhoto}}" />
<!--                    <img class="profile-profilepic" src="./assets/images/profile-square.jpg" />-->
                  <h1 class="profile-username">{{this.fname}}&nbsp;{{this.lname}}</h1>
                  <h6 class="profile-userInfo">{{this.jobTitle?this.jobTitle+',':''}}
                      <br>{{this.companyName}}</h6>
                    <div class="profile-followers">
                        <span>{{this.followersCount}}</span>
                        <span>Followers</span>
                        <span>{{this.followingCount}}</span>
                        <span>Following</span>
                        <span>{{this.appliedJobsCount}}</span>
                        <span>Jobs Applied</span>
                    </div>
                    <div>
<!--                        <a *ngIf="!requestedButton" (click)="follow()" class="btn btn-primary btn-rounded primary-backgroundcolor smallBtn profileEditProfileBtn"><span>FOLLOW</span></a>-->
<!--                        <a *ngIf="requestedButton" (click)="requested()" class="btn btn-primary btn-rounded primary-backgroundcolor smallBtn profileEditProfileBtn"><span>Requested</span></a>-->
<!--                        <a  (click)="following()" class="btn btn-primary btn-rounded primary-backgroundcolor smallBtn profileEditProfileBtn"><span>Following</span></a>-->
                      <div class="manage-request-button">
                        <button
                          *ngIf="this.followingStatus == null
                                        &&
                                        this.followerStatus == null"
                          (click)="onFollow()"
                          class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                          class="manage-accept-button">Follow</span>
                        </button>


                        <button
                          *ngIf="this.followingStatus ===
                                        'UNFOLLOW'
                                        &&
                                        this.followerStatus == null"
                          (click)="onUnfollowFollowing()"
                          class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                          class="manage-accept-button">UNFOLLOW</span>
                        </button>
                        <button
                          *ngIf="this.followingStatus
                                        === 'Pending'"
                          (click)="onCancelFollowingRequest()"
                          class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                          class="manage-accept-button">Cancel
                                            Request</span>
                        </button>
                        <button *ngIf="this.followingStatus === null
                                        && this.followerStatus === 'Follower'"
                                (click)="onFollow()"
                                class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                          class="manage-accept-button">FOLLOW
                                            BACK</span>
                        </button>

                        <button *ngIf="this.followingStatus ===
                                        'UNFOLLOW'
                                        "
                                (click)="onMessage(basicProfiles.id)"
                                class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                          class="manage-accept-button">Message</span>
                        </button>

                        <button *ngIf="this.followingStatus ===
                                        'Following'
                                        &&
                                        this.followerStatus == null"
                                (click)="onMessage(basicProfiles.id)"
                                class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                          class="manage-accept-button">Message</span>
                        </button>

                        <button *ngIf="this.followingStatus == null
                                        &&
                                        this.followerStatus === 'Follower'"
                                (click)="onMessage(basicProfiles.id)"
                                class="btn btn-blue-border btn-sm
                                        btn-rounded with-icon accept-btn"><span
                          class="manage-accept-button">Message</span>
                        </button>

                        <a class="btn btn-default btn-sm btn-rounded with-icon">
                          <span>
                                <img *ngIf="this.openForHiring" class="onlineIcon" src="./assets/images/online.png"/>
                               <img *ngIf="!this.openForHiring" class="onlineIcon" src="./assets/images/online1.png"/>

                            OPEN FOR HIRING</span></a>
                      </div>

                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div style="position:relative;">
                    <img src="./assets/images/blue-dot.png" class="profile-coverimage-upper-dot" />
                    <img src="./assets/images/blue-dot.png" class="profile-coverimage-bottom-dot" />
                  <img *ngIf="!this.userBackgroundPhotoPath" src="./assets/images/profilecoverimage.jpeg" class="profile-coverimage" />
                  <img *ngIf="this.userBackgroundPhotoPath" src="{{environment.userPhotoHost}}{{this.userBackgroundPhotoPath}}" class="profile-coverimage" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="profile-detailedInfo">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-lg-9">
                <div class="card profileCard profileInfo">
                    <div class="card-body">
                        <h3 class="profile-aboutTitle">About</h3>
                        <p class="profile-aboutDesc">{{this.aboutUser}}</p>
<!--                        <p class="profile-aboutDesc">I have in-depth experience of setting up Design Teams from ground up and managing them with efficient processes and strong people management skills.</p>-->
<!--                        <p class="profile-aboutDesc">I love to take a project from concept to launch and beyond, integrating insights from end users, clients, team membe...<a href="">See More</a></p>-->
                    </div>
                </div>
                <!-- Experience -->

                <div class="card profileCard" style="border: none !important;" >
                    <div class="card-body">
                        <label class="profile-aboutTitle">Experience</label>
                        <div class="row" *ngFor="let proInfo of DesignationAndCompanyName">
                            <div class="col-md-2 col-lg-2">
                                <img src="./assets/images/ibm.png" class="profile-experience-image" />
                            </div>
                            <div class="col-md-9 col-lg-9">
                                <div class="profile-experience-position">{{proInfo.jobTitle}}</div>
                                <div class="profile-experience-company">{{proInfo.companyName}}</div>
                                <div class="profile-experience-date">{{proInfo.startDate | date: 'MMM y'}} - Present | 2.6 Years</div>
                                <div class="profile-experience-city">{{proInfo.jobLocationCity}}</div>
                                <div class="profile-experience-desc">{{proInfo.description}}</div>
                            </div>
                            <div class="col-md-1 col-lg-1">
                            </div>
                        </div>
<!--                        <hr class="horizontal_line">-->
<!--                        <div class="row">-->
<!--                            <div class="col-md-2 col-lg-2">-->
<!--                                <img src="./assets/images/hitachi.png" class="profile-experience-image" />-->
<!--                            </div>-->
<!--                            <div class="col-md-9 col-lg-9">-->
<!--                                <div class="profile-experience-position">Lead UI/UX Designer (Product)</div>-->
<!--                                <div class="profile-experience-company">IBM</div>-->
<!--                                <div class="profile-experience-date">Feb 2018 - Present | 2.6 Years</div>-->
<!--                                <div class="profile-experience-city">Pune, Maharastra</div>-->
<!--                            </div>-->
<!--                            <div class="col-md-1 col-lg-1">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <hr class="horizontal_line">-->
<!--                        <div class="row">-->
<!--                            <div class="col-md-2 col-lg-2">-->
<!--                                <img src="./assets/images/netflix.png" class="profile-experience-image" />-->
<!--                            </div>-->
<!--                            <div class="col-md-9 col-lg-9">-->
<!--                                <div class="profile-experience-position">Lead UI/UX Designer (Product)</div>-->
<!--                                <div class="profile-experience-company">IBM</div>-->
<!--                                <div class="profile-experience-date">Feb 2018 - Present | 2.6 Years</div>-->
<!--                                <div class="profile-experience-city">Pune, Maharastra</div>-->
<!--                                <div class="profile-experience-desc">Core Responsibilities include managing and providing direction to a team of UI/UX & Graphics designers. Working with teams across the globe to deliver customer facing experiences through digital - web/mobile (UI/UX), print-->
<!--                                    and social media.<a href="">See More</a></div>-->
<!--                                <div class="profile-experience-internal-journey">INTERNAL JOURNEY</div>-->
<!--                                <div class="vertical-line">-->
<!--                                    <div class="profile-experience-internal-company">-->
<!--                                        <div class="profile-experience-position" style="margin-left:24px;">Lead UI/UX Designer (Product)</div>-->
<!--                                        <div class="profile-experience-date" style="margin-left:24px;">Feb 2018 - Present | 2.6 Years</div>-->
<!--                                        <div class="profile-experience-city" style="margin-left:24px;">Pune, Maharastra</div>-->
<!--                                    </div>-->
<!--                                    <div class="profile-experience-internal-company">-->
<!--                                        <div class="profile-experience-position" style="margin-left:24px;">Lead UI/UX Designer (Product)</div>-->
<!--                                        <div class="profile-experience-date" style="margin-left:24px;">Feb 2018 - Present | 2.6 Years</div>-->
<!--                                        <div class="profile-experience-city" style="margin-left:24px;">Pune, Maharastra</div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-md-1 col-lg-1">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <hr class="horizontal_line">-->
<!--                        <div class="row">-->
<!--                            <div class="col-md-2 col-lg-2">-->
<!--                                <img src="./assets/images/siemens.png" class="profile-experience-image" />-->
<!--                            </div>-->
<!--                            <div class="col-md-9 col-lg-9">-->
<!--                                <div class="profile-experience-position">Lead UI/UX Designer (Product)</div>-->
<!--                                <div class="profile-experience-company">IBM</div>-->
<!--                                <div class="profile-experience-date">Feb 2018 - Present | 2.6 Years</div>-->
<!--                                <div class="profile-experience-city">Pune, Maharastra</div>-->
<!--                            </div>-->
<!--                            <div class="col-md-1 col-lg-1">-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
                <!-- Education-->

                <div class="card profileCard" style="border: none !important;">
                    <div class="card-body">
                        <h3 class="profile-aboutTitle">Education</h3>
<!--                        <div class="row">-->
<!--                            <div class="col-md-2 col-lg-2">-->
<!--                                <div class="profile-education-image-background">-->
<!--                                    <img src="./assets/images/university.png" class="profile-education-image" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-md-9 col-lg-9">-->
<!--                                <div class="profile-experience-position">Name of Institute</div>-->
<!--                                <div class="profile-experience-date">Name of Degree</div>-->
<!--                                <div class="profile-experience-city">2004 - 2008</div>-->
<!--                            </div>-->
<!--                            <div class="col-md-1 col-lg-1">-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="row" *ngFor="let education of educationalInfo">
                            <!--Removed margin bottom 24px-->
                            <div class="col-md-2 col-lg-2">
                                <div class="profile-education-image-background">
                                    <img src="./assets/images/university.png" class="profile-education-image" />
                                </div>
                            </div>

                            <div class="col-md-9 col-lg-9">
                                <div class="profile-experience-position">{{education.instituteUniversityName}}</div>
                                <div class="profile-experience-date">{{education.certificateDegreeName}}</div>
                                <div class="profile-experience-city">{{education.startDate | date:'y'}} - {{education.completionDate | date: 'y'}}</div>
                            </div>
                            <div class="col-md-1 col-lg-1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3">
                <div class="card profileCard profileInfoCompletion">
                    <app-add-box></app-add-box>
                </div>
            </div>
        </div>
    </div>
</section>

