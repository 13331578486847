import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import {SocketService} from '../../services/socket-service/socket.service';
import * as socketConfig from '../../services/socket-service/socket-config';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {ApiCallService} from '../../services/api-call-svc/api-call.service';
import {ArraySortPipePipe} from '../../pipes/array-sort-pipe.pipe'
import { Router } from '@angular/router';

@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss']
})
export class ChatboxComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  stompClient:any;
  connectedUsers:any=[];
  s3PhotoHost = environment.userPhotoHost;
  showChatBox:boolean = false;
  toUserId;
  toUserName;
  toUserPic;
  msgArray = {};
  userMsg = '';
  userTypingStatus=false;
  isSubscriptionDone = false;
  showLoader = true;
  @Input() isMessgaeWindow = false;
  showChatInMsgBox = false;
  isSocketSubscribed=false;
  @Input() slaveObj;
  @Input() chatWindowInstanceId;
  openChatForUser;
  constructor(private socketService: SocketService, public authService: AuthenticationService, public apiCall: ApiCallService,private router: Router) {
    const _this = this;
     this.stompClient = this.socketService.stompClient;
     console.log('chat constructor called');  

    this.socketService.reloadChatWindow.subscribe((obj:any)=>{
     // console.log('reload window',_this.chatWindowInstanceId,obj);
      if(_this.chatWindowInstanceId == obj.chatWinInstace){
        _this.msgArray = {};
        _this.toUserName = obj.slaveObj.userName;
        _this.toUserId = obj.slaveObj.userId;
        _this.toUserPic = obj.slaveObj.pic;
        _this.showLoader = true;
        setTimeout(()=>{
          _this.stompClient.send(socketConfig.GET_CHAT_HISTORY_EVENT, {}, JSON.stringify({'fromUserId': _this.authService.getUserId()
          ,'toUserId':_this.toUserId})); 
        },500);  
      }
    })

     let interval = setInterval(()=>{
        if(_this.socketService.socketConnected){
          clearInterval(interval);
          _this.subscribeSockets(_this);
        }
     },100)
    //_this.unsuscribe(_this);
             
   }

  ngOnInit(): void {  
    this.connectedUsers = [];
    this.msgArray = {};
    if(this.slaveObj){
      this.toUserName = this.slaveObj.userName;
      this.toUserId = this.slaveObj.userId;
      this.toUserPic = this.slaveObj.pic;
      setTimeout(()=>{
        this.stompClient.send(socketConfig.GET_CHAT_HISTORY_EVENT, {}, JSON.stringify({'fromUserId': this.authService.getUserId()
        ,'toUserId':this.toUserId})); 
      },500);
       
    } else{
      this.subscribeSockets(this);
      this.socketService.getUserList();
      let currentUrl = this.router.url;
      this.openChatForUser = currentUrl.substring(currentUrl.lastIndexOf('/')+1, currentUrl.length);
    }
  }

  openChatBox(user){
    //get chat history
    //console.log('open Chat Box....');
    this.showLoader = true;
    this.toUserName = user.userName;
    this.toUserId = user.userId;
    this.toUserPic = user.pic;
    user.unreadMessageCount = 0;
    this.showChatBox = true;    
    this.stompClient.send(socketConfig.GET_CHAT_HISTORY_EVENT, {}, JSON.stringify({'fromUserId': this.authService.getUserId()
    ,'toUserId':this.toUserId}));    
  }

  openMsgBox(user){
    user.unreadMessageCount = 0;
    this.apiCall.subscribeMessagesEvents.next(user);
  }

  sendPrivateMessage() {
   // console.log('send private message..');
    this.stompClient.send(socketConfig.SEND_PRIVATE_MESSAGE_EVENT, {}, JSON.stringify({'fromUserId': this.authService.getUserId()
    ,'toUserId':this.toUserId,'message':this.userMsg}));
  }

  sendHeartBeat(){
    this.stompClient.send(socketConfig.SEND_HEART_BEAT_EVENT, {}, JSON.stringify({'fromUserId': this.authService.getUserId()
    }));
  }

  onEnter() {
    this.sendPrivateMessage();
  }

  onKeydown(event) {
    if (event.key != "Enter") {
      this.stompClient.send(socketConfig.SEND_TYPING_EVENT, {}, JSON.stringify({'fromUserId': this.authService.getUserId()
      ,'toUserId':this.toUserId}));
    }
  }
  
  scrollToBottom(): void {
    setTimeout(()=>{
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { } 
    },100); 
                    
  }

  subscribeSockets(_this){
    
    
     let interval = setInterval(()=>{
       if(_this.stompClient){
        clearInterval(interval);
        _this.socketService.onSendHeartBeat.subscribe(() => {
          //console.log('heartBeat event subscribed');
          _this.sendHeartBeat();
        });
        _this.stompClient.subscribe(socketConfig.CONNECTED_USERS_SUBSCRIPTION, function (users) {
          _this.connectedUsers = JSON.parse(users.body);
          if(!_this.slaveObj && _this.openChatForUser){
            let user = _this.connectedUsers.filter((obj)=>{
              return obj.userId.toString() == _this.openChatForUser
            });
            console.log( _this.connectedUsers, _this.openChatForUser, user);
            if(user && user.length > 0){
              _this.openChatBox(user[0]);
            }
          }
         }); 
    
         _this.stompClient.subscribe(socketConfig.RESPONSE_SUBSCRIPTION, function (calResult) {
           // console.log('inside 2');
            let calObj=JSON.parse(calResult.body);
            let fromUser= calObj["fromUserId"];
              if(_this.msgArray[fromUser]){
                _this.msgArray[fromUser].push(calObj);
              } else{
                _this.msgArray[fromUser]= [calObj];
              } 
              _this.connectedUsers.forEach((user) => {
                if(user.userId == fromUser){
                  user.lastChatFromId = fromUser;
                  user.lastChatMessage = calObj["message"];
                  user.lastChatTime = calObj["time"];
                  if(_this.toUserId == fromUser){
                    _this.stompClient.send(socketConfig.SEND_READ_ALL_MSG_EVENT, {}, JSON.stringify({'fromUserId': _this.authService.getUserId()
                    ,'toUserId':_this.toUserId}));
                  } else{
                    user.unreadMessageCount = user.unreadMessageCount + 1;
                  }
                }
              });           
        });
    
        _this.stompClient.subscribe(socketConfig.CHAT_HISTORY_SUBSCRIPTION, function (calResult) {
          // console.log('inside 3'); 
           _this.showLoader = false;
           let calArray=JSON.parse(calResult.body);
           if(calArray && _this.toUserId){
             calArray.forEach(calObj => {            
              if((calObj.fromUserId == _this.authService.getUserId() && calObj.toUserId == _this.toUserId) || 
              (calObj.fromUserId == _this.toUserId && calObj.toUserId == _this.authService.getUserId())){
                if(_this.msgArray[_this.toUserId]){
                  _this.msgArray[_this.toUserId].push(calObj);
                } else{
                  _this.msgArray[_this.toUserId]= [calObj];
                }
              }    
             });
             _this.scrollToBottom();
           }                   
        });
    
        _this.stompClient.subscribe(socketConfig.TYPING_SUBSCRIPTION, function (calResult) {
        //  console.log('inside typing subs...') 
          let calObj=JSON.parse(calResult.body);
          let fromTypeUser= calObj["fromUserId"];
          if(fromTypeUser == _this.toUserId){
            _this.userTypingStatus = true;         
            if(_this.userTypingStatus){
              _this.scrollToBottom();
              setTimeout(()=>{_this.userTypingStatus=false;},1000);
            }
          }      
       });
    
        _this.stompClient.subscribe(socketConfig.ACK_SUBSCRIPTION, function (calResult) {
         //  console.log('inside 4') 
           _this.userMsg = '';
           let calObj=JSON.parse(calResult.body);
           let toUser= calObj["toUserId"];
             if(_this.msgArray[toUser]){
               _this.msgArray[toUser].push(calObj);
             } else{
               _this.msgArray[toUser]= [calObj];
             }

            _this.connectedUsers.forEach((user) => {
              if(user.userId == toUser){
                user.lastChatFromId = _this.authService.getUserId();
                user.lastChatMessage = calObj["message"];
                user.lastChatTime = calObj["time"];
              }
            });
             _this.scrollToBottom();       
        });
    
        _this.stompClient.subscribe(socketConfig.GET_STATUS_SUBSCRIPTION, function (calResult) {
         // console.log('inside 4')        
          if(_this.connectedUsers && _this.connectedUsers.length > 0){
            let calObjArray=JSON.parse(calResult.body);
            let usrObj;
            calObjArray.forEach(calObj => {
              usrObj = _this.connectedUsers.filter((obj)=>obj.userId == calObj.userId);
              if(usrObj){
                usrObj[0].status = calObj.status
              }
            });
          }
       });
       }
     },100);
    
  }

  unsuscribe(_this){
    if(_this.stompClient){
       _this.socketService.onSendHeartBeat.unsubscribe();

    _this.stompClient.unsubscribe(socketConfig.CONNECTED_USERS_SUBSCRIPTION);          
     _this.stompClient.unsubscribe(socketConfig.RESPONSE_SUBSCRIPTION);
    _this.stompClient.unsubscribe(socketConfig.CHAT_HISTORY_SUBSCRIPTION);
    _this.stompClient.unsubscribe(socketConfig.TYPING_SUBSCRIPTION);
    _this.stompClient.unsubscribe(socketConfig.ACK_SUBSCRIPTION);
    _this.stompClient.unsubscribe(socketConfig.GET_STATUS_SUBSCRIPTION);
    }
  }

}
