import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'app-videobox',
  templateUrl: './videobox.component.html',
  styleUrls: ['./videobox.component.scss']
})
export class VideoboxComponent implements AfterViewInit, OnInit, OnDestroy {
  public vjs: any;
  @Input() urlVideo: string;
  @Input() urlPoster: string;
  @Input() id: string;
  constructor() { }
  
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngAfterViewInit() {
   // console.log('vdo ngAfterViewInit')
    const options = {
      'sources' : [{
        'src' : this.urlVideo,
        'type' : 'video/mp4'
        }
      ],
      'poster' : this.urlPoster
    };
   
   this.vjs = videojs('videoplayer_'+this.id, options);

  }

  ngOnInit(){
    //console.log('vdo ngOnInit');
  }

  ngOnDestroy(){
    this.vjs.dispose();
  }

}
